import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import { Collapse } from "@mui/material";
import { convertTimestampToDate } from "../../util/util";
import Pagination from "@mui/material/Pagination";
import HospitalInstrumentDetail from "../../components/mudracomponents/HospitalInstrumentDetail";

import "../../css/User.css";
import Lock from "../../components/Lock";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const InstrumentInHospital = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [hospitalList, setHospitalList] = useState([]);
  const [fitleredHospitalList, setFilteredHospitalList] = useState([]);
  const [system_no, setSystem_no] = useState(null);
  const [zone_name, setZoneName] = useState("all");
  const [openDetail, setOpenDetail] = useState(-1);
  const [isReset, setIsReset] = useState(null);
  const [instrumentCategory, setInstrumentCategory] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [productionYear, setProductionYear] = useState(null);
  const [productionMonth, setProductionMonth] = useState(null);
  const [verifiedBy, setVerifiedBy] = useState(null);
  const [instrumentId, setInstrumentId] = useState(null);
  const [mudraList, setMudraList] = useState([]);

  const showDetailIndex = (index) => {
    setOpenDetail((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      //getMudraList();
      instrumentListInHospital();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  // const getMudraList = () => {
  //   const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
  //   httpGetReq(url, "application/json", (response) => {
  //     if (response.data && response.data.status === "success") {
  //       setMudraList(response.data.data);
  //     } else {
  //       console.error("Error fetching instrument list:", response.data.message);
  //     }
  //   });
  // };

  const instrumentListInHospital = () => {
    let url = `${config.baseUrl}${config.ver}${config.instrumentInHospital}?limit=${limitPerPage}&page=${currentPage}`;
    if (system_no != null && system_no != "") {
      url += "&system_no=" + system_no;
    }
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setHospitalList(response.data.hospitals);
        // setTotalRecords(response.data.total);
        // setDataInPage(response.data.data.length);
        // setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const onChangeHosptialZone = (zone) => {
    let hospital_search_url = `${config.baseUrl}${config.ver}${config.hospitalList}?`;
    if (zone !== "all") {
      hospital_search_url += `area_zone=${zone}`;
    }
    httpGetReq(hospital_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setFilteredHospitalList(response.data.data);
        } else {
          setFilteredHospitalList([]);
        }
      }
    });
  };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
          <h4>Page is under Development and Testing...</h4>{" "}
          <div className="searchnoheader-bar-container">
            <div className="search-bar-keep-right"
              style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", }}
            >
              <select
                style={{ width: 160 }}
                className="search-bar-input-dropdown"
                value={zone_name}
                onChange={(e) => {
                  setZoneName(e.target.value);
                  onChangeHosptialZone(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  Select Area Zone
                </option>
                {ConVar.hospitalZone.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                className="search-bar-input-dropdown"
                value={system_no}
                onChange={(e) => {
                  setSystem_no(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  Select Hospital
                </option>
                {fitleredHospitalList.length ? (
                  fitleredHospitalList.map((option) => (
                    <option key={option._id} value={option.system_no}>
                      {option.name}-{option.location}
                      {" : "}
                      {option.system_no}
                    </option>
                  ))
                ) : (
                  <option key={"no_2353"} value={"No hospital"}>
                    {"Oops! No System Installed yet🫠"}
                  </option>
                )}
              </select>
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  instrumentListInHospital();
                }}
                startIcon={<SearchIcon />}
              >
                <SearchIcon />
              </button>
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setSystem_no("");
                  instrumentListInHospital();
                }}
              >
                <RestartAltRoundedIcon />
              </button>
            </div>
            <div className="pagination-setup">
              Page
              <Pagination
                className="pagination-setup-button"
                count={totalPages}
                color="primary"
                page={currentPage}
                onChange={(event, value) => {
                  setCurrentPage(value);
                }}
              />
            </div>
          </div>
          <div className="property-list-container">
            <table className="property-table">
              <thead>
                <tr>
                  <th>Hospital</th>
                  <th>Count</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="property-table-body">
                {hospitalList.map((row, index) => (
                  <React.Fragment key={row._id}>
                    <tr>
                      <td className="r10start">{row._id}</td>
                      <td className="r20">{row.count}</td>
                      <td className="r20end">
                        <button onClick={() => showDetailIndex(index)}>
                          {openDetail === index ? (
                            <ExpandLessIcon className="chevron-right-icon" />
                          ) : (
                            <ExpandMore className="expand-more-icon" />
                          )}
                        </button>
                      </td>
                    </tr>
                    {openDetail === index && (
                      <tr className="inner-property-expand">
                        <td colSpan="6" className="inner-details-expand-styling">
                          <Collapse in={openDetail === index}>
                            <HospitalInstrumentDetail system_no={row._id} />
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    );
  } else {
    return <Lock />;
  }
};
