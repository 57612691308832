import React from "react";
import { saveAs } from "file-saver";
import { Document, Page, pdf, Text } from "@react-pdf/renderer";
import Lock from "../Lock";
import ServiceReport from "./ServiceReport";
export const ServiecReportContainer = (props) => {
  const generatePDF = () => {
    const MyDocument = () => (
      <Document>
        <Page size="A4">
          <Text>Hello, this is a PDF!</Text>
        </Page>
      </Document>
    );

    pdf(<MyDocument />)
      .toBlob()
      .then((blob) => {
        saveAs(blob, "service report.pdf");
      });
  };

  if (true) {
    return (
      <div className="main-data-container">
        <h4>Page is under Development and Testing...</h4>
        {/* <button onClick={generatePDF}>Generate PDF</button> */}
        <ServiceReport />
      </div>
    );
  } else {
    return <Lock />;
  }
};
