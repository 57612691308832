import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import { convertTimestampToDate } from "../../util/util";
import "../../css/CommonStyle.css";

const DetailInstrument = (props) => {
  const [instrumentDetail, setInstrumentDetail] = useState();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMudraDetail();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getMudraDetail = () => {
    let url =
      config.baseUrl + config.ver + config.instrument + props.insrumentId;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        setInstrumentDetail(response.data.data);
      }
    });
  };

  if (instrumentDetail) {
    return (
      <div className="detail-container">
        <div className="detail-container-maindata"> <label>Last Hospital :</label> {instrumentDetail.present_system_no || ""} </div>
        <div className="detail-container-maindata"> <label>Unique ID :</label> {instrumentDetail.instrument_unique_no || ""} </div>
        <div className="detail-container-maindata"> <label>Name :</label> {instrumentDetail.label || ""} </div>
        <div className="detail-container-maindata"> <label>Parts No :</label> {instrumentDetail.parts_no || ""} </div>
        <div className="detail-container-maindata"> <label>No of Use :</label> {instrumentDetail.uses_count || ""} </div>

        <div className="detail-container-realtimedata">
          <div className="section-title">Hospital History:</div>
          {instrumentDetail.hospitals.map((vdx, index) => (
            <div key={index}>
              <div> {vdx.system || ""} on {convertTimestampToDate(vdx.date)} </div>
              <div>Remain Use : {vdx.uses_count ? vdx.uses_count : "NA"}</div>
            </div>
          ))}
        </div>

        <div className="detail-container-realtimedata">
          <div className="section-title">All Journey :</div>
          {instrumentDetail.journey.map((vdx, index) => (
            <div key={index}>
              <div> {vdx.system || ""} {vdx.issue || ""} {vdx.description || ""} on {convertTimestampToDate(vdx.date)} </div>
            </div>
          ))}
        </div>

        <div className="detail-container-realtimedata">
          <div className="section-title">All Issues :</div>
          {instrumentDetail.issues.map((vdx, index) => (
            <div key={index} className="issue-item">
              <div> <strong>Issue :</strong> {vdx.issue || ""} | {vdx.description || ""} on {convertTimestampToDate(vdx.date)} </div>
              <div> <strong>Preventive Maintenance :</strong> {vdx.is_prventive_maintenance ? "YES" : "NO"} </div>
              <div> <strong>Claved :</strong> {vdx.auto_claved ? "YES" : "NO"} </div>
              <div> <strong>Service Request :</strong> {vdx.service_type} </div>
            </div>
          ))}
        </div>

        <div className="detail-container-realtimedata">
          <div className="section-title">Surgeries:</div>
          {instrumentDetail.surgeries.map((vdx, index) => (
            <div key={index}>
              <div>
                {vdx.system || ""} | {vdx.case_no || ""} |
                {vdx.surgery_specification || ""} | {vdx.patient_id || ""} on
                {convertTimestampToDate(vdx.date)}
              </div>
            </div>
          ))}
        </div>

        <div className="detail-container-realtimedata">
          <div className="section-title">Finished Services:</div>
          {instrumentDetail.finishedServices.map((vdx, index) => (
            <div key={index} className="finished-service-item">
              <div> <strong>Date :</strong> {vdx.date ? convertTimestampToDate(vdx.date) : "N/A"} </div>
              <div> <strong>Label :</strong> {vdx.label || ""} </div>
              <div> <strong>Previous System No :</strong> {vdx.present_system_no || ""} </div>
              <div> <strong>Production Date :</strong> {vdx.production_date ? convertTimestampToDate(vdx.production_date) : "N/A"} </div>
              <div> <strong>Tested By :</strong> {vdx.tested_by || ""} </div>
              <div> <strong>Rework Category :</strong> {vdx.rework_category || ""} </div>
              <div> <strong>Rework Action :</strong> {vdx.rework_action || ""} </div>
              <div> <strong>Job Card Number :</strong> {vdx.job_card_number || ""} </div>
              <div> <strong>Internal Observation :</strong> {vdx.internal_observation || ""} </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <div className="loading">Loading.....🫠</div>;
  }
};

export default DetailInstrument;
