import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link, useLocation } from "react-router-dom";
import { ClearCookie } from "../../util/util";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import SutraLogo from "../../images/SutraLogo5776EA.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Face6RoundedIcon from "@mui/icons-material/Face6Rounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

const Header = (props) => {
  const userInfo = props.userInfo;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const handleFullscreenClick = () => {
    openFullscreen();
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove(config.authCookieName);
    ClearCookie(config.authCookieName);
    window.location.reload(true);
    window.history.replaceState({}, document.title, "/");
    window.location.replace("/login");
  };

  return (
    <>
      <div className="header">
        <div className="left-side">
          <img src={SutraLogo} alt="Logo" />
          <span className="ssi-sutra" onClick={handleFullscreenClick}>
            SSi Sutra
          </span>

          {location.pathname.includes("/callgenerate") && (
            <div className="middle-side">
              <button className={`header-buttons ${ location.pathname.includes("allcalls") ? "active" : "" }`}
                onClick={() => (window.location.href = "/callgenerate/allcalls") }
              >
                All Calls
              </button>
              <button className={`header-buttons ${ location.pathname.includes("alltasks") ? "active" : "" }`}
                onClick={() => (window.location.href = "/callgenerate/alltasks") }
              >
                All Tasks
              </button>
            </div>
          )}

          {location.pathname.includes("/mudra/service") && (
            <div className="middle-side">
              <button className={`header-buttons ${ location.pathname.includes("in-service") ? "active" : "" }`}
                onClick={() => (window.location.href = "/mudra/service/in-service") }
              >
                In-Service
              </button>
              <button className={`header-buttons ${ location.pathname.includes("service-request") ? "active" : "" }`}
                onClick={() => (window.location.href = "/mudra/service/service-request") }
              >
                Service Requests
              </button>
            </div>
          )}

        </div>
        <div className="right-side">
          <div className="header-name-area">
            <label className="user-header-name">
              {userInfo.name ? userInfo.name : ""}
            </label>
            <label className="user-type">
              {ConVar.userTypeVal[userInfo.user_type]
                ? ConVar.userTypeVal[userInfo.user_type]
                : ""}
            </label>
          </div>
          <Face6RoundedIcon className="header-icon" onClick={handleMenuClick} />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="header-menu-button"
            style={{ top: "5px", left: "-35px" }}
          >
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/schedulecalendar"
            >
              <CalendarMonthRoundedIcon style={{ marginRight: "10px" }} />
              Calendar
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/Profile">
              <AccountCircleRoundedIcon style={{ marginRight: "10px" }} />
              Edit Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <LogoutRoundedIcon style={{ marginRight: "10px" }} />
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default Header;
