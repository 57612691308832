import React, { useEffect, useState } from "react";
import "../../css/ServiceReport.css";
import { ServiceReportData } from "./ServiceReportData";
import { httpGetReq, httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { connect } from "react-redux";
import { fetchUsers } from "../../store/actionCreator";
import ServiceReportPDF from "./ServiceReportPDF";

const ServiceReport = () => {
  const [formData, setFormData] = useState({});
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    getHospitalList();
    getServiceList();
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem("serviceReportData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData.formData || {});
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Autosaving...");
      handleAutoSave();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [formData]);

  const handleAutoSave = () => {
    const savedData = {
      formData: formData,
    };
    localStorage.setItem("serviceReportData", JSON.stringify(savedData));
  };

  const handleResetting = () => {
    setFormData({});
    localStorage.removeItem("serviceReportData");
    window.location.reload();
  };

  const getHospitalList = () => {
    const url = config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setHospitalList(response.data.data);
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  const getServiceList = () => {
    const url = config.baseUrl + config.ver + config.serviceList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setServiceList(response.data.data);
      } else {
        console.error("Error fetching Service Report list:", response.data.message);
      }
    });
  };

  const handleDownloadPDF = () => {
    ServiceReportPDF(formData);
  };

  const handlePostResponse = (response) => {
    if (response && response.data && response.data.status === "success") {
      setMessage("Service Report submitted successfully");
      setWarning("");
      handleResetting();
    } else if (response && response.data && response.data.status === "fail") {
      setWarning(response.data.message);
      setMessage("");
    }
  };

  const handleSubmit = async () => {
    const sendServiceReportURL = config.baseUrl + config.ver + config.serviceNew;
    try {
      const response = await httpPostReq(
        sendServiceReportURL,
        await transformFormData(formData),
        "application/json",
        handlePostResponse
      );
      if (response && response.data && response.data.status === "success") {
        setMessage("Service Report submitted successfully");
        setWarning("");
        handleResetting();
      } else if (response && response.data && response.data.status === "fail") {
        setWarning(response.data.message);
        setMessage("");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };  

  const transformFormData = (data) => {
    const selectedHospital = hospitalList.find((hospital) => hospital._id === data.hospital_name);
    const serviceListLength = serviceList.length + 1;
    const report_no = `SSI/${selectedHospital.system_no}/${serviceListLength}`;

    return {
      customer: {
        customer: data.hospital_name,
        date: new Date().getTime(),
        address: data.address,
        state: data.state,
        pin: parseInt(data.pin),
        name: data.contactName,
        mobile: data.mobile,
        email: data.email,
      },
      report_no: report_no,
      visit: data.visitType,
      equipment_status: data.equipmentStatus,
      issue_reported: {
        issueReport: data.issue_report,
        call_reported_by: data.callReportedBy,
        system_usable: data.systemUsable === "YES" ? true : false,
        next_surgery_schedule_date: new Date().getTime(),
        location_of_system_ot_number: data.locationOfSystemOTNumber,
      },
      equipment: {
        description: data.description,
        model_no: data.modelNo,
        sr_no: data.srNo,
        physical_damage: data.physicalDamage === "YES" ? true : false,
        major_finding: data.majorFinding,
        serial_no: data.srNo,
        remarks: data.modalremarks,
      },
      services_rendered: data.servicesRendered,
      engineer_remarks: data.engineerRemarks,
      onsite_repair: {
        parts_replaced: data.partsReplacement && data.partsReplacement.partsReplaced === "YES" ? true : false,
        parts_replaced_detail: data.partsReplacedDetails,
        parts_required: data.partsReplacement && data.partsReplacement.partsRequired === "YES" ? true : false,
        parts_required_detail: data.partsRequiredDetails,
      },
      safety_checks_inspection: {
        site_voltage: {
          ln: parseInt(data.ln),
          ng: parseInt(data.ng),
          lg: parseInt(data.lg),
        },
        test: {
          test: data.testResult,
          report_attached: data.reportAttached === "YES" ? true : false,
        },
        power_supply_through: data.powerSupplyThrough,
        other_finding: {
          temperature: parseInt(data.temperature),
          humidity: parseInt(data.humidity),
        },
      },
      status_after_service_visit: data.statusAfterServiceVisit,
      customer_feedback: {
        customerFeedback: data.customerfeedback,
        customerRemarks: data.customerremarks,
      },
      engineerandcustomerSign: {
        engineer_sign: data.engineerSignature,
        customer_sign: data.customerSignature,
      },
    };
  };

  const renderField = (fieldKey, fieldData) => {
    const fieldValue = formData[fieldKey] || "";

    const handleChange = (e) => {
      const { name, value } = e.target;

      if (fieldKey === "zone_id") {
        const selectedZone = value;
        handleZoneChange(selectedZone);
      }

      if (fieldKey === "hospital_name") {
        const selectedHospital = hospitalList.find(
          (hospital) => hospital._id === value
        );
        setFormData((prevData) => ({
          ...prevData,
          hospital_name: value,
          hospital_id: selectedHospital ? selectedHospital._id : null,
          state: selectedHospital ? selectedHospital.state : "",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    };

    const handleZoneChange = (selectedZone) => {
      const filteredHospitals = hospitalList.filter(
        (hospital) => hospital.area_zone === selectedZone
      );
      setFormData((prevData) => ({
        ...prevData,
        zone_id: selectedZone,
        hospital_name: "",
        filteredHospitals: filteredHospitals,
      }));
    };

    if (fieldKey === "zone_id") {
      return (
        <select
          value={fieldValue}
          onChange={handleChange}
          required={fieldData.required}
          className="form-select"
        >
          <option value="">Select Zone</option>
          {ConVar.hospitalZone.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    if (fieldKey === "hospital_name" && formData.filteredHospitals) {
      return (
        <select
          value={fieldValue}
          onChange={handleChange}
          required={fieldData.required}
          className="form-select"
        >
          <option value="">Select Hospital</option>
          {formData.filteredHospitals.map((hospital, index) => (
            <option key={index} value={hospital._id}>
              {hospital.name}, {hospital.location} ({hospital.system_no})
            </option>
          ))}
        </select>
      );
    }

    switch (fieldData.inputType) {
      case "text":
      case "email":
      case "datetime-local":
        return (
          <input
            type={fieldData.inputType}
            name={fieldKey}
            value={fieldValue}
            onChange={handleChange}
            required={fieldData.required}
            className="form-input"
          />
        );
      case "textarea":
        return (
          <textarea
            name={fieldKey}
            value={fieldValue}
            onChange={handleChange}
            required={fieldData.required}
            className="form-textarea"
          />
        );
      case "number":
        return (
          <input
            type="number"
            name={fieldKey}
            value={fieldValue}
            onChange={handleChange}
            required={fieldData.required}
            className="form-input"
          />
        );
      case "radio":
        return (
          <div className="radio-group-container">
            {fieldData.options.map((option, index) => (
              <div key={index} className="radio-group">
                <input
                  type="radio"
                  id={`${fieldKey}_${index}`}
                  name={fieldKey}
                  value={option}
                  checked={fieldValue === option}
                  onChange={handleChange}
                  required={fieldData.required}
                  className="radio-input"
                />
                <label htmlFor={`${fieldKey}_${index}`} className="radio-label">
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderSubGroup = (subGroupData) => {
    return (
      <div key={subGroupData.label} className="subgroup">
        <h4>{subGroupData.label}</h4>
        {subGroupData.fields.map((field, index) => (
          <div key={index} className="field-group-subgroup">
            <label htmlFor={field.key} className="field-label-subgroup">
              {field.label}
            </label>
            {renderField(field.key, field)}
          </div>
        ))}
      </div>
    );
  };

  const renderGroup = (groupData) => {
    return (
      <div key={groupData.label} className="group">
        <h3>{groupData.label}</h3>
        {groupData.fields.map((field, index) => (
          <div key={index} className="field-group">
            {field.type === "subgroup" ? (
              renderSubGroup(field)
            ) : (
              <>
                <label htmlFor={field.key} className="field-label">
                  {field.label}
                </label>
                {renderField(field.key, field)}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderMenu = () => {
    return Object.values(ServiceReportData).map((groupData, index) => (
      <div key={index}>
        {groupData.type === "group" ? renderGroup(groupData) : null}
      </div>
    ));
  };

  return (
    <div className="service-report-box">
      <div className="service-report">{renderMenu()}</div>
      <div className="button-service-report">
        <button
          type="button"
          onClick={handleSubmit}
          className="addproperty-button"
        >
          Submit
        </button>
        <button
          type="button"
          onClick={handleResetting}
          className="cancel-button"
        >
          Reset
        </button>
        <button type="button" onClick={handleDownloadPDF} className="addproperty-button">
          Download
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceReport);
