let mode = "stage"; // 'prod' | 'stage'
// 172.16.5.227 gyan ip
// 172.16.3.189 rohan ip

const config = {
  baseUrl: mode === "stage" ? "http://20.235.118.162:8080" : "http://172.16.5.227:8080",
  // baseUrl: mode === "stage" ? "http://118.139.165.4:8080" : "http://172.16.5.227:8080",
  // baseUrl: mode === "stagegyan" ? "http://20.235.118.162:8080" : "http://172.16.5.227:8080",
  // baseUrl: mode === "stagerohan" ? "http://20.235.118.162:8080" : "http://172.16.3.189:8080",

  ver: "/api/1.0",
  login: "/user/login",
  changepassword: "/user/changepassword",
  auth: "/user/auth/",
  roles: "/role/list",
  authCookieName: "_sutra",
  activityLog: "/activity/new",

  userDetail: "/user/",
  userList: "/user/list",
  userNew: "/user/new",
  userEdit: "/user/edit",

  taskNew: "/task/new",
  taskAssign: "/task/assign",
  taskStart: "/task/start/",
  taskEnd: "/task/end/",
  myTaskList: "/task/my-task/",
  taskDetail: "/task/detail/",

  callNew: "/call/new",
  callToTask: "/call/totask/",
  callList: "/call/list",
  taskList: "/task/list/",

  callTotal: "/call/total-call",
  callActive: "/call/total-active",
  callAssigned: "/call/total-assigned",

  taskClose: "/task/total-closed",
  taskActive: "/task/total-active",
  taskOpen: "/task/total-open",

  mudraList: "/mudra/list/",
  mudraNew: "/mudra/new/",

  downloadSurgeries: "/surgeries/download/filtered-surgeries",
  surgeries: "/surgeries/list",
  surgeriesEdit: "/surgeries/edit/",
  surgeriesInsrument: "/surgeries/instrument-list",
  surgeriesDetail: "/surgeries/detail/",
  surgeriesNew: "/surgeries/new/",
  surgeryBySystemNo: "/surgeries/number-of-surgery-by-system/",
  procedure: "/procedure/",
  procedureEdit: "/procedure/edit/",
  procedureList: "/procedure/list",

  surgeriesYearWise: "/surgeries/surgery-by-year",
  surgeryByHospital: "/surgeries/surgery-in-the-hospital",
  allProcedureInHospital: "/surgeries/all-procedure-in-hospital",
  topTenHospitalInSurgeries: "/surgeries/top-ten-hospital-by-surgeries",

  topTenProcedurebyMantra: "/surgeries/top-ten-procedures-by-count",
  typeofprocedure: "/surgeries/types-of-procedures-by-count",
  typeofprocedureDownload: "/surgeries/types-of-procedures-by-count/download",

  surgeonNew: "/surgeon/new",
  surgeonList: "/surgeon/list",

  hospitalList: "/hospital/list",
  todayMaintenanceList: "/maintenance/list",
  test: "/maintenance/testupdate/",
  allMaintenance: "/maintenance/list/fort-night/all",

  hostpitalDetail: "/hospital/",
  hostpitalNew: "/hospital/new/",

  mantarUnits: "/mantra/list",
  mantarSubUnits: "/mantra-sub/list",

  timeTesting: "/surgeries/testing", //ROHAN-TESTING
  scheduleTesting: "/schedule/new", //ROHAN-TESTING

  warehouse: "/instrument/warehouse",
  instrument: "/instrument/",
  inHospital: "/instrument/inhospital",
  discarded: "/instrument/discard",
  service: "/instrument/service",

  totalInstrument: "/instrument/total-instrument",
  totalWarehouse: "/instrument/total-warehouse",
  totalInHospital: "/instrument/total-inhospital",
  totalInService: "/instrument/total-service",
  totalInDiscard: "/instrument/total-discard",
  totalMinor: "/instrument/total-minor",

  instrumentList: "/instrument/list",
  instrumentNew: "/instrument/new",
  sendHospital: "/instrument/send-for-hospital/",
  sendHospitalAgain: "/instrument/send-for-hospital-again/",
  instrumentInHospital: "/instrument/hospital-store",
  sendService: "/instrument/send-for-service/",
  sendScrap: "/instrument/send-for-scrap/",
  instrumentInHospitalDetail: "/instrument/hospital-store-detail",
  instrumentAvailableInHospital: "/instrument/available/hospital/",
  instrumentUse: "/instrument/used/",
  serviceRequested: "/instrument/service-request-list",
  sendServiceRequest: "/instrument/send-for-service-request/",
  acceptServiceRequest: "/instrument/accept-for-service/",
  finishService: "/instrument/service-finished/",

  serviceNew: "/service-report/new",
  serviceList: "/service-report/list",
  serviceDetail: "/service-report/detail/",
  deskList: "/desk/list",
  deskNew: "/desk/new",
};

export default config;
