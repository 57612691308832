import React, { useState, useEffect } from "react";
import AdminMenuItems from "./Menu/AdminMenuItems";
import ServiceEngineerMenuItem from "./Menu/ServiceEngineerMenuItem";
import ClinicalSupportMenuItems from "./Menu/ClinicalSupportMenuItem";
import { Divider, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MudraAdminMenuItem from "./Menu/MudraMenuItem";

const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("sidebarState") === "open"
  );

  useEffect(() => {
    localStorage.setItem("sidebarState", isOpen ? "open" : "closed");
  }, [isOpen]);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  let userTypeComponent;
  if (props.usertype === "admin") {
    userTypeComponent = <AdminMenuItems isOpen={isOpen} />;
  } else if (props.usertype === "service-engineer") {
    userTypeComponent = <ServiceEngineerMenuItem isOpen={isOpen} />;
  } else if (props.usertype === "clinical-support") {
    userTypeComponent = <ClinicalSupportMenuItems isOpen={isOpen} />;
  }else if (props.usertype === "mudra-admin") {
    userTypeComponent = <MudraAdminMenuItem isOpen={isOpen} />;
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="menu-toggle">
        <IconButton onClick={handleToggleSidebar}>
          <MenuIcon className="menu-icon" />
        </IconButton>
      </div>
      <Divider sx={{ paddingTop: "1px", marginBottom: 0, backgroundColor: "#007bff", opacity: "25%", }} />
      <div className="sidebar-content">{userTypeComponent}</div>
    </div>
  );
};

export default Sidebar;
