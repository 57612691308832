// frontend/src/components/Login.js
import React, { useState } from "react";
import config from "../../config/configUrl";
import { httpPostReq } from "../../util/request";

import Modal from "react-modal";
import SutraLogo from "../../images/SutraLogo.png";
import SSIFull from "../../images/SSIFullWhite.png";
import MayaLogo from "../../images/MayaLogoName.png";
import RickRoll from "../../images/Rickrolling_QR_code.png";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import { ic_admin_panel_settings } from "react-icons-kit/md/ic_admin_panel_settings";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import "./Login.css";

import { getOS } from "../../util/util";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [error, setError] = useState("");
  const [buttonShake, setButtonShake] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let loginUrl = config.baseUrl + config.ver + config.login;

  const handleLogin = async () => {
    httpPostReq(
      loginUrl,
      {
        emp_email: username,
        password: password,
      },
      "application/json",
      (response) => {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "fail"
        ) {
          setError("Invalid username or password");
          setButtonShake(true);
        } else if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          onLogin(response.data.data);
        } else {
          console.log(response.message);
          setError("Maybe it's.. " + response.message + "🤷‍♂️");
          setButtonShake(true);
        }
      }
    );
  };

  const handleContactAdmin = () => {
    setModalIsOpen(true);
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const resetButtonShake = () => {
    setButtonShake(false);
  };

  // if("Android" == getOS()){
  //   return (
  //     <div className="outer-container-mob">
  //       <div className="login-container">
  //         <div >
  //           <div >
  //             <label htmlFor="username">Login ID</label>
  //             <input
  //               type="text"
  //               id="username"
  //               placeholder="Enter Mail ID/ SSI-000"
  //               value={username}
  //               onChange={(e) => setUsername(e.target.value)}
  //               className="input-field username-input"
  //             />

  //             <label htmlFor="password">Password</label>

  //             <div className="password-field">
  //               <input
  //                 type={type}
  //                 name="password"
  //                 placeholder="Password"
  //                 value={password}
  //                 onChange={(e) => setPassword(e.target.value)}
  //                 autoComplete="current-password"
  //                 className="input-field password-input"

  //               />
  //               <span
  //                 className="flex justify-around items-center icon"
  //                 onClick={handleToggle}
  //                 onTouchEnd={handleToggle}
  //               >
  //                 <Icon icon={icon} size={20} />
  //               </span>
  //             </div>
  //             <div className="login-button">

  //               <button
  //                 onClick={handleLogin}
  //                 onTouchEnd={handleLogin}
  //                 className={`login-button ${buttonShake ? "shake" : ""}`}
  //                 onAnimationEnd={resetButtonShake}
  //               >
  //                 <Icon icon={arrowRight} size={40} />
  //               </button>
  //             </div>
  //           </div>
  //           {error && <p className="error-message">{error}</p>}
  //         </div>
  //       </div>
  //       <div className="center-text">
  //         <div className="logo-bottom">
  //           <img src={SSIFull} alt="Logo" />
  //           <span className="separator">|</span>
  //           <img src={MayaLogo} alt="MayaLogo" />
  //         </div>
  //         <p>SSi Sutra Portal</p>
  //       </div>
  //       <Modal
  //         isOpen={modalIsOpen}
  //         onRequestClose={() => setModalIsOpen(false)}
  //         contentLabel="Example Modal"
  //       >
  //         <img src={RickRoll} alt="Logo" />
  //         <div className="contact-admin-link-content">
  //           <p>Contact Admin</p>
  //         </div>
  //       </Modal>
  //     </div>
  //   );
  // }else if ("Windows" == getOS())
  {
    return (
      <div className="outer-container">
        <div className="login-container">
          <div className="logo">
            <img src={SutraLogo} alt="Logo" />
            <div className="login-container-text">
              <p>Kindly,</p> <p>enter your</p>
              <p> details</p>
            </div>
          </div>
          <div className="inner-box">
            <div className="bottom-text"></div>
            <div className="form-fields">
              <label htmlFor="username">Login ID</label>
              <br />
              <input
                type="text"
                id="username"
                placeholder="Enter Mail ID/ SSI-000"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input-field username-input"
              />
              <br />
              <label htmlFor="password">Password</label>
              <br />
              <div className="password-field">
                <input
                  type={type}
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  className="input-field password-input"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) handleLogin();
                  }}
                />
                <span
                  className="flex justify-around items-center icon"
                  onClick={handleToggle}
                >
                  <Icon icon={icon} size={20} />
                </span>
              </div>
              <div className="login-button">
                <div
                  className="contact-admin-link"
                  onClick={handleContactAdmin}
                >
                  <Icon icon={ic_admin_panel_settings} size={20} />
                  Contact Admin
                </div>
                <button
                  onClick={handleLogin}
                  className={`login-button ${buttonShake ? "shake" : ""}`}
                  onAnimationEnd={resetButtonShake}
                >
                  <Icon icon={arrowRight} size={40} />
                </button>
              </div>
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
        <div className="center-text">
          <div className="logo-bottom">
            <img src={SSIFull} alt="Logo" />
            <span className="separator">|</span>
            <img src={MayaLogo} alt="MayaLogo" />
          </div>
          <p>SSi Sutra Portal</p>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Example Modal"
        >
          <img src={RickRoll} alt="Logo" />
          <div className="contact-admin-link-content">
            <p>Contact Admin</p>
          </div>
        </Modal>
      </div>
    );
  }
};

export default Login;
