import React, { useState, useEffect } from "react";
import { httpGetReq } from "../../util/request";
import { connect } from "react-redux";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import Pagination from "@mui/material/Pagination";
import { convertTimestampToDate } from "../../util/util";
import { fetchProcedureDetails, fetchUsers, fetchHospitals, } from "../../store/actionCreator";

import "../../css/AllSurgeries.css";
import "../../css/CommonStyle.css";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const SurgeryInstrumentList = (props) => {
  let procedureName = {}, hospitalName = {};
  const [suregeriesLlist, setSuregeriesLlist] = useState([]);
  const [filterHospitalList, setFilterHospitalList] = useState([]);
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [hospital, setHospital] = useState();
  const [procedure, setProcedure] = useState();
  const [surgeryYear, setSurgeryYear] = useState();
  const [searchDependency, setSearchDependency] = useState(null);
  const [mudraList, setMudraList] = useState([]);

  if (props.procedures && props.procedures.length) {
    props.procedures.forEach((val) => {
      procedureName[val.procedure_code] = val.procedure_name;
    });
  }

  if (props.hospitals && props.hospitals.length) {
    props.hospitals.forEach((val) => {
      hospitalName[val.system_no] = val.name;
    });
  }

  if (props.procedures.length === 0) {
    props.fetchProcedureDetails();
  }

  if (props.hospitals.length === 0) {
    props.fetchHospitals();
  }

  if (Object.keys(props.surgeons).length === 0) {
    props.fetchSurgeonDetails();
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllSurgeries();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, searchDependency]);

  const getAllSurgeries = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.surgeriesInsrument}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (procedure !== "all" && procedure !== undefined) {
      if (url.includes("?")) {
        url += "&procedure_code=" + procedure;
      } else {
        url += "?procedure_code=" + procedure;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setSuregeriesLlist(response.data.data);
          setTotalRecords(response.data.total);
          setDataInPage(response.data.data.length);
          setTotalPages(Math.ceil(response.data.total / limitPerPage));
        }
      }
    });
  };

  const getFilteredHospitals = (arg) => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?area_zone=" + arg;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setFilterHospitalList(response.data.data);
        }
      }
    });
  };

  const downloadDataCSV = () => {
    let array = [];
    let obj_ = {};
    obj_["Date Of Surgery"] = "Date Of Surgery";
    obj_["System ID"] = "System ID";
    obj_["Name"] = "Name";
    obj_["Instrument Used"] = "Instrument Used";
    obj_["Instrument Issue"] = "Instrument Issue";
    array.push(obj_);
    suregeriesLlist.forEach((kobj) => {
      let obj = {};
      obj["Date Of Surgery"] = convertTimestampToDate(kobj.date_of_surgery);
      obj["System ID"] = kobj.system_no;
      obj["Name"] = hospitalName[kobj.system_no] ? hospitalName[kobj.system_no] : "";
      obj["Instrument Used"] = instrumentUsedToString(kobj.instruments.used);
      obj["Instrument Issue"] = instrumentIssuedToString( kobj.instruments.issues );
      array.push(obj);
    });

    // Convert array of objects to CSV string
    const csvContent = "data:text/csv;charset=utf-8," +
      array
        .map((obj) => {
          return Object.values(obj)
            .map((value) => '"' + value + '"')
            .join(",");
        })
        .join("\n");

    // Create a dummy anchor element to initiate the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "instrument_surgery_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  // const instrumentUsedToString = (arg) => {
  //   let retval = "";
  //   if (arg && Array.isArray(arg)) {
  //     arg.forEach((obj, idx) => {
  //       // let instrumentId = obj.instrument_id ? obj.instrument_id : "";
  //       let instrumentName = obj.instrument_used ? obj.instrument_used : "";
  //       // if (instrumentId !== '') {
  //       //   retval += instrumentId +' , ';
  //       // }
  //       if (instrumentName !== "") {
  //         retval += instrumentName;
  //         if (idx < arg.length - 1) {
  //           retval += ", ";
  //         }
  //       }
  //     });
  //   }
  //   return retval;
  // };

  const instrumentIssuedToString = (arg) => {
    let retval = "";
    if (arg && Array.isArray(arg)) {
      arg.forEach((obj, idx) => {
        let issue = obj.instrument_issue ? obj.instrument_issue : obj;
        retval += issue;
        if (idx < arg.length - 1) {
          retval += ", ";
        }
      });
    }
    return retval;
  };

  const getInstrumentDisplayString = (usedInstruments, mudraList) => {
    let retval = "";
    if (usedInstruments && Array.isArray(usedInstruments)) {
      usedInstruments.forEach((obj, idx) => {
        const matchingMudra = mudraList.find(mudra => mudra.key === obj.instrument_used);
        const instrumentShort = matchingMudra ? matchingMudra.short : obj.instrument_used;
        const instrumentDisplay = `${instrumentShort}-${obj.instrument_id}`;
        const cleanedDisplay = instrumentDisplay.replace("-undefined", "").trim();
  
        if (cleanedDisplay) {
          if (retval) {
            retval += ", ";
          }
          retval += cleanedDisplay;
        }
      });
    }
    return retval;
  };  

  const getMudraList = () => {
    const url = `${config.baseUrl}${config.ver}${config.mudraList}?limit=1000`;
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };
  
  useEffect(() => {
    getMudraList();
  }, []);

  const instrumentUsedToString = (usedInstruments) => {
    return getInstrumentDisplayString(usedInstruments, mudraList);
  };

  return (
    <div className="main-data-container">
      <div key="1" className="searchnoheader-bar-container">
        <div className="search-bar-keep-right">
          <select
            value={zone_name}
            onChange={(event) => {
              setZoneName(event.target.value);
              getFilteredHospitals(event.target.value);
            }}
            style={{ width: 150 }}
            className="search-bar-input-dropdown"
          >
            <option key={""} value={"all"}> Zone </option>
            {ConVar.hospitalZone.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className="search-bar-input-dropdown"
            value={hospital}
            onChange={(e) => {
              setHospital(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Select Hospitals </option>
            {filterHospitalList.map((option) => (
              <option key={option.label} value={option.system_no}>
                {option.name}
              </option>
            ))}
          </select>
          <select
            label="Procedure"
            className="search-bar-input-dropdown"
            value={procedure}
            onChange={(e) => {
              setProcedure(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Select Procedure </option>
            {props.procedures.map((option, idx) => (
              <option
                key={option.procedure_code + idx}
                value={option.procedure_code}
              >
                {option.procedure_code}-{option.procedure_name}
              </option>
            ))}
          </select>
          <select
            value={surgeryYear}
            className="search-bar-input-dropdown"
            style={{ width: 100 }}
            onChange={(e) => {
              setSurgeryYear(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Year </option>
            {ConVar.surgeryYear.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSearchDependency(searchDependency + 1);
              getAllSurgeries();
            }}
            startIcon={<SearchIcon />}
          >
            <SearchIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSurgeryYear("all");
              setProcedure("all");
              setHospital("all");
              setSearchDependency(searchDependency + 1);
              getAllSurgeries();
            }}
          >
            <RestartAltRoundedIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              downloadDataCSV();
            }}
          >
            <DownloadRoundedIcon />
            <span style={{ marginLeft: "2px" }}></span>
          </button>
        </div>
        <div className="pagination-setup">
          Page
          <Pagination
            className="pagination-setup-button"
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
          />
        </div>
      </div>
      <div className="propertynoheader-list-container">
        <table className="propertynoheader-table">
          <thead>
            <tr>
              <th>Hospital</th>
              <th>Date of Surgery</th>
              <th>Surgery Specification</th>
              <th>Instrument Used</th>
              <th>Instrument Issues</th>
            </tr>
          </thead>
          <tbody className="propertynoheader-table-body">
            {suregeriesLlist.length ? (
              <>
                {suregeriesLlist.map((row) => (
                  <>
                    <tr key={row._id}>
                      <td className="r10start" title={ hospitalName[row.system_no] ? hospitalName[row.system_no] : "" } > {row.system_no} </td>
                      <td className="r10"> {convertTimestampToDate(row.date_of_surgery)} </td>
                      <td className="r20"> {row.surgery_name ? row.surgery_name : row.surgery_specification} </td>
                      <td className="r30"> {instrumentUsedToString(row.instruments.used)} </td>
                      <td className="r20end"> {instrumentIssuedToString(row.instruments.issues)} </td>
                    </tr>
                  </>
                ))}
              </>
            ) : (
              <div className="no-match-found">No Match Found 😒😁</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
    fetchProcedureDetails: (callback) =>
      fetchProcedureDetails(dispatch, callback),
    fetchHospitals: (callback) => fetchHospitals(dispatch, callback),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )(SurgeryInstrumentList);
