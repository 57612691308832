import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import config from "../../config/configUrl";
import Stack from "@mui/material/Stack";
import { httpGetReq } from "../../util/request";
const Surgeon = (props) => {
  const limitPerPage = 10;
  const [value, setValue] = React.useState(0);
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const fetchSurgeons = async () => {
    try {
      let url = `${config.baseUrl}${config.ver}${config.surgeonList}?limit=${limitPerPage}&page=${currentPage}`;
      httpGetReq(url, "application/json", function (response) {
        if (response && response.data) {
          if (response.data.available > 0) {
            setTasks(response.data.data);
            setTotalPages(Math.ceil(response.data.total / limitPerPage));
            setNoDataFound(response.data.data === 0);
            setExpandedRows([]);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  useEffect(() => {
    fetchSurgeons();
  }, [currentPage]);
  return (
    <Box>
      <div>
        <table className="custom-table">
          <thead>
            <tr>
              <th className="table-header">Surgeon Name</th>
              <th className="table-header">Area Expertise</th>
              <th className="table-header">Hospital</th>
              <th className="table-header">Zone ID</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <React.Fragment key={task._id}>
                <tr>
                  <td>{task.surgeon_name}</td>
                  <td>{task.area_of_expertise}</td>
                  <td>{task.aligned_hospitals.toString()}</td>
                  <td>{task.surgeonId}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            color="primary"
            onChange={(e, value) => {
              setCurrentPage(value);
            }}
          />
        </Stack>
      </div>
    </Box>
  );
};

export default Surgeon;
