import React, { useEffect, useState } from "react";
import { convertTimestampToDate, millisecondsToTime } from "../../util/util";
import { httpGetReq } from "../../util/request";
import config from "../../config/configUrl";

const ServiceReportDetails = ({ serviceId }) => {
  const [serviceDetail, setServiceDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSurgeryDetail = () => {
      let url = config.baseUrl + config.ver + config.serviceDetail + serviceId;
      httpGetReq(url, "application/json", function (response) {
        if (response) {
          if (
            response.data &&
            response.data.status &&
            response.data.status === "success"
          ) {
            setServiceDetail(response.data.data);
          } else {
            setError("Failed to fetch surgery details");
          }
          setLoading(false);
        } else {
          setError("Failed to fetch surgery details");
          setLoading(false);
        }
      });
    };
    getSurgeryDetail();
    return () => {};
  }, [serviceId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="inner-row-surgery-details">
      <div className="container-details-row">
        <span className="surgery-detail-span">
          <label>Customer Id:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.customer.customer ? serviceDetail.customer.customer : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Customer Name:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.customer.name ? serviceDetail.customer.name : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Issue Reported:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.issue_reported.call_reported_by ? serviceDetail.issue_reported.call_reported_by : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>OT Number:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.issue_reported.location_of_system_ot_number ? serviceDetail.issue_reported.location_of_system_ot_number : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Description:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.equipment.description ? serviceDetail.equipment.description : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Part Model No.:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.equipment.model_no ? serviceDetail.equipment.model_no : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Physical Damage:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.equipment.physical_damage ? serviceDetail.equipment.physical_damage : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Major Finding:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.equipment.major_finding ? serviceDetail.equipment.major_finding : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Serial No.:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.equipment.serial_no ? serviceDetail.equipment.serial_no : "" }
            disabled
          />
        </span>




        {/* <span className="surgery-detail-span">
          <label>BMI:</label>
          <input
            type="text"
            value={ serviceDetail && serviceDetail.bmi ? parseFloat(serviceDetail.bmi.toPrecision(4)) : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Date of Surgery:</label>
          <input
            type="text"
            value={ serviceDetail.date_of_surgery && serviceDetail.date_of_surgery ? convertTimestampToDate(serviceDetail.date_of_surgery) : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Surgery Duration:</label>
          <input
            type="text"
            value={ serviceDetail.surgery && serviceDetail.surgery && serviceDetail.surgery.duration ? millisecondsToTime(serviceDetail.surgery.duration) : "" }
            disabled
          />
        </span>
        <span className="surgery-detail-span">
          <label>Instrument Used:</label>
          <input
            type="text"
            value={ serviceDetail.instruments && serviceDetail.instruments.used ? serviceDetail.instruments.used.toString() : "" }
            disabled
          />
        </span> */}
      </div>
    </div>
  );
};

export default ServiceReportDetails;
