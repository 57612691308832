import React, { useState } from "react";
import { httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";

import { Dialog } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import "../../css/AddProcedure.css";
import "../../css/CommonStyle.css";

const AddDesks = ({ open, onClose }) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [deskName, setProcedureName] = useState("");
  const [deskCode, setProcedureCode] = useState("");
  const [deskType, setProcedureCategory] = useState("");
  const [label, setLabel] = useState("");
  const [key, setKey] = useState("");
  const [detail, setDetail] = useState("");
  const [processSteps, setProcessSteps] = useState([]);

  const handleSubmit = async () => {
    if (!deskName || !deskCode) {
      setWarning("Please fill in all fields for the procedure.");
      return;
    }
    const addProcedureUrl = config.baseUrl + config.ver + config.deskNew ;
    const requestData = {
      desk_name: deskName,
      desk_type: deskType,
      desk_code: deskCode.toUpperCase(),
      process_steps: processSteps,
    };
    httpPostReq(
      addProcedureUrl,
      requestData,
      "application/json",
      (response) => {
        if (response.data && response.data.status === "success") {
          setMessage("Procedure added successfully");
          setWarning("");
          onClose();
        } else if (response.data && response.data.status === "fail") {
          setWarning(response.data.message);
          setMessage("");
        }
      }
    );
  };

  const handleAddStep = () => {
    if (!label || !key || !detail) {
      alert("Please fill in all fields before adding a new step.");
      return;
    }

    const newStep = {
      label: label,
      key: key,
      detail: detail,
    };

    setProcessSteps([...processSteps, newStep]);
    setLabel("");
    setKey("");
    setDetail("");
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = [...processSteps];
    updatedSteps.splice(index, 1);
    setProcessSteps(updatedSteps);
  };

  return (
    <Dialog open={open} onClose={onClose} className="addproperty-dialog">
      {warning && <div className="error-message">{warning}</div>}
      {message && <div className="success-message">{message}</div>}
      <div className="addproperty-container">
        <div className="addproperty-form-title">Add Desk</div>
        <div className="addproperty-form">
          <div className="addproperty-form-control">
            <label>Desk Name</label>
            <input
              className="addproperty-text-field"
              type="text"
              placeholder="Enter Procedure Name"
              value={deskName}
              onChange={(e) => setProcedureName(e.target.value)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Desk Code</label>
            <input
              className="addproperty-text-field"
              type="text"
              placeholder="Enter Procedure Code"
              value={deskCode}
              onChange={(e) => setProcedureCode(e.target.value.toUpperCase())}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Desk Type</label>
            <select
              className="addproperty-select"
              value={deskType}
              onChange={(e) => {
                setProcedureCategory(
                  e.target.value.toLowerCase().replace(/\s+/g, "_")
                );
                setProcedureCategory(e.target.value);
              }}
            >
              <option key="" value="Select Category">
                Select Category
              </option>
              {ConVar.deskType.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="addproperty-form-control">
            <label>Steps Name</label>
            <input
              className="addproperty-text-field"
              type="text"
              placeholder="Procedure Step Label"
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
                setKey(e.target.value.toLowerCase().replace(/\s+/g, "_"));
              }}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Step Code</label>
            <input
              className="addproperty-text-field"
              type="text"
              placeholder="Step Code"
              value={key}
              disabled
            />
          </div>
          <div className="addproperty-form-control">
            <label>Description</label>
            <input
              className="addproperty-form-textarea"
              type="text"
              placeholder="Description"
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />
            <button className="add-step-button" onClick={handleAddStep}>
              <AddRoundedIcon />
            </button>
          </div>
        </div>

        {processSteps.length > 0 && (
          <div className="surgery-steps-container">
            {processSteps.map((stepObj, index) => (
              <div className="surgery-step" key={index}>
                <div className="surgery-step-info">
                  <div className="addproperty-form-update-group">
                    <label className="addproperty-form-update-label">
                      Name
                    </label>
                    <input
                      className="addproperty-form-update-input"
                      type="text"
                      placeholder="Name"
                      value={stepObj.label}
                      disabled
                      onChange={(e) => {
                        const updatedSteps = [...processSteps];
                        updatedSteps[index].label = e.target.value;
                        setProcessSteps(updatedSteps);
                      }}
                    />
                  </div>
                  <div className="addproperty-form-update-group">
                    <label className="addproperty-form-update-label">Code</label>
                    <input
                      className="addproperty-form-update-input"
                      type="text"
                      placeholder="Code"
                      value={stepObj.key}
                      disabled
                      onChange={(e) => {
                        const updatedSteps = [...processSteps];
                        updatedSteps[index].key = e.target.value;
                        setProcessSteps(updatedSteps);
                      }}
                    />
                  </div>
                  <div className="addproperty-form-update-group">
                    <label className="addproperty-form-update-label">
                      Description
                    </label>
                    <textarea
                      className="addproperty-form-update-textarea"
                      type="text"
                      placeholder="Description"
                      value={stepObj.detail}
                      disabled
                      onChange={(e) => {
                        const updatedSteps = [...processSteps];
                        updatedSteps[index].detail = e.target.value;
                        setProcessSteps(updatedSteps);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveStep(index)}
                  >
                    <ClearRoundedIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="addproperty-dialog-action">
          <button className="addproperty-button" onClick={handleSubmit}>
            Submit
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddDesks;
