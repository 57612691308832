import React, { useState } from "react";
import { httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";

import { Dialog } from "@mui/material";
import "../../css/AddMudra.css";
import "../../css/CommonStyle.css";

const AddMudra = ({ open, onClose }) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [keyId, setKeyId] = useState("");
  const [shortForm, setShortForm] = useState("");
  const [partsNo, setPartsNo] = useState("");
  const [category, setCategory] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  function convertToSnakeCase(inputString) {
    return inputString.toLowerCase().split(" ").join("_");
  }
  function abbreviate(inputString) {
    const words = inputString.split(" ");
    const abbreviation = words.map((word) => word[0]).join("");
    return abbreviation.toUpperCase();
  }
  const handleSubmit = async () => {
    const url_new_mudra = config.baseUrl + config.ver + config.mudraNew;
    const postBody = {
      key: keyId,
      parts_no: partsNo,
      label: name,
      short: shortForm,
      short_desc: shortDescription,
      long_desc: longDescription,
      category: category,
    };

    console.log(postBody);

    httpPostReq(url_new_mudra, postBody, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMessage("Mudra added successfully");
        setWarning("");
        onClose();
      } else if (response.data && response.data.status === "fail") {
        setWarning(response.data.message);
        setMessage("");
      }
    });
  };
  return (
    <Dialog open={open} onClose={onClose} className="addproperty-dialog">
      {warning && <div className="error-message">{warning}</div>}
      {message && <div className="success-message">{message}</div>}
      <div className="addproperty-container">
        <div className="addproperty-form-title">Add Mudra Instrument</div>
        <div className="addproperty-form">
          <div className="addproperty-form-control">
            <label>Name</label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setKeyId(convertToSnakeCase(e.target.value));
                setShortForm(abbreviate(e.target.value));
              }}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Key</label>
            <input
              type="text"
              placeholder="Key"
              disabled
              value={keyId}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Short Form</label>
            <input
              type="text"
              placeholder="Short Form"
              disabled
              value={shortForm}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Part No</label>
            <input
              type="text"
              placeholder="Parts No"
              value={partsNo}
              onChange={(e) => setPartsNo(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div fullWidth className="addproperty-form-control">
            <label>Category</label>
            <select
              onChange={(e) => setCategory(e.target.value)}
              className="addproperty-select"
            >
              <option key={""} value={""}>
                Select Category
              </option>
              {ConVar.instrumentCategory.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="addproperty-form-control">
            <label>Short Description</label>
            <input
              type="text"
              placeholder="Short Description"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Long Description</label>
            <input
              type="text"
              placeholder="Long Description"
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
        </div>
        <div className="addproperty-dialog-action">
          <button
            type="button"
            onClick={handleSubmit}
            className="addproperty-button"
          >
            Submit
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddMudra;
