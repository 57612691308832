import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import "../../css/EditProcedure.css";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

const EditDesks = ({ procedureDetail }) => {
  const procedureLongId = procedureDetail._id;
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [procedureName, setProcedureName] = useState("");
  const [procedureCode, setProcedureCode] = useState("");
  const [procedureCategory, setProcedureCategory] = useState("");
  const [label, setLabel] = useState("");
  const [key, setKey] = useState("");
  const [detail, setDetail] = useState("");
  const [surgerySteps, setSurgerySteps] = useState([]);

  useEffect(() => {
    if (procedureDetail) {
      setProcedureName(procedureDetail.procedure_name);
      setProcedureCode(procedureDetail.procedure_code);
      setProcedureCategory(procedureDetail.procedure_category);
      setSurgerySteps(procedureDetail.process_steps);
    }
  }, [procedureDetail]);
 
  const handleSubmit = async () => {
    if (!procedureName || !procedureCode) {
      setWarning("Please fill in all fields for the procedure.");
      return;
    }
    const updateProcedureUrl = `${config.baseUrl}${config.ver}${config.procedureEdit}${procedureLongId}`;
    const requestData = {
      procedure_name: procedureName,
      procedure_category: procedureCategory,
      procedure_code: procedureCode.toUpperCase(),
      process_steps: surgerySteps,
    };

    httpPostReq(
      updateProcedureUrl,
      requestData,
      "application/json",
      (response) => {
        if (response.data && response.data.status === "success") {
          setMessage("Procedure updated successfully");
          setWarning("");
          setOpen(false);
        } else if (response.data && response.data.status === "fail") {
          setWarning(response.data.message);
          setMessage("");
        }
      }
    );
  };

  const handleAddStep = () => {
    if (!label || !key || !detail) {
      alert("Please fill in all fields before adding a new step.");
      return;
    }

    const newStep = {
      label: label,
      key: key,
      detail: detail,
    };

    setSurgerySteps([...surgerySteps, newStep]);
    setLabel("");
    setKey("");
    setDetail("");
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = [...surgerySteps];
    updatedSteps.splice(index, 1);
    setSurgerySteps(updatedSteps);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <>
      <button className="red-button" onClick={() => setOpen(true)}>
        <EditTwoToneIcon />
      </button>
      <Dialog open={open} onClose={handleClose} className="addproperty-dialog" >
        {warning && <div className="error-message">{warning}</div>}
        {message && <div className="success-message">{message}</div>}
        {procedureDetail && (
          <div className="addproperty-container">
            <div className="addproperty-form-title">Edit Desk Info</div>
            <div className="addproperty-form">
              <div className="addproperty-form-control">
                <label> Desk Name *</label>
                <input
                  className="addproperty-text-field"
                  type="text"
                  value={procedureName}
                  onChange={(e) => setProcedureName(e.target.value)}
                />
              </div>
              <div className="addproperty-form-control">
                <label> Desk Code *</label>
                <input
                  className="addproperty-text-field"
                  type="text"
                  value={procedureCode}
                  onChange={(e) =>
                    setProcedureCode(e.target.value.toUpperCase())
                  }
                />
              </div>
              <div className="addproperty-form-control">
                <label> Desk Type</label>
                <select
                  className="addproperty-select"
                  value={procedureCategory}
                  onChange={(e) =>
                    setProcedureCategory(
                      e.target.value.toLowerCase().replace(/\s+/g, "_")
                    )
                  }
                >
                  {ConVar.procedureCategory.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <label className="editdetail-label">Kindly write the Brief Process  Step in the provided field. (Do Not Write `Step 1, Step 2`)</label>
              <div className="addproperty-form-control">
                <label>Steps Name</label>
                <input
                  className="addproperty-text-field"
                  type="text"
                  value={label}
                  placeholder="Surgery Step"
                  onChange={(e) => {
                    setLabel(e.target.value);
                    setKey(e.target.value.toLowerCase().replace(/\s+/g, "_"));
                  }}
                />
              </div>
              <div className="addproperty-form-control">
                <label>Steps Code</label>
                <input
                  className="addproperty-text-field"
                  placeholder="Key"
                  type="text"
                  value={key}
                  disabled
                />
              </div>
              <div className="addproperty-form-control">
                <label className="addproperty-form-label-detail"> Description </label>
                <input
                  className="addproperty-form-textarea"
                  type="textarea"
                  placeholder="Please explain the Description"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
                <button className="add-step-button" onClick={handleAddStep}>
                  <AddRoundedIcon />
                </button>
              </div>
            </div>

            {surgerySteps.length > 0 && (
              <div className="surgery-steps-container">
                {surgerySteps.map((stepObj, index) => (
                  <div className="surgery-step" key={index}>
                    <div className="surgery-step-info">
                      <div className="addproperty-form-update-group">
                        <label className="addproperty-form-update-label"> Label </label>
                        <input
                          className="addproperty-form-update-input"
                          type="text"
                          value={stepObj.label}
                          disabled
                          onChange={(e) => {
                            const updatedSteps = [...surgerySteps];
                            updatedSteps[index].label = e.target.value;
                            setSurgerySteps(updatedSteps);
                          }}
                        />
                      </div>
                      <div className="addproperty-form-update-group">
                        <label className="addproperty-form-update-label"> Key </label>
                        <input
                          className="addproperty-form-update-input"
                          type="text"
                          value={stepObj.key}
                          disabled
                          onChange={(e) => {
                            const updatedSteps = [...surgerySteps];
                            updatedSteps[index].key = e.target.value;
                            setSurgerySteps(updatedSteps);
                          }}
                        />
                      </div>
                      <div className="addproperty-form-update-group">
                        <label className="addproperty-form-update-label"> Detail </label>
                        <input
                          className="addproperty-form-update-textarea"
                          type="text"
                          value={stepObj.detail}
                          onChange={(e) => {
                            const updatedSteps = [...surgerySteps];
                            updatedSteps[index].detail = e.target.value;
                            setSurgerySteps(updatedSteps);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <button className="remove-button" onClick={() => handleRemoveStep(index)} > <ClearRoundedIcon /> </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="button-container">
              <button className="update-button" onClick={handleSubmit}> Update </button>
              <button className="cancel-button" onClick={() => setOpen(false)}> Cancel </button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default EditDesks;
