import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import config from "../../config/configUrl";
import Stack from "@mui/material/Stack";
import { httpGetReq } from "../../util/request";
const Clinical = (props) => {
  const limitPerPage = 10;
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const fetchClinicalUserList = async () => {
    try {
      let url =
        config.baseUrl +
        config.ver +
        config.userList +
        "?user_type=clinical-support&limit" +
        limitPerPage +
        "&page=" +
        currentPage;
      console.log("url", url);
      httpGetReq(url, "application/json", function (response) {
        if (response && response.data) {
          if (response.data.available > 0) {
            console.log(response.data);
            setTasks(response.data.data);
            setTotalPages(Math.ceil(response.data.available / limitPerPage));
            setNoDataFound(response.data.data === 0);
            setExpandedRows([]);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  useEffect(() => {
    fetchClinicalUserList();
  }, [currentPage]);
  return (
    <Box>
      <div>
        <table className="custom-table">
          <thead>
            <tr>
              <th className="table-header">Name</th>
              <th className="table-header">Email</th>
              <th className="table-header">Aligned Hospital</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <React.Fragment key={task._id}>
                <tr>
                  <td>{task.name}</td>
                  <td>{task.emp_email}</td>
                  <td>TEST</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            color="primary"
            onChange={(e, value) => {
              setCurrentPage(value);
            }}
          />
        </Stack>
      </div>
    </Box>
  );
};

export default Clinical;
