import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../css/Loader.css";
import myImage from "../images/loader.gif";

function Lock() {
  return (
    <div className="loader-container">
      <CircularProgress />
      {/* <img className='loader-image' src={myImage} alt="loader.." /> */}
    </div>
  );
}

export default Lock;
