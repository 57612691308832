import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq, httpPostReq } from "../../util/request";
import SendToService from "../../components/mudracomponents/SendToService";
import { MultiSelect } from "react-multi-select-component";
import "../../css/MudraDashboard.css";
import Lock from "../../components/Lock";

export const InstrumentUseContainer = (props) => {
  const [hospitalList, setHospitalList] = useState([]);
  const [fitleredHospitalList, setFilteredHospitalList] = useState([]);
  const [system_no, setSystem_no] = useState(null);
  const [zone_name, setZoneName] = useState("all");
  const [selectedInstrument, setSelectedInstrument] = useState([]);
  const [InstrumentOption, setInstrumentOption] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onChangeHosptialZone = (zone) => {
    let hospital_search_url = `${config.baseUrl}${config.ver}${config.hospitalList}?`;
    if (zone !== "all") {
      hospital_search_url += `area_zone=${zone}`;
    }
    httpGetReq(hospital_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setFilteredHospitalList(response.data.data);
        } else {
          setFilteredHospitalList([]);
        }
      }
    });
  };

  const handleSubmit = async () => {
    selectedInstrument.forEach((val) => {
      let useInstrumet =
        config.baseUrl + config.ver + config.instrumentUse + val.value;
      let postobj = {
        case_no: "caseNO",
        patient_id: "patientID",
        hospital: "hospitalname with id",
        surgery_specification: "SurgerySpecification",
        current_hospital: system_no,
        last_updated: Date.now(),
      };
      httpPostReq(useInstrumet, postobj, "application/json", (response) => {
        console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          console.log("failed to submit");
        }
      });
    });
  };

  const onChangeHospital = (system_no) => {
    let url = `${config.baseUrl}${config.ver}${config.instrumentAvailableInHospital}${system_no}`;
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let sgopt = [];
        response.data.data.forEach((element) => {
          sgopt.push({ label: element.label, value: element._id });
        });
        setInstrumentOption(sgopt);
      }
    });
  };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
        <h4>Page is under Development and Testing...</h4>
        <div style={{ padding: "10px 10px 10px 10px", margin: "10px 10px 10px 10px", border: "2px solid red", }} >
          <h5>Entry From Intrument Used in Surgery</h5>
          <div className="searchnoheader-bar-container">
            <div className="search-bar-keep-right"
              style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", }}
            >
              <select
                style={{ width: "auto" }}
                className="search-bar-input-dropdown"
                value={zone_name}
                onChange={(e) => {
                  setZoneName(e.target.value);
                  onChangeHosptialZone(e.target.value);
                }}
              >
                <option key={""} value={""}> Select Area Zone </option>
                {ConVar.hospitalZone.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                className="search-bar-input-dropdown"
                style={{ width: 300 }}
                value={system_no}
                onChange={(e) => {
                  setSystem_no(e.target.value);
                  onChangeHospital(e.target.value);
                }}
              >
                <option key={""} value={""}> Select Hospital </option>
                {fitleredHospitalList.length ? (
                  fitleredHospitalList.map((option) => (
                    <option key={option._id} value={option.system_no}>
                      {option.name}-{option.location}
                      {" : "}
                      {option.system_no}
                    </option>
                  ))
                ) : (
                  <option key={"no_2353"} value={"No hospital"}>
                    {"Oops! No System Installed yet🫠"}
                  </option>
                )}
              </select>
              <div style={{ width: 600 }}>
                <MultiSelect
                  hasSelectAll={false}
                  options={InstrumentOption}
                  value={selectedInstrument}
                  onChange={setSelectedInstrument}
                />
              </div>
              <button onClick={() => { handleSubmit(); }} >
                Surgery Submit
              </button>
            </div>
          </div>
        </div>

        <div style={{ padding: "10px 10px 10px 10px", margin: "10px 10px 10px 10px", border: "2px solid red", }} >
          <h5>Generate Service Request for Instrument</h5>
          <div className="searchnoheader-bar-container">
            <div className="search-bar-keep-right"
              style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", }}
            >
              <select
                style={{ width: "auto" }}
                className="search-bar-input-dropdown"
                value={zone_name}
                onChange={(e) => {
                  setZoneName(e.target.value);
                  onChangeHosptialZone(e.target.value);
                }}
              >
                <option key={""} value={""}> Select Area Zone </option>
                {ConVar.hospitalZone.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                className="search-bar-input-dropdown"
                style={{ width: 300 }}
                value={system_no}
                onChange={(e) => {
                  setSystem_no(e.target.value);
                  onChangeHospital(e.target.value);
                }}
              >
                <option key={""} value={""}> Select Hospital </option>
                {fitleredHospitalList.length ? (
                  fitleredHospitalList.map((option) => (
                    <option key={option._id} value={option.system_no}>
                      {option.name}-{option.location}
                      {" : "}
                      {option.system_no}
                    </option>
                  ))
                ) : (
                  <option key={"no_2353"} value={"No hospital"}> {"Oops! No System Installed yet🫠"} </option>
                )}
              </select>
              <div style={{ width: 600 }}>
                <MultiSelect
                  hasSelectAll={false}
                  options={InstrumentOption}
                  value={selectedInstrument}
                  onChange={setSelectedInstrument}
                />
              </div>
              <SendToService
                longId={selectedInstrument[0]?.value}
                fromGenerateRequest={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
