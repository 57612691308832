import React, { useState, useEffect } from "react";
import "../css/Permission.css";
import { httpGetReq } from "../util/request";
import config from "../config/configUrl";
import { convertTimestampToDateTime } from "../util/util";
import CustomizedSnackbars from "../components/Alert";
const permissionsData = [
  {
    label: "hospital",
    value: "hospital",
    children: [
      {
        label: "add",
        value: "hospital_add",
      },
      {
        label: "Edit",
        value: "hospital_edit",
      },
    ],
  },
  {
    label: "User",
    value: "user",
    children: [
      {
        label: "Clinical Support",
        value: "user_clinical",
        children: [
          {
            label: "Add Users",
            value: "user_clinical_add",
          },
        ],
      },
    ],
  },
  {
    label: "Surgery",
    value: "surgery",
    children: [
      {
        label: "add",
        value: "surgery_add",
      },
      {
        label: "edit",
        value: "surgey_edit",
      },
    ],
  },
  {
    label: "Instrument",
    value: "instrument",
    children: [
      {
        label: "add",
        value: "instrument_add",
      },
      {
        label: "edit",
        value: "instrument_edit",
      },
    ],
  },
  {
    label: "Procedure",
    value: "procedure",
    children: [
      {
        label: "add",
        value: "procedure_add",
      },
      {
        label: "edit",
        value: "procedure_edit",
      },
    ],
  },
  {
    label: "Surgeon",
    value: "surgeon",
    children: [
      {
        label: "add",
        value: "surgeon_add",
      },
      {
        label: "edit",
        value: "surgeon_edit",
      },
    ],
  },
];

const CheckboxTree = ({ data }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [expandedItems, setExpandedItems] = useState({});

  const getAllChildrenValues = (nodes) => {
    let childValues = [];
    nodes.forEach((node) => {
      childValues.push(node.value);
      if (node.children) {
        childValues = childValues.concat(getAllChildrenValues(node.children));
      }
    });
    return childValues;
  };
  const handleCheckboxChange = (value, isChecked, node) => {
    const updatedCheckedItems = { ...checkedItems, [value]: isChecked };
    if (node.children) {
      const childValues = getAllChildrenValues(node.children);
      if (isChecked) {
        childValues.forEach((childValue) => {
          updatedCheckedItems[childValue] = true;
        });
      } else {
        childValues.forEach((childValue) => {
          delete updatedCheckedItems[childValue];
        });
      }
    }

    const parentValue = getParentValue(data, value);
    if (parentValue && isChecked) {
      const allChildValues = getAllChildrenValues(
        getNodeByValue(data, parentValue).children
      );
      const isAllChildrenChecked = allChildValues.every(
        (childValue) => updatedCheckedItems[childValue]
      );
      if (isAllChildrenChecked) {
        updatedCheckedItems[parentValue] = true;
      }
    }

    setCheckedItems(updatedCheckedItems);
  };

  const handleToggle = (value) => {
    setExpandedItems({
      ...expandedItems,
      [value]: !expandedItems[value],
    });
  };

  const toggleNode = (value) => {
    handleToggle(value);
  };

  const renderTree = (nodes, level = 0) => {
    return nodes.map((node) => (
      <div key={node.value}>
        <div className="node" style={{ paddingLeft: level * 20 }}>
          {node.children && (
            <span
              className={`toggle ${expandedItems[node.value] ? "open" : ""}`}
              onClick={() => handleToggle(node.value)}
            >
              {expandedItems[node.value] ? "⬆️" : "➡️"}
            </span>
          )}
          <input
            type="checkbox"
            checked={checkedItems[node.value] || false}
            onChange={(e) =>
              handleCheckboxChange(node.value, e.target.checked, node)
            }
          />
          <label onClick={() => toggleNode(node.value)}>{node.label}</label>
        </div>
        {expandedItems[node.value] && node.children && (
          <div className="children">{renderTree(node.children, level + 1)}</div>
        )}
      </div>
    ));
  };

  return <div style={{ marginLeft: 200 }}>{renderTree(data)}</div>;
};

const getParentValue = (nodes, childValue) => {
  for (const node of nodes) {
    if (node.children) {
      for (const child of node.children) {
        if (child.value === childValue) {
          return node.value;
        }
      }
    }
  }
  return null;
};

const getNodeByValue = (nodes, value) => {
  for (const node of nodes) {
    if (node.value === value) {
      return node;
    }
    if (node.children) {
      const childNode = getNodeByValue(node.children, value);
      if (childNode) {
        return childNode;
      }
    }
  }
  return null;
};

const PermissionsList = (props) => {
  const [roleList, setAllRoleList] = useState([]);
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleButtonClick = () => {
    setShowSnackbar(true);
  };

  const handleOnClose = () => {
    setShowSnackbar(false);
  };

  const getAllRoles = (arg) => {
    let url = config.baseUrl + config.ver + config.roles;
    httpGetReq(url, "PermissionsListlication/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setAllRoleList(response.data.data);
        }
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllRoles();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="app-permisson-container">
      {showSnackbar && (
        <CustomizedSnackbars
          message="Not Implemented yet..!"
          open={showSnackbar}
          close={handleOnClose}
        />
      )}
      <div>
        <span className="role-add " onClick={handleButtonClick}>
          Add Role
        </span>
      </div>
      <div>
        {" "}
        {roleList.length && (
          <div>
            <table>
              <tr>
                <th>Role</th>
                <th>Permission Level </th>
                <th>Updated On </th>
                <th>Updated By </th>
                <th>Action </th>
              </tr>
              <tbody>
                {roleList.map((row) => (
                  <tr key={row._id}>
                    <td>{row.role_label ? row.role_label : ""}</td>
                    <td>{row.permission_level ? row.permission_level : ""}</td>
                    <td>
                      {row.last_updated
                        ? convertTimestampToDateTime(row.last_updated)
                        : ""}
                    </td>
                    <td>{row.last_updated_by ? row.last_updated_by : ""}</td>
                    <td>
                      <span onClick={handleButtonClick}>✏️</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div>
        <CheckboxTree data={permissionsData} />
      </div>
    </div>
  );
};

export default PermissionsList;
