import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function CustomizedSnackbars(props) {
  const [open, setOpen] = React.useState(props.open); // Initially set to true to display the Snackbar

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false); 
      props.close();
    }, 5000); 
    return () => clearTimeout(timer); 
  }, []);

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning at top right
    >
      <Alert
        severity="success"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
