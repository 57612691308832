import React, { Component } from "react";
import { httpGetReq, httpPostReq } from "../util/request";
import config from "../config/configUrl";
import { ConVar } from "../config/configVar";
import { Dialog } from "@mui/material";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { connect } from "react-redux";
import { fetchUsers } from "../store/actionCreator";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../css/ScheduleCalendar.css";

const localizer = momentLocalizer(moment);
class ScheduleCalendar extends Component {
  constructor() {
    super();
    const storedData = localStorage.getItem("selectedData");
    const parsedData = storedData ? JSON.parse(storedData) : {};
    this.state = {
      events: [],
      hospital: parsedData.hospital || "",
      surgery: parsedData.surgery || "",
      surgeon: parsedData.surgeon || "",
      zone: parsedData.zone || "",
      surgerycategory: parsedData.surgerycategory || "",
      start: "",
      end: "",
      openSlot: false,
      openEvent: false,
      clickedEvent: null,
      hospitalList: [],
      filteredHospitals: [],
      procedureList: [],
      filteredSurgery: [],
      surgeonList: [],
      filteredSurgeon: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.setNewAppointment = this.setNewAppointment.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.getStoredData = this.getStoredData.bind(this);
    this.storeData = this.storeData.bind(this);
  }

  componentDidMount() {
    this.getCachedEvents();
    this.getHospitalList();
    this.getProcedureList();
    this.getStoredData();
    this.getSurgeonList();
  }

  getCachedEvents() {
    const cachedEvents = localStorage.getItem("cachedEvents");
    if (cachedEvents) {
      this.setState({ events: JSON.parse(cachedEvents) });
    }
  }

  getHospitalList = () => {
    const url = config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        this.setState({ hospitalList: response.data.data });
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  getProcedureList = () => {
    const url = config.baseUrl + config.ver + config.procedureList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        this.setState({ procedureList: response.data.data });
      } else {
        console.error("Error fetching procedure list:", response.data.message);
      }
    });
  };

  getSurgeonList = () => {
    const url = config.baseUrl + config.ver + config.surgeonList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        this.setState({ surgeonList: response.data.data });
      } else {
        console.error("Error fetching surgeon list:", response.data.message);
      }
    });
  };

  getStoredData() {
    const storedData = localStorage.getItem("selectedData");
    if (storedData) {
      const { zone, hospital, surgery, surgerycategory, surgeon } = JSON.parse(storedData);
      this.setState( { zone, hospital, surgery, surgerycategory, surgeon }, () => {
          if (zone) {
            this.updateFilteredHospitals();
          }
        }
      );
    }
  }

  storeData() {
    const { zone, hospital, surgery, surgerycategory, surgeon } = this.state;
    const data = { zone, hospital, surgery, surgerycategory, surgeon };
    localStorage.setItem("selectedData", JSON.stringify(data));
  }

  updateFilteredHospitals() {
    const { zone, hospitalList } = this.state;
    const filteredHospitals = hospitalList.filter(
      (hospital) => hospital.area_zone === zone
    );
    this.setState({ filteredHospitals });
  }

  updateFilteredSurgery() {
    const { surgerycategory, procedureList } = this.state;
    const filteredSurgery = procedureList.filter(
      (surgery) => surgery.procedure_category === surgerycategory
    );
    this.setState({ filteredSurgery });
  }

  handleClose() {
    this.setState({ openEvent: false, openSlot: false, clickedEvent: null });
    window.location.reload();
  }

  handleSlotSelected(slotInfo) {
    this.setState({
      hospital: "",
      surgery: "",
      surgerycategory: "",
      zone: "",
      surgeon: "",
      openSlot: true,
      start: slotInfo.start,
      end: slotInfo.end,
    });
  }

  handleEventSelected(event) {
    this.setState(
      {
        openEvent: true,
        clickedEvent: event,
        hospital: event.hospital,
        surgery: event.surgery,
        surgeon: event.surgeon,
        surgerycategory: event.surgerycategory,
        zone: event.zone,
        start: event.start,
        end: event.end,
      },
      () => {
        if (this.state.zone) {
          this.updateFilteredHospitals();
        }
        if (this.state.surgerycategory) {
          this.updateFilteredSurgery();
        }
      }
    );
  }

  setZone(e) {
    const selectedZone = e.target.value;
    this.setState({ zone: selectedZone }, () => {
      this.storeData();
      this.updateFilteredHospitals();
    });
  }

  setHospital(e) {
    const selectedHospitalId = e.target.value;
    const hospitalWithSystemNo = this.state.hospitalList.find( (hospital) => hospital._id === selectedHospitalId );
    const systemNo = hospitalWithSystemNo ? hospitalWithSystemNo.system_no : "";
    const filteredSurgeon = this.state.surgeonList.filter( (surgeon) => surgeon.system_no === systemNo );
    this.setState(
      { hospital: selectedHospitalId, filteredSurgeon, surgeon: "" },
      () => {
        this.setState({ surgery: "", surgerycategory: "" });
        this.storeData();
      }
    );
  }

  setSurgeryCategory(e) {
    const selectedCategory = e.target.value;
    this.setState({ surgerycategory: selectedCategory, surgery: "" }, () => {
      this.storeData();
      this.updateFilteredSurgery();
    });
  }

  setSurgery(e) {
    const selectedSurgery = e.target.value;
    this.setState({ surgery: selectedSurgery }, () => {
      this.storeData();
    });
  }

  setSurgeon(e) {
    const selectedSurgeon = e.target.value;
    this.setState({ surgeon: selectedSurgeon }, () => {
      this.storeData();
    });
  }

  handleStartTime = (event, date) => {
    this.setState({ start: date });
  };

  handleEndTime = (event, date) => {
    this.setState({ end: date });
  };

  setNewAppointment() {
    const { hospital, surgery, surgerycategory, zone, start, end, surgeon } = this.state;
    const selectedHospital = this.state.hospitalList.find( (h) => h._id === hospital );
    const selectedSurgery = this.state.procedureList.find( (h) => h.procedure_name === surgery );
    const { userDetails } = this.props;

    const eventData = {
      date: start.getTime(),
      zone_id: zone,
      hospital_name: selectedHospital.name,
      procedure_category: surgerycategory,
      procedure_name: surgery,
      procedure_code: selectedSurgery ? selectedSurgery.procedure_code : null,
      surgeon_name: surgeon,
      scheduled_by: userDetails.name,
      system_no: selectedHospital ? selectedHospital.system_no : null,
      location: selectedHospital ? selectedHospital.location : null,
    };

    const url = config.baseUrl + config.ver + config.scheduleTesting;

    httpPostReq(url, eventData, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        console.log(eventData);
        console.log("Event created successfully:", response.data);
        const newEvent = {
          title: `${selectedHospital.name} - ${surgery}`,
          start,
          end,
          hospital: hospital,
          surgerycategory: surgerycategory,
          surgery: surgery,
          zone: zone,
          surgeon: surgeon,
        };
        const events = [...this.state.events, newEvent];
        localStorage.setItem("cachedEvents", JSON.stringify(events));
        this.setState({ events, openSlot: false });
      } else {
        console.error("Error creating event:", response.data.message);
      }
    });
  }

  updateEvent() {
    const { zone, hospital, surgery, surgerycategory, surgeon, events, clickedEvent, } = this.state;
    const updatedEvent = events.map((event) =>
      event === clickedEvent
        ? {
            ...event,
            hospital,
            surgerycategory,
            surgery,
            zone,
            surgeon,
            title: `${hospital} - ${surgery}`,
          }
        : event
    );
    localStorage.setItem("cachedEvents", JSON.stringify(updatedEvent));
    this.setState({
      events: updatedEvent,
      openEvent: false,
      clickedEvent: null,
    });
  }

  deleteEvent() {
    const { start, clickedEvent } = this.state;
    const updatedEvents = this.state.events.filter( (event) => event !== clickedEvent );
    localStorage.setItem("cachedEvents", JSON.stringify(updatedEvents));
    this.setState({
      events: updatedEvents,
      openEvent: false,
      clickedEvent: null,
    });
  }

  render() {
    const { hospital, surgery, surgerycategory, zone, surgeon, filteredHospitals, filteredSurgery, filteredSurgeon, } = this.state;

    const eventActions = [
      <button onClick={this.handleClose}>Cancel</button>,
      <button onClick={() => { this.deleteEvent(); }} > Delete </button>,
      <button onClick={() => { this.updateEvent(); }} > Confirm Edit </button>,
    ];

    const appointmentActions = [
      <button onClick={this.handleClose}>Cancel</button>,
      <button onClick={() => { this.setNewAppointment(); }} > Submit </button>,
    ];

    return (
      <div className="calendar-main-container">
        <BigCalendar
          events={this.state.events}
          views={["month"]}
          timeslots={2}
          defaultView="month"
          defaultDate={new Date()}
          selectable={true}
          localizer={localizer}
          onSelectEvent={(event) => this.handleEventSelected(event)}
          onSelectSlot={(slotInfo) => this.handleSlotSelected(slotInfo)}
        />

        <Dialog
          title={`Book an appointment on ${moment(this.state.start).format( "MMMM Do YYYY" )}`}
          className="addproperty-dialog"
          actions={appointmentActions}
          modal={false}
          open={this.state.openSlot}
          onRequestClose={this.handleClose}
        >
          <div className="addproperty-container">
            <div className="addproperty-form-title">
              {moment(this.state.start).format("MMMM Do YYYY")} - Schedule Surgery
            </div>
            <div className="addproperty-form">
              <div className="addproperty-form-control">
                <label>Select Zone</label>
                <select
                  className="addproperty-select"
                  value={zone}
                  onChange={(e) => this.setZone(e)}
                >
                  <option value="">Select Zone</option>
                  {ConVar.hospitalZone.map((zone) => (
                    <option
                      className="list-option"
                      key={zone.value}
                      value={zone.value}
                    >
                      {zone.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="addproperty-form-control">
                <label>Select Hospital</label>
                <select
                  className="addproperty-select"
                  autoFocus
                  value={hospital}
                  onChange={(e) => this.setHospital(e)}
                >
                  <option value="">Select Hospital</option>
                  {filteredHospitals.map((hospital) => (
                    <option key={hospital._id} value={hospital._id}>
                      {hospital.name} - {hospital.location} (
                      {hospital.system_no})
                    </option>
                  ))}
                </select>
              </div>

              <div className="addproperty-form-control">
                <label>Select Procedure Category</label>
                <select
                  className="addproperty-select"
                  value={surgerycategory}
                  onChange={(e) => this.setSurgeryCategory(e)}
                >
                  <option value="">Select Procedure Category</option>
                  {ConVar.procedureCategory.map((surgerycategory) => (
                    <option
                      className="list-option"
                      key={surgerycategory.value}
                      value={surgerycategory.value}
                    >
                      {surgerycategory.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="addproperty-form-control">
                <label>Select Procedure</label>
                <select
                  className="addproperty-select"
                  autoFocus
                  value={surgery}
                  onChange={(e) => this.setSurgery(e)}
                >
                  <option value="">Select Procedure</option>
                  {filteredSurgery.map((surgery) => (
                    <option key={surgery.id} value={surgery.procedure_name}>
                      {surgery.procedure_code} - {surgery.procedure_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="addproperty-form-control">
                <label>Select Surgeon</label>
                <select
                  className="addproperty-select"
                  autoFocus
                  value={surgeon}
                  onChange={(e) => this.setSurgeon(e)}
                >
                  <option value="">Select Primary Surgeon</option>
                  {filteredSurgeon.map((surgeon) => (
                    <option key={surgeon.id} value={surgeon.surgeon_name}>
                      {surgeon.surgeon_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="addproperty-dialog-action">
              <button onClick={this.setNewAppointment} className="addproperty-button" > Submit </button>
              <button onClick={this.handleClose} className="cancel-button"> Cancel </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          title={`View/Edit Appointment of ${moment(this.state.start).format( "MMMM Do YYYY" )}`}
          className="addproperty-dialog"
          actions={eventActions}
          modal={false}
          open={this.state.openEvent}
          onRequestClose={this.handleClose}
        >
          <div className="addproperty-container">
            <div className="addproperty-form-title">
              {moment(this.state.start).format("MMMM Do YYYY")} - Update Scheduled Surgery
            </div>
            <div className="addproperty-form">
              <div className="addproperty-form-control">
                <label>Select Zone</label>
                <select
                  className="addproperty-select"
                  value={zone}
                  onChange={(e) => this.setZone(e)}
                >
                  <option value="">Select Zone</option>
                  {ConVar.hospitalZone.map((zone) => (
                    <option
                      className="list-option"
                      key={zone.value}
                      value={zone.value}
                    >
                      {zone.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="addproperty-form-control">
                <label>Select Hospital</label>
                <select
                  className="addproperty-select"
                  autoFocus
                  value={hospital}
                  onChange={(e) => this.setHospital(e)}
                >
                  {filteredHospitals.map((hospital) => (
                    <option key={hospital.id} value={hospital.name}>
                      {hospital.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="addproperty-form-control">
                <label>Surgery Name</label>
                <input
                  className="addproperty-text-field"
                  placeholder="Surgery Name"
                  type="text"
                  value={surgery}
                  onChange={(e) => this.setSurgery(e.target.value)}
                />
              </div>
            </div>
            <div className="addproperty-dialog-action">
              <button onClick={this.deleteEvent} className="cancel-button"> Delete </button>
              <button onClick={this.updateEvent} className="addproperty-button"> Confirm Edit </button>
              <button onClick={this.handleClose} className="cancel-button"> Cancel </button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
