import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";

import Lock from "../../components/Lock";

export const Ipqc1 = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const RadioInput = (props) => {
    let label = props.label;
    let optionArr = props.options;

    const [radioVal, setRadioVal] = useState(props.val ? props.val : null);
    const handleRadioChange = (event) => {
      setRadioVal(event.target.value);
      props.onUpdate(event.target.value);
    };
    return (
      <span>
        <label>{label}</label> &nbsp;&nbsp; &nbsp;&nbsp; :-
        {optionArr.map((option) => (
          <>
            &nbsp;&nbsp;
            <label>
              <input
                type="radio"
                name={label}
                value={option}
                onChange={handleRadioChange}
              />
              {option.toUpperCase()}
            </label>
          </>
        ))} &nbsp;&nbsp; &nbsp;&nbsp;
        <select onChange={(e) => {}}>
          <option value=""> Visually </option>
          <option value=""> Scale </option>
        </select> &nbsp;&nbsp; &nbsp;&nbsp;
        <input type="text" placeholder="SCALE ID"  />
      </span>
    );
  };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
        <h4>Occupied BY: John Doe</h4>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="desk-bucket">
            <h4>MY BUCKET</h4>
            <div>
              <div>One<button>Taken🔒</button></div>
              <div>One<button>Take</button></div>
              <div>One<button>Take</button></div>
              <div>One<button>Take</button></div>
            </div>
          </div>
          <div className="desk-bucket">
            <h4>Done</h4>
            <div>
              <div>One<button>Send to Store</button></div>
              <div>One<button>Send to Store</button></div>
              <div>One<button>Send to R1</button></div>
              <div>One<button>Send to R1</button></div>
            </div>
          </div>
        </div>
        <div className="desk-bucket-working">
          <h4 style={{ textAlign: "center" }}>Processing</h4>
          <span>Current: XXX12SF </span>
          <div >
            <div></div><p>Visually</p>
            <div><RadioInput  label={"Dent/Dust"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Break"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Position of component"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Position of Assembly pully"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Alignmnet"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Burrs"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Wire Tension"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><p>Dimensions</p></div>
            <div><RadioInput  label={"Total length after assembly"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"wire Length after crimping"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Cripmp Tube Dia.After Assembly"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
            <div><RadioInput  label={"Torque on Pully Screw"} onUpdate={(val) => {}} options={["pass", "fail"]} /></div>
          </div>
        </div>
        <button>Done</button>
      </div>
    );
  } else {
    return <Lock />;
  }
};
