import React, { useEffect, useState } from "react";
import { httpGetReq } from "../../util/request";
import config from "../../config/configUrl";

import "../../css/Mudra.css";
import SearchIcon from "@mui/icons-material/Search";
import AddMudra from "../../components/addcomponents/AddMudra";
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import Pagination from "@mui/material/Pagination";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import getImageUrl from "../../components/ImageList";
import "../../css/CommonStyle.css";
import Lock from "../../components/Lock";

export const Mudras = (props) => {
  const [instrumentList, setInstrumentList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [mudraDetail, setMudraDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [openAddMudraModal, setOpenAddMudraModal] = useState(false);
  const [label_name, setLabelSearchName] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);

  const limitPerPage = 150;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getInstrumentList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const getInstrumentList = () => {
    let url = `${config.baseUrl}${config.ver}${config.mudraList}?limit=${limitPerPage}&page=${currentPage}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setInstrumentList(response.data.data);
        setShowDetail(false);
        if (response.data.data.length) {
          setFound(true);
        } else {
          setFound(false);
        }
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);

        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const searchInstrument = (label_search) => {
    if (label_search === "") {
      setInstrumentList([]);
      setSearchToggle(!searchToggle);
      return null;
    }
    let instrument_search_url = config.baseUrl + config.ver + config.mudraList;
    if (label_search !== "") {
      instrument_search_url = instrument_search_url + "?name=" + label_search;
    }
    httpGetReq(instrument_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setInstrumentList(response.data.data);
          setSearchToggle(!searchToggle);
        } else if (response.data.available === 0) {
          setInstrumentList([]);
          setSearchToggle(!searchToggle);
        }
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddMudraModal(false);
    }
  };

  if (true) {
    return (
      <div className="main-data-container">
        {true && (
          <div className="add-data-button-container">
            <button
              className="add-data-button"
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenAddMudraModal(true);
              }}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Add Mudra</span>
            </button>
            <AddMudra open={openAddMudraModal} onClose={handleClose} />
          </div>
        )}

        <div className="search-bar-container">
          <div className="search-bar-keep-right">
            <input
              className="search-bar-input-text"
              id="outlined-basic"
              placeholder="Instrument name"
              variant="outlined"
              size="small"
              value={label_name}
              onChange={(event) => {
                setLabelSearchName(event.target.value);
                searchInstrument(label_name);
              }}
            />
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                searchInstrument(label_name);
              }}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                getInstrumentList();
                setLabelSearchName("");
              }}
            >
              <RestartAltRoundedIcon />
            </button>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>

        <Dialog className="mudraproperty-dialog" open={showDetail} onClose={() => setShowDetail(false)} >
          <div className="addproperty-form-title">{mudraDetail?.label || "Instrument Name"}</div>
            <div className="details-container">
              <div className="details-image">
                {mudraDetail && (
                  <img
                    src={getImageUrl(mudraDetail.key)}
                    alt={mudraDetail.label}
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </div>
              <div className="details-info">
                {/* <div className="detail-row">
                  <span className="detail-label">Name</span>
                  <span className="detail-value"> : {mudraDetail?.label || ""} </span>
                </div> */}
                <div className="detail-row">
                  <span className="detail-label">Short Form</span>
                  <span className="detail-value"> : {mudraDetail?.short || ""} </span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">Description</span>
                  <span className="detail-value"> : {mudraDetail?.long_desc || ""} </span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">Max No. of Uses Allowed</span>
                  <span className="detail-value"> : {mudraDetail?.no_of_max_use || ""} </span>
                </div>
              </div>
          </div>
        </Dialog>

        <div className="property-list-container">
          {instrumentList.map((item, rowIndex) => (
            <div
              key={item._id}
              className="mudra-box"
              title={item.label}
              onClick={() => {
                setShowDetail(true);
                setMudraDetail(item);
              }}
            >
              <img
                className="mudra-image"
                src={getImageUrl(item.key)}
                alt="inst-image"
              />
              <div className="mudra-text">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return <Lock />;
};
