import React, { useState, useEffect } from "react";
import config from "../../config/configUrl";
import { httpPostReq } from "../../util/request";
import { Dialog } from "@mui/material";

const FinishService = (props) => {
  const [internalObservation, setInternalObservation] = useState("");
  const [reworkCategory, setReworkCategory] = useState("");
  const [reworkAction, setReworkAction] = useState("");
  const [jobCardNumber, setJobCardNumber] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [testedBy, setTestedBy] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsModalOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    props.onClose();
  };

  const handleSubmit = async () => {
    const instrumentId = props.longId;
    if (!instrumentId) {
      setWarning("Instrument ID is missing");
      return;
    }

    const url = `${config.baseUrl}${config.ver}${config.finishService}${instrumentId}`;
    const data = {
      status: 44,
      label: props.label,
      present_system_no: props.present_system_no,
      description: props.description,
      production_date: props.production_date,
      tested_by: testedBy,
      rework_category: reworkCategory,
      rework_action: reworkAction,
      job_card_number: jobCardNumber,
      internal_observation: internalObservation,
      last_updated: Date.now(),
    };

    httpPostReq(url, data, "application/json", (response) => {
      console.log("Response from server:", response);
      if (response.data && response.data.status === "success") {
        setWarning("");
        setMessage("Service finished successfully");
        handleCloseModal();
        window.location.reload();
      } else if (response.data && response.data.status === "fail") {
        setMessage("");
        setWarning(response.data.message || "Failed to submit");
      }
    });
  };

  return (
    <div className="add-data-button-container">
      <Dialog open={isModalOpen} onClose={handleCloseModal} className="addproperty-dialog">
        <div className="addproperty-container">
          <div className="addproperty-form-title">Performed Service Details</div>
          <div className="addproperty-form">
            {warning && <div className="error-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="addproperty-form-control">
              <label>Tested and Verified By</label>
              <select
                className="addproperty-select"
                value={testedBy}
                onChange={(e) => setTestedBy(e.target.value)}
              >
                <option value="">Tested and Verified By</option>
                <option value="debasmita.parida@ssinnovations.org">Debasmita</option>
                <option value="kunal.indra@ssinnovations.org">Kunal</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Rework Category</label>
              <input
                className="addproperty-text-field"
                type="text"
                placeholder="Rework Category"
                value={reworkCategory}
                onChange={(e) => setReworkCategory(e.target.value)}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Rework Action</label>
              <input
                className="addproperty-text-field"
                type="text"
                placeholder="Rework Actions Performed"
                value={reworkAction}
                onChange={(e) => setReworkAction(e.target.value)}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Job Card No.</label>
              <input
                className="addproperty-text-field"
                type="text"
                placeholder="Job Card Number"
                value={jobCardNumber}
                onChange={(e) => setJobCardNumber(e.target.value)}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Internal Observations</label>
              <input
                className="addproperty-text-field"
                type="text"
                placeholder="Internal Observations"
                value={internalObservation}
                onChange={(e) => setInternalObservation(e.target.value)}
              />
            </div>
          </div>
          <div className="addproperty-dialog-action">
            <button type="button" className="addproperty-button" onClick={handleSubmit}>
              Finish Service
            </button>
            <button type="button" className="cancel-button" onClick={handleCloseModal}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FinishService;
