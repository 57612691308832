import React, { useEffect, useState } from "react";
//import { Container, Row } from "react-bootstrap";
import AddUser from "../../components/addcomponents/AddUser";
import { Row } from "react-bootstrap";
import config from "../../config/configUrl";
import EditUser from "../../components/editcomponents/EditUser";
import { Link, useLocation } from "react-router-dom";

import UserContainerTable from "../clinical/UserContainerTable";

import { httpGetReq } from "../../util/request";
import "../../css/User.css";

import Loader from "../../components/Loader";
import Lock from "../../components/Lock";

export const ServiceEngineerListing = (props) => {
    const [userList, setUserList] = useState([]);
    const [clinicalSupporList, setClinicalSupporList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [show_edit_user, setShowEditUser] = useState(false);
    const [show_add_user, setShowAddUser] = useState(false);
    const [test_state, setTestState] = useState(false);
    const [test_state_2, setTestState2] = useState(false);
    const [test_state_3, setTestState3] = useState(false);

    const [username, setUsername] = useState("");
    const [usertype, setUserType] = useState("");
    const [emp_id, setEmpId] = useState("");
    const [emp_email, setEmpEmail] = useState("");
    const [user_id, setUserId] = useState("");

    const location = useLocation();
    const { pathname } = location;

    const updateShowEditUser = (row) => {
        //console.log(row);
        setShowEditUser(true);
        setTestState(!test_state);
        setTestState2(!test_state_2);
        //setTestState3(!test_state_3);
        setShowAddUser(false);
        setUsername(row.name);
        setUserType(row.user_type);
        setEmpId(row.emp_id);
        setEmpEmail(row.emp_email);
        // console.log(
        //     "------------------------------------------------------------"
        // );
        //console.log(row._id);
        setUserId(row._id);
        //console.log(row.user_type);
    };

    const closeEditUser = () => {
        setShowEditUser(false);
        setTestState2(!test_state_2);
        setTestState3(!test_state_3);
        //setTestState(!test_state);
    };

    // const closeAddUser = () => {

    // }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getUserList("clinical-support");
            getUserList("service-engineer");
        }
        return () => {
            mounted = false;
        };
    }, []);

    const getUserList = (userType) => {
        let url =
            config.baseUrl +
            config.ver +
            config.userList +
            "?user_type=" +
            userType;
        setLoading(true);
        httpGetReq(url, "application/json", function (response) {
            setLoading(false);
            if (response) {
                if (userType === "clinical-support") {
                    setClinicalSupporList(response.data.data);
                } else if (userType === "service-engineer") {
                    setUserList(response.data.data);
                }
            }
        });
    };

    if (props.UserInfo.user_type === "admin") {
        return (
            <div className="user-container">
                {loading ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <div>
                        {" "}
                        <Row>
                            <AddUser
                                closeEditUser_={closeEditUser}
                                show_state={show_add_user}
                                key={test_state}
                            />
                        </Row>
                        <Row>
                            {/* <EditUser
                                show_state={show_edit_user}
                                username_={username}
                                usertype_={usertype}
                                emp_id_={emp_id}
                                emp_email_={emp_email}
                                set_username_={setUsername}
                                set_usertype_={setUserType}
                                set_emp_id_={setEmpId}
                                set_emp_email_={setEmpEmail}
                                key={test_state_2}
                            /> */}
                        </Row>
                        <Row>
                            {" "}
                            <h1>Service Engineer Listing:</h1>
                            <UserContainerTable
                                data={userList}
                                rowsPerPage={5}
                                updateEditUser={updateShowEditUser}
                                username__={username}
                                usertype__={usertype}
                                emp_id__={emp_id}
                                emp_email__={emp_email}
                                setUsername_={setUsername}
                                setUserType_={setUserType}
                                setEmpId_={setEmpId}
                                setEmpEmail_={setEmpEmail}
                                user_id_={user_id}
                                key={test_state_3}
                            />
                        </Row>
                    </div>
                )}
            </div>
        );
    } else {
        return <Lock />;
    }
};
