import { httpGetReq } from "../util/request";
import config from "../config/configUrl";
import * as actionTypes from "./actions";

export const fetchUsers = (dispatch, callback) => {
  const url = config.baseUrl + config.ver + config.userList + "?limit=100";
  httpGetReq(url, "application/json", function (response) {
    if (
      response.data &&
      response.data.status &&
      response.data.status === "success"
    ) {
      if (callback) callback();
      return dispatch({
        type: actionTypes.FETCH_USERS,
        payload: response.data.data ? response.data.data : [],
      });
    }
  });
};

export const fetchHospitals = (dispatch, callback) => {
  const url = config.baseUrl + config.ver + config.hospitalList + "?limit=100";
  httpGetReq(url, "application/json", function (response) {
    if (
      response.data &&
      response.data.status &&
      response.data.status === "success"
    ) {
      if (callback) callback();
      return dispatch({
        type: actionTypes.FETCH_HOSPITALS,
        payload: response.data.data ? response.data.data : [],
      });
    }
  });
};

// export const authenticateUser = (dispatch, email) => {
//   const url = config.baseUrl + config.ver + config.auth + email;
//   httpGetReq(url, "application/json", function (response) {
//     if (
//       response.data &&
//       response.data.status &&
//       response.data.status === "success"
//     ) {
//       return dispatch({
//         type: actionTypes.USER_AUTHENTICATE,
//         payload: response.data.data ? response.data.data : {},
//       });
//     }

//   });
// };

export const fetchUserDetails = (userInfo, dispatch) => {
  return dispatch({
    type: actionTypes.USER_DETAILS,
    payload: userInfo,
  });
};

export const fetchProcedureDetails = (dispatch, callback) => {
  const url = config.baseUrl + config.ver + config.procedureList + "/all";
  httpGetReq(url, "application/json", function (response) {
    if (
      response.data &&
      response.data.status &&
      response.data.status === "success"
    ) {
      if (callback) callback();
      return dispatch({
        type: actionTypes.PROCEDURE_DETAILS,
        payload: response.data.data ? response.data.data : [],
      });
    }
  });
};

export const fetchSurgeonDetails = (dispatch, callback) => {
  const url = config.baseUrl + config.ver + config.surgeonList + "?limit=100";
  httpGetReq(url, "application/json", function (response) {
    if (
      response.data &&
      response.data.status &&
      response.data.status === "success"
    ) {
      let PROCEDURE_LIST_t ={};
      if(response.data.data.length){        
        response.data.data.forEach((val)=>{
          PROCEDURE_LIST_t[val._id] = val;          
        });       
      }
      if (callback) callback();
      return dispatch({
        type: actionTypes.SURGEON_DETAILS,
        //payload: response.data.data ? response.data.data : [],
        payload: PROCEDURE_LIST_t,
      });
    }
  });
};
