import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../config/configUrl";
import { convertTimestampToDate, getTaskStatus } from "../../util/util";
import TaskDetail from "../../components/TaskDetail";
import { httpGetReq, httpPostReq } from "../../util/request";
import EndTaskModal from "./EndTaskModal";

import "../../css/MyTask.css";
import Loader from "../../components/Loader";
import Lock from "../../components/Lock";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';

export const MyTasks = (props) => {
  const UserDetail = props.UserInfo;
  const navigate = useNavigate();
  const location = useLocation();

  const [expandedRows, setExpandedRows] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endTaskWidow, setEndTaskWindow] = useState(false);
  const [observation, setObservation] = useState("");
  const [observedBy, setObservedBy] = useState("");
  const [correctPlan, setCorrectPlan] = useState("");
  const [suggestedBy, setSuggestedBy] = useState("");
  const [changesDoneBy, setChangesDoneBy] = useState("");
  const [verifiedBy, setVerifiedBy] = useState("");
  const [endTaskId, setEndTaskID] = useState("");
  const [isSolved, setIsSolved] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      mytaskList();

      const searchParams = new URLSearchParams(location.search);
      const taskID = searchParams.get("task");
      
      if (taskID) {
        setExpandedRows((prevExpandedRows) => ({
          ...prevExpandedRows,
          [taskID]: true,
        }));
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const mytaskList = (arg) => {
    let url = config.baseUrl + config.ver + config.myTaskList + UserDetail.emp_email;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response) {
        if (response.data) {
          console.log(response.data);
          setTaskList(response.data.data);
        }
      }
    });
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));

    if (!expandedRows[rowId]) {
      const taskID = encodeURIComponent(rowId);
      navigate(`${location.pathname}?task=${taskID}`);
    } else {
      navigate(location.pathname);
    }
  };

  const startTask = (taskId) => {
    let openTaskUrl = config.baseUrl + config.ver + config.taskStart + taskId;
    const formData = {
      emp_email: UserDetail.emp_email,
    };

    httpPostReq(openTaskUrl, formData, "application/json", (response) => {
      if (response.data.status === "success") {
        window.location.reload(true);
      }
    });
  };

  const endTask = (taskId) => {
    setEndTaskWindow(!endTaskWidow);
    setEndTaskID(taskId);
  };

  // const handleTaskModalOpen = (taskId) => {
  //   setEndTaskID(taskId);
  //   setTaskModalOpen(true);
  // };

  const handleTaskModalClose = () => {
    setTaskModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "observation":
        setObservation(value);
        break;
      case "observedBy":
        setObservedBy(value);
        break;
      case "correctPlan":
        setCorrectPlan(value);
        break;
      case "suggestedBy":
        setSuggestedBy(value);
        break;
      case "changesDoneBy":
        setChangesDoneBy(value);
        break;
      case "verifiedBy":
        setVerifiedBy(value);
        break;
      case "isSolved":
        setIsSolved(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    let endTaskUrl = config.baseUrl + config.ver + config.taskEnd + endTaskId;
    const formData = {
      emp_email: UserDetail.emp_email,
      status: isSolved ? 3 : 2,
      action: {
        observation: observation,
        observedBy: observedBy,
        correctPlan: correctPlan,
        suggestedBy: suggestedBy,
        changesDoneBy: changesDoneBy,
        verifiedBy: verifiedBy,
      },
    };
    httpPostReq(endTaskUrl, formData, "application/json", (response) => {
      if (response.data.status === "success") {
        window.location.reload(true);
      }
    });
  };

  if (props.UserInfo.user_type === "service-engineer") {
    return (
      <div className="main-data-container">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            {endTaskWidow && (
              <EndTaskModal
                observation={observation}
                observedBy={observedBy}
                correctPlan={correctPlan}
                suggestedBy={suggestedBy}
                changesDoneBy={changesDoneBy}
                verifiedBy={verifiedBy}
                isSolved={isSolved}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                handleClose={() => setEndTaskWindow(false)}
                handleModalClose={handleTaskModalClose}
              />
            )}
            <div>
              <div className="property-list-container">
                <table className="property-table">
                <thead>
                  <tr>
                    <th>Task Name</th>
                    <th>Type</th>
                    <th>Hospital</th>
                    <th>Creation Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody className="property-table-body">
                    {taskList.length ? (
                      <>
                        {taskList.map((row) => (
                          <>
                            <tr key={row._id} onClick={() => {}}>
                              <td className="r10start">{row.task_name}</td>
                              <td className="r30">{row.task_type}</td>
                              <td className="r10">{row.system_no}</td>
                              <td className="r10"> {convertTimestampToDate(row.assign_time)} </td>
                              <td className="r10"> {getTaskStatus(row.status)} </td>
                              <td className="r20end">
                                <button onClick={() => { toggleRow(row._id); }} ><InfoRoundedIcon/></button>
                                {row.status == 1 && (
                                  <>
                                    <button className="green-button" onClick={() => { startTask(row._id); }} ><EventAvailableRoundedIcon/></button>
                                  </>
                                )}
                                {row.status == 2 && (
                                  <>
                                    <button className="red-button" onClick={() => { endTask(row._id); }} ><EventBusyRoundedIcon/></button>
                                  </>
                                )}
                              </td>
                            </tr>
                            {expandedRows[row._id] && (
                              <tr>
                                <td colspan="8" className="inner-property-expand"> <TaskDetail data={row} /> </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <div className="no-match-found">
                        Oops ! No Match Found 😁
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <Lock />;
  }
};
