import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Lock from "../../components/Lock";
import PdfViewer from "../../components/PdfViewer";

import "../../css/Procedure.css";
import { Button } from "@mui/material";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import AddProcedure from "../../components/addcomponents/AddProcedure";
import EditProcedure from "../../components/editcomponents/EditProcedure";
import Pagination from "@mui/material/Pagination";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export const SurgeryProcedure = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [procedureList, setProcedureList] = useState([]);
  const [procedureCategory, setProcedureCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [openAddProcedureModal, setOpenAddProcedureModal] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [innerList, setInnerList] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProcedureList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, innerList]);

  const getProcedureList = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.procedureList}?limit=${limitPerPage}&page=${currentPage}`;

    if (procedureCategory != undefined && procedureCategory != "") {
      url += "&procedure_category=" + procedureCategory;
    }
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setProcedureList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const toggleExpand = (row) => {
    if (expandedRow === row._id) {
      setExpandedRow(null);
      setInnerList([]);
    } else {
      setExpandedRow(row._id);
      if (expandedRow !== null) {
        setInnerList([]);
      }
      getProcedureSteps(row);
    }
  };

  const getProcedureSteps = (arg) => {
    console.log(arg);
    if (arg && arg.surgery_steps) {
      setInnerList(arg.surgery_steps);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddProcedureModal(false);
    }
  };

  if (true) {
    return (
      <>
        <div className="procedure-container">
          <div key="0" className="add-procedure-main-container">
            <Button
              variant="contained"
              
              color="primary"
              onClick={() => setOpenAddProcedureModal(true)}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Add Procedure</span>
            </Button>
            <AddProcedure open={openAddProcedureModal} onClose={handleClose} />
          </div>

          <div key="1" className="search-bar-container">
            <div className="search-bar-keep-right">
              <select
                className="search-bar-input-dropdown"
                labelId="area-zone-label"
                placeholder="Instrument Category"
                value={procedureCategory}
                onChange={(e) => {
                  setProcedureCategory(e.target.value);
                }}
              >
                <option key={""} value={""}> Select Procedure Category </option>
                {ConVar.procedureCategory.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  console.log("clik");
                  getProcedureList();
                }}
                startIcon={<SearchIcon />}
              >
                <SearchIcon />
              </button>
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  getProcedureList();
                  setCurrentPage(1);
                  setProcedureCategory("");
                }}
              >
                <RestartAltRoundedIcon />
              </button>
            </div>
            <div className="pagination-setup">
              Page
              <Pagination
                className="pagination-setup-button"
                count={totalPages}
                color="primary"
                page={currentPage}
                onChange={(event, value) => {
                  setCurrentPage(value);
                }}
              />
            </div>
          </div>
          <div className="property-list-container">
            <table className="property-table">
              <thead>
                <tr>
                  <th>Procedure Code</th>
                  <th>Category</th>
                  <th>Procedure Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="property-table-body">
                {procedureList.map((surgery) => (
                  <React.Fragment key={surgery._id}>
                    <tr>
                      <td className="r10start">{surgery.procedure_code}</td>
                      <td className="r20"> {ConVar.procedureCategoryAssArr[ surgery.procedure_category ] ? ConVar.procedureCategoryAssArr[ surgery.procedure_category ] : ""} </td>
                      <td className="r30">{surgery.procedure_name}</td>
                      <td className="r20end">
                        <button onClick={() => toggleExpand(surgery)}>
                          <InfoRoundedIcon />
                        </button>
                        <EditProcedure procedureDetail={surgery} />
                      </td>
                    </tr>
                    {expandedRow === surgery._id && (
                      <tr>
                        <td colSpan="4" className="inner-property-expand">
                          <div className="left-steps">
                            <strong>Steps:</strong> <br />
                            <div>
                              {innerList.map((subUnit, index) => (
                                <>
                                  <span key={index} className="procedure-steps">
                                    {subUnit.label ? subUnit.label : subUnit}
                                  </span>
                                  <br />
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="right-steps-pdf">
                            <PdfViewer />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return <Lock />;
};

export default SurgeryProcedure;
