import React from "react";
import AddTask from "../../components/addcomponents/AddTask";

const AssignMaintenance = ({ callData, openModal, onCloseModal }) => {
  const taskType = "maintenance-checkup";

  return (
    <div>
      {openModal && (
        <AddTask
          open={openModal}
          close={onCloseModal}
          data={{
            system_no: callData.system_no,
            taskType: taskType,
          }}
          isFromAssignMaintenance={true}
        />
      )}
    </div>
  );
};

export default AssignMaintenance;
