import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Lock from "../../components/Lock";

import "../../css/MudraServiceContainer.css";
import { ServiceListing } from "./ServiceContainer";
import { ServiceRequestedListing } from "./ServiceRequested";

function CustomTabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && <>{props.children}</>}
    </div>
  );
}
export const MudraServiceContainer = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (true) {
    return (
      <div className="profile-container">
        <div>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="In Service" />
            <Tab label="Service Requested" />
          </Tabs>
        </div>
        <div>
          <CustomTabPanel value={value} index={0}>
           <ServiceListing {...props} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
          <ServiceRequestedListing  {...props}  />         
          </CustomTabPanel>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
