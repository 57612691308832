import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq } from "../../util/request";
import { numberToMonthName } from "../../util/util";
import { Dialog } from "@mui/material";
import "../../css/AddInstrument.css";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";

const SendToService = (props) => {
  const [open, setOpen] = useState(props.show_state);
  const [error, setError] = useState("");
  const [issue, setIssue] = useState();
  const [description, setDescription] = useState();
  const [routineService, serRoutineService] = useState(false);
  const [autoClave, setAutoClave] = useState(false);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleSetRoutineSevice = (event) => {
    serRoutineService(event.target.checked);
  };

  const handleAutoClave = (event) => {
    setAutoClave(event.target.checked);
  };

  const handleSubmit = async () => {
    let sendToService = config.baseUrl + config.ver + (props.fromGenerateRequest ? config.sendServiceRequest : config.sendService) + props.longId;
    let postobj = {
      issue: issue,
      description: description,
      last_updated: Date.now(),
      is_prventive_maintenance: routineService,
      auto_claved: autoClave,
      service_type: props.fromGenerateRequest ? "Request" : "Accepted",
    };
    console.log(postobj);
    httpPostReq(sendToService, postobj, "application/json", (response) => {
      console.log(response);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setWarning("");
        setMessage("User Added successfully.");
        setOpen(false);
        return null;
      } else if (
        response.data &&
        response.data.status &&
        response.data.status === "fail"
      ) {
        setMessage(response.data.message);
        setWarning("");
        return null;
      }
    });
  };

  const handleShowModal = () => {
    setOpen(true);
    navigate(
      `${location.pathname}?service=${encodeURIComponent(props.longId)}`
    );
  };

  const handleShowHide = () => {
    setOpen(!open);
    if (open) {
      navigate(location.pathname);
    }
  };

  return (
    <>
      <button title="Send For Service" variant="contained" color="primary" onClick={handleShowModal} >
        <BuildRoundedIcon />
      </button>
      <Dialog open={open} onClose={handleShowHide} className="addproperty-dialog" >
        <div className="addproperty-container">
          <div className="addproperty-form-title">Send for Service</div>
          <div className="addproperty-form">
            {warning && <div className="error-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="addproperty-form-control">
              <label>Issue</label>
              <select
                className="addproperty-select"
                onChange={(e) => {
                  setIssue(e.target.value);
                }}
              >
                <option value={""}>Select Issue</option>
                {ConVar.instrumentIssueList.map((inst) => (
                  <option value={inst.value}>{inst.label}</option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Preventive Maintenance</label>
              <input
                type="checkbox"
                className="addproperty-checkbox"
                checked={routineService}
                onChange={handleSetRoutineSevice}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Autoclaved</label>
              <input
                type="checkbox"
                className="addproperty-checkbox"
                checked={autoClave}
                onChange={handleAutoClave}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Description</label>
              <input
                className="addproperty-form-textarea-normal"
                type="text"
                placeholder="Description"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="addproperty-dialog-action">
            <button className="addproperty-button" onClick={handleSubmit}>
              Send For Service
            </button>
            <button className="cancel-button" onClick={handleShowHide}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SendToService;
