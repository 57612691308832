import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import { connect } from "react-redux";
import HospitalTable from "./HospitalTable";
import AddHospital from "../../components/addcomponents/AddHospital";
import { fetchProcedureDetails } from "../../store/actionCreator";
import Pagination from "@mui/material/Pagination";

import "../../css/CommonStyle.css";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import Loader from "../../components/Loader";

export const Hospitals = (props) => {
  if (props.procedures.length === 0) {
    props.fetchProcedureDetails();
  }

  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hospital_name, setHospitalName] = useState("");
  const [zone_name, setZoneName] = useState("all");
  const [system_no, setSystemNoName] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [openAddHospitalModal, setOpenAddHospitalModal] = useState(false);

  const searchHospital = (name, zone, system_no) => {
    if (name === "" && zone === "" && system_no === "") {
      setHospitalList([]);
      setSearchToggle(!searchToggle);
      return null;
    }
    let hospital_search_url = `${config.baseUrl}${config.ver}${config.hospitalList}?`;
    if (name !== "") {
      hospital_search_url += `name=${name}`;
    }
    if (zone !== "") {
      hospital_search_url += `${name !== "" ? "&" : ""}area_zone=${zone}`;
    }
    if (system_no !== "") {
      hospital_search_url += `${
        name !== "" || zone !== "" ? "&" : ""
      }system_no=${system_no}`;
    }
    httpGetReq(hospital_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setHospitalList(response.data.data);
          setSearchToggle(!searchToggle);
        } else if (response.data.available === 0) {
          setHospitalList([]);
          setSearchToggle(!searchToggle);
        }
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getHospitalList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const getHospitalList = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.hospitalList}?limit=${limitPerPage}&page=${currentPage}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setHospitalList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const delayedSearchHospital = debounce(searchHospital, 500);

  const handleHospitalNameChange = (event) => {
    setHospitalName(event.target.value);
    delayedSearchHospital(event.target.value, zone_name, system_no);
  };

  const handleZoneNameChange = (event) => {
    setZoneName(event.target.value);
    delayedSearchHospital(hospital_name, event.target.value, system_no);
  };

  const handleSystemNoChange = (event) => {
    setSystemNoName(event.target.value);
    delayedSearchHospital(hospital_name, zone_name, event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddHospitalModal(false);
    }
  };

  return (
    <div className="main-data-container">
      <div key="0" className="add-data-button-container">
        <button
          className="add-data-button"
         
          onClick={() => setOpenAddHospitalModal(true)}
        >
          <AddHomeRoundedIcon />
          <span style={{ marginLeft: "10px" }}>Add Hospital</span>
        </button>
        <AddHospital open={openAddHospitalModal} onClose={handleClose} />
      </div>
      <div key="1" className="search-bar-container">
        <div className="search-bar-keep-right">
        <select
            className="search-bar-input-dropdown"
            id="area-zone-label"
            placeholder="Area Zone"
            value={zone_name}
            onChange={handleZoneNameChange}
          >
            <option key={""} value={""}>
              Select Area Zone
            </option>
            {ConVar.hospitalZone.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <input
            className="search-bar-input-text"
            id="hospital_name"
            type="text"
            size="small"
            placeholder="Hospital name"
            value={hospital_name}
            onChange={handleHospitalNameChange}
          />
          <input
            className="search-bar-input-text"
            id="system_no"
            type="number"
            placeholder="System number"
            size="small"
            value={system_no}
            onChange={handleSystemNoChange}
          />
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              searchHospital(hospital_name, zone_name, system_no);
            }}
          >
            <SearchIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              getHospitalList();
              setHospitalName("");
              setZoneName("");
              setSystemNoName("");
            }}
          >
            <RestartAltRoundedIcon />
          </button>
        </div>
        <div className="pagination-setup">
          Page
          <Pagination
            className="pagination-setup-button"
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
          />
        </div>
      </div>
      <div key="2">
        {loading ? (
          <div>
            <Loader />
          </div>
          ) : (
          <>
          <HospitalTable key={searchToggle} data={hospitalList} />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcedureDetails: (callback) =>
      fetchProcedureDetails(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hospitals);
