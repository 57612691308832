import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import { connect } from "react-redux";

const HospitalDetail = (props) => {
  let procedureName = {};

  let system_no = props.hospitalInfo.system_no;
  const [loading, setLoading] = useState(true);
  const [surgeonList, setSurgeonList] = useState();
  const [procedureList, setProcedureList] = useState();
  const [total, setTotal] = useState("");

  if (props.procedures && props.procedures.length) {
    props.procedures.forEach((val) => {
      procedureName[val.procedure_code] = val.procedure_name;
    });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getNumberOfCaseInHospital();
      getTotalSurgeonsList();
      getSurgeryProcedureWise();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getNumberOfCaseInHospital = (arg) => {
    let url = config.baseUrl + config.ver + config.surgeryBySystemNo + system_no;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setTotal(response.data.total_surgeries);
        }
      }
    });
  };

  const getTotalSurgeonsList = (arg) => {
    let url = config.baseUrl + config.ver + config.surgeonList + "?system_no=" + props.hospitalInfo.system_no;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setSurgeonList(response.data.data);
          //setTotal(response.data.total);
          //setTotal(response.data.total_surgeries);
        }
      }
    });
  };

  const getSurgeryProcedureWise = (arg) => {
    let url = config.baseUrl + config.ver + config.allProcedureInHospital + "/" + props.hospitalInfo.system_no.toLowerCase();
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          console.log("procedure");
          console.log(response);
          setProcedureList(response.data.procedures);
          //setTotal(response.data.total);
          //setTotal(response.data.total_surgeries);
        }
      }
    });
  };

  return (
    <>
      <div className="inner-details-expand-styling">
        <div>
          <strong>Total Number of Surgeries:</strong> {total}
        </div>
        <div>
          <strong>Available Surgeons:</strong>
          {surgeonList &&
            surgeonList.map((surgeon) => (
              <div>
                <span>
                  {surgeon.surgeon_name} {"  ("} {surgeon.area_of_expertise}
                  {")"}
                </span>
              </div>
            ))}
        </div>
        <div>
          <strong>Procedures and their Count:</strong>
          {procedureList &&
            procedureList.map((proc) => (
              <div>
                <span>
                  {proc._id} {"  => "}
                  {procedureName[proc._id] ? procedureName[proc._id] : ""}
                  {"  <= "} {proc.count}
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    procedures: state.procedures,
  };
};
export default connect(mapStateToProps)(HospitalDetail);
