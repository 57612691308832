import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import "../../css/MudraDashboard.css";
import Lock from "../../components/Lock";

export const MudraDashboard = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalInstrumentManufactured, setTotalInstrumentManufactured] = useState();
  const [totalWareHouse, setTotalWareHouse] = useState();
  const [totalInHospital, setTotalInHospital] = useState();
  const [totalService, setTotalService] = useState();
  const [totalDiscard, setTotalDiscard] = useState();
  const [totalMinor, setTotalMinor] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getTotalNubmerofInstrumentManufactured();
      getTotalNubmerofInstrumentWarehouse();
      getTotalNubmerofInstrumentInHospital();
      // getTotalNubmerofInstrumentInMinor();
      getTotalNubmerofInstrumentInService();
      getTotalNubmerofInstrumentInScrap();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const getTotalNubmerofInstrumentManufactured = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalInstrument}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalInstrumentManufactured(response.data.total);
      }
    });
  };

  const getTotalNubmerofInstrumentWarehouse = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalWarehouse}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalWareHouse(response.data.total);
      }
    });
  };

  const getTotalNubmerofInstrumentInMinor = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalMinor}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalMinor(response.data.total);
      }
    });
  };

  const getTotalNubmerofInstrumentInService = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalInService}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalService(response.data.total);
      }
    });
  };

  const getTotalNubmerofInstrumentInHospital = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalInHospital}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalInHospital(response.data.total);
      }
    });
  };

  const getTotalNubmerofInstrumentInScrap = () => {
    let url = `${config.baseUrl}${config.ver}${config.totalInDiscard}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setTotalDiscard(response.data.total);
      }
    });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
        <div className="mudra-dashbaord-container">
          <div className="dashboarddata">
            <div className="dashboarddata-top"> {totalInstrumentManufactured} </div>
            <div className="dashboarddata-bottom"> <label>Instrument Manufactured</label> </div>
          </div>
          <div className="dashboarddata">
            <div className="dashboarddata-top"> {totalWareHouse} </div>
            <div className="dashboarddata-bottom"> <label>Warehouse</label> </div>
          </div>
          <div className="dashboarddata">
            <div className="dashboarddata-top"> {totalService} </div>
            <div className="dashboarddata-bottom"> <label>In Service</label> </div>
          </div>
          <div className="dashboarddata">
            <div className="dashboarddata-top"> {totalDiscard} </div>
            <div className="dashboarddata-bottom"> <label>Scrap</label> </div>
          </div>
          <div className="dashboarddata">
            <div className="dashboarddata-top"> {totalInHospital} </div>
            <div className="dashboarddata-bottom"> <label>Hospitals</label> </div>
          </div>
          {/* <div className="gbox">In Minor<br></br>Total : {totalMinor}</div> */}
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
