import React, { useState, useEffect } from "react";
import { convertTimestampToDateTime, getTaskStatus } from "../../util/util";
import configUrl from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import '../../css/AllTask.css';

import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const limitPerPage = 10;

const TaskList = () => {
    const [tasks, setTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filterName, setFilterName] = useState("");
    const [filterSystem, setFilterSystem] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [noDataFound, setNoDataFound] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [taskDetail, setTaskDetail] = useState(null);

    const TaskStatusOptions = ConVar.taskTypesStatusTypes.map((obj) => {
        return { value: obj.value, label: obj.label };
    });

    const fetchTaskDetail = async (taskId) => {
        try {
            const url = `${configUrl.baseUrl}${configUrl.ver}/task/${taskId}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            setTaskDetail(responseData.data);
        } catch (error) {
            console.error("Error fetching task details:", error);
        }
    };

    const MakeActions = ({ taskDetail }) => {
        if (!taskDetail || !taskDetail.action_type) return null;

        const { action_type, action_time, date, verified_by, suggested_by, observation, remarks } = taskDetail;

        switch (action_type) {
            case "assigned":
            case "task_start":
            case "task_end":
                return (
                    <div>
                        {action_type} on {convertTimestampToDateTime(action_time)}
                    </div>
                );
            case "task_process":
                return (
                    <div>
                        {action_type} on {convertTimestampToDateTime(date)} verified_by:{" "}
                        {verified_by && verified_by.employee_id ? verified_by.employee_id.toString() : ""} suggested_by:{" "}
                        {suggested_by ? suggested_by.toString() : ""}
                    </div>
                );
            default:
                return (
                    <div>
                        <hr />
                        <hr />
                        <div>
                            <span className="g1"> observation:</span>
                            {observation ? observation : ""}
                        </div>
                        <hr />
                        <div>
                            <span className="g1">remarks:</span>
                            {remarks ? remarks : ""}
                        </div>
                        <hr />
                        <div>
                            <span className="g1">suggested_by:</span>
                            {suggested_by ? suggested_by : ""}
                        </div>
                        <hr />
                        <div>
                            <span className="g1"> verified_by:</span>
                            {verified_by && verified_by.employee_id ? verified_by.employee_id.toString() : ""}
                        </div>
                    </div>
                );
        }
    };

    const renderDetail = () => {
        if (!taskDetail || !taskDetail.actions) return null;

        return (
            <div className="expanded-row">
                {taskDetail.actions.map((taskD, index) => (
                    <MakeActions key={index} taskDetail={taskD} />
                ))}
            </div>
        );
    };

    const fetchTasks = async () => {
        try {
            const url = `${configUrl.baseUrl}${configUrl.ver}${configUrl.myTaskList}?limit=${limitPerPage}&page=${currentPage}&name=${filterName}&system_no=${filterSystem}&status=${filterStatus}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTasks(data.data);
            setTotalPages(Math.ceil(data.available / limitPerPage));
            setNoDataFound(data.data.length === 0);
            setExpandedRows([]);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, [currentPage, filterName, filterStatus, filterSystem]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchTasks();
    };

    const handleReset = () => {
        setFilterName("");
        setFilterStatus("");
        setFilterSystem("");
        setCurrentPage(1);
        fetchTasks();
    };

    const toggleRow = (taskId) => {
        setExpandedRows((prevRows) => {
            if (prevRows.includes(taskId)) {
                return prevRows.filter((id) => id !== taskId);
            } else {
                fetchTaskDetail(taskId);
                return [...prevRows, taskId];
            }
        });
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === currentPage ? "active" : ""}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="all-task-container-new">
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Enter System No"
                    value={filterSystem}
                    onChange={(e) => setFilterSystem(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Enter Task Name"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                />
                <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                >
                    <option value="">Select Status</option>
                    {TaskStatusOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <Button onClick={handleSearch} startIcon={<SearchIcon />}> </Button>
                <Button onClick={handleReset} startIcon={<RestartAltIcon />}> </Button>
            </div>

            {noDataFound ? (
    <div className="no-data-container">
        <p>No data found</p>
    </div>
) : (
    <div className="table-container">
        <table className="custom-table">
            <thead>
                <tr>
                    <th className="table-header">Task Name</th>
                    <th className="table-header">Location</th>
                    <th className="table-header">Assign Time</th>
                    <th className="table-header">Last Updated</th>
                    <th className="table-header">Status</th>
                    <th className="table-header">Actions</th>
                </tr>
            </thead>
            <tbody>
                {tasks.map((task) => (
                    <React.Fragment key={task._id}>
                        <tr>
                            <td>{task.task_name}</td>
                            <td>
                                <span>
                                    {task.hospital_name} ( {task.system_no} )
                                </span>
                            </td>
                            <td>{convertTimestampToDateTime(task.assign_time)}</td>
                            <td>{convertTimestampToDateTime(task.last_updated)}</td>
                            <td>{getTaskStatus(task.status)}</td>
                            <td>
                                <button className="toggle-button" onClick={() => toggleRow(task._id)}>
                                    {expandedRows.includes(task._id) ? "➖" : "➕"}
                                </button>
                            </td>
                        </tr>
                        {expandedRows.includes(task._id) && (
                            <tr>
                                <td colSpan="6">
                                   
                                        {renderDetail()}
                                
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
        <div className="pagination-container">
            <p>
                Showing {currentPage * limitPerPage - limitPerPage + 1} to{" "}
                {Math.min(currentPage * limitPerPage, totalPages * limitPerPage)}{" "}
                of {totalPages * limitPerPage} entries
            </p>
            <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className="pagination-button"
            >
                Previous
            </button>
            {generatePageNumbers().map((pageNumber, index) => (
                <React.Fragment key={index}>{pageNumber}</React.Fragment>
            ))}
            <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className="pagination-button"
            >
                Next
            </button>
        </div>
    </div>
)}

        </div>
    );
};

export default TaskList;
