import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AssignMaintenance from "./AssignMaintenance";
import { httpGetReq, httpPostReq } from "../../util/request";
import Pagination from "@mui/material/Pagination";
import { convertTimestampToDate } from "../../util/util";
import config from "../../config/configUrl";
import "../../css/CallContainer.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded";
import Lock from "../../components/Lock";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";

export const MaintenanceContainer = (props) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [callList, setCallList] = useState([]);
  const [allHospitalList, setAllHospitalList] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [showConvertDialog, setShowConvertDialog] = useState(false);

  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [frequency, setFrequency] = useState(15);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [openScheduleMaintenance, setOpenScheduleMaintenance] = useState(false);
  const [nameHere, setNameHere] = useState("");
  const [new_date, setnew_Date] = useState(null);
  const [maintDay, setMainDay] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getAllHospital();
    filteredHospitalList();

    const searchParams = new URLSearchParams(location.search);
    const callID = searchParams.get("call");
    if (callID) {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [callID]: true,
      }));
    }
  }, [currentPage]);

  const handleDayFrequency = (event) => {
    setMainDay(event.target.value);
  };

  const getAllHospital = (arg) => {
   
    let url = `${config.baseUrl}${config.ver}${config.allMaintenance}?limit=${limitPerPage}&page=${currentPage}`;

    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setAllHospitalList(response.data.data);
        // setTotalRecords(response.data.total);
        // setDataInPage(response.data.data.length);
        // const totalPages = Math.ceil(response.data.total / limitPerPage);
        // setTotalPages(totalPages);
      }
    });
  };

  const filteredHospitalList = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.todayMaintenanceList}?frequency=${frequency}&limit=${limitPerPage}&page=${currentPage}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setCallList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        const totalPages = Math.ceil(response.data.total / limitPerPage);
        setTotalPages(totalPages);
      }
    });
  };

  const handleAssignMaintenance = (callData) => {
    setSelectedCall(callData);
    setShowConvertDialog(true);
  };

  const handleCloseConvertDialog = () => {
    setSelectedCall(null);
    setShowConvertDialog(false);
  };

  const handleConvertSuccess = () => {
    setShowConvertDialog(false);
    getAllHospital();
  };

  const handleNameChange = (newName) => {
    setNameHere(newName);
  };
  const handleUpdate = (id) => {
    let url = `${config.baseUrl}${config.ver}${config.test}${id}`;
    httpPostReq(
      url,
      {
        last_done: new Date(new_date).getTime(),
        frequency: maintDay,
      },
      "application/json",
      (response) => {
        if (response.code === "ERR_BAD_REQUEST") {
          console.log("Unable to generate call ,Try after sometime :)");
        } else if (
          response &&
          response.data &&
          response.data.status === "fail"
        ) {
          console.log("Unable to generate call ,Try after sometime :)");
        } else if (response.data.status === "success") {
          console.log("huaaa");
        }
      }
    );
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));

    const callID = encodeURIComponent(rowId);
    if (!expandedRows[rowId]) {
      navigate(`${location.pathname}?call=${callID}`);
    } else {
      navigate(location.pathname);
    }
  };

  if (
    props.UserInfo.user_type === "service-engineer" ||
    props.UserInfo.user_type === "clinical-support" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
        {/* <div className="add-data-button-container">
        </div> */}
         <h4>Page is under Development and Testing...</h4>
        <div key="1" className="search-bar-container">
          <div className="search-bar-keep-right">
            <p className="maintenance-header">{nameHere}</p>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>
        <div className="double-container2">
          <div className="call-list-container-maintenance">
            <table className="call-table">
              <thead>
                <tr>
                  <th>Hospital Name</th>
                  <th>System Number</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="call-table-body">
                {callList.length ? (
                  <>
                    {callList.map((row) => (
                      <>
                        <tr key={row._id} data={callList} onClick={() => {}}>
                          <td className="r20start">{row.name}</td>
                          <td className="r20">{row.system_no}</td>
                          <td className="r20">{row.location}</td>
                          <td className="r10end">
                            <button
                              onClick={() => {
                                toggleRow(row._id);
                              }}
                            >
                              {expandedRows[row._id] ? (
                                <ExpandLessIcon className="chevron-right-icon" />
                              ) : (
                                <ExpandMore className="expand-more-icon" />
                              )}
                            </button>
                            <button
                              className="red-button"
                              onClick={() => handleAssignMaintenance(row)}
                            >
                              <ChangeCircleRoundedIcon />
                            </button>
                          </td>
                        </tr>
                        {expandedRows[row._id] && (
                          <tr>
                            <td colspan="8" className="inner-property-expand">
                              <div className="inner-details-expand-styling">
                                <label>
                                  <strong>Location : </strong> {row.location}
                                </label>
                                <label>
                                  <strong>System : </strong> {row.system_no}
                                </label>
                                Date:
                                <input
                                  type="date"
                                  value={new_date}
                                  onChange={(e) => setnew_Date(e.target.value)}
                                />
                                <button
                                  onClick={() => {
                                    handleUpdate(row._id);
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no-match-found">Oops ! No Match Found 😁</div>
                )}
              </tbody>
            </table>
          </div>
          ========================================================================================================================
          <div className="call-list-container-maintenance">
            <table className="call-table">
              <thead>
                <tr>
                  <th>Hospital Name</th>
                  <th>Last M-15</th>
                  {/* <th>Last M-30</th> */}
                  <th>System Number</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="call-table-body">
                {allHospitalList.length ? (
                  <>
                    {allHospitalList.map((row) => (
                      <>
                        <tr
                          key={row._id}
                          data={allHospitalList}
                          onClick={() => {}}
                        >
                          <td className="r20start">{row.name ? row.name : ''}</td>
                          {/* <td className="r20">
                            {convertTimestampToDate(row.fort_night_checked.last_done )}{'<====>'}{convertTimestampToDate(Date.now())} {'Gap : '}{  Math.floor((Date.now()-row.fort_night_checked.last_done)/86400000)
                            }
                            &nbsp; &nbsp;Scheduele:
                            {row.fort_night_checked.is_scheduled ? "YES" : "NO"}
                          </td> */}
                          {/* <td className="r20">
                            {convertTimestampToDate(
                              row.monthly_checked.last_done
                            )}{" "}
                            &nbsp; &nbsp;Scheduele:
                            {row.monthly_checked.is_scheduled ? "YES" : "NO"}
                          </td> */}
                          
                          <td className="r20">{row.system_no}</td>
                          <td className="r20">{row.location}</td>

                          <td className="r10end">
                            <button
                              onClick={() => {
                                toggleRow(row._id);
                              }}
                            >
                              {expandedRows[row._id] ? (
                                <ExpandLessIcon className="chevron-right-icon" />
                              ) : (
                                <ExpandMore className="expand-more-icon" />
                              )}
                            </button>
                            <button
                              className="red-button"
                              onClick={() => handleAssignMaintenance(row)}
                            >
                              <ChangeCircleRoundedIcon />
                            </button>
                          </td>
                        </tr>
                        {expandedRows[row._id] && (
                          <tr>
                            <td colspan="8" className="inner-property-expand">
                              <div className="inner-details-expand-styling">
                                <label>
                                  <strong>Location : </strong> {row.location}
                                </label>
                                <label>
                                  <strong>System : </strong> {row.system_no}
                                </label>
                                <span>
                                  Maintenance
                                  <label>
                                    <input
                                      type="radio"
                                      name="maintDay"
                                      value={15}
                                      onChange={handleDayFrequency}
                                    />
                                    15 Day
                                  </label>
                                  <label>
                                    <input
                                      type="radio"
                                      name="maintDay"
                                      value={30}
                                      onChange={handleDayFrequency}
                                    />
                                    30 Day
                                  </label>
                                </span>
                                Date:
                                <input
                                  type="date"
                                  value={new_date}
                                  onChange={(e) => setnew_Date(e.target.value)}
                                />
                                <button
                                  onClick={() => {
                                    handleUpdate(row._id);
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no-match-found">Oops ! No Match Found 😁</div>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="side-data-container-maintenance">
            <button
              className="side-data-maintenance"
              onClick={() => handleNameChange("Bi-Weekly Maintenance")}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Bi-Weekly Maintenance</span>
            </button>
            <button
              className="side-data-maintenance"
              onClick={() => handleNameChange("Monthly Maintenance")}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Monthly Maintenance</span>
            </button>
            <button
              className="side-data-maintenance"
              onClick={() => handleNameChange("Semi Annual Maintenance")}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Semi Annual Maintenance</span>
            </button>
            <button
              className="side-data-maintenance"
              onClick={() => handleNameChange("Annual Maintenance")}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Annual Maintenance</span>
            </button>
          </div> */}
        </div>
        {showConvertDialog && (
          <AssignMaintenance
            callData={selectedCall}
            openModal={showConvertDialog}
            onCloseModal={handleCloseConvertDialog}
            onSuccess={handleConvertSuccess}
          />
        )}
      </div>
    );
  } else {
    return <Lock />;
  }
};
