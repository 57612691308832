import React, { useState, useEffect } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq, httpGetReq } from "../../util/request";
import { numberToMonthName } from "../../util/util";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { Dialog } from "@mui/material";
import "../../css/AddInstrument.css";

const SendAgainHospital = (props) => {
  const [open, setOpen] = useState(props.show_state);
  const [error, setError] = useState("");
  const [hospital, setHospital] = useState();
  const [dateOfDispatch, setDateOfDispatch] = useState();
  const  [useCount,setUseCount] = useState(props.uses_count);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);

  useEffect(() => {
    getHospitalList();
  }, []);

  useEffect(() => {
    setFilteredHospitals(
      hospitalList.filter((hospital) => hospital.area_zone === selectedZone)
    );
  }, [selectedZone, hospitalList]);

  const getHospitalList = () => {
    const url =
      config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setHospitalList(response.data.data);
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  const handleSubmit = async () => {
    let transferToHospitalUrl = config.baseUrl + config.ver + config.sendHospitalAgain + props.longId;
    let postobj = {
      date_of_dispatch: dateOfDispatch,
      last_updated: Date.now(),
      hospital: hospital,
      uses_count:useCount,
      current_hospital: hospital.substr(-8, 7),
    };

    httpPostReq(transferToHospitalUrl, postobj, "application/json", (response) => {
      console.log(response);
      if (response.data && response.data.status && response.data.status === "success") {
        setWarning("");
        setMessage("User Added successfully.");
        props.onClose();
      } else if (response.data && response.data.status && response.data.status === "fail") {
        setMessage(response.data.message);
        setWarning("");
      }
    });
  };

  return (
    <div className="add-data-button-container">
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        className="addproperty-dialog"
      >
        <div className="addproperty-container">
          <div className="addproperty-form-title">Send Again Instruments</div>
          <div className="addproperty-form">
            {warning && <div className="error-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="addproperty-form-control">
              <label>Zone</label>
              <select
                onChange={(e) => setSelectedZone(e.target.value)}
                className="addproperty-select"
              >
                <option key="" value="">
                  Select Zone
                </option>
                {ConVar.hospitalZone.map((zone) => (
                  <option key={zone.value} value={zone.value}>
                    {zone.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Hospital Name</label>
              <select
                onChange={(e) => setHospital(e.target.value)}
                value={hospital}
                className="addproperty-select"
              >
                <option key="" value="">
                  Select Hospital
                </option>
                {filteredHospitals.map((hospital) => (
                  <option key={hospital.id} value={hospital.id}>
                    {hospital.name}, {hospital.location} ({hospital.system_no})
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Number Of Use</label>
              <input
                className="addproperty-text-field"              
                type='number'
                value={useCount}
                onChange={(e) => {                
                  setUseCount(e.target.value);
                }}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Date of Dispatch</label>
              <input
                className="addproperty-date-picker"              
                type="date"
                onChange={(e) => {
                  let selectedDate = new Date(e.target.value);
                  setDateOfDispatch(selectedDate.getTime());
                }}
              />
            </div>
            
          </div>
          <div className="addproperty-dialog-action">
            <button
              type="button"
              className="addproperty-button"
              onClick={handleSubmit}
            >
              Send To Hospital
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SendAgainHospital;
