import * as actionTypes from "./actions";
const initialState = {
  users: [],
  hospitals: [],
  userDetails: {},
  procedures: [],
  surgeons: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case actionTypes.FETCH_HOSPITALS:
      return {
        ...state,
        hospitals: action.payload,
      };
    case actionTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case actionTypes.PROCEDURE_DETAILS:
      return {
        ...state,
        procedures: action.payload,
      };
    case actionTypes.SURGEON_DETAILS:
      return {
        ...state,
        surgeons: action.payload,
      };
    case actionTypes.USER_AUTHENTICATE:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
