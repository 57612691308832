import React, { useState, useEffect } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq, httpGetReq } from "../../util/request";
import { numberToMonthName } from "../../util/util";
import { Dialog } from "@mui/material";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import "../../css/AddInstrument.css";

const AddInstrument = (props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [production_date, setProductionDate] = useState();
  const [production_month, setProdcutionMonth] = useState();
  const [production_year, setProductionYear] = useState();
  const [category, setCategory] = useState("");
  const [label, setLabel] = useState("");
  const [uniqueNo, setUniqueNo] = useState("");
//  const [partsNo, setPartsNo] = useState("");
  const [testedBy, setTestedBy] = useState("");
  const [nameList, setNameList] = useState([]);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [mudraList, setMudraList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    getMudraList();
  }, []);

  const getMudraList = () => {
    const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };

  const handleSubmit = async () => {
    let addInstrumentNewUrl = config.baseUrl + config.ver + config.instrumentNew;

    let postobj = {
      production_date: production_date,
      purchase_order_date: 0,
      category: category,
      label: label,
      production_year: production_year,
      production_month: production_month.toLowerCase(),
      //parts_no: partsNo,
      instrument_unique_no: uniqueNo,
      status: 1,
      uses_count: 10,
      tested_by: testedBy,
    };

    httpPostReq(
      addInstrumentNewUrl,
      postobj,
      "application/json",
      (response) => {
        console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setWarning("");
          setMessage("User Added successfully.");
          setOpen(false);
          window.location.reload();
          return null;
        } else if (
          response.data &&
          response.data.status &&
          response.data.status === "fail"
        ) {
          setMessage(response.data.message);
          setWarning("");
          return null;
        }
      }
    );
  };

  const handleShowHide = () => {
    setOpen(!open);
  };

  const handleCategoryChange = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
    setCategory(selectedCategory);
    const filteredNames = mudraList.filter((mudra) => mudra.category === selectedCategory).map((mudra) => mudra.label);
    setNameList(filteredNames);
    setSelectedName("");
  };

  return (
    <div className="add-data-button-container">
      <button className="add-data-button" onClick={handleShowHide}>
        <PersonAddAltRoundedIcon />
        <span style={{ marginLeft: "10px" }}>Add Instruments</span>
      </button>
      <Dialog open={open} onClose={handleShowHide} className="addproperty-dialog" >
        <div className="addproperty-container">
          <div className="addproperty-form-title">Add Instruments</div>
          <div className="addproperty-form">
            {warning && <div className="error-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="addproperty-form-control">
              <label>Date of Production</label>
              <input
                className="addproperty-date-picker"
                type="date"
                onChange={(e) => {
                  let selectedDate = new Date(e.target.value);
                  setProductionDate(selectedDate.getTime());
                  setProdcutionMonth(
                    numberToMonthName(selectedDate.getMonth() + 1)
                  );
                  setProductionYear(selectedDate.getFullYear());
                }}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Instrument Category</label>
              <select
                onChange={(e) => handleCategoryChange(e.target.value)}
                className="addproperty-select"
              >
                <option key="" value="">Select Category</option>
                {ConVar.instrumentCategory.map((kdx) => (
                  <option key={kdx.value} value={kdx.value}>{kdx.label}</option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Instrument Name</label>
              <select
                className="addproperty-select"
                value={selectedName}
                onChange={(e) => {
                  setSelectedName(e.target.value);
                  setLabel(e.target.value);
                }}
              >
                <option key="" value="">Select Name</option>
                {nameList.map((name, index) => (
                  <option key={index} value={name}>{name}</option>
                ))}
              </select>
            </div>
            {/* <div className="addproperty-form-control">
              <label>Parts No (version)</label>
              <input
                type="text"
                placeholder="Parts No (version)"
                className="addproperty-text-field"
                onChange={(e) => {
                  setPartsNo(e.target.value);
                }}
              />
            </div> */}
            <div className="addproperty-form-control">
              <label>Instrument Unique ID</label>
              <input
                type="text"
                placeholder="Instrument Unique ID"
                className="addproperty-text-field"
                onChange={(e) => {
                  setUniqueNo(e.target.value);
                }}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Tested and Verified By</label>
              <select
                className="addproperty-select"
                onChange={(e) => {
                  setTestedBy(e.target.value);
                }}
              >
                <option key={""} value={""}> Tested and Verified By </option>
                <option key="cate-0" value="debasmita.parida@ssinnovations.org"> Debasmita </option>
                <option key="cate-1" value="kunal.indra@ssinnovations.org"> Kunal </option>
                <option key="cate-2" value="other"> Other </option>
              </select>
            </div>
          </div>
          <div className="addproperty-dialog-action">
            <button type="button" className="addproperty-button" onClick={handleSubmit}>
              Add Instrument
            </button>
            <button type="button" className="cancel-button" onClick={handleShowHide}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddInstrument;
