import React, { useEffect, useState, useRef } from "react";
import { httpGetReq, httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { PreOperationData, MantraOperationData, PostOperationData, } from "./TimeTestingData";

import "../../css/TimeTesting.css";
import { Dialog, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";
import Button from "@mui/material/Button";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ReactSelect from "react-select";

const TimeTesting = ({ open, onClose }) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [surgeonList, setSurgeonList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [mudraList, setMudraList] = useState([]);
  const [selectedField, setSelectedField] = useState("");
  const [selectedTextField, setSelectedTextField] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [formData, setFormData] = useState({});
  const inputRefs = useRef({});
  const [selectedStepLabel, setSelectedStepLabel] = useState("");
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [interruptStartTime, setInterruptStartTime] = useState(null);
  const [missingProperties, setMissingProperties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [postData, setPostData] = useState({});
  const [userComment, setUserComment] = useState("");
  const [editableFields, setEditableFields] = useState({});
  const [interruptionHistory, setInterruptionHistory] = useState([]);

  useEffect(() => {
    const savedData = localStorage.getItem("timeTestingFormData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData.formData || {});
      setSelectedTextField(parsedData.selectedTextField || {});
      setInterruptionHistory(parsedData.interruptionHistory || []);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Autosaving...");
      handleAutoSave();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [formData, selectedTextField]);

  const handleAutoSave = () => {
    const savedData = {
      formData: formData,
      selectedTextField: selectedTextField,
      interruptionHistory: interruptionHistory
    };
    localStorage.setItem("timeTestingFormData", JSON.stringify(savedData));
  };

  useEffect(() => {
    const calculateLengthOfStay = () => {
      const admissionDate = new Date(formData.date_of_admission);
      const dischargeDate = new Date(formData.date_of_discharge);

      if (!isNaN(admissionDate.getTime()) && !isNaN(dischargeDate.getTime())) {
        const timeDifference = dischargeDate.getTime() - admissionDate.getTime();
        const lengthOfStay = Math.ceil(timeDifference / (1000 * 3600 * 24));

        setFormData((prevState) => ({
          ...prevState,
          length_of_stay_days: lengthOfStay.toString(),
        }));
      }
    };

    calculateLengthOfStay();
  }, [formData.date_of_admission, formData.date_of_discharge]);

  const renderAccordion = (data, title, accordionKey) => (
    <Accordion
      expanded={selectedAccordion === accordionKey}
      onChange={() =>
        setSelectedAccordion(selectedAccordion === accordionKey ? null : accordionKey)
      }
    >
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>
        <form className="timetesting-form">{renderFormFields(data)}</form>
      </AccordionDetails>
    </Accordion>
  );

  useEffect(() => {
    getProcedureList();
    getHospitalList();
    getSurgeonList();
    getUserList();
    getMudraList();
  }, []);

  const getProcedureList = () => {
    const url = config.baseUrl + config.ver + config.procedureList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setProcedureList(response.data.data);
      } else {
        console.error("Error fetching procedure list:", response.data.message);
      }
    });
  };

  const getHospitalList = () => {
    const url = config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setHospitalList(response.data.data);
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  const getSurgeonList = () => {
    const url = config.baseUrl + config.ver + config.surgeonList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setSurgeonList(response.data.data);
      } else {
        console.error("Error fetching surgeon list:", response.data.message);
      }
    });
  };

  const getUserList = () => {
    const url = config.baseUrl + config.ver + config.userList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setUserList(response.data.data);
      } else {
        console.error("Error fetching users list:", response.data.message);
      }
    });
  };

  const getMudraList = () => {
    const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };

  // Timestamp method
  const handleTimeStamp = (fieldLabel) => {
    if (
      (fieldLabel.endsWith("End") || fieldLabel.endsWith("Off Time")) &&
      (!formData[fieldLabel.replace(/End|Off Time/, "Start")] ||
        !selectedTextField[fieldLabel.replace(/End|Off Time/, "Start")])
    ) {
      alert( `Please add the ${fieldLabel.replace( /End|Off Time/, "Start" )} first before adding the ${fieldLabel}.` );
      return;
    }

    if (fieldLabel.endsWith("Duration")) {  
      return;
    }

    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10);
    const formattedTime = now.toTimeString().slice(0, 8);
    const timeStamp = `${formattedDate} ${formattedTime}`;

    const fieldInSubMenu = [
      ...PreOperationData,
      ...MantraOperationData,
      ...PostOperationData,
    ].find((field) => {
      if (field.subType === "group") {
        return field.subMenu.some((subField) => subField.label === fieldLabel);
      }
      return false;
    });

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [fieldLabel]: timeStamp,
      };

      if (fieldInSubMenu) {
        updatedFormData[fieldInSubMenu.key] = {
          ...prevFormData[fieldInSubMenu.key],
          [fieldLabel]: timeStamp,
        };
      }

      if (fieldLabel.endsWith("End")) {
        const startTimeLabel = fieldLabel.replace(/End/, "Start");
        if (
          selectedTextField[startTimeLabel] &&
          selectedTextField[startTimeLabel] !== ""
        ) {
          const startTime = new Date(selectedTextField[startTimeLabel]);
          const endTime = now;
          const durationInMillis = endTime.getTime() - startTime.getTime();
          const durationInHumanReadable = convertToHumanReadableDuration(durationInMillis);

          const durationField = fieldInSubMenu.subMenu.find((subField) =>
            subField.label.endsWith("Duration")
          );

          if (durationField) {
            const durationFieldKey = durationField.key;
            setSelectedTextField((prevTimestamps) => ({
              ...prevTimestamps,
              [durationFieldKey]: durationInHumanReadable,
            }));

            setSelectedTextField((prevTimestamps) => ({
              ...prevTimestamps,
              [durationField.label]: durationInHumanReadable,
            }));
          } else {
            console.error("Duration field not found!");
          }
        }
      }

      return updatedFormData;
    });

    setSelectedTextField((prevTimestamps) => ({
      ...prevTimestamps,
      [fieldLabel]: timeStamp,
    }));
  };

  function convertToHumanReadableDuration(durationInMillis) {
    const milliseconds = parseInt((durationInMillis % 1000) / 100),
      seconds = Math.floor((durationInMillis / 1000) % 60),
      minutes = Math.floor((durationInMillis / (1000 * 60)) % 60),
      hours = Math.floor((durationInMillis / (1000 * 60 * 60)) % 24);

    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  const handleStepButtonClick = (stepLabel) => {
    setSelectedStepLabel(stepLabel);
  };

  // Function to handle editing of timestamp
  const handleEditTimestamp = (label) => {
    const isEndTimestamp = label.endsWith("End");
    const correspondingLabel = isEndTimestamp ? label.replace(/End/, "Start") : label.replace(/Start/, "End");

    if (editableFields[label]) {
      const timestamp = selectedTextField[label];
  
      if (!isValidTimestamp(timestamp)) {
        alert(`Invalid timestamp format. Please use "YYYY-MM-DD HH:MM:SS".`);
        return;
      }
  
      if (
        selectedTextField[correspondingLabel] &&
        selectedTextField[correspondingLabel] !== "" &&
        !isChronologicalOrder(
          isEndTimestamp ? selectedTextField[correspondingLabel] : timestamp,
          isEndTimestamp ? timestamp : selectedTextField[correspondingLabel]
        )
      ) {
        alert(`Start time cannot be greater than end time.`);
        return;
      }
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [label]: selectedTextField[label],
      }));
  
      if (
        selectedTextField[correspondingLabel] &&
        selectedTextField[correspondingLabel] !== ""
      ) {
        const startTime = new Date( isEndTimestamp ? selectedTextField[correspondingLabel] : timestamp );
        const endTime = new Date( isEndTimestamp ? timestamp : selectedTextField[correspondingLabel] );
        const durationInMillis = endTime.getTime() - startTime.getTime();
        const durationInHumanReadable = convertToHumanReadableDuration(durationInMillis);
  
        setSelectedTextField((prevTimestamps) => ({
          ...prevTimestamps,
          [label.replace(/(Start|End)/, "") + "Duration"]: durationInHumanReadable,
        }));
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          [label.replace(/(Start|End)/, "") + "Duration"]: durationInHumanReadable,
        }));
      }
    }
  
    setEditableFields((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };
  
  const isValidTimestamp = (timestamp) => {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return regex.test(timestamp);
  };
  
  const isChronologicalOrder = (startTimestamp, endTimestamp) => {
    const startTime = new Date(startTimestamp).getTime();
    const endTime = new Date(endTimestamp).getTime();
    return startTime <= endTime;
  };  

  // Console Interruption Function
  const handleSystemHaltTime = () => {
    if (!selectedTextField["Console Start"]) {
      alert("Please add the Console Start time first before noting the interruption.");
      return;
    }
  
    setIsDialogOpen(!isDialogOpen);
    if (!isDialogOpen) {
      setInterruptStartTime(new Date());
    }
  };  

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsDialogOpen(false);
    }
  };

  const endInterruption = () => {
    const interruptionEndTime = new Date();
    const durationInMillis = Math.abs(interruptionEndTime.getTime() - interruptStartTime.getTime());
    const durationInHumanReadable = convertToHumanReadableDuration(durationInMillis);
  
    const existingDuration = formData.console_interrupt ? convertToMillis(formData.console_interrupt) : 0;
    const totalDurationInMillis = existingDuration + durationInMillis;
    const totalDurationInHumanReadable = convertToHumanReadableDuration(totalDurationInMillis);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      console_interrupt: totalDurationInHumanReadable,
    }));
  
    setInterruptionHistory((prevHistory) => [
      ...prevHistory,
      {
        start: interruptStartTime.toISOString(),
        end: interruptionEndTime.toISOString(),
        duration: durationInHumanReadable,
        displayStart: interruptStartTime.toLocaleString(),
        displayEnd: interruptionEndTime.toLocaleString(),
      },
    ]);
  
    setIsDialogOpen(false);
  };

  function convertToMillis(humanReadableDuration) {
    const [hours, minutes, seconds] = humanReadableDuration.split(':').map(Number);
    return (hours * 3600000) + (minutes * 60000) + (seconds * 1000);
  }  

  // To check which field is selected
  const handleFieldClick = (label) => {
    const field = [
      ...PreOperationData,
      ...MantraOperationData,
      ...PostOperationData,
    ].find((item) => item.label === label);
    if (field && field.subType === "group") {
      const subField = field.subMenu.find((subItem) => subItem.label === label);
      if (subField) {
        setSelectedField(`${field.label} - ${subField.label}`);
      }
    } else {
      setSelectedField(label);
    }
  };

  // Conditional logic changes to few fields
  const handleInputChange = (key, value, instrumentIndex) => {
    if (["patient_in_time", "patient_out_time", "on_time", "start", "end", "off_time"].includes(key) && !isValidTimestamp(value)) {
      return;
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  
    if (["patient_in_time", "patient_out_time", "on_time", "start", "end", "off_time"].includes(key)) {
      setSelectedTextField((prevTimestamps) => ({
        ...prevTimestamps,
        [key]: value,
      }));
    }

    if (key === "procedure_category") {
      const selectedCategory = value;
      const filteredProcedures = procedureList.filter( (procedure) => procedure.procedure_category === selectedCategory ) || [];
      setFormData((prevState) => ({
        ...prevState,
        procedure_code: "",
        filteredProcedures: filteredProcedures,
      }));
    }

    if (key === "procedure_code") {
      const selectedProcedure = procedureList.find( (procedure) => procedure.procedure_code === value );
      if (selectedProcedure) {
        setFormData((prevState) => ({
          ...prevState,
          surgery_steps: selectedProcedure.surgery_steps,
        }));
      }
    }

    if (key === "zone_id") {
      const selectedZone = value;
      const filteredHospitals = hospitalList.filter( (hospital) => hospital.area_zone === selectedZone ) || [];
      setFormData((prevState) => ({
        ...prevState,
        hospital_name: "",
        system_no: "",
        surgeon_name: "",
        filteredHospitals: filteredHospitals,
      }));
    }

    if (key === "hospital_name") {
      const selectedHospital = value;
      const filteredSystemNumbers = hospitalList.filter((hospital) => hospital._id === selectedHospital) || [];
      setFormData((prevState) => ({
        ...prevState,
        system_no: "",
        surgeon_name: "",
        filteredSystemNumbers: filteredSystemNumbers,
      }));
    }

    if (key === "system_no") {
      const selectedSystemNo = value;
      const filteredSurgeonName = surgeonList.filter( (surgeon) => surgeon.system_no === selectedSystemNo ) || [];
      setFormData((prevState) => ({
        ...prevState,
        surgeon_name: "",
        filteredSurgeonName: filteredSurgeonName,
      }));
    }

    if (key === "weight" || key === "height") {
      const { weight, height } = { ...formData, [key]: value };
      const numericWeight = parseFloat(weight);
      const numericHeight = parseFloat(height);

      if (
        !isNaN(numericWeight) &&
        !isNaN(numericHeight)
      ) {
        const heightInMeters = numericHeight / 100;
        const bmi = (numericWeight / (heightInMeters * heightInMeters)).toFixed( 2 );
        setFormData((prevState) => ({
          ...prevState,
          bmi: bmi.toString(),
        }));
      }
    }

    if (key.startsWith("instrument_category_")) {
      const selectedCategory = value;
      const filteredInstruments = mudraList.filter((mudra) => mudra.category === selectedCategory) || [];
      setFormData((prevState) => ({
        ...prevState,
        [`filteredInstruments_${instrumentIndex}`]: filteredInstruments,
        [`instrument_used_${instrumentIndex}`]: "",
      }));
    }

    if (key.startsWith(`instrument_used_`)) {
      const selectedInstrument = mudraList.find((mudra) => mudra._id === value);
      if (selectedInstrument) {
        setFormData((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    }
  };

  const handleAddInstrument = () => {
    const instrumentIndex = (formData.instruments && formData.instruments.length) + 1 || 1;
    const newInstrument = {
      key: `instrument_${instrumentIndex}`,
      label: `Instrument ${instrumentIndex}`,
      subType: "group",
      noteTime: false,
      required: true,
      subMenu: [
        {
          key: `instrument_category_${instrumentIndex}`,
          label: `Instrument Category ${instrumentIndex}`,
          inputType: "text",
          noteTime: false,
          required: true,
        },
        {
          key: `instrument_used_${instrumentIndex}`,
          label: `Instruments Used ${instrumentIndex}`,
          inputType: "text",
          noteTime: false,
          required: true,
        },
        {
          key: `instrument_id_${instrumentIndex}`,
          label: `Instruments ID ${instrumentIndex}`,
          inputType: "text",
          noteTime: false,
          required: true,
        },
      ]
    };
  
    setFormData((prevState) => ({
      ...prevState,
      instruments: [...(prevState.instruments || []), newInstrument],
    }));
  };  
  
  const handleAddInstrumentIssueField = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      instruments: prevState.instruments.map((instrument, idx) =>
        idx === index ? { ...instrument, subMenu: [...instrument.subMenu, {
          key: `instrument_issue_${index+1}`,
          label: `Instrument Issue ${index+1}`,
          inputType: "text",
          noteTime: false,
          required: false,
        }] } : instrument
      ),
    }));
  };
  
  const handleRemoveInstrument = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      instruments: prevState.instruments.filter((_, index) => index !== indexToRemove),
    }));
  };
  
  const handleReportIssue = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      instruments: prevState.instruments.map((instrument, idx) =>
        idx === index ? { ...instrument, issue: true } : instrument
      ),
    }));
    handleAddInstrumentIssueField(index);
  };
  
  const handleIssueReportChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      instruments: prevState.instruments.map((instrument, idx) =>
        idx === index ? { ...instrument, issueReport: value } : instrument
      ),
    }));
  };

  // Render form fields along with few conditional field logic changes
  const renderFormFields = (fields, instrumentIndex) => {
    return fields.map((field, index) => (
      <div
        key={index}
        className={`form-field ${ selectedField === field.label ? "active" : "" }`}
        onClick={() => handleFieldClick(field.label)}
      >
        {field.key === "instruments" && field.subType === "group" ? (
          <label>
            {field.label}
            <button type="button" className="add-inst-group-button" onClick={() => handleAddInstrument()}>
              Add Instrument
            </button>
          </label>
        ) : (
          <label>
            {field.label}
            {field.noteTime && (
              <button
                disabled
                className="edit-timestamp-button"
              >
                <MoreTimeRoundedIcon />
              </button>
            )}
          </label>
        )}
        {field.subType === "group" ? (
          <div className="group-submenu">
            {(field.key !== "instruments" || !formData.instruments) &&
              renderFormFields(field.subMenu, instrumentIndex)}
            {field.key === "instruments" &&
              formData.instruments &&
              formData.instruments.map((instrument, idx) => (
                <div key={idx} className={`instrument-field ${instrument.issue ? "issue" : ""}`}>
                  <div className="instrument-content">
                    {renderFormFields(instrument.subMenu, idx + 1)}
                  </div>
                  <div className="action-buttons">
                    <button type="button" className="remove-inst-button" onClick={() => handleRemoveInstrument(idx)}>
                    <ClearRoundedIcon />
                    </button>
                    <button type="button" className="report-issue-button" onClick={() => handleReportIssue(idx)}>
                    <ReportGmailerrorredRoundedIcon />
                    </button>
                  </div>
                  {instrument.subMenu.find(item => item.key === `instrument_issue_${idx+1}` && item.visible) && (
                    <div className="issue-report">
                      <label>Instrument Issue:</label>
                      <input
                        type="text"
                        value={instrument.issueReport || ""}
                        onChange={(e) => handleIssueReportChange(e, idx)}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        ) : field.key === "procedure_category" ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select Procedure Category</option>
            {ConVar.procedureCategory.map((procedure) => (
              <option
                className="list-option"
                key={procedure.value}
                value={procedure.value}
              >
                {procedure.label}
              </option>
            ))}
          </select>
        ) : field.key === "procedure_code" && formData.filteredProcedures ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select Procedure Code</option>
            {formData.filteredProcedures.map((procedure) => (
              <option
                className="list-option"
                key={procedure._id}
                value={procedure.procedure_code}
              >
                {procedure.procedure_code} - {procedure.procedure_name}
              </option>
            ))}
          </select>
        ) : field.key === "zone_id" ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select Zone</option>
            {ConVar.hospitalZone.map((zone) => (
              <option
                className="list-option"
                key={zone.value}
                value={zone.value}
              >
                {zone.label}
              </option>
            ))}
          </select>
        ) : field.key === "hospital_name" && formData.filteredHospitals ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select Hospital</option>
            {formData.filteredHospitals.map((hospital) => (
              <option
                className="list-option"
                key={hospital._id}
                value={hospital._id}
              >
                {hospital.name}, {hospital.location}
              </option>
            ))}
          </select>
        ) : field.key === "system_no" && formData.filteredSystemNumbers ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select System No</option>
            {formData.filteredSystemNumbers.map((hospital) => (
              <option
                className="list-option"
                key={hospital._id}
                value={hospital.system_no}
              >
                {hospital.system_no}
              </option>
            ))}
          </select>
        ) : field.key === "present_clinical_people" ? (
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData[field.key] || []}
            onChange={(selectedOptions) => handleInputChange(field.key, selectedOptions)}
            isMulti
            options={userList
              .filter(user => user.user_type === "clinical-support")
              .map((user) => ({
                label: user.name,
                value: user._id
              }))
            }
          />
        ) : field.key === "present_service_people" ? (
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData[field.key] || []}
            onChange={(selectedOptions) => handleInputChange(field.key, selectedOptions)}
            isMulti
            options={userList
              .filter(user => user.user_type === "service-engineer")
              .map((user) => ({
                label: user.name,
                value: user._id
              }))
            }
          />
        ) : field.key === "surgeon_name" && formData.filteredSurgeonName ? (
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(selectedOption) => handleInputChange(field.key, selectedOption)}
            isMulti
            options={formData.filteredSurgeonName.map((surgeon) => ({
              label: surgeon.surgeon_name,
              value: surgeon._id
            }))}
          />
        ) : field.key === "assistant_surgeon" && formData.filteredSurgeonName ? (
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(selectedOption) => handleInputChange(field.key, selectedOption)}
            isMulti
            options={formData.filteredSurgeonName.map((surgeon) => ({
              label: surgeon.surgeon_name,
              value: surgeon._id
            }))}
          />
        ) : field.key.startsWith("instrument_category_") ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) =>
              handleInputChange(field.key, e.target.value, instrumentIndex)
            }
          >
            <option value="" >Select Instrument Category</option>
            {ConVar.instrumentCategory.map((category) => (
              <option
                className="list-option"
                key={category.value}
                value={category.value}
              >
                {category.label}
              </option>
            ))}
          </select>
        ) : field.key.startsWith(`instrument_used_`) && formData[`filteredInstruments_${instrumentIndex}`] ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value, instrumentIndex) }
          >
            <option value="" >Select Instrument Used</option>
            {formData[`filteredInstruments_${instrumentIndex}`].map(
              (instrument) => (
                <option
                  className="list-option"
                  key={instrument._id}
                  value={instrument.key}
                >
                  {instrument.short} - {instrument.label}
                </option>
              )
            )}
          </select>
        ) : field.key === "surgery_steps" ? (
          <div className="loaded-surgery-steps-container">
            {formData[field.key] &&
              formData[field.key].map((step) => (
                <div className="loaded-surgery-step" key={step.id}>
                  <label
                    className="loaded-surgery-step-label"
                    onClick={() => {
                      handleStepButtonClick(step.label);
                    }}
                  >
                    <span>{step.label}</span>
                  </label>
                  <input
                    type="text"
                    onClick={() => {
                      handleStepButtonClick(step.label);
                    }}
                    className="loaded-surgery-step-text"
                    value={selectedTextField[step.label] || ""}
                    disabled
                  />
                  <button
                    type="button"
                    className="edit-timestamp-button"
                    onClick={() => handleEditTimestamp(step.label)}
                  >
                    <EditRoundedIcon />
                  </button>
                </div>
              ))}
          </div>
        ) : field.inputType === "radio" ? (
          <div className="radio-button-container">
          {field.options.map((option, optionIndex) => (
            <div className="radio-button-inner" key={optionIndex}>
              <input
                type="radio"
                id={`${field.key}-${option}`}
                name={field.key}
                value={option}
                checked={formData[field.key] === option}
                onChange={(e) => handleInputChange(field.key, e.target.value)}
              />
              <label htmlFor={`${field.key}-${option}`}>{option}</label>
            </div>
          ))}
        </div>
        ) : field.key === "clavien" ? (
          <select
            className="timetesting-dropdownmenu"
            value={formData[field.key]}
            onChange={(e) => handleInputChange(field.key, e.target.value)}
          >
            <option value="" >Select Clavien Dindo Scale of Surgical Complications</option>
            {ConVar.typeOfClavien.map((complication) => (
              <option
                className="list-option"
                key={complication.value}
                value={complication.value}
              >
                {complication.label}
              </option>
            ))}
          </select>
        ) : (
          <>
            <input
              ref={(ref) => (inputRefs.current[field.label] = ref)}
              type={field.inputType === "datetime-local" ? "text" : field.inputType}
              placeholder={field.noteTime ? "HH:MM:SS" : ""}
              value={ !field.noteTime ? formData[field.key] || "" : selectedTextField[field.label] || "" }
              onChange={(e) => {
                const value = e.target.value;
                if (field.noteTime) {
                  setSelectedTextField((prevTimestamps) => ({
                    ...prevTimestamps,
                    [field.label]: value,
                  }));
                } else {
                  handleInputChange(field.key, value, instrumentIndex);
                }
              }}
              disabled={!editableFields[field.label] && (field.noteTime || field.key === "bmi" || field.key === "console_interrupt" || field.key === "length_of_stay_days")}
            />
            {field.noteTime && field.key !== "duration" && (
              <div className="timestamp-required">
                <button
                  type="button"
                  className="edit-timestamp-button"
                  onClick={() => handleEditTimestamp(field.label)}
                >
                  {editableFields[field.label] ? <DoneAllRoundedIcon/> : <EditRoundedIcon />}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    ));
  };

  const handleSubmit = async () => {
    setOpenDialog(true);
    const postData = {};

    const timestampRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    const timestampRegexOnlyTime = /^\d{2}:\d{2}:\d{2}$/;

    [...PreOperationData, ...MantraOperationData, ...PostOperationData].forEach(
      (section) => {
        if (section.subType === "group") {
          if (!postData[section.key]) {
            postData[section.key] = {};
          }
          section.subMenu.forEach((subItem) => {
            // Check if formData[subItem.key] exists before assigning it
            if (formData[subItem.key] !== undefined) {
              postData[section.key][subItem.key] = formData[subItem.key];
            }
            if (subItem.noteTime && selectedTextField[subItem.label]) {
              let timestamp = selectedTextField[subItem.label];
              if (typeof timestamp === "string") {
                if (timestamp.match(timestampRegex)) {
                  postData[section.key][subItem.key] = Math.floor(
                    new Date(timestamp).getTime()
                  );
                } else if (timestamp.match(timestampRegexOnlyTime)) {
                  const [hours, minutes, seconds] = timestamp.split(":").map(Number);
                  postData[section.key][subItem.key] =
                    hours * 3600000 + minutes * 60000 + seconds * 1000;
                } else {
                  postData[section.key][subItem.key] = timestamp;
                }
              } else {
                postData[section.key][subItem.key] = timestamp;
              }
            }
          });
        } else {
          if (formData[section.key] !== undefined) {
            postData[section.key] = formData[section.key];
          }
          if (section.noteTime && selectedTextField[section.label]) {
            let timestamp = selectedTextField[section.label];
            if (typeof timestamp === "string") {
              if (timestamp.match(timestampRegex)) {
                postData[section.key] = Math.floor(
                  new Date(timestamp).getTime()
                );
              } else if (timestamp.match(timestampRegexOnlyTime)) {
                const [hours, minutes, seconds] = timestamp.split(":").map(Number);
                postData[section.key] = hours * 3600000 + minutes * 60000 + seconds * 1000;
              } else {
                postData[section.key] = timestamp;
              }
            } else {
              postData[section.key] = timestamp;
            }
          }
        }
      }
    );    

   if(formData.filteredProcedures && formData.filteredProcedures.length){
    const selectedProcedure = formData.filteredProcedures.find(
      (procedure) => procedure.procedure_code === formData.procedure_code
    );

    if (selectedProcedure) {
      postData.procedure_name = selectedProcedure.procedure_name;
    }
   }

    // Causing trouble while sending post body to note only surgery steps timestamp so had to mention these as "not values" to be noted
    const surgeryStepTimestamps = {};
    if(Object.keys(formData).length){
      Object.keys(formData).forEach((step) => {
        if (
          step !== "Patient In Time" &&
          step !== "Patient Out Time" &&
          step !== "Mantra System Start" &&
          step !== "Mantra System End" &&
          step !== "Mantra System Duration" &&
          step !== "Draping Start" &&
          step !== "Draping End" &&
          step !== "Draping Duration" &&
          step !== "Surgery Start" &&
          step !== "Surgery End" &&
          step !== "Surgery Duration" &&
          step !== "1st Port Placement Start" &&
          step !== "1st Port Placement End" &&
          step !== "1st Port Placement Duration" &&
          step !== "2nd Port Placement Start" &&
          step !== "2nd Port Placement End" &&
          step !== "2nd Port Placement Duration" &&
          step !== "1st Dock Start" &&
          step !== "1st Dock End" &&
          step !== "1st Dock Duration" &&
          step !== "2nd Dock Start" &&
          step !== "2nd Dock End" &&
          step !== "2nd Dock Duration" &&
          step !== "Console Start" &&
          step !== "Console End" &&
          step !== "Console Duration" &&
          step !== "Console Interrupt" &&
          step !== "1st Undock Start" &&
          step !== "1st Undock End" &&
          step !== "1st Undock Duration" &&
          step !== "2nd Undock Start" &&
          step !== "2nd Undock End" &&
          step !== "2nd Undock Duration" &&
          step !== "Intubation Start" &&
          step !== "Intubation End" &&
          step !== "Sub Components"
        ) {
          if (
            selectedTextField[step] &&
            timestampRegex.test(selectedTextField[step])
          ) {
            const timestamp = selectedTextField[step];
            surgeryStepTimestamps[step] = Math.floor(
              new Date(timestamp).getTime()
            );
          }
        }
      });
    } 

    const instrumentsData = {
      used: [],
      issues: []
    };

   if(formData.instruments && formData.instruments.length){
    formData.instruments.forEach((instrument, index) => {
      const instrumentObj = {
        category: formData[`instrument_category_${index + 1}`],
        instrument_used: formData[`instrument_used_${index + 1}`],
        instrument_id: formData[`instrument_id_${index + 1}`],
      };

      if (instrument.issue) {
        instrumentsData.issues.push({
          ...instrumentObj,
          instrument_issue: formData[`instrument_issue_${index + 1}`]
        });
      } else {
        instrumentsData.used.push(instrumentObj);
      }
    });
   }

    postData.instruments = instrumentsData;

    postData.surgery_steps = surgeryStepTimestamps;

    const dateOfAdmission = formData.date_of_admission ? new Date(formData.date_of_admission) : null;
    const dateOfSurgery = formData.date_of_surgery ? new Date(formData.date_of_surgery) : null;
    const dateOfDischarge = formData.date_of_discharge ? new Date(formData.date_of_discharge) : null;

    const surgeryMonth = dateOfSurgery ? dateOfSurgery.getMonth() + 1 : null;
    const surgeryYear = dateOfSurgery ? dateOfSurgery.getFullYear() : null;

    postData.date_of_admission = dateOfAdmission ? dateOfAdmission.getTime() : null;
    postData.date_of_surgery = dateOfSurgery ? dateOfSurgery.getTime() : null;

    postData.patient_info = {
      serial_no: formData.serial_no,
      case_no: formData.case_no,
      patient_id: formData.patient_id,
      patient_name: formData.patient_name,
      gender: formData.gender,
      height: formData.height ? Number(formData.height) : null,
      weight: formData.weight ? Number(formData.weight) : null,
      age: formData.age ? Number(formData.age) : null,
      bmi: formData.bmi ? Number(formData.bmi) : null,
      diagnosis: formData.diagnosis,
    };

    postData.post_surgery_info = {
      date_of_discharge: dateOfDischarge ? dateOfDischarge.getTime() : null,
      length_of_stay_days: formData.length_of_stay_days ? Number(formData.length_of_stay_days) : null,
      readmission: formData.readmission,
      post_discharge_complications: formData.post_discharge_complications,
      clavien: formData.clavien,
    };

    postData.surgery_month = surgeryMonth;
    postData.surgery_year = surgeryYear;
    postData.entry_remarks_info = userComment;
    postData.interruption_history = interruptionHistory.map(interruption => {
      const startTime = typeof interruption.start === 'string' ? new Date(interruption.start).getTime() : interruption.start;
      const endTime = typeof interruption.end === 'string' ? new Date(interruption.end).getTime() : interruption.end;
      return {
        start: startTime || new Date(interruption.start).getTime(),
        end: endTime || new Date(interruption.end).getTime(),
        duration: convertToMillis(interruption.duration)
      };
    });    

    const hospitalIdToDetailsMap = {};
    hospitalList.forEach((hospital) => {
      hospitalIdToDetailsMap[hospital._id] = {
        name: hospital.name,
        location: hospital.location
      };
    });

    if (formData.hospital_name && hospitalIdToDetailsMap[formData.hospital_name]) {
      const hospitalDetails = hospitalIdToDetailsMap[formData.hospital_name];
      postData.hospital_name = hospitalDetails.name;
      postData.hospital_location = hospitalDetails.location;
    }

    handleMissingCheck(postData);
    setPostData(postData);
  };

  const handleConfirmSubmit = async (postData) => {
    postData.entry_remarks_info = userComment;
    const addSurgeryParameterUrl = config.baseUrl + config.ver + config.surgeriesNew;
    try {
      const response = await httpPostReq(addSurgeryParameterUrl, postData, "application/json", (response) => {
          if (response.data && response.data.status === "success") {
              setMessage("Surgery Data added successfully");
              setWarning("");
              setOpenDialog(false);
              handleResetting();
              onClose();
          } else if (response.data && response.data.status === "fail") {
              setWarning(response.data.message);
              setMessage("");
          }
      });
    } catch (error) {
        console.error("Error occurred while submitting form:", error);
    }
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMissingProperties([]);
  };

  const handleMissingCheck = (postData) => {
    let missingProperties = [];
    const checkEmptyObject = (obj, path) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            checkEmptyObject(obj[key], `${path}.${key}`);
          } else if (
            obj[key] === null ||
            obj[key] === undefined ||
            obj[key] === ""
          ) {
            if (key !== "console_interrupt" && key !== "entry_remarks_info") {
              missingProperties.push(`${path}.${key}`);
            }
          }
        }
      }
    };

    checkEmptyObject(postData, "");
    if (missingProperties.length > 0) {
      setMissingProperties(missingProperties);
      return false;
    }
    return true;
  };

  // Prevent TAP button to be clicked
  const handleTapLockTime = async () => {
    setIsLocked(!isLocked);
  };

  const handleResetting = async () => {
    setFormData({});
    setSelectedTextField({});
    localStorage.removeItem("timeTestingFormData");
    window.location.reload();
  };

  const handleCommentInput = (event) => {
    setUserComment(event.target.value);
  };

  return (
    <div className="timetesting-container">
      <div className="timetesting-table">
        {renderAccordion(PreOperationData, "Pre-Operative Form", "pre")}
        {renderAccordion(MantraOperationData, "Intra-Operative Form", "mantra")}
        {renderAccordion(PostOperationData, "Post-Operative Form", "post")}
      </div>
      <div className="timetesting-sidebar">
        <div className="timetesting-button-container">
          <div className="timetesting-objective-container">
            <p>Objective</p>
            {selectedField === "Surgery Steps" && (
              <div className="selected-textfield-label">
                {selectedField} <Divider />
                {selectedStepLabel}
              </div>
            )}
            {selectedField !== "Surgery Steps" && (
              <div className="selected-textfield-label">{selectedField}</div>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              if (selectedField === "Surgery Steps" && selectedStepLabel) {
                handleTimeStamp(selectedStepLabel);
              } else {
                handleTimeStamp(selectedField);
              }
            }}
            disabled={isLocked}
          >
            <span className="tap-text">TAP</span>
            <br /> to record the timestamp
          </button>
          <Button
            type="button"
            className="tap-lock-button"
            onClick={handleTapLockTime}
            startIcon={isLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
          ></Button>
          <div className="caution-reset">
            <Button
              type="button"
              className="system-halt-button"
              onClick={handleSystemHaltTime}
            >
              <WarningRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Console Interruption</span>
            </Button>
            <Button
              type="button"
              className="form-reset-button"
              onClick={handleResetting}
            >
              <DeleteForeverRoundedIcon />
            </Button>
          </div>
        </div>
        <div className="timetesting-submit-container">
          <button onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
      <Dialog className="interruption-dialog" open={isDialogOpen} onClose={handleClose}>
        <div className="interruption-dialog-content">
          <p>Console Interruption Start Time : {interruptStartTime && interruptStartTime.toLocaleString()}</p>
          <button className="interruption-button" onClick={endInterruption}>Console Interruption End</button>
          <div className="interruption-history">
            <h4>Interruption History</h4>
            <ul>
              {interruptionHistory.map((record, index) => (
                <li key={index}>
                  <p>Start: {record.displayStart}</p>
                  <p>End: {record.displayEnd}</p>
                  <p>Duration: {record.duration}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
      <Dialog className="missing-dialog" open={openDialog} onClose={handleCloseDialog}>
        <div className="missing-dialog-content">
          <p style={{marginBottom: "10px"}}>Verify all the data before submission. Proceed to Submit Data ?</p>
          {missingProperties.length > 0 && (
            <div className="missing-fields-list">
              <p>The following fields are missing:</p>
              <ul>
                {missingProperties.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="missing-comment-area">
            <textarea
              placeholder="Comments Before Submission..."
              onChange={handleCommentInput}
              className="comment-area-missing"
              value={userComment}
              rows={4}
            />
          </div>
          <div className="missing-button-area">
            <button className="missing-button" onClick={() => handleConfirmSubmit(postData)}>
              Confirm Submit
            </button>
            <button className="missing-close-button" onClick={handleCloseDialog}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TimeTesting;
