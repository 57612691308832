import React from "react";
import { jsPDF } from "jspdf";

const ServiceReportPDF = (rowData) => {
  const pdfDoc = new jsPDF();

  const addData = (rowData) => {
    console.log("rowData:", rowData);
    const margin = 20;
    const fontSize = 10;
    const pageHeight = pdfDoc.internal.pageSize.height;
    let currentY = margin;

    const addText = (text, x, y) => {
      pdfDoc.text(text, x, y);
    };

    const addKeyValue = (key, value, x, y) => {
      pdfDoc.setFont("helvetica", "bold");
      addText(`${key}:`, x, y);
      pdfDoc.setFont("helvetica", "normal");
      addText(`${value || ""}`, x + 70, y);
    };

    pdfDoc.setFontSize(fontSize);

    const checkYPosition = (heightToAdd) => {
      if (currentY + heightToAdd > pageHeight - margin) {
        pdfDoc.addPage();
        currentY = margin;
      }
    };

    addKeyValue("Zone Area", rowData[""] || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Customer Name", rowData.hospital_name || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Address", rowData.address || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("State", rowData.state || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Pin", rowData.pin || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Contact Name", rowData.contactName || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Mobile", rowData.mobile || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("E-mail", rowData.email || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Visit Type", rowData.visitType || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Equipment Status", rowData.equipmentStatus || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Issue Report", rowData.description || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Call Reported By", rowData.callReportedBy || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("System Usable", rowData.systemUsable || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Next Surgery Schedule Date", rowData.nextSurgeryScheduleDate || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Location of System (OT Number)", rowData.locationOfSystemOTNumber || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Description", rowData.description || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Physical Damage", rowData.physicalDamage || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Model No", rowData.modelNo || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Major Finding (Functional/Working)", rowData.majorFinding || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Serial No", rowData.srNo || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Remarks", rowData.modalremarks || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Parts Replaced", rowData.partsReplaced || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Parts Replaced Details", rowData.partsReplacedDetails || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Parts Required", rowData.partsRequired || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Parts Required Details", rowData.partsRequiredDetails || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Site Voltage (L-N)", rowData.ln || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Site Voltage (N-G)", rowData.ng || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Site Voltage (L-G)", rowData.lg || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Electrical Safety Test (EST)", rowData.testResult || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Report Attached", rowData.reportAttached || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Power Supply Through", rowData.powerSupplyThrough || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Temperature (°C)", rowData.temperature || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Humidity", rowData.humidity || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Feedback", rowData.customerfeedback || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue("Remarks", rowData.customerremarks || "", margin, currentY);
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Engineer's Signature", rowData.engineerSignature || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);
    addKeyValue( "Customer's Signature", rowData.customerSignature || "", margin, currentY );
    currentY += fontSize + 1;
    checkYPosition(fontSize + 1);

    pdfDoc.save("ServiceReport.pdf");
  };

  addData(rowData);
  console.log(rowData);

  pdfDoc.save("ServiceReport.pdf");
};

export default ServiceReportPDF;
