import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../css/User.css";
import "../css/Sutrauser.css";
import Surgeon from "../components/sutrauser/Surgeon";
import Clinical from "../components/sutrauser/Clinical";
import Users from "../components/sutrauser/Users"
import Lock from "../components/Lock";

function CustomTabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}     
    >
      {props.value === props.index && (       
          <>{props.children}</>       
      )}
    </div>
  );
}

export const SutraUserListing = (props) => {
  const [value, setValue] = React.useState(0);

  
  const handleChange = (event, newValue) => {   
    setValue(newValue);
  };
  if (props.UserInfo.user_type === "admin") {
    return (
      <div className="sutra-user-container">
        <h5>Sutra Users:</h5>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}   >
              <Tab label="Admin"  />
              <Tab label="Surgeon"  />
              <Tab label="Clinical Support"  />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>           
           <Box>
           <div><Users/></div>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div><Surgeon/></div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
          <div><Clinical/></div>                     
          </CustomTabPanel>
        </Box>
      </div>
    );
  } else {
    return <Lock />;
  }
};
