// frontend/src/components/ChangePassword.js
import React, { useState } from "react";
import config from "../config/configUrl";
import { httpPostReq } from "../util/request";
import { TextField, Button, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../css/ChangePassword.css";

const ChangePassword = (props) => {
  const [open, setOpen] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const emp_email = props.UserInfo.emp_email;

  const handleSubmit = async () => {

    let change_password_url = config.baseUrl + config.ver + config.changepassword;
    
    httpPostReq(
      change_password_url,
      {
        password: passwordOld,
        new_password: passwordNew,
        _id: props.UserInfo._id,
      },
      "application/json",
      (response) => {
        console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setWarning("");
          setMessage("Password Changed Successfully.");
          setOpen(false);
          window.location.reload();
          return null;
        } else if (
          response.data &&
          response.data.status &&
          response.data.status === "fail"
        ) {
          setMessage("");
          setWarning(response.data.message);
          return null;
        }
      }
    );
  };
  return (
    <>
      <div className="changepassword-form-container">
        {warning && <div className="error-message">{warning}</div>}
        {message && <div className="success-message">{message}</div>}
        <div>
          <TextField
            disabled
            label="Employee Email"
            value={emp_email}
            className="text-field"
          />
        </div>
        <div>
          <TextField
            label="Old Password"
            type={showPasswordOld ? "text" : "password"}
            value={passwordOld}
            onChange={(e) => setPasswordOld(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPasswordOld((prev) => !prev)}
                  edge="end"
                >
                  {showPasswordOld ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            className="text-field"
          />
        </div>
        <div>
          <TextField
            label="New Password"
            type={showPasswordNew ? "text" : "password"}
            value={passwordNew}
            onChange={(e) => setPasswordNew(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPasswordNew((prev) => !prev)}
                  edge="end"
                >
                  {showPasswordNew ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            className="text-field"
          />
        </div>

        <div>
          <TextField
            label="Confirm Password"
            type={showPasswordConfirm ? "text" : "password"}
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
              if (passwordNew == e.target.value) {
                setWarning("");
              } else {
                setWarning("not same password");
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPasswordConfirm((prev) => !prev)}
                  edge="end"
                >
                  {showPasswordConfirm ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            className="text-field"
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="submit-button"
          >
            Submit
          </Button>
        </div>
        <div>{error && <div className="error-message">{error}</div>}</div>
      </div>
    </>
  );
};

export default ChangePassword;
