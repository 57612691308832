import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import "../../css/Mantra.css";
import Lock from "../../components/Lock";

export const MantraUnits = (props) => {
    const [mantraUnits, setMantraUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    // State to track the expanded row
    const [expandedRow, setExpandedRow] = useState(null);
    const [innerList, setInnerList] = useState([]);

    // Function to toggle the expanded row
    const toggleExpand = (row) => {
        setExpandedRow(expandedRow === row._id ? null : row._id);
        if (expandedRow === null) {
            getMantraSubUnitList(row.key);
        }
    };

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getMantraUnitList();
        }
        return () => {
            mounted = false;
        };
    }, [innerList]);

    const getMantraUnitList = (arg) => {
        let url = config.baseUrl + config.ver + config.mantarUnits;
        setLoading(true);
        httpGetReq(url, "application/json", function (response) {
            setLoading(false);
            if (
                response.data &&
                response.data.status &&
                response.data.status === "success"
            ) {
                setMantraUnits(response.data.data);
            }
        });
    };

    const getMantraSubUnitList = (arg) => {
        console.log("arg", arg);
        let url =
            config.baseUrl +
            config.ver +
            config.mantarSubUnits +
            "?category=" +
            arg;
        console.log(url);
        httpGetReq(url, "application/json", function (response) {
            setLoading(false);
            if (
                response.data &&
                response.data.status &&
                response.data.status === "success"
            ) {
                setInnerList(response.data.data);
            }
        });
    };

    if (true) {
        return (
            <div className="mantra-container">
                <h2>Mantra Units</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mantraUnits.map((surgery) => (
                            <React.Fragment key={surgery._id}>
                                <tr onClick={() => toggleExpand(surgery)}>
                                    <td>{surgery.label}</td>
                                    <td>{surgery.key}</td>
                                    <td>{surgery.short}</td>
                                    <td>{surgery.short_desc}</td>
                                </tr>
                                {expandedRow === surgery._id && (
                                    <tr>
                                        <td colSpan="4">
                                            <div className="sub-list">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Label</th>
                                                        </tr>
                                                    </thead>
                                                    {innerList.map(
                                                        (subUnit) => (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        subUnit.label
                                                                    }
                                                                </td>{" "}
                                                                <td>
                                                                    {
                                                                        subUnit.key
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    return <Lock />;
};
