import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddTask from "../../components/addcomponents/AddTask";
import { httpGetReq } from "../../util/request";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Pagination from "@mui/material/Pagination";
import config from "../../config/configUrl";
import "../../css/CallContainer.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import TaskDetail from "../../components/TaskDetail";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import CallRoundedIcon from '@mui/icons-material/CallRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';

import Lock from "../../components/Lock";
import { convertTimestampToDate, getTaskStatus } from "../../util/util";
import { ConVar } from "../../config/configVar";

export const AllTaskContainer = (props) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [callList, setCallList] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecorsCall] = useState(0);
  const [filterHospitalList, setFilterHospitalList] = useState([]);
  const [dataInPage, setDataInpageCall] = useState(0);
  const [totalPages, setTotalPagesTasks] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [hospital, setHospital] = useState();
  const [procedure, setProcedure] = useState();
  const [surgeryYear, setSurgeryYear] = useState();
  const [value, setValue] = React.useState(0);
  const [openAddTask, setOpenAddTask] = useState(false);

  const [totalActiveTasks, setTotalActiveTasks] = useState(0);
  const [totalAssignedTasks, setTotalAssignedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getAllCalls();
    fetchCounts();
    getAllTasks();

    const searchParams = new URLSearchParams(location.search);
    const taskID = searchParams.get("task");
    if (taskID) {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [taskID]: true,
      }));
    }
  }, [currentPage]);

  const fetchCounts = () => {
    httpGetReq(`${config.baseUrl}${config.ver}${config.taskOpen}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalActiveTasks(response.data.available || 0);
      }
    });

    httpGetReq(`${config.baseUrl}${config.ver}${config.taskClose}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalAssignedTasks(response.data.available || 0);
      }
    });

    httpGetReq(`${config.baseUrl}${config.ver}${config.taskActive}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalTasks(response.data.available || 0);
      }
    });
  };

  const getAllCalls = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.callList}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (procedure !== "all" && procedure !== undefined) {
      if (url.includes("?")) {
        url += "&procedure_code=" + procedure;
      } else {
        url += "?procedure_code=" + procedure;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setCallList(response.data.data);
          setTotalRecorsCall(response.data.total);
          setDataInpageCall(response.data.data.length);
          setTotalPagesTasks(Math.ceil(response.data.total / limitPerPage));
        }
      }
    });
  };

  const getAllTasks = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.taskList}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (procedure !== "all" && procedure !== undefined) {
      if (url.includes("?")) {
        url += "&procedure_code=" + procedure;
      } else {
        url += "?procedure_code=" + procedure;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setTaskList(response.data.data);
        }
      }
    });
  };

  const getFilteredHospitals = (arg) => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?area_zone=" + arg;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setFilterHospitalList(response.data.data);
        }
      }
    });
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));

  const taskID = encodeURIComponent(rowId);
    if (!expandedRows[rowId]) {
      navigate(`${location.pathname}?task=${taskID}`);
    } else {
      navigate(location.pathname);
    }
  };

  const handleOpeningCall = (call_id) => {
    navigate(`/callgenerate/allcalls?call=${call_id}`);
  };  

  if (
    props.UserInfo.user_type === "service-engineer" ||
    props.UserInfo.user_type === "clinical-support" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
          <h4>Page is under Observation and Testing...</h4>
        <div className="add-data-button-container">
          <AddTask
            open={openAddTask}
            close={() => {
              setOpenAddTask(false);
            }}
            {...props}
          />
          <button className="add-data-button" onClick={() => { setOpenAddTask(true); }} > <PersonAddAltRoundedIcon /> <span style={{ marginLeft: "10px" }}>Add Task</span> </button>
        </div>
        <div key="1" className="search-bar-container">
          <div className="search-bar-keep-right">
            <select
              label="Area Zone"
              value={zone_name}
              onChange={(event) => {
                setZoneName(event.target.value);
                getFilteredHospitals(event.target.value);
              }}
              className="search-bar-input-dropdown"
            >
              <option key={""} value={"all"}>
                Select Area Zone
              </option>
              {ConVar.hospitalZone.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <select
              label="Area Zone"
              className="search-bar-input-dropdown"
              value={hospital}
              onChange={(e) => {
                setHospital(e.target.value);
              }}
            >
              <option key={""} value={"all"}>
                Select Hospitals
              </option>
              {filterHospitalList.map((option) => (
                <option key={option.label} value={option.system_no}>
                  {option.name}
                </option>
              ))}
            </select>

            <select
              label="Area Zone"
              value={surgeryYear}
              className="search-bar-input-dropdown"
              onChange={(e) => {
                setSurgeryYear(e.target.value);
              }}
            >
              <option key={""} value={"all"}>
                Select year
              </option>
              {ConVar.surgeryYear.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
            >
              <RestartAltRoundedIcon />
            </button>

          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>
        <div className="double-container">
          <div className="call-list-container">
            <table className="call-table">
              <tr>
                <th>Task Name</th>
                <th>Type</th>
                <th>System Number</th>
                <th>Hospital Name</th>
                <th>Creation Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              <tbody className="call-table-body">
                {taskList.length ? (
                  <>
                    {taskList.map((row) => (
                      <>
                        <tr key={row._id} onClick={() => {}}>
                          <td className="r20start">{row.task_name}</td>
                          <td className="r30">{row.task_type}</td>
                          <td className="r10">{row.system_no}</td>
                          <td className="r20">{row.hospital_name}</td>
                          <td className="r20"> {convertTimestampToDate(row.assign_time)} </td>
                          <td className="r10"> {getTaskStatus(row.status)} </td>
                          <td className="r20end">
                            <button
                              title="Get Task Detail"
                              onClick={() => {
                                toggleRow(row._id);
                              }}
                            >
                              {expandedRows[row._id] ? (
                                <ExpandLessIcon className="chevron-right-icon" />
                              ) : (
                                <ExpandMore className="expand-more-icon" />
                              )}
                            </button>
                              {row.call_id && (
                                <button
                                  title="Go to Call Details"
                                  className="red-button"
                                  onClick={() => {
                                    handleOpeningCall(row.call_id);
                                  }}
                                >
                                  <InfoRoundedIcon />
                                </button>
                              )}
                          </td>
                        </tr>
                        {expandedRows[row._id] && (
                          <tr>
                            <td className="inner-property-expand" colspan="8">
                              <div className="inner-details-expand-styling">                            
                                <label><strong>System :</strong> {row.system_no}</label>                             
                                <TaskDetail data={row} />
                                <label><strong>Last Updated :</strong> {convertTimestampToDate(row.last_updated)}</label>                                
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no-match-found">Oops ! No Match Found 😁</div>
                )}
              </tbody>
            </table>
          </div>
          <div className="side-data-container">
            <div className="side-data">
              <div className="side-data-left">
                {totalActiveTasks}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <ContactPhoneRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Active Tasks</label>
                </div>
              </div>
            </div>
            <div className="side-data">
              <div className="side-data-left">
                {totalAssignedTasks}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <AssignmentTurnedInRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Completed Tasks</label>
                </div>
              </div>
            </div>
            <div className="side-data">
              <div className="side-data-left">
                {totalTasks}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <CallRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Tasks</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
