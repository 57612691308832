import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function YesNoPrompt(props) {
  const Title= props.heading;
  const Detail = props.detail;
  const Open = props.open;

   const handleClose = () => {
    console.log('Dialog Closed');
  };

  const handleOK = () => {
    props.Ok();   
  };

  const handleCancel = () => {   
    props.Cancel();
  };

  return (
    <React.Fragment>      
      <Dialog
        open={Open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {Detail}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Disagree</Button>
          <Button onClick={handleOK} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
