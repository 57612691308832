import React, { useState } from "react";
import { Box, Collapse } from "@mui/material";
import { ConVar } from "../../config/configVar";

import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddTaskTwoToneIcon from "@mui/icons-material/AddTaskTwoTone";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import EditUser from "../../components/editcomponents/EditUser";
import "../../css/UserTable.css";

const UserContainerTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(-1);
  const [openState, setOpenState] = useState(true);
  const [old_index, setOldIndex] = useState(-1);

  const [open_detail, setOpenDetail] = useState(-1);
  const [open_detail_state, setOpenDetailState] = useState(false);
  const [old_detail_index, setOldDetailIndex] = useState(-1);
  const [details, setDetails] = useState("");

  const [edit_show_state, setEditShowState] = useState(true);

  const indexOfLastRow = currentPage * props.rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - props.rowsPerPage;
  const currentRows = props.data.slice(indexOfFirstRow, indexOfLastRow);

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const totalPages = Math.ceil(props.data.length / props.rowsPerPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const showDetailIndex = (index, row) => {
    setDetails("Details: " + row.description + "\n Assigned task: ");

    if (open_detail_state) {
      if (old_detail_index == index) {
        setOpenDetail(-1);
        setOpenDetailState(false);
      } else {
        setOpenDetail(index);
        setOpenDetailState(true);
        if (openState) {
          setOpen(-1);
          setOpenState(false);
          setOldIndex(-1);
        }
      }
    } else {
      setOpenDetail(index);
      setOpenDetailState(true);
      if (openState) {
        setOpen(-1);
        setOpenState(false);
        setOldIndex(-1);
      }
    }
    setOldDetailIndex(index);
  };

  const showIndex = (index, row) => {
    if (openState) {
      if (old_index === index) {
        setOpen(-1);
        setOpenState(false);
      } else {
        setOpen(index);
        setOpenState(true);
        if (open_detail_state) {
          setOpenDetail(-1);
          setOpenDetailState(false);
          setOldDetailIndex(-1);
        }
      }
    } else {
      setOpen(index);
      setOpenState(true);
      if (open_detail_state) {
        setOpenDetail(-1);
        setOpenDetailState(false);
        setOldDetailIndex(-1);
      }
    }
    setOldIndex(index);
  };

  function nameToFirstAndLast(name) {
    let namearr = name.split(" ");
    let firstName = namearr[0];
    let lastName = namearr[2] ? namearr[2] : "";
    let final = firstName; //;+ " " + lastName ;
    return final;
  }

  const closeEditUser = () => {
    setOpen(-1);
    setOpenState(false);
    setOldIndex(-1);
  };

  return (
    <div>
      <div className="property-list-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="property-table-body">
            {currentRows.map((row, index) => {
              return (
                <React.Fragment key={row._id}>
                  <tr>
                    <td className="r10start">{row.emp_id}</td>
                    <td className="r20" title={row.name ? row.name : ""}> {nameToFirstAndLast(row.name)} </td>
                    <td className="r20"> {row.user_type ? ConVar.userTypesAssoArr[row.user_type] : ""} </td>
                    <td className="r20end">
                      <span>
                        {/* <button className="ub1">
                          <AddTaskTwoToneIcon />
                        </button> */}
                        <button
                          title="Edit"
                          className="ub2"
                          onClick={() => {
                            showIndex(index, row);
                            props.updateEditUser(row);
                          }}
                        >
                          <EditTwoToneIcon />
                        </button>
                        {/* <button
                          title="Detail"
                          className="ub3"
                          onClick={() => {
                            showDetailIndex(index, row);
                            // props.updateEditUser(row);
                          }}
                        >
                          {open_detail_state && open_detail === index ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightTwoToneIcon />
                          )}
                        </button> */}
                      </span>
                    </td>
                  </tr>

                  {open === index && (
                    <tr className="inner-expanded-row">
                      <td colSpan="4" className="inner-property-expand">
                        <Collapse in={open === index}>
                          <div>
                            <EditUser
                              show_state={true}
                              username_={props.username__}
                              usertype_={props.usertype__}
                              emp_id_={props.emp_id__}
                              emp_email_={props.emp_email__}
                              set_username__={props.setUsername_}
                              set_usertype__={props.setUserType_}
                              set_emp_id__={props.setEmpId_}
                              set_emp_email__={props.setEmpEmail_}
                              user_id__={props.user_id_}
                              closeEditUser_={closeEditUser}
                            />
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  )}

                  {open_detail === index && (
                    <tr className="detail-row">
                      <td colSpan="4" className="second-detail-row">
                        <Collapse in={open_detail === index}>
                          <div>
                            <span>
                              <strong>Description: </strong>
                              {row.description}
                            </span>
                            <br />
                            <span>
                              <strong>Assigned task: </strong>
                              {row.assigned_task?.map((item, index) => (
                                <React.Fragment key={index}>
                                  <span>{item.task_name}</span>
                                  <span>,</span>
                                </React.Fragment>
                              ))}
                            </span>
                            <br />
                            <span>
                              <strong>Completed task: </strong>
                              {row.completed_task?.map((item, index) => (
                                <React.Fragment key={index}>
                                  <span>{item.task_name}</span>
                                  <span>,</span>
                                </React.Fragment>
                              ))}
                            </span>
                            <br />
                            <span>
                              <strong>Processing task: </strong>
                              {row.processing_task?.map((item, index) => (
                                <React.Fragment key={index}>
                                  <span>{item.task_name}</span>
                                  <span>,</span>
                                </React.Fragment>
                              ))}
                            </span>
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserContainerTable;
