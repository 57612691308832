import axios from "axios";
import config from "../config/configUrl";
import {  loadCookie } from "../util/util";
var UserDetail = loadCookie(config.authCookieName);

export const httpPostReq = (url, dataObj, cType, callback,name) => {
    console.log(UserDetail);
    let headers_ = {
        Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`
        )}`,
        "Content-Type": cType ? cType : "application/x-www-form-urlencoded",
        "User-Name": (UserDetail && UserDetail.emp_email) ?  UserDetail.emp_email : name ? name:'NO USER',
    };  
    callAjaxData();
    function callAjaxData() {
        try {
            axios
                .post(url, dataObj, {
                    headers: headers_,
                })
                .then((response) => {
                    callback(response);
                })
                .catch((handleFailure) => {
                    console.log("error1", handleFailure);
                    callback(handleFailure);
                });
        } catch (error) {
            console.log("error2");
            console.error(
                "Error in post request method\n for this url =",
                url,
                error
            );
        }
    }
};

export const httpPostReqNew = (url, dataObj, cType, callback) => {
    let headers_ = {
        Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`
        )}`,
        "Content-Type": cType ? cType : "application/x-www-form-urlencoded",
        "User-Name": (UserDetail && UserDetail.emp_email) ?  UserDetail.emp_email : "NO USER",
    };
    callAjaxData();
    function callAjaxData() {
        try {
            axios
                .post(url, dataObj, {
                    headers: headers_,
                })
                .then((response) => {
                    callback(response);
                })
                .catch((handleFailure) => {
                    console.log("error1", handleFailure);
                    callback(handleFailure);
                });
        } catch (error) {
            console.log("error2");
            console.error(
                "Error in post request method\n for this url =",
                url,
                error
            );
        }
    }
};
export const httpGetReq = (url, cType, callback) => {
    let headers_ = {
        Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`
        )}`,
        "Content-Type": cType ? cType : "application/x-www-form-urlencoded",
        "User-Name": (UserDetail && UserDetail.emp_email) ?  UserDetail.emp_email : "NO USER",
    };
    callAjaxData();
    function callAjaxData() {
        try {
            axios
                .get(url, {
                    headers: headers_,
                })
                .then((response) => {
                    callback(response);
                })
                .catch((handleFailure) => {
                    callback(handleFailure);
                });
        } catch (error) {
            console.error(
                "Error in post request method\n for this url =",
                url,
                error
            );
        }
    }
};
