import React, { useState, useEffect } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq, httpPostReq } from "../../util/request";
import "../../css/EditUser.css";
import ReactSelect from "react-select";

const EditUser = (props) => {
  const [showform, setShowform] = useState(props.show_state);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [areaZone, setAreaZone] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [usertype_, setUserType] = useState("");
  const [emp_id_, setEmpId] = useState("");
  const [emp_email_, setEmpEmail] = useState("");

  useEffect(() => {
    getHospitalList();
  }, []);

  useEffect(() => {
    if (hospitalList.length > 0) {
      fetchUserDetails();
    }
  }, [hospitalList]);

  const getHospitalList = () => {
    const url = config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setHospitalList(response.data.data);
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  const fetchUserDetails = () => {
    const userDetailUrl = config.baseUrl + config.ver + "/user/" + props.user_id__;
    httpGetReq(userDetailUrl, "application/json", (response) => {
      if (response && response.data && response.data.status === "success") {
        const userData = response.data.data;
        props.set_username__(userData.name || "");
        setUserType(userData.user_type || "");
        setEmpId(userData.emp_id || "");
        setEmpEmail(userData.emp_email || "");
        setAreaZone(userData.area_zone || "");

        const alignedHospitals = (userData.aligned_hospital || []).map(systemNo => {
          const hospital = hospitalList.find(hospital => hospital.system_no === systemNo);
          return hospital ? { value: hospital.system_no, label: `${hospital.name} (${hospital.system_no})` } : null;
        }).filter(Boolean);
        setSelectedHospitals(alignedHospitals);
      } else {
        console.error("Error fetching user details:", response && response.data ? response.data.message : "Unknown error occurred");
      }
    });
  };  

  const filteredHospitals = hospitalList.filter((hospital) => hospital.area_zone === areaZone);

  const HandleEditClick = () => {
    if (props.username_ === "") {
      setWarning("Username cannot be empty.");
      setMessage("");
      return null;
    }
    if (usertype_ === "") {
      setWarning("UserType cannot be empty.");
      setMessage("");
      return null;
    }
    if (emp_id_ === "") {
      setWarning("Employee id cannot be empty.");
      setMessage("");
      return null;
    }
    if (emp_email_ === "") {
      setWarning("Employe email cannot be empty.");
      setMessage("");
      return null;
    }
    if (areaZone === "") {
      setWarning("Please select Area Zone.");
      setMessage("");
      return;
    }
    if (selectedHospitals.length === 0) {
      setWarning("Please select a Hospital.");
      setMessage("");
      return;
    }

    const requestBody = {
      name: props.username_,
      user_type: usertype_,
      emp_email: emp_email_,
      emp_id: emp_id_,
      area_zone: areaZone,
      aligned_hospital: selectedHospitals.map(hospital => hospital.value)
    };

    let EditUserUrl = config.baseUrl + config.ver + "/user/update/" + props.user_id__;

    httpPostReq(
      EditUserUrl,
      requestBody,
      "application/json",
      (response) => {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "fail"
        ) {
          console.log("error");
          setWarning(response.data.message);
          setMessage("");
          setShowform(false);
          return null;
        } else if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setMessage("User updated successfully");
          setWarning("");
          setShowform(false);
          window.location.reload();
          return null;
        }
      }
    );
  };

  return (
    <>
      {showform && (
        <div className="edit-user-form">
          <div className="edit-user-box">
            {warning && <div className="warning-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="editproperty-form-control">
              <label>Profile Name</label>
              <input
                className="editproperty-text-field"
                value={props.username_}
                onChange={(e) => props.set_username__(e.target.value)}
              />
            </div>
            <div className="editproperty-form-control">
              <label>Select User Type</label>
              <select
                className="editproperty-select"
                value={usertype_}
                onChange={(e) => setUserType(e.target.value)}
              >
                {ConVar.userTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="editproperty-form-control">
              <label>Employee ID</label>
              <input
                className="editproperty-text-field"
                value={emp_id_}
                onChange={(e) => setEmpId(e.target.value)}
                disabled
              />
            </div>
            <div className="editproperty-form-control">
              <label>Employee Email</label>
              <input
                className="editproperty-text-field"
                value={emp_email_}
                onChange={(e) => setEmpEmail(e.target.value)}
                disabled
              />
            </div>
            <div className="editproperty-form-control">
              <label>Assign Area Zone</label>
              <select
                label="Area Zone"
                value={areaZone}
                onChange={(e) => setAreaZone(e.target.value)}
                className="addproperty-select"
              >
                <option key={""} value={""}> Select Area Zone </option>
                {ConVar.hospitalZone.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="editproperty-form-control">
              <label>Assign Hospital(s)</label>
                <ReactSelect
                  className="editproperty-multiselect"
                  onChange={(selectedOptions) => {
                    setSelectedHospitals(selectedOptions);
                  }}
                  value={selectedHospitals}
                  isMulti
                  options={filteredHospitals.map((hospital) => ({
                    label: `${hospital.name} ${hospital.location} (${hospital.system_no})`,
                    value: hospital.system_no,
                  }))}
                />
            </div>
            <div className="edit-user-button-box">
              <button
                type="button"
                className="addproperty-button"
                onClick={HandleEditClick}
              >
                Edit Details
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  props.closeEditUser_();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditUser;
