import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DashboardIcon from "@mui/icons-material/HomeRounded";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PersonIcon from "@mui/icons-material/Person";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import AirlineSeatFlatAngledRoundedIcon from "@mui/icons-material/AirlineSeatFlatAngledRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded";
import HardwareRoundedIcon from "@mui/icons-material/HardwareRounded";

import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import FlareIcon from '@mui/icons-material/Flare';
import BalconyIcon from '@mui/icons-material/Balcony';
import VaccinesIcon from '@mui/icons-material/Vaccines';

const menujson = [
  {
    path: "/dashboard",
    icon: <DashboardIcon />,
    text: "Dashboard",
  },
  {
    path: "/hospitals",
    icon: <LocalHospitalIcon />,
    text: "Hospitals",
  },
  {
    path: "/surgeries",
    icon: <AirlineSeatFlatAngledRoundedIcon />,
    text: "Surgery Details",
  },
  {
    path: "/surgeries-instruments",
    icon: <AirlineSeatFlatAngledRoundedIcon />,
    text: "Surgery Instruments",
  },
  {
    icon: <FlareIcon />,
    text: "SSi Mudra",
    submenu: [  
      {
        path: "/mudra/dashboard",
        text: "Dashboard",
        icon: <SettingsAccessibilityRoundedIcon />,
      },   
      {
        path: "/mudra/warehouse",
        text: "Warehouse",        
        icon: <PrecisionManufacturingIcon />,
      },
      {
        path: "/mudra/inhospitals",
        icon: <LocalPharmacyIcon />,
        text: "In-Hospitals",
      },
      {
        path: "/mudra/service",
        icon: <LocalLaundryServiceIcon />,
        text: "In-Service",
      },
      {
        path: "/mudra/discarded",
        icon: <BalconyIcon />,
        text: "Discarded",
      },
      {
        path: "/mudra/instrument-in-hospitals",
        icon: <SettingsAccessibilityRoundedIcon />,
        text: "Instrument In Hospitals",
      },
      {
        path: "/mudra/do-surgery",        
        icon: <VaccinesIcon />,
        text: "Surgery Form",
      },
      {
        path: "/mudra",
        icon: <HardwareRoundedIcon />,
        text: "All Instruments",
      },
    ],
  },
];

const Submenu = ({ menu, selected, isOpen }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <ListItem
        button
        component={Link}
        to={menu.submenu ? menu.submenu[0].path : menu.path}
        onClick={handleClick}
        className={pathname.startsWith(menu.path) ? "selected-menu" : ""}
      >
        <ListItemIcon>{menu.icon}</ListItemIcon>
        {isOpen && <ListItemText primary={menu.text} />}
        <ListItemIcon>
          {open ? (
            <ExpandLessIcon className="chevron-right-icon" />
          ) : (
            <ExpandMore className="expand-more-icon" />
          )}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {menu.submenu.map((item, index) => (
          <ListItem
            className={
              item.path === pathname
                ? "sub-menu-selected sub-menu-item"
                : "sub-menu-item"
            }
            key={index}
            button
            component={Link}
            to={item.path}
          >
            <ListItemIcon className="small-icon">
              {item.icon || <DashboardIcon />}
            </ListItemIcon>
            {isOpen && (
              <ListItemText primary={item.text} className="small-text" />
            )}
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

const MudraAdminMenuItem = ({ isOpen }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="menu-items">
      {menujson.map((menuItem, index) => (
        <div
          className={pathname === menuItem.path ? "selected-menu" : ""}
          key={index}
        >
          {menuItem.submenu == null && (
            <>
              <ListItem button component={Link} to={menuItem.path}>
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                {isOpen && <ListItemText primary={menuItem.text} />}
              </ListItem>
            </>
          )}

          {menuItem.submenu && (
            <div className="submenu-container">
              <Submenu
                menu={menuItem}
                selected={pathname.startsWith(menuItem.path)}
                isOpen={isOpen}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MudraAdminMenuItem;
