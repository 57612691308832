import React, { useEffect, useState } from "react";
import AddCall from "../components/addcomponents/AddCall";
import AddTask from "../components/addcomponents/AddTask";

import { httpGetReq } from "../util/request";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Pagination from "@mui/material/Pagination";
import config from "../config/configUrl";
import { Row } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Lock from "../components/Lock";
import { convertTimestampToDate, getCallStatus,getTaskStatus,getTaskStatusEngineer } from "../util/util";
import { ConVar } from "../config/configVar";

export const CallsContainer = (props) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [callList, setCallList] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecorsCall] = useState(0);
  const [filterHospitalList, setFilterHospitalList] = useState([]);
  const [dataInPage, setDataInpageCall] = useState(0);
  const [totalPages, setTotalPagesCalls] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [hospital, setHospital] = useState();
  const [procedure, setProcedure] = useState();
  const [surgeryYear, setSurgeryYear] = useState();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    return (
      <div
        role="tabpanel"
        hidden={props.value !== props.index}
        id={`simple-tabpanel-${props.index}`}
        aria-labelledby={`simple-tab-${props.index}`}
      >
        {props.value === props.index && <>{props.children}</>}
      </div>
    );
  }
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllCalls();
      getAllTasks();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getAllCalls = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.callList}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (procedure !== "all" && procedure !== undefined) {
      if (url.includes("?")) {
        url += "&procedure_code=" + procedure;
      } else {
        url += "?procedure_code=" + procedure;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setCallList(response.data.data);
          setTotalRecorsCall(response.data.total);
          setDataInpageCall(response.data.data.length);
          setTotalPagesCalls(Math.ceil(response.data.total / limitPerPage));
        }
      }
    });
  };

  const getAllTasks = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.taskList}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (procedure !== "all" && procedure !== undefined) {
      if (url.includes("?")) {
        url += "&procedure_code=" + procedure;
      } else {
        url += "?procedure_code=" + procedure;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setTaskList(response.data.data);
        }
      }
    });
  };

  const getFilteredHospitals = (arg) => {
    let url =
      config.baseUrl + config.ver + config.hospitalList + "?area_zone=" + arg;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setFilterHospitalList(response.data.data);
        }
      }
    });
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));
  };
  if (
    props.UserInfo.user_type === "service-engineer" ||
    props.UserInfo.user_type === "clinical-support" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
         <h4>Page is under Observation and Testing...</h4>
        <div>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="All Calls" />
            <Tab label="All Tasks" />
          </Tabs>
        </div>
        <div>
          <CustomTabPanel value={value} index={0}>
            <Row>
              <Row>
                <label>Total Active Calls</label> &nbsp; : 3 &nbsp; &nbsp;
                <label>Total Assigned Calls</label> &nbsp;: 3 &nbsp; &nbsp;
                <label>Total Calls</label> &nbsp;: 3 &nbsp; &nbsp;
                <AddCall {...props} />
              </Row>
              <div key="1" className="search-bar-container">
                <div className="search-bar-keep-right">
                  <input
                    className="search-bar-input-text"
                    id="outlined-basic"
                    placeholder="Call name"
                    variant="outlined"
                    size="small"
                    onChange={(event) => {}}
                  />
                  <select
                    label="Area Zone"
                    value={zone_name}
                    onChange={(event) => {
                      setZoneName(event.target.value);
                      getFilteredHospitals(event.target.value);
                    }}
                    className="search-bar-input-dropdown"
                  >
                    <option key={""} value={"all"}>
                      Select Area Zone
                    </option>
                    {ConVar.hospitalZone.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <button
                    className="search-bar-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {}}
                    startIcon={<SearchIcon />}
                  >
                    <SearchIcon />
                  </button>
                  <button
                    className="search-bar-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {}}
                  >
                    <RestartAltRoundedIcon />
                  </button>
                </div>
              </div>
              <Row>
                <div className="surgeries-list-container">
                  <table className="surgeries-table">
                    <tr>
                      <th>Call Name</th>
                      <th>Location</th>
                      <th>Hospital</th>
                      <th>Creation Date</th>
                      <th>Status</th>
                    </tr>
                    <tbody className="surgeries-table-body">
                      {callList.length ? (
                        <>
                          {callList.map((row) => (
                            <>
                              <tr key={row._id} onClick={() => {}}>
                                <td className="sgg1">{row.call_name}</td>

                                <td className="sgg3">{row.location}</td>
                                <td className="sgg2">{row.system_no}</td>

                                <td className="sgg2">
                                  {convertTimestampToDate(
                                    row.call_creation_date
                                  )}
                                </td>
                                <td className="sgg5">
                                  {getCallStatus(row.call_status)}
                                </td>
                                <td>
                                  <button
                                    title="Get Detail"
                                    onClick={() => {
                                      toggleRow(row._id);
                                    }}
                                  >
                                    Detail
                                  </button>
                                </td>
                              </tr>
                              {expandedRows[row._id] && (
                                <tr>
                                  <td align="center" colspan="8">
                                    <label>Last Updated</label>:{" "}
                                    {convertTimestampToDate(row.last_updated)}
                                    <br></br>
                                    <label>Name</label>: {row.call_name}
                                    <br></br>
                                    <label>Status</label>:{" "}
                                    {getCallStatus(row.call_status)}
                                    <br></br>
                                    <label>Location</label>: {row.location}
                                    <br></br>
                                    <label>System</label>: {row.system_no}
                                    <br></br>
                                    <label>Raised By</label>:{" "}
                                    {row.call_created_by}
                                    <br></br>
                                    <label>Raised On</label>:{" "}
                                    {convertTimestampToDate(
                                      row.call_creation_date
                                    )}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="no-match-found">
                          Oops !, No Match Found 😒😁
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Row>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Row>
              <label>Total Active Tasks</label> &nbsp; : 3 &nbsp; &nbsp;
              <label>Total Completed Tasks</label> &nbsp;: 3 &nbsp; &nbsp;
              <label>Total Tasks</label> &nbsp;: 3 &nbsp; &nbsp;
              <AddTask {...props} />
            </Row>
            <Row>
              <div key="1" className="search-bar-container">
                <div className="search-bar-keep-right">
                  <select
                    label="Area Zone"
                    value={zone_name}
                    onChange={(event) => {
                      setZoneName(event.target.value);
                      getFilteredHospitals(event.target.value);
                    }}
                    className="search-bar-input-dropdown"
                  >
                    <option key={""} value={"all"}>
                      Select Area Zone
                    </option>
                    {ConVar.hospitalZone.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <select
                    label="Area Zone"
                    className="search-bar-input-dropdown"
                    value={hospital}
                    onChange={(e) => {
                      setHospital(e.target.value);
                    }}
                  >
                    <option key={""} value={"all"}>
                      Select Hospitals
                    </option>
                    {filterHospitalList.map((option) => (
                      <option key={option.label} value={option.system_no}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  <select
                    label="Area Zone"
                    value={surgeryYear}
                    className="search-bar-input-dropdown"
                    onChange={(e) => {
                      setSurgeryYear(e.target.value);
                    }}
                  >
                    <option key={""} value={"all"}>
                      Select year
                    </option>
                    {ConVar.surgeryYear.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <button
                    className="search-bar-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {}}
                    startIcon={<SearchIcon />}
                  >
                    <SearchIcon />
                  </button>
                  <button
                    className="search-bar-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {}}
                  >
                    <RestartAltRoundedIcon />
                  </button>
                </div>
              </div>
            </Row>
            <Row>
              <div className="surgeries-list-container">
                <table className="surgeries-table">
                  <tr>
                    <th>Task Name</th>
                    <th>Type</th>
                    <th>Hospital</th>
                    <th>Creation Date</th>
                    <th>Status</th>
                  </tr>
                  <tbody className="surgeries-table-body">                  
                    {taskList.length ? (
                      <>
                        {taskList.map((row) => (
                          <>
                            <tr key={row._id} onClick={() => {}}>
                              <td className="sgg1">{row.task_name}</td>
                              <td className="sgg3">{row.task_type}</td>
                              <td className="sgg2">{row.system_no}</td>

                              <td className="sgg2">
                                {convertTimestampToDate(row.assign_time)}
                              </td>
                              <td className="sgg5">
                                {getTaskStatus(row.status)}
                              </td>
                              <td>
                                <button
                                  title="Get Detail"
                                  onClick={() => {
                                    toggleRow(row._id);
                                  }}
                                >
                                  Detail
                                </button>
                              </td>
                            </tr>
                            {expandedRows[row._id] && (
                              <tr>
                                <td align="center" colspan="8">
                                  <label>Last Updated</label>:{" "}
                                  {convertTimestampToDate(row.last_updated)}
                                  <br></br>
                                  <label>Name</label>: {row.task_name}
                                  <br></br>
                                  <label>Status</label>:{" "}
                                  {getTaskStatusEngineer(row.call_status)}
                                  <br></br>
                                  <label>Hospital Name</label>: {row.hospital_name}
                                  <br></br>
                                  <label>System</label>: {row.system_no}
                                  <br></br>
                                  <label>Assigned  </label>:{" "}
                                  {row.assigned[0]}
                                  <br></br>
                                  <label>Last Updated By </label>:{" "}
                                  {row.updatedBy}
                                  <br></br>
                                  
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <div className="no-match-found">
                        Oops !, No Match Found 😒😁
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </Row>
          </CustomTabPanel>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
