import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddCall from "../../components/addcomponents/AddCall";
import ConvertCallToTask from "./ConvertCallToTask";
import { httpGetReq } from "../../util/request";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Pagination from "@mui/material/Pagination";
import config from "../../config/configUrl";
import "../../css/CallContainer.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';

import Lock from "../../components/Lock";
import { convertTimestampToDate, getCallStatus } from "../../util/util";
import { ConVar } from "../../config/configVar";

export const AllCallContainer = (props) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [callList, setCallList] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [showConvertDialog, setShowConvertDialog] = useState(false);
  const [totalActiveCalls, setTotalActiveCalls] = useState(0);
  const [totalAssignedCalls, setTotalAssignedCalls] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);

  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterHospitalList, setFilterHospitalList] = useState([]);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getAllCalls();
    fetchCounts();
    getAllTasks();

    const searchParams = new URLSearchParams(location.search);
    const callID = searchParams.get("call");
    if (callID) {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [callID]: true,
      }));
    }
  }, [currentPage]);

  const fetchCounts = () => {
    httpGetReq(`${config.baseUrl}${config.ver}${config.callActive}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalActiveCalls(response.data.available || 0);
      }
    });

    httpGetReq(`${config.baseUrl}${config.ver}${config.callAssigned}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalAssignedCalls(response.data.available || 0);
      }
    });

    httpGetReq(`${config.baseUrl}${config.ver}${config.callTotal}`, "application/json", function (response) {
      if (response.data && response.data.status === "success") {
        setTotalCalls(response.data.available || 0);
      }
    });
  };

  const getAllTasks = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.taskList}?limit=${limitPerPage}&page=${currentPage}`;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setTaskList(response.data.data);
        }
      }
    });
  };

  const getAllCalls = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.callList}?limit=${limitPerPage}&page=${currentPage}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setCallList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        const totalPages = Math.ceil(response.data.total / limitPerPage);
        setTotalPages(totalPages);
      }
    });
  };

  const getFilteredHospitals = (arg) => {
    let url =
      config.baseUrl + config.ver + config.hospitalList + "?area_zone=" + arg;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setFilterHospitalList(response.data.data);
        }
      }
    });
  };

  const handleConvertCallToTask = (callData) => {
    setSelectedCall({ ...callData, call_id: callData._id });
    setShowConvertDialog(true);
  };

  const handleCloseConvertDialog = () => {
    setSelectedCall(null);
    setShowConvertDialog(false);
  };

  const handleConvertSuccess = () => {
    setShowConvertDialog(false);
    getAllCalls();
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));

  const callID = encodeURIComponent(rowId);
    if (!expandedRows[rowId]) {
      navigate(`${location.pathname}?call=${callID}`);
    } else {
      navigate(location.pathname);
    }
  };

  if (
    props.UserInfo.user_type === "service-engineer" ||
    props.UserInfo.user_type === "clinical-support" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
         <h4>Page is under Observation and Testing...</h4>
        <div className="add-data-button-container">
          <AddCall {...props} />
        </div>
        <div key="1" className="search-bar-container">
          <div className="search-bar-keep-right">
            <input
              className="search-bar-input-text"
              id="outlined-basic"
              placeholder="Call name"
              variant="outlined"
              size="small"
              onChange={(event) => {}}
            />
            <select
              label="Area Zone"
              value={zone_name}
              onChange={(event) => {
                setZoneName(event.target.value);
                getFilteredHospitals(event.target.value);
              }}
              className="search-bar-input-dropdown"
            >
              <option key={""} value={"all"}>
                Select Area Zone
              </option>
              {ConVar.hospitalZone.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
            >
              <RestartAltRoundedIcon />
            </button>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>
        <div className="double-container">
          <div className="call-list-container">
            <table className="call-table">
              <thead>
              <tr>
                <th>Call Name</th>
                <th>Hospital</th>
                <th>Location</th>
                <th>Creation Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody className="call-table-body">
                {callList.length ? (
                  <>
                    {callList.map((row) => (
                      <>
                        <tr key={row._id} data={callList} onClick={() => {}}>
                          <td className="r20start">{row.call_name}</td>
                          <td className="r20">{row.system_no}</td>
                          <td className="r20">{row.location}</td>
                          <td className="r10"> {convertTimestampToDate(row.call_creation_date)} </td>
                          <td className="r10"> {getCallStatus(row.call_status)} </td>
                          <td className="r10end">
                            <button
                              onClick={() => {
                                toggleRow(row._id);
                              }}
                            >
                              {expandedRows[row._id] ? (
                                <ExpandLessIcon className="chevron-right-icon" />
                              ) : (
                                <ExpandMore className="expand-more-icon" />
                              )}
                            </button>
                            {/* {row.call_status == 1 && <button 
                                className="red-button" 
                                onClick={() => handleConvertCallToTask(row)}
                              >
                                <ChangeCircleRoundedIcon />
                              </button> } */}
                            {!taskList.some(task => task.call_id === row._id) && (
                              <button 
                                className="red-button" 
                                onClick={() => handleConvertCallToTask(row)}
                              >
                                <ChangeCircleRoundedIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                        {expandedRows[row._id] && (
                          <tr>
                            <td colspan="8" className="inner-property-expand">
                              <div className="inner-details-expand-styling">
                                <label><strong>Last Updated : </strong> {convertTimestampToDate(row.last_updated)}</label>
                                <label><strong>Name : </strong> {row.call_name}</label>
                                <label><strong>Status : </strong> {getCallStatus(row.call_status)}</label>
                                <label><strong>Location : </strong> {row.location}</label>
                                <label><strong>System : </strong> {row.system_no}</label>
                                <label><strong>Raised By : </strong> {row.call_created_by}</label>
                                <label><strong>Raised On : </strong> {convertTimestampToDate(row.call_creation_date)}</label>
                                <label><strong>Extra Description : </strong> {row.extra_desc}</label>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no-match-found">
                    Oops ! No Match Found 😁
                  </div>
                )}
              </tbody>
            </table>
          </div>
          <div className="side-data-container">
            <div className="side-data">
              <div className="side-data-left">
                {totalActiveCalls}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <ContactPhoneRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Unassigned Calls</label>
                </div>
              </div>
            </div>
            <div className="side-data">
              <div className="side-data-left">
                {totalAssignedCalls}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <AssignmentTurnedInRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Assigned Calls</label>
                </div>
              </div>
            </div>
            <div className="side-data">
              <div className="side-data-left">
                {totalCalls}
              </div>
              <div className="side-data-right">
                <div className="side-data-right-top">
                  <CallRoundedIcon/>
                </div>
                <div className="side-data-right-bottom">
                  <label>Total Calls</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showConvertDialog && (
          <ConvertCallToTask
            callData={selectedCall}
            openModal={showConvertDialog}
            onCloseModal={handleCloseConvertDialog}
            onSuccess={handleConvertSuccess}
          />
        )}
      </div>
    );    
  } else {
    return <Lock />;
  }
};
