import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";

import Lock from "../../components/Lock";

export const DeskR1 = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    let mounted = true;
    if (mounted) {
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);






  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
         <h4>Occupied BY: John Doe</h4>
        <div style={{display:'flex',justifyContent:'space-evenly'}}>
          <div className="desk-bucket">
            <h4>MY BUCKET</h4>
            <div>
              <div>One<button>Taken🔒</button></div>
              <div>One<button>Take</button></div>
              <div>One<button>Take</button></div>
              <div>One<button>Take</button></div>
            </div>
          </div>         
          <div className="desk-bucket">
            <h4>Done</h4>
            <div>
              <div>One<button>Send To IPQC</button></div>
              <div>One<button>Send To IPQC</button></div>
              <div>One<button>Send To IPQC</button></div>
              <div>One<button>Send To IPQC</button></div>
            </div>
          </div>      
        </div>
        <div className="desk-bucket-working">
            <h4 style={{textAlign:'center'}}>Processing</h4>
            <span>Current: XXX12SF </span>
            <div style={{display:'flex'}}>
              <div className="step-bubble-complete">step 2.1</div> 
              <div className="step-bubble-complete">step 2.2</div> 
              <div className="step-bubble-complete">step 2.3</div> 
              <div className="step-bubble-complete">step 2.4</div> 
              <div className="step-bubble-complete">step 2.5</div> 
              <div className="step-bubble">step 2.6</div> 
              <div className="step-bubble">step 2.7</div> 
              <div className="step-bubble">step 2.8</div> 
              <div className="step-bubble">step 2.9</div>              
              <div className="step-bubble">step 2.10</div> 
            </div>
          </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
