import React, { useState } from "react";
import AddTask from "../../components/addcomponents/AddTask";

const ConvertCallToTask = ({ callData, openModal, onCloseModal }) => {
  const [error, setError] = useState("");

  return (
    <div>
      {openModal && (
        <AddTask
          open={openModal}
          close={onCloseModal}
          data={{
            call_name: callData.call_name,
            system_no: callData.system_no,
            extra_desc: callData.extra_desc,
            call_id: callData.call_id,
          }}
          isFromConvertCallToTask={true}
        />
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ConvertCallToTask;
