import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq, httpPostReq } from "../../util/request";

import "../../css/TimeTesting.css";
import "../../css/EditSurgery.css";
import { Dialog } from "@mui/material";
import ReactSelect from "react-select";

const EditSurgery = ({ open, onClose, surgeryData }) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [surgeonList, setSurgeonList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedTextField, setSelectedTextField] = useState("");
  const [formData, setFormData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [postData, setPostData] = useState({});
  const [editableFields, setEditableFields] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const savedData = localStorage.getItem("editSurgeryFormData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData.formData || {});
      setSelectedTextField(parsedData.selectedTextField || {});
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Autosaving...");
      handleAutoSave();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [formData, selectedTextField]);

  useEffect(() => {
    const calculateLengthOfStay = () => {
      const admissionDate = new Date(formData.date_of_admission);
      const dischargeDate = new Date(formData.date_of_discharge);

      if (!isNaN(admissionDate.getTime()) && !isNaN(dischargeDate.getTime())) {
        const timeDifference =
          dischargeDate.getTime() - admissionDate.getTime();
        const lengthOfStay = Math.ceil(timeDifference / (1000 * 3600 * 24));

        setFormData((prevState) => ({
          ...prevState,
          length_of_stay_days: lengthOfStay.toString(),
        }));
      }
    };

    calculateLengthOfStay();
  }, [formData.date_of_admission, formData.date_of_discharge]);

useEffect(() => {
    if (surgeryData) {
      const initialData = {
          serial_no: surgeryData.patient_info?.serial_no,
          case_no: surgeryData.patient_info?.case_no,
          patient_id: surgeryData.patient_info?.patient_id,
          patient_name: surgeryData.patient_info?.patient_name,
          gender: surgeryData.patient_info?.gender,
          height: surgeryData.patient_info?.height,
          weight: surgeryData.patient_info?.weight,
          age: surgeryData.patient_info?.age,
          bmi: surgeryData.patient_info?.bmi,
          diagnosis: surgeryData.patient_info?.diagnosis,
          present_clinical_people: surgeryData.present_clinical_people?.map(person => ({
              value: person.value,
              label: person.label
          })),
          present_service_people: surgeryData.present_service_people?.map(person => ({
              value: person.value,
              label: person.label
          })),
          co_morbidities: surgeryData.co_morbidities,
          date_of_admission: convertToDateString(surgeryData.date_of_admission),
          date_of_surgery: convertToDateString(surgeryData.date_of_surgery),
          zone_id: surgeryData.zone_id,
          hospital_name: { value: surgeryData.hospital_name, label: surgeryData.hospital_name },
          system_no: { value: surgeryData.system_no, label: surgeryData.system_no },
          surgeon_name: surgeryData.surgeon_name?.map(surgeon => ({
              value: surgeon.value,
              label: surgeon.label
          })),
          assistant_surgeon: surgeryData.assistant_surgeon?.map(surgeon => ({
              value: surgeon.value,
              label: surgeon.label
          })),
          procedure_category: surgeryData.procedure_category,
          procedure_code: surgeryData.procedure_code,
          surgery_specification: surgeryData.surgery_specification,
          patient_in_time: convertToTimestamp(surgeryData.patient_in_time),
          patient_out_time: convertToTimestamp(surgeryData.patient_out_time),
          no_of_arms: surgeryData.no_of_arms,
          mantra_on_time: convertToTimestamp(surgeryData.mantra_system_operating_info?.on_time),
          mantra_off_time: convertToTimestamp(surgeryData.mantra_system_operating_info?.off_time),
          mantra_duration: surgeryData.mantra_system_operating_info?.duration,
          patient_intubation_start: convertToTimestamp(surgeryData.patient_intubation?.start),
          patient_intubation_end: convertToTimestamp(surgeryData.patient_intubation?.end),
          patient_intubation_duration: surgeryData.patient_intubation?.duration,
          drapping_start: convertToTimestamp(surgeryData.drapping?.start),
          drapping_end: convertToTimestamp(surgeryData.drapping?.end),
          port_placement_start: convertToTimestamp(surgeryData.port_placement?.start),
          port_placement_end: convertToTimestamp(surgeryData.port_placement?.end),
          port_placement_duration: surgeryData.port_placement?.duration,
          second_port_placement_start: convertToTimestamp(surgeryData.second_port_placement?.start),
          second_port_placement_end: convertToTimestamp(surgeryData.second_port_placement?.end),
          second_port_placement_duration: surgeryData.second_port_placement?.duration,
          dock_start: convertToTimestamp(surgeryData.dock?.start),
          dock_end: convertToTimestamp(surgeryData.dock?.end),
          dock_duration: surgeryData.dock?.duration,
          second_dock_start: convertToTimestamp(surgeryData.second_dock?.start),
          second_dock_end: convertToTimestamp(surgeryData.second_dock?.end),
          second_dock_duration: surgeryData.second_dock?.duration,
          console_start: convertToTimestamp(surgeryData.console?.start),
          console_end: convertToTimestamp(surgeryData.console?.end),
          console_interrupt: convertToTimestamp(surgeryData.console?.console_interrupt),
          console_duration: surgeryData.console?.duration,
          surgery_start: convertToTimestamp(surgeryData.surgery?.start),
          surgery_end: convertToTimestamp(surgeryData.surgery?.end),
          surgery_duration: surgeryData.surgery?.duration,
          undock_start: convertToTimestamp(surgeryData.undock?.start),
          undock_end: convertToTimestamp(surgeryData.undock?.end),
          undock_duration: surgeryData.undock?.duration,
          second_undock_start: convertToTimestamp(surgeryData.second_undock?.start),
          second_undock_end: convertToTimestamp(surgeryData.second_undock?.end),
          second_undock_duration: surgeryData.second_undock?.duration,
          date_of_discharge: convertToDateString(surgeryData.post_surgery_info?.date_of_discharge),
          length_of_stay_days: surgeryData.post_surgery_info?.length_of_stay_days,
          readmission: surgeryData.post_surgery_info?.readmission,
          post_discharge_complications: surgeryData.post_surgery_info?.post_discharge_complications,
          clavien: surgeryData.post_surgery_info?.clavien,
          blood_transfusion: surgeryData.blood_transfusion,
          mortality: surgeryData.mortality,
          case_conversion: surgeryData.case_conversion,
          blood_loss: surgeryData.blood_loss,
          intra_operative_complications: surgeryData.intra_operative_complications,
          entry_remarks_info: surgeryData.entry_remarks_info,
          updatedBy: surgeryData.updatedBy,
          hospital_location: surgeryData.hospital_location,
          sub_components: surgeryData.system_issues?.sub_components,
          devices_issues: surgeryData.system_issues?.devices_issues,
          device_patient_complications: surgeryData.system_issues?.device_patient_complications,
      };

      setFormData(initialData);
      setInitialFormData(initialData);
    }
}, [surgeryData]);

  function convertToDateString(ms) {
    const date = new Date(ms);
    return date.toISOString().substring(0, 10);
  }

  function convertToTimestamp(ms) {
    const date = new Date(ms);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach((key) => {
      if (
        key === "surgeon_name" ||
        key === "assistant_surgeon" ||
        key === "present_clinical_people" ||
        key === "present_service_people"
      ) {
        const initialValues = initialFormData[key].map((item) => item.value);
        const currentValues = formData[key].map((item) => item.value);
        if (JSON.stringify(initialValues) !== JSON.stringify(currentValues)) {
          changedFields[key] = formData[key].map((item) => ({
            label: item.label,
            value: item.value,
          }));
        }
      } else if (
        JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])
      ) {
        changedFields[key] = formData[key];
      }
    });

    return changedFields;
  };

  const handleAutoSave = () => {
    const savedData = {
      formData: formData,
      selectedTextField: selectedTextField,
    };
    localStorage.setItem("editSurgeryFormData", JSON.stringify(savedData));
  };

  useEffect(() => {
    getProcedureList();
    getHospitalList();
    getSurgeonList();
    getUserList();
  }, []);

  const getProcedureList = () => {
    const url = config.baseUrl + config.ver + config.procedureList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setProcedureList(response.data.data);
      } else {
        console.error("Error fetching procedure list:", response.data.message);
      }
    });
  };

  const getHospitalList = () => {
    const url = config.baseUrl + config.ver + config.hospitalList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setHospitalList(response.data.data);
      } else {
        console.error("Error fetching hospital list:", response.data.message);
      }
    });
  };

  const getSurgeonList = () => {
    const url = config.baseUrl + config.ver + config.surgeonList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setSurgeonList(response.data.data);
      } else {
        console.error("Error fetching surgeon list:", response.data.message);
      }
    });
  };

  const getUserList = () => {
    const url = config.baseUrl + config.ver + config.userList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setUserList(response.data.data);
      } else {
        console.error("Error fetching users list:", response.data.message);
      }
    });
  };

  const isValidTimestamp = (timestamp) => {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return regex.test(timestamp);
  };

  const isChronologicalOrder = (startTimestamp, endTimestamp) => {
    const startTime = new Date(startTimestamp).getTime();
    const endTime = new Date(endTimestamp).getTime();
    return startTime <= endTime;
  };

  const handleInputChange = (key, value) => {
    setFormData((prevState) => {
      if (typeof key === 'string' && key.includes('.')) {
        const keys = key.split('.');
        const updatedData = { ...prevState };
        let currentLevel = updatedData;
        for (let i = 0; i < keys.length - 1; i++) {
          currentLevel = currentLevel[keys[i]];
        }
        currentLevel[keys[keys.length - 1]] = value;
        return updatedData;
      } else {
        return {
          ...prevState,
          [key]: value,
        };
      }
    });
  };  

  const handleSelectChange = (key, selectedOption) => {
    if (Array.isArray(selectedOption)) {
      handleInputChange(key, selectedOption.map(option => ({ label: option.label, value: option.value })));
    } else {
      handleInputChange(key, selectedOption.value);
    }
  };  
  
  const handleTimestampChange = (key, value) => {
      setInputValues((prevState) => ({
          ...prevState,
          [key]: value,
      }));
  };

  const handleTimestampBlur = (key) => {
      const value = inputValues[key];
      if (!isValidTimestamp(value)) {
          alert('Invalid timestamp format. Please use "YYYY-MM-DD HH:MM:SS".');
          return;
      }

      const isEndTimestamp = key.endsWith("end") || key.endsWith("End");
      const correspondingKey = isEndTimestamp ? key.replace(/end|End/, "start") : key.replace(/start|Start/, "end");

      if (formData[correspondingKey] && !isChronologicalOrder(isEndTimestamp ? formData[correspondingKey] : value, isEndTimestamp ? value : formData[correspondingKey])) {
          alert("Start time cannot be greater than end time.");
          return;
      }

      handleInputChange(key, value);
  };

  const handleFieldChange = (e) => {
    const { id, value } = e.target;
    if (
      id.includes("start") ||
      id.includes("end") ||
      id.includes("Start") ||
      id.includes("End") ||
      id.includes("time") ||
      id.includes("Time")
    ) {
      handleTimestampChange(id, value);
    } else {
      handleInputChange(id, value);
    }
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    if (
      id.includes("start") ||
      id.includes("end") ||
      id.includes("Start") ||
      id.includes("End") ||
      id.includes("time") ||
      id.includes("Time")
    ) {
        handleTimestampBlur(id);
    }
};

  const calculateDuration = (start, end) => {
    if (start && end) {
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();
      return endTime - startTime;
    }
    return null;
  };

  const handleSubmit = async () => {
    setOpenDialog(true);

    const convertToMilliseconds = (dateString) => {
      return dateString ? new Date(dateString).getTime() : null;
    };

    const changedFields = getChangedFields();
    
    // Calculate and update duration fields if timestamps have changed
    if (changedFields.patient_intubation_start || changedFields.patient_intubation_end) {
      changedFields.patient_intubation_duration = calculateDuration(changedFields.patient_intubation_start || initialFormData.patient_intubation_start, changedFields.patient_intubation_end || initialFormData.patient_intubation_end);
    }

    if (changedFields.drapping_start || changedFields.drapping_end) {
      changedFields.drapping_duration = calculateDuration(changedFields.drapping_start || initialFormData.drapping_start, changedFields.drapping_end || initialFormData.drapping_end);
    }

    if (changedFields.port_placement_start || changedFields.port_placement_end) {
      changedFields.port_placement_duration = calculateDuration(changedFields.port_placement_start || initialFormData.port_placement_start, changedFields.port_placement_end || initialFormData.port_placement_end);
    }

    if (changedFields.second_port_placement_start || changedFields.second_port_placement_end) {
      changedFields.second_port_placement_duration = calculateDuration(changedFields.second_port_placement_start || initialFormData.second_port_placement_start, changedFields.second_port_placement_end || initialFormData.second_port_placement_end);
    }

    if (changedFields.dock_start || changedFields.dock_end) {
      changedFields.dock_duration = calculateDuration(changedFields.dock_start || initialFormData.dock_start, changedFields.dock_end || initialFormData.dock_end);
    }

    if (changedFields.second_dock_start || changedFields.second_dock_end) {
      changedFields.second_dock_duration = calculateDuration(changedFields.second_dock_start || initialFormData.second_dock_start, changedFields.second_dock_end || initialFormData.second_dock_end);
    }

    if (changedFields.console_start || changedFields.console_end) {
      changedFields.console_duration = calculateDuration(changedFields.console_start || initialFormData.console_start, changedFields.console_end || initialFormData.console_end);
    }

    if (changedFields.surgery_start || changedFields.surgery_end) {
      changedFields.surgery_duration = calculateDuration(changedFields.surgery_start || initialFormData.surgery_start, changedFields.surgery_end || initialFormData.surgery_end);
    }

    if (changedFields.undock_start || changedFields.undock_end) {
      changedFields.undock_duration = calculateDuration(changedFields.undock_start || initialFormData.undock_start, changedFields.undock_end || initialFormData.undock_end);
    }

    if (changedFields.second_undock_start || changedFields.second_undock_end) {
      changedFields.second_undock_duration = calculateDuration(changedFields.second_undock_start || initialFormData.second_undock_start, changedFields.second_undock_end || initialFormData.second_undock_end);
    }

    const postData = {
      "patient_info": {
        "serial_no": changedFields.serial_no || initialFormData.serial_no,
        "case_no": changedFields.case_no || initialFormData.case_no,
        "patient_id": changedFields.patient_id || initialFormData.patient_id,
        "patient_name": changedFields.patient_name || initialFormData.patient_name,
        "gender": changedFields.gender || initialFormData.gender,
        "height": changedFields.height || initialFormData.height,
        "weight": changedFields.weight || initialFormData.weight,
        "age": changedFields.age || initialFormData.age,
        "bmi": changedFields.bmi || initialFormData.bmi,
        "diagnosis": changedFields.diagnosis || initialFormData.diagnosis
      },
      "present_clinical_people": changedFields.present_clinical_people || initialFormData.present_clinical_people,
      "present_service_people": changedFields.present_service_people || initialFormData.present_service_people,
      "co_morbidities": changedFields.co_morbidities || initialFormData.co_morbidities,
      "date_of_admission": convertToMilliseconds(changedFields.date_of_admission) || convertToMilliseconds(initialFormData.date_of_admission),
      "date_of_surgery": convertToMilliseconds(changedFields.date_of_surgery) || convertToMilliseconds(initialFormData.date_of_surgery),
      "zone_id": changedFields.zone_id || initialFormData.zone_id,
      "hospital_name": changedFields.hospital_name || initialFormData.hospital_name,
      "system_no": changedFields.system_no || initialFormData.system_no,
      "surgeon_name": changedFields.surgeon_name || initialFormData.surgeon_name,
      "assistant_surgeon": changedFields.assistant_surgeon || initialFormData.assistant_surgeon,
      "procedure_category": changedFields.procedure_category || initialFormData.procedure_category,
      "procedure_code": changedFields.procedure_code || initialFormData.procedure_code,
      "surgery_specification": changedFields.surgery_specification || initialFormData.surgery_specification,
      "patient_in_time": convertToMilliseconds(changedFields.patient_in_time) || convertToMilliseconds(initialFormData.patient_in_time),
      "mantra_system_operating_info": {
        "on_time": convertToMilliseconds(changedFields.mantra_on_time) || convertToMilliseconds(initialFormData.mantra_on_time),
        "off_time": convertToMilliseconds(changedFields.mantra_off_time) || convertToMilliseconds(initialFormData.mantra_off_time),
        "duration": changedFields.mantra_duration || initialFormData.mantra_duration
      },
      "no_of_arms": changedFields.no_of_arms || initialFormData.no_of_arms,
      "patient_intubation": {
        "start": convertToMilliseconds(changedFields.patient_intubation_start) || convertToMilliseconds(initialFormData.patient_intubation_start),
        "end": convertToMilliseconds(changedFields.patient_intubation_end) || convertToMilliseconds(initialFormData.patient_intubation_end),
        "duration": changedFields.patient_intubation_duration || initialFormData.patient_intubation_duration
      },
      "drapping": {
        "start": convertToMilliseconds(changedFields.drapping_start) || convertToMilliseconds(initialFormData.drapping_start),
        "end": convertToMilliseconds(changedFields.drapping_end) || convertToMilliseconds(initialFormData.drapping_end),
        "duration": changedFields.drapping_duration || initialFormData.drapping_duration
      },
      "port_placement": {
        "start": convertToMilliseconds(changedFields.port_placement_start) || convertToMilliseconds(initialFormData.port_placement_start),
        "end": convertToMilliseconds(changedFields.port_placement_end) || convertToMilliseconds(initialFormData.port_placement_end),
        "duration": changedFields.port_placement_duration || initialFormData.port_placement_duration
      },
      "second_port_placement": {
        "start": convertToMilliseconds(changedFields.second_port_placement_start) || convertToMilliseconds(initialFormData.second_port_placement_start),
        "end": convertToMilliseconds(changedFields.second_port_placement_end) || convertToMilliseconds(initialFormData.second_port_placement_end),
        "duration": changedFields.second_port_placement_duration || initialFormData.second_port_placement_duration
      },
      "dock": {
        "start": convertToMilliseconds(changedFields.dock_start) || convertToMilliseconds(initialFormData.dock_start),
        "end": convertToMilliseconds(changedFields.dock_end) || convertToMilliseconds(initialFormData.dock_end),
        "duration": changedFields.dock_duration || initialFormData.dock_duration
      },
      "second_dock": {
        "start": convertToMilliseconds(changedFields.second_dock_start) || convertToMilliseconds(initialFormData.second_dock_start),
        "end": convertToMilliseconds(changedFields.second_dock_end) || convertToMilliseconds(initialFormData.second_dock_end),
        "duration": changedFields.second_dock_duration || initialFormData.second_dock_duration
      },
      "console": {
        "start": convertToMilliseconds(changedFields.console_start) || convertToMilliseconds(initialFormData.console_start),
        "end": convertToMilliseconds(changedFields.console_end) || convertToMilliseconds(initialFormData.console_end),
        "duration": changedFields.console_duration || initialFormData.console_duration
      },
      "surgery": {
        "start": convertToMilliseconds(changedFields.surgery_start) || convertToMilliseconds(initialFormData.surgery_start),
        "end": convertToMilliseconds(changedFields.surgery_end) || convertToMilliseconds(initialFormData.surgery_end),
        "duration": changedFields.surgery_duration || initialFormData.surgery_duration
      },
      "undock": {
        "start": convertToMilliseconds(changedFields.undock_start) || convertToMilliseconds(initialFormData.undock_start),
        "end": convertToMilliseconds(changedFields.undock_end) || convertToMilliseconds(initialFormData.undock_end),
        "duration": changedFields.undock_duration || initialFormData.undock_duration
      },
      "second_undock": {
        "start": convertToMilliseconds(changedFields.second_undock_start) || convertToMilliseconds(initialFormData.second_undock_start),
        "end": convertToMilliseconds(changedFields.second_undock_end) || convertToMilliseconds(initialFormData.second_undock_end),
        "duration": changedFields.second_undock_duration || initialFormData.second_undock_duration
      },
      "patient_out_time": convertToMilliseconds(changedFields.patient_out_time) || convertToMilliseconds(initialFormData.patient_out_time),
      "system_issues": {
        "sub_components": changedFields.sub_components || initialFormData.sub_components,
        "devices_issues": changedFields.devices_issues || initialFormData.devices_issues,
        "device_patient_complications": changedFields.device_patient_complications || initialFormData.device_patient_complications
      },
      "post_surgery_info": {
        "date_of_discharge": convertToMilliseconds(changedFields.date_of_discharge) || convertToMilliseconds(initialFormData.date_of_discharge),
        "length_of_stay_days": changedFields.length_of_stay_days || initialFormData.length_of_stay_days,
        "readmission": changedFields.readmission || initialFormData.readmission,
        "post_discharge_complications": changedFields.post_discharge_complications || initialFormData.post_discharge_complications,
        "clavien": changedFields.clavien?.value || initialFormData.clavien?.value
      },
      "blood_transfusion": changedFields.blood_transfusion || initialFormData.blood_transfusion,
      "mortality": changedFields.mortality || initialFormData.mortality,
      "case_conversion": changedFields.case_conversion || initialFormData.case_conversion,
      "blood_loss": changedFields.blood_loss || initialFormData.blood_loss,
      "intra_operative_complications": changedFields.intra_operative_complications || initialFormData.intra_operative_complications,
      "entry_remarks_info": changedFields.entry_remarks_info || initialFormData.entry_remarks_info,
      "hospital_location": changedFields.hospital_location || initialFormData.hospital_location
    };

    if (changedFields.present_clinical_people) {
      postData.present_clinical_people = formData.present_clinical_people.map(
        (person) => ({
          label: person.label,
          value: person.value,
        })
      );
    } else {
      postData.present_clinical_people = initialFormData.present_clinical_people.map(
        (person) => ({
          label: person.label,
          value: person.value,
        })
      );
    }

    if (changedFields.present_service_people) {
      postData.present_service_people = formData.present_service_people.map(
        (person) => ({
          label: person.label,
          value: person.value,
        })
      );
    } else {
      postData.present_service_people = initialFormData.present_service_people.map(
        (person) => ({
          label: person.label,
          value: person.value,
        })
      );
    }

    if (changedFields.surgeon_name) {
      postData.surgeon_name = formData.surgeon_name.map((surgeon) => ({
        label: surgeon.label,
        value: surgeon.value,
      }));
    } else {
      postData.surgeon_name = initialFormData.surgeon_name.map((surgeon) => ({
        label: surgeon.label,
        value: surgeon.value,
      }));
    }

    if (changedFields.assistant_surgeon) {
      postData.assistant_surgeon = formData.assistant_surgeon.map(
        (surgeon) => ({
          label: surgeon.label,
          value: surgeon.value,
        })
      );
    } else {
      postData.assistant_surgeon = initialFormData.assistant_surgeon.map(
        (surgeon) => ({
          label: surgeon.label,
          value: surgeon.value,
        })
      );
    }

    setPostData(postData);
  };

  const handleConfirmSubmit = async (postData) => {
    const editUrl = `${config.baseUrl}${config.ver}${config.surgeriesEdit}${surgeryData._id}`;

    try {
      const response = await httpPostReq( editUrl, postData, "application/json",
        (response) => {
          if (response.data && response.data.status === "success") {
            setMessage("Surgery data updated successfully");
            setWarning("");
            setOpenDialog(false);
            handleResetting();
            onClose();
          } else if (response.data && response.data.status === "fail") {
            setWarning(response.data.message);
            setMessage("");
          }
        }
      );
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleResetting = async () => {
    setFormData({});
    setSelectedTextField({});
    localStorage.removeItem("editSurgeryFormData");
    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={onClose} className="edittimeproperty-dialog" maxWidth={false} fullWidth={true} >
      {warning && <div className="error-message">{warning}</div>}
      {message && <div className="success-message">{message}</div>}
      <div className="edittimeproperty-form-title">Edit Form</div>
      <form className="timetesting-editform">
        <div className="form-field">
          <label htmlFor="serial_no">Serial No</label>
          <input
            type="text"
            id="serial_no"
            value={formData.serial_no || ""}
            onChange={(e) => handleInputChange("serial_no", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="case_no">Case No</label>
          <input
            type="text"
            id="case_no"
            value={formData.case_no || ""}
            onChange={(e) => handleInputChange("case_no", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="patient_id">Patient ID</label>
          <input
            type="text"
            id="patient_id"
            value={formData.patient_id || ""}
            onChange={(e) => handleInputChange("patient_id", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="patient_name">Patient Name</label>
          <input
            type="text"
            id="patient_name"
            value={formData.patient_name || ""}
            onChange={(e) => handleInputChange("patient_name", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label>Gender</label>
          <div className="radio-button-container">
          <input
            type="radio"
            id="male"
            name="gender"
            value="Male"
            checked={formData.gender === "Male"}
            onChange={(e) => handleInputChange("gender", e.target.value)}
          />
          <label htmlFor="male">Male</label>
          <input
            type="radio"
            id="female"
            name="gender"
            value="Female"
            checked={formData.gender === "Female"}
            onChange={(e) => handleInputChange("gender", e.target.value)}
          />
          <label htmlFor="female">Female</label>
          <input
            type="radio"
            id="others"
            name="gender"
            value="Others"
            checked={formData.gender === "Others"}
            onChange={(e) => handleInputChange("gender", e.target.value)}
          />
          <label htmlFor="others">Others</label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="age">Age</label>
          <input
            type="number"
            id="age"
            value={formData.age || ""}
            onChange={(e) => handleInputChange("age", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="height">Height (cm)</label>
          <input
            type="number"
            id="height"
            value={formData.height || ""}
            onChange={(e) => handleInputChange("height", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="weight">Weight (Kg)</label>
          <input
            type="number"
            id="weight"
            value={formData.weight || ""}
            onChange={(e) => handleInputChange("weight", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="bmi">BMI</label>
          <input
            type="text"
            id="bmi"
            value={formData.bmi || ""}
            onChange={(e) => handleInputChange("bmi", e.target.value)}
            disabled
          />
        </div>
        <div className="form-field">
          <label htmlFor="diagnosis">Diagnosis</label>
          <input
            type="text"
            id="diagnosis"
            value={formData.diagnosis || ""}
            onChange={(e) => handleInputChange("diagnosis", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="present_clinical_people"> Present Clinical Service Members </label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.present_clinical_people || []}
            onChange={(selectedOptions) => handleSelectChange('present_clinical_people', selectedOptions)}
            isMulti
            options={userList.filter((user) => user.user_type === "clinical-support").map((user) => ({ label: user.name, value: user._id }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="present_service_people"> Present Field Service Members </label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.present_service_people || []}
            onChange={(selectedOptions) => handleSelectChange('present_service_people', selectedOptions)}
            isMulti
            options={userList.filter((user) => user.user_type === "service-engineer").map((user) => ({ label: user.name, value: user._id }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="co_morbidities">Co-morbidities</label>
          <input
            type="text"
            id="co_morbidities"
            value={formData.co_morbidities || ""}
            onChange={(e) => handleInputChange("co_morbidities", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="date_of_admission">Date of Admission</label>
          <input
            type="date"
            id="date_of_admission"
            value={formData.date_of_admission || ""}
            onChange={(e) => handleInputChange("date_of_admission", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="date_of_surgery">Date of Surgery</label>
          <input
            type="date"
            id="date_of_surgery"
            value={formData.date_of_surgery || ""}
            onChange={(e) => handleInputChange("date_of_surgery", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="zone_id">Zone</label>
          <select
            className="timetesting-dropdownmenu"
            value={formData.zone_id || ""}
            onChange={(e) => handleInputChange(formData.zone_id, e.target.value) }
            disabled
          >
            <option value="">Select Zone</option>
            {ConVar.hospitalZone.map((zone) => (
              <option className="list-option" key={zone.value} value={zone.value} >
                {zone.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="hospital_name">Hospital Name</label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.hospital_name || null}
            onChange={(selectedOption) => handleInputChange(formData.hospital_name, selectedOption) }
            options={hospitalList.map((hospital) => ({
              label: hospital.name,
              value: hospital._id,
            }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="system_no">System No</label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.system_no || null}
            onChange={(selectedOption) => handleInputChange(formData.system_no, selectedOption) }
            options={hospitalList.map((hospital) => ({
              label: hospital.system_no,
              value: hospital._id,
            }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="surgeon_name">Surgeon Name</label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.surgeon_name || []}
            onChange={(selectedOptions) => handleSelectChange('surgeon_name', selectedOptions)}
            isMulti
            options={surgeonList.map((surgeon) => ({ label: surgeon.surgeon_name, value: surgeon._id }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="assistant_surgeon">Assistant Surgeon</label>
          <ReactSelect
            className="timetesting-dropdownmenu"
            value={formData.assistant_surgeon || []}
            onChange={(selectedOptions) => handleSelectChange('assistant_surgeon', selectedOptions)}
            isMulti
            options={surgeonList.map((surgeon) => ({ label: surgeon.surgeon_name, value: surgeon._id }))}
          />
        </div>
        <div className="form-field">
          <label htmlFor="procedure_category">Procedure Category</label>
          <select
            className="timetesting-dropdownmenu"
            value={formData.procedure_category || ""}
            onChange={(e) => handleInputChange(formData.procedure_category, e.target.value)}
          >
            <option value="">Select Procedure Category</option>
            {ConVar.procedureCategory.map((procedure) => (
              <option className="list-option" key={procedure.value} value={procedure.value}>
                {procedure.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="procedure_code">Procedure Code</label>
          <select
            className="timetesting-dropdownmenu"
            value={formData.procedure_code || ""}
            onChange={(e) => handleInputChange(formData.procedure_code, e.target.value)}
          >
            <option value="">Select Procedure Code</option>
            {procedureList.map((procedure) => (
              <option className="list-option" key={procedure._id} value={procedure.procedure_code}>
                {procedure.procedure_code} - {procedure.procedure_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="surgery_specification">Surgery Specification</label>
          <input
            type="text"
            id="surgery_specification"
            value={formData.surgery_specification || ""}
            onChange={(e) => handleInputChange("surgery_specification", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="patient_in_time">Patient In Time</label>
          <input
            type="text"
            id="patient_in_time"
            value={inputValues.patient_in_time || formData.patient_in_time || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="mantra_on_time">Mantra System Start</label>
          <input
            type="text"
            id="mantra_on_time"
            value={inputValues.mantra_on_time || formData.mantra_on_time || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label>No. of ARM Setup</label>
          <div className="radio-button-container">
          <input
            type="radio"
            id="3_arm"
            name="no_of_arms"
            value="3 ARM"
            checked={formData.no_of_arms === "3 ARM"}
            onChange={(e) => handleInputChange("no_of_arms", e.target.value)}
          />
          <label htmlFor="3_arm">3 ARM</label>
          <input
            type="radio"
            id="4_arm"
            name="no_of_arms"
            value="4 ARM"
            checked={formData.no_of_arms === "4 ARM"}
            onChange={(e) => handleInputChange("no_of_arms", e.target.value)}
          />
          <label htmlFor="4_arm">4 ARM</label>
          <input
            type="radio"
            id="5_arm"
            name="no_of_arms"
            value="5 ARM"
            checked={formData.no_of_arms === "5 ARM"}
            onChange={(e) => handleInputChange("no_of_arms", e.target.value)}
          />
          <label htmlFor="5_arm">5 ARM</label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="patient_intubation_start">Intubation Start</label>
          <input
            type="text"
            id="patient_intubation_start"
            value={inputValues.patient_intubation_start || formData.patient_intubation_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="patient_intubation_end">Intubation End</label>
          <input
            type="text"
            id="patient_intubation_end"
            value={inputValues.patient_intubation_end || formData.patient_intubation_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="drapping_start">Draping Start</label>
          <input
            type="text"
            id="drapping_start"
            value={inputValues.drapping_start || formData.drapping_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
        />
        </div>
        <div className="form-field">
          <label htmlFor="drapping_end">Draping End</label>
          <input
            type="text"
            id="drapping_end"
            value={inputValues.drapping_end || formData.drapping_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="port_placement_start">1st Port Placement Start</label>
          <input
            type="text"
            id="port_placement_start"
            value={inputValues.port_placement_start || formData.port_placement_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="port_placement_end">1st Port Placement End</label>
          <input
            type="text"
            id="port_placement_end"
            value={inputValues.port_placement_end || formData.port_placement_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_port_placement_start"> 2nd Port Placement Start </label>
          <input
            type="text"
            id="second_port_placement_start"
            value={inputValues.second_port_placement_start || formData.second_port_placement_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_port_placement_end"> 2nd Port Placement End </label>
          <input
            type="text"
            id="second_port_placement_end"
            value={inputValues.second_port_placement_end || formData.second_port_placement_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="dock_start">1st Dock Start</label>
          <input
            type="text"
            id="dock_start"
            value={inputValues.dock_start || formData.dock_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="dock_end">1st Dock End</label>
          <input
            type="text"
            id="dock_end"
            value={inputValues.dock_end || formData.dock_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_dock_start">2nd Dock Start</label>
          <input
            type="text"
            id="second_dock_start"
            value={inputValues.second_dock_start || formData.second_dock_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_dock_end">2nd Dock End</label>
          <input
            type="text"
            id="second_dock_end"
            value={inputValues.second_dock_end || formData.second_dock_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="console_start">Console Start</label>
          <input
            type="text"
            id="console_start"
            value={inputValues.console_end || formData.console_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="surgery_start">Surgery Start</label>
          <input
            type="text"
            id="surgery_start"
            value={inputValues.surgery_start || formData.surgery_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="blood_transfusion">Blood Transfusion (ml)</label>
          <input
            type="number"
            id="blood_transfusion"
            value={formData.blood_transfusion || ""}
            onChange={(e) => handleInputChange("blood_transfusion", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label>Mortality</label>
          <div className="radio-button-container">
          <input
            type="radio"
            id="mortality_yes"
            name="mortality"
            value="Yes"
            checked={formData.mortality === "Yes"}
            onChange={(e) => handleInputChange("mortality", e.target.value)}
          />
          <label htmlFor="mortality_yes">Yes</label>
          <input
            type="radio"
            id="mortality_no"
            name="mortality"
            value="No"
            checked={formData.mortality === "No"}
            onChange={(e) => handleInputChange("mortality", e.target.value)}
          />
          <label htmlFor="mortality_no">No</label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="surgery_end">Surgery End</label>
          <input
            type="text"
            id="surgery_end"
            value={inputValues.surgery_end || formData.surgery_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label>Case Conversion</label>
          <div className="radio-button-container">
          <input
            type="radio"
            id="no_conversion"
            name="case_conversion"
            value="No Conversion"
            checked={formData.case_conversion === "No Conversion"}
            onChange={(e) => handleInputChange("case_conversion", e.target.value) }
          />
          <label htmlFor="no_conversion">No Conversion</label>
          <input
            type="radio"
            id="laparoscopic"
            name="case_conversion"
            value="Laparoscopic"
            checked={formData.case_conversion === "Laparoscopic"}
            onChange={(e) => handleInputChange("case_conversion", e.target.value) }
          />
          <label htmlFor="laparoscopic">Laparoscopic</label>
          <input
            type="radio"
            id="open_surgery"
            name="case_conversion"
            value="Open Surgery"
            checked={formData.case_conversion === "Open Surgery"}
            onChange={(e) => handleInputChange("case_conversion", e.target.value) }
          />
          <label htmlFor="open_surgery">Open Surgery</label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="blood_loss">Blood Loss (ml)</label>
          <input
            type="number"
            id="blood_loss"
            value={formData.blood_loss || ""}
            onChange={(e) => handleInputChange("blood_loss", e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="intra_operative_complications"> Intra Operative Complications </label>
          <input
            type="text"
            id="intra_operative_complications"
            value={formData.intra_operative_complications || ""}
            onChange={(e) => handleInputChange("intra_operative_complications", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="console_end">Console End</label>
          <input
            type="text"
            id="console_end"
            value={inputValues.console_end || formData.console_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="console_interrupt">Console Interrupt</label>
          <input
            type="text"
            id="console_interrupt"
            value={formData.console_interrupt || ""}
            onChange={handleFieldChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="console_end">Console Remark</label>
          <input
            type="text"
            id="console_end"
            value={formData.console_end || ""}
            onChange={handleFieldChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="undock_start">1st Undock Start</label>
          <input
            type="text"
            id="undock_start"
            value={inputValues.undock_start || formData.undock_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="undock_end">1st Undock End</label>
          <input
            type="text"
            id="undock_end"
            value={inputValues.undock_end || formData.undock_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_undock_start">2nd Undock Start</label>
          <input
            type="text"
            id="second_undock_start"
            value={inputValues.second_undock_start || formData.second_undock_start || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="second_undock_end">2nd Undock End</label>
          <input
            type="text"
            id="second_undock_end"
            value={inputValues.second_undock_end || formData.second_undock_end || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="mantra_off_time">Mantra System End</label>
          <input
            type="text"
            id="mantra_off_time"
            value={inputValues.mantra_off_time || formData.mantra_off_time || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="patient_out_time">Patient Out Time</label>
          <input
            type="text"
            id="patient_out_time"
            value={inputValues.patient_out_time || formData.patient_out_time || ""}
            onChange={handleFieldChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="form-field">
          <label htmlFor="sub_components"> System Issues Sub Components </label>
          <input
            type="text"
            id="sub_components"
            value={formData.sub_components || ""}
            onChange={(e) => handleInputChange("sub_components", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="devices_issues">Device Issues</label>
          <input
            type="text"
            id="devices_issues"
            value={formData.devices_issues || ""}
            onChange={(e) => handleInputChange("devices_issues", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="device_patient_complications"> Device Patient Complications </label>
          <input
            type="text"
            id="device_patient_complications"
            value={formData.device_patient_complications || ""}
            onChange={(e) => handleInputChange("device_patient_complications", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="date_of_discharge">Date of Discharge</label>
          <input
            type="date"
            id="date_of_discharge"
            value={formData.date_of_discharge || ""}
            onChange={(e) => handleInputChange("date_of_discharge", e.target.value) }
          />
        </div>
        <div className="form-field">
          <label htmlFor="length_of_stay_days">Length of Stay (Days)</label>
          <input
            type="number"
            id="length_of_stay_days"
            value={formData.length_of_stay_days || ""}
            onChange={(e) => handleInputChange("length_of_stay_days", e.target.value) }
            disabled
          />
        </div>
        <div className="form-field">
          <label>Readmission</label>
          <div className="radio-button-container">
          <input
            type="radio"
            id="readmission_yes"
            name="readmission"
            value="Yes"
            checked={formData.readmission === "Yes"}
            onChange={(e) => handleInputChange("readmission", e.target.value)}
          />
          <label htmlFor="readmission_yes">Yes</label>
          <input
            type="radio"
            id="readmission_no"
            name="readmission"
            value="No"
            checked={formData.readmission === "No"}
            onChange={(e) => handleInputChange("readmission", e.target.value)}  
          />
          <label htmlFor="readmission_no">No</label>
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="clavien">Clavien-Dindo Classification</label>
          <select
            className="timetesting-dropdownmenu"
            value={formData.clavien?.value || ""}
            onChange={(e) => handleInputChange('clavien', { label: e.target.options[e.target.selectedIndex].text, value: e.target.value })}
          >
            <option value="">Select Clavien Dindo Scale of Surgical Complications</option>
            {ConVar.typeOfClavien.map((complication) => (
              <option className="list-option" key={complication.value} value={complication.value}>
                {complication.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="post_discharge_complications"> Post-Discharge Complications </label>
          <input
            type="text"
            id="post_discharge_complications"
            value={formData.post_discharge_complications || ""}
            onChange={(e) => handleInputChange("post_discharge_complications", e.target.value) }
          />
        </div>
      </form>
      <div className="addproperty-dialog-action">
        <button type="button" className="addproperty-button" onClick={() => handleSubmit()}>
          Edit Form
        </button>
        <button type="button" className="cancel-button" onClick={onClose}>
          Cancel
        </button>
      </div>
      <Dialog className="missing-dialog" open={openDialog} onClose={handleCloseDialog} >
        <div className="missing-dialog-content">
          <p style={{ marginBottom: "10px" }}> Verify all the data before submission. Proceed to Submit Data ? </p>
          <div className="missing-button-area">
            <button className="missing-button" onClick={() => handleConfirmSubmit(postData)} >
              Submit Edited Data
            </button>
            <button className="missing-close-button" onClick={handleCloseDialog} >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};

export default EditSurgery;
