import React, { useState, useEffect } from "react";
import { httpGetReq } from "../../util/request";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import Pagination from "@mui/material/Pagination";
import { convertTimestampToDate, millisecondsToTime } from "../../util/util";
import { fetchProcedureDetails, fetchUsers, fetchHospitals, } from "../../store/actionCreator";
import SurgeryDetails from "../../components/SurgeryDetail";
import EditSurgery from "../../components/editcomponents/EditSurgery";

import "../../css/AllSurgeries.css";
import "../../css/CommonStyle.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

const SurgeryList = (props) => {
  let procedureName = {}, hospitalName = {};
  const [surgeriesList, setSurgeriesList] = useState([]);
  const [filteredHospitalList, setFilteredHospitalList] = useState([]);
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [hospital, setHospital] = useState("all");
  const [procedure, setProcedure] = useState("all");
  const [surgeryYear, setSurgeryYear] = useState("all");
  const [searchDependency, setSearchDependency] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [expandedDetailRows, setExpandedDetailRows] = useState({});
  const [expandedEditRows, setExpandedEditRows] = useState({});
  const [selectedEditSurgery, setSelectedEditSurgery] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [procedureList, setProcedureList] = useState([]);

  const handleClick = () => {
    if (pathname !== "/surgical-timesheet") {
      window.location.href = "/surgical-timesheet";
    }
  };

  if (props.procedures && props.procedures.length) {
    props.procedures.forEach((val) => {
      procedureName[val.procedure_code] = val.procedure_name;
    });
  }

  if (props.hospitals && props.hospitals.length) {
    props.hospitals.forEach((val) => {
      hospitalName[val.system_no] = val.name;
    });
  }

  if (props.procedures.length === 0) {
    props.fetchProcedureDetails();
  }

  if (props.hospitals.length === 0) {
    props.fetchHospitals();
  }

  if (Object.keys(props.surgeons).length === 0) {
    props.fetchSurgeonDetails();
  }

  useEffect(() => {
    getAllSurgeries();
  }, [currentPage, searchDependency]);

  useEffect(() => {
    if (zone_name !== "all") {
      getFilteredHospitals(zone_name);
    } else {
      setFilteredHospitalList(props.hospitals);
    }
  }, [zone_name]);

  useEffect(() => {
    getProcedureList();
  }, []);

  useEffect(() => {
    if (hospital !== "all") {
      getProceduresByHospital(hospital);
    } else {
      setProcedureList(props.procedures);
    }
  }, [hospital, props.procedures]);  

  const getProcedureList = () => {
    const url = config.baseUrl + config.ver + config.procedureList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setProcedureList(response.data.data);
      } else {
        console.error("Error fetching procedure list:", response.data.message);
      }
    });
  };

  const getAllSurgeries = () => {
    let url = `${config.baseUrl}${config.ver}${config.surgeries}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      url += `&surgery_year=${surgeryYear}`;
    }
    if (procedure !== "all" && procedure !== undefined) {
      url += `&procedure_code=${procedure}`;
    }
    if (hospital !== "all" && hospital !== undefined) {
      url += `&system_no=${hospital}`;
    }

    httpGetReq(url, "application/json", (response) => {
      if (response?.data?.status === "success") {
        setSurgeriesList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
        const hospitalIdsWithData = new Set( response.data.data.map((surgery) => surgery.system_no) );
        const filteredHospitals = props.hospitals.filter((hospital) => hospitalIdsWithData.has(hospital.system_no) );
        setFilteredHospitalList(filteredHospitals);
      }
    });
  };

  const getFilteredHospitals = (zone) => {
    let hospitalUrl = `${config.baseUrl}${config.ver}${config.hospitalList}?area_zone=${zone}`;
  
    httpGetReq(hospitalUrl, "application/json", (hospitalResponse) => {
      if (hospitalResponse?.data?.status === "success") {
        let surgeriesUrl = `${config.baseUrl}${config.ver}${config.surgeries}?limit=1000&area_zone=${zone}`;
  
        httpGetReq(surgeriesUrl, "application/json", (surgeryResponse) => {
          if (surgeryResponse?.data?.status === "success") {
            const hospitalIdsWithData = new Set(surgeryResponse.data.data.map((surgery) => surgery.system_no));
            const filteredHospitals = hospitalResponse.data.data.filter((hospital) => hospitalIdsWithData.has(hospital.system_no));
            setFilteredHospitalList(filteredHospitals);
          } else {
            console.error("Error fetching surgeries:", surgeryResponse?.data?.message || "Unknown error");
          }
        });
      } else {
        console.error("Error fetching hospitals by zone:", hospitalResponse?.data?.message || "Unknown error");
      }
    });
  };

  const getProceduresByHospital = (hospitalId) => {
    let procedureUrl = `${config.baseUrl}${config.ver}${config.procedureList}?limit=1000`;
  
    httpGetReq(procedureUrl, "application/json", (procedureResponse) => {
      if (procedureResponse?.data?.status === "success") {
        let surgeriesUrl = `${config.baseUrl}${config.ver}${config.surgeries}?limit=1000&system_no=${hospitalId}`;
  
        httpGetReq(surgeriesUrl, "application/json", (surgeryResponse) => {
          if (surgeryResponse?.data?.status === "success") {
            const proceduresSet = new Set(surgeryResponse.data.data.map((surgery) => surgery.procedure_code));
            const filteredProcedures = procedureResponse.data.data.filter((procedure) => proceduresSet.has(procedure.procedure_code));
            setProcedureList(filteredProcedures);
          } else {
            console.error("Error fetching surgeries by hospital:", surgeryResponse?.data?.message || "Unknown error");
          }
        });
      } else {
        console.error("Error fetching procedures:", procedureResponse?.data?.message || "Unknown error");
      }
    });
  };  

  const toggleRow = (rowId, type) => {
    if (type === "detail") {
      setExpandedDetailRows((prevExpandedDetailRows) => {
        const newExpandedDetailRows = { ...prevExpandedDetailRows };
        if (newExpandedDetailRows[rowId]) {
          delete newExpandedDetailRows[rowId];
        } else {
          newExpandedDetailRows[rowId] = true;
        }
        return newExpandedDetailRows;
      });
      setExpandedEditRows({});
    } else if (type === "edit") {
      setExpandedEditRows((prevExpandedEditRows) => {
        const newExpandedEditRows = { ...prevExpandedEditRows };
        if (newExpandedEditRows[rowId]) {
          delete newExpandedEditRows[rowId];
        } else {
          newExpandedEditRows[rowId] = true;
        }
        return newExpandedEditRows;
      });
      setExpandedDetailRows({});
    }

    const surgeryID = encodeURIComponent(rowId);
    if (
      (type === "detail" && !expandedDetailRows[rowId]) ||
      (type === "edit" && !expandedEditRows[rowId])
    ) {
      navigate(`${location.pathname}?${type}=${surgeryID}`);
    } else {
      navigate(location.pathname);
    }
  };

  const downloadDataCSV = () => {
    let url = `${config.baseUrl}${config.ver}${config.downloadSurgeries}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      url += `&surgery_year=${surgeryYear}`;
    }
    if (procedure !== "all" && procedure !== undefined) {
      url += `&procedure_code=${procedure}`;
    }
    if (hospital !== "all" && hospital !== undefined) {
      url += `&system_no=${hospital}`;
    }
    window.open(url);
    console.log("Dummy Download Button Clicked");
  };

  const openEditModal = (surgeryId) => {
    const detailUrl = `${config.baseUrl}${config.ver}${config.surgeriesDetail}${surgeryId}`;

    httpGetReq(detailUrl, "application/json", (response) => {
      if (response && response.data && response.data.status === "success") {
        setSelectedEditSurgery(response.data.data);
        setIsEditModalOpen(true);
        const surgeryID = encodeURIComponent(surgeryId);
        navigate(`${location.pathname}?edit=${surgeryID}`);
      } else {
        console.error( "Error fetching surgery data:", response?.data?.message || "Unknown error" );
      }
    });
  };

  const closeEditModal = () => {
    setSelectedEditSurgery(null);
    setIsEditModalOpen(false);
    navigate(location.pathname);
  };

  return (
    <div className="main-data-container">
      <div key="0" className="add-data-button-container">
        <button className="add-data-button" onClick={handleClick}>
          <RunningWithErrorsRoundedIcon />
          <span style={{ marginLeft: "10px" }}>
            Record Mantra Surgical Parameters
          </span>
        </button>
      </div>
      <div key="1" className="search-bar-container">
        <div className="search-bar-keep-right">
          <select
            value={zone_name}
            onChange={(event) => {
              setZoneName(event.target.value);
              setHospital("all");
            }}
            style={{ width: 150 }}
            className="search-bar-input-dropdown"
          >
            <option key={""} value={"all"}> Zone </option>
            {ConVar.hospitalZone.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className="search-bar-input-dropdown"
            value={hospital}
            onClick={() => {
              if (zone_name !== "all") {
                getFilteredHospitals(zone_name);
              }
            }}
            onChange={(e) => {
              setHospital(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Select Hospitals </option>
            {filteredHospitalList.map((option) => (
              <option key={option.system_no} value={option.system_no}>
                {option.name}
              </option>
            ))}
          </select>

          <select
            label="Procedure"
            className="search-bar-input-dropdown"
            value={procedure}
            onChange={(e) => {
              setProcedure(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Select Procedure </option>
            {procedureList.map((option, idx) => (
              <option
                key={option.procedure_code + idx}
                value={option.procedure_code}
              >
                {option.procedure_code}-{option.procedure_name}
              </option>
            ))}
          </select>
          <select
            value={surgeryYear}
            className="search-bar-input-dropdown"
            style={{ width: 100 }}
            onChange={(e) => {
              setSurgeryYear(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Year </option>
            {ConVar.surgeryYear.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSearchDependency(searchDependency + 1);
              getAllSurgeries();
            }}
            startIcon={<SearchIcon />}
          >
            <SearchIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSurgeryYear("all");
              setProcedure("all");
              setHospital("all");
              setSearchDependency(searchDependency + 1);
              getAllSurgeries();
              window.location.reload();
            }}
          >
            <RestartAltRoundedIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              downloadDataCSV();
            }}
          >
            <DownloadRoundedIcon />
            <span style={{ marginLeft: "2px" }}></span>
          </button>
        </div>
        <div className="pagination-setup">
          Page
          <Pagination
            className="pagination-setup-button"
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
          />
        </div>
      </div>

      <div className="property-list-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>CP-ID</th>
              <th>Hospital</th>
              <th>Date of Surgery</th>
              <th>Procedure Code</th>
              <th>Surgery Specification</th>
              <th>Total Console Time</th>
              <th>Total Surgery Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="property-table-body">
            {surgeriesList.length ? (
              <>
                {surgeriesList.map((row) => (
                  <>
                    <tr key={row._id}>
                      <td className="r10start"> {row.patient_info.case_no}-{row.patient_info.patient_id} </td>
                      <td className="r10" title={ hospitalName[row.system_no] ? hospitalName[row.system_no] : "" } > {row.system_no} </td>
                      <td className="r10"> {convertTimestampToDate(row.date_of_surgery)} </td>
                      <td className="r10" title={ procedureName[row.procedure_code] ? procedureName[row.procedure_code] : "" } > {row.procedure_code} </td>
                      <td className="r30"> {row.surgery_name ? row.surgery_name : row.surgery_specification} </td>
                      <td className="r10"> {row.console && row.console.duration && ( <>{millisecondsToTime(row.console.duration)}</> )} </td>
                      <td className="r10"> {row.surgery && row.surgery.duration && ( <>{millisecondsToTime(row.surgery.duration)}</> )} </td>
                      <td className="r20end">
                        <button onClick={() => toggleRow(row._id, "detail")}>
                          {expandedDetailRows[row._id] ? (
                            <ExpandLessIcon className="chevron-right-icon" />
                          ) : (
                            <ExpandMore className="expand-more-icon" />
                          )}
                        </button>
                        {props.UserInfo.user_type === "admin" && (
                          <button onClick={() => openEditModal(row._id)}>
                            <EditTwoToneIcon />
                          </button>
                        )}
                      </td>
                    </tr>

                    {expandedDetailRows[row._id] && (
                      <tr>
                        <td colSpan="8" className="inner-row-surgery-details-upper" >
                          <SurgeryDetails surgeryId={row._id} />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div className="no-match-found">No Match Found 😒😁</div>
            )}
          </tbody>
        </table>
      </div>

      {selectedEditSurgery && (
        <EditSurgery
          open={isEditModalOpen}
          onClose={closeEditModal}
          surgeryData={selectedEditSurgery}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
    fetchProcedureDetails: (callback) =>
      fetchProcedureDetails(dispatch, callback),
    fetchHospitals: (callback) => fetchHospitals(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryList);
