import React, { useEffect, useState } from "react";
import { ConVar } from "../../config/configVar";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import { convertTimestampToDate } from "../../util/util";

const HospitalInstrumentDetail = (props) => {
  const [instrumentDetail, setInstrumentDetail] = useState();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMudraDetail();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getMudraDetail = () => {
    let url =  config.baseUrl + config.ver + config.instrumentInHospitalDetail +"?system_no=" + props.system_no;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        setInstrumentDetail(response.data.hospitals);
      }
    });
  };
  if(instrumentDetail){
    return (
      <>
      {console.log(instrumentDetail)}
        <div>
          <div><label>Hospital:</label><label>{ instrumentDetail[0]._id || ''  }</label> </div>
          <p>        
          {instrumentDetail &&            
            instrumentDetail[0].instruments.map((vdx) => (
              <>               
                <p>                
                  {vdx.label}={vdx.instrument_unique_no} Count = {vdx.total_uses_count}                 
                </p> 
                <hr></hr>
              </>
            ))}
        </p>  
        <br></br>  
       
        Scrap
          <p>   {console.log(instrumentDetail[0].scrap)}            
          {instrumentDetail &&            
            instrumentDetail[0].scrap.map((vdx) => (
              <>               
                <p>                
                  {vdx.label}={vdx.instrument_unique_no} Count = {vdx.total_uses_count}                 
                </p> 
                <hr></hr>
              </>
            ))}
        </p> 
              
        </div>
      </>
    );
  }else{
    return (
      <>
        <p>Loading.....🫠</p>
      </>
    );
  }
 
};

export default HospitalInstrumentDetail;
