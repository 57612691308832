import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Lock from "../components/Lock";

import ChangePassword from "../components/ChangePassword";
import "../css/Profile.css";
function CustomTabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.value === props.index && <>{props.children}</>}
    </div>
  );
}
export const Profile = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (true) {
    return (
      <div className="profile-container">
        <div>
          <Tabs value={value} onChange={handleChange}>
            {/* <Tab label="Assigned Hospitals" /> */}
            <Tab label="Change Password" />
          </Tabs>
        </div>
        <div>
          {/* <CustomTabPanel value={value} index={0}>
            <div>Your hospitals</div>
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={0}>
            <ChangePassword {...props} />
          </CustomTabPanel>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
