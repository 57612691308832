import React, { useEffect, useState } from "react";
import config from "../config/configUrl";
import { httpGetReq } from "../util/request";
import "../css/Dashboard.css";

import Loader from "../components/Loader";
import Lock from "../components/Lock";
import BarChart from "../components/charts/Bar";
import BarChartNew from "../components/charts/BarNew";
import SimplePieChart from "../components/charts/PieChart";
import SimpleLineChart from "../components/charts/LineChart";
import StackOffsetDemo from "../components/charts/Stack";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

export const Dashboard = (props) => {
  const [surgeryInHospital, setSurgeryInHospital] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [topTenHospitalList, setTopTenHospitalList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [allProcedureData, setAllProcedureData] = useState([]);
  const [topTenProcedureData, setTopTenProcedureData] = useState([]);

  const [allHospitalList, setAllHospitalList] = useState([]);
  const [allProcedureList, setAllProcedureList] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProcedureByCount();
      getTopTenProcedureByCount();
      getSurgeryCountByHospitals();
      getSurgeryByYears();
      topTenHospitalsBySurgeries();
      totalProcedrues();
      totalHospitals();
    }
    return () => {
      mounted = false;
    };
  }, []);

  // const getUserList = (arg) => {
  //   let url = config.baseUrl + config.ver + config.userList;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (response && response.data) {
  //       setUserList(response.data.data);
  //     }
  //   });
  // };

  const getProcedureByCount = (arg) => {
    let url = config.baseUrl + config.ver + config.typeofprocedure;
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setAllProcedureData(response.data.procedures);
      }
    });
  };

  const getTopTenProcedureByCount = (arg) => {
    let url = config.baseUrl + config.ver + config.topTenProcedurebyMantra;
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setTopTenProcedureData(response.data.procedures);
      }
    });
  };

  const getSurgeryCountByHospitals = (arg) => {
    let url = config.baseUrl + config.ver + config.surgeryByHospital;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response && response.data) {
        setSurgeryInHospital(response.data.procedures);
      }
    });
  };

  const getSurgeryByYears = (arg) => {
    let url = config.baseUrl + config.ver + config.surgeriesYearWise;
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setYearList(response.data.procedures);
      }
    });
  };

  //top-ten-hospital-by-surgeries
  const topTenHospitalsBySurgeries = (arg) => {
    let url = config.baseUrl + config.ver + config.topTenHospitalInSurgeries;
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setTopTenHospitalList(response.data.procedures);
      }
    });
  };

  const totalProcedrues = (arg) => {
    let url = config.baseUrl + config.ver + config.procedureList + "/all";
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setAllProcedureList(response.data.data);
      }
    });
  };

  const totalHospitals = (arg) => {
    let url = config.baseUrl + config.ver + config.hospitalList + "/all";
    httpGetReq(url, "application/json", function (response) {
      if (response && response.data) {
        setAllHospitalList(response.data.data);
      }
    });
  };

  if (true) {
    return (
      <div className="dashboard-container">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {/* <iframe title="sutra" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=f351022d-ca00-46e8-8134-4dbeeaeddde1&autoAuth=true&ctid=407f49c9-c9e9-4550-acbf-9f27a3846709" frameborder="0" allowFullScreen="true"></iframe> */}
            <div className="dashboard-content">
              <div className="chart-row">
                <div className="chart-area-limited">
                  <div className="header-span">
                    <span>Installed Systems</span>
                  </div>
                  <div className="graphical-chart">
                    {allHospitalList.map((option) => (
                      <p key={option.system_no}>
                        <span className="first-span">{option.system_no}</span>
                        <span>:</span>
                        <span>{option.name}</span>
                      </p>
                    ))}
                  </div>
                </div>

                <div className="chart-area">
                  <span>Top ten procedure by Mantra</span>
                  <div className="graphical-chart">
                    <BarChart data={topTenProcedureData} />
                  </div>
                </div>

                <div className="chart-area-download-list">
                  <div className="header-span">
                    <span>All Surgery Data Count</span>
                    <div
                      className="download-icon"
                      onClick={() => { window.open( config.baseUrl + config.ver + config.typeofprocedureDownload ); }}
                    >
                      <DownloadForOfflineRoundedIcon />
                    </div>
                  </div>
                  {allProcedureData.length ? (
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allProcedureData.map((ndk) => (
                          <tr key={ndk._id}>
                            <td>{ndk._id}</td>
                            <td>{ndk.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>

              <div className="chart-row">
                <div className="chart-area-limited">
                  <div className="header-span">
                    <span>Surgeries Code List</span>
                  </div>
                  <div className="graphical-chart">
                    {allProcedureList.map((option) => (
                      <p key={option.procedure_code}>
                        <span className="first-span"> {option.procedure_code} </span>
                        <span>:</span>
                        <span>{option.procedure_name}</span>
                      </p>
                    ))}
                  </div>
                </div>

                {/* <div className="chart-area">
                  <span>Hospitals : Number of Surgery</span>
                  <SimplePieChart data={surgeryInHospital} />
                </div> */}

                <div className="chart-area">
                  <span>Top Ten Hospital Surgery Wise</span>
                  <div className="graphical-chart">
                    <BarChartNew data={topTenHospitalList} />
                  </div>
                </div>

                <div className="chart-area">
                  <span>Number of Surgery Year Wise</span>
                  <div className="graphical-chart">
                    <BarChartNew data={yearList} />
                  </div>
                </div>
              </div>

              {/* {false && (
              <div className="chart-row">
                <div className="chart-area">
                  <div>
                    {" "}
                    <span>Sample Line Chart</span>{" "}
                  </div>
                  <div>
                    {" "}
                    <SimpleLineChart />{" "}
                  </div>
                </div>
                <div className="chart-area">
                  <div>
                    {" "}
                    <span>Sample Stack Chart</span>{" "}
                  </div>
                  <div>
                    {" "}
                    <StackOffsetDemo />{" "}
                  </div>
                </div>
              </div>
            )} */}
            </div>
          </>
        )}
      </div>
    );
  } else {
    return <Lock />;
  }
};
