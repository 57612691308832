import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import AddSurgeon from "../../components/addcomponents/AddSurgeon";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Lock from "../../components/Lock";

import "../../css/SurgeonContainer.css";
import "../../css/CommonStyle.css";

export const SurgeonContainer = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [surgeonList, setSurgeonList] = useState([]);
  const [surgeon_name, setSurgeonName] = useState("");
  const [aligned_hospitals, setHospitalName] = useState("");
  const [area_of_expertise, setExpertiseName] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const [hospitalMap, setHospitalMap] = useState({});

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getSurgeonList();
      getHospitalList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const getSurgeonList = () => {
    let url = `${config.baseUrl}${config.ver}${config.surgeonList}?limit=${limitPerPage}&page=${currentPage}`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setSurgeonList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);

        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const getHospitalList = () => {
    let url = `${config.baseUrl}${config.ver}${config.hospitalList}?limit=100`;
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let map = {};
        response.data.data.forEach((hospital) => {
          map[hospital.system_no] = {
            name: hospital.name,
            area_zone: hospital.area_zone,
            system_no: hospital.system_no,
            location: hospital.location,
          };
        });
        setHospitalMap(map);
      }
    });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const delayedSearchSurgeon = debounce(searchSurgeon, 500);

  const handleSurgeonNameChange = (event) => {
    setSurgeonName(event.target.value);
    delayedSearchSurgeon(
      event.target.value,
      aligned_hospitals,
      area_of_expertise
    );
  };

  const handleHospitalNameChange = (event) => {
    setHospitalName(event.target.value);
    delayedSearchSurgeon(surgeon_name, event.target.value, area_of_expertise);
  };

  const handleExpertiseNameChange = (event) => {
    setExpertiseName(event.target.value);
    delayedSearchSurgeon(surgeon_name, aligned_hospitals, event.target.value);
  };

  function searchSurgeon(name, aligned_hospitals, area_of_expertise) {
    if (name === "" && aligned_hospitals === "" && area_of_expertise === "") {
      setSurgeonList([]);
      setSearchToggle(!searchToggle);
      return null;
    }

    let surgeon_search_url = `${config.baseUrl}${config.ver}${config.surgeonList}`;

    if (name !== "") {
      surgeon_search_url += `?surgeon_name=${name}`;
    }
    if (aligned_hospitals !== "") {
      surgeon_search_url += `${
        name !== "" ? "&" : "?"
      }aligned_hospitals=${aligned_hospitals}`;
    }
    if (area_of_expertise !== "") {
      surgeon_search_url += `${
        name !== "" || aligned_hospitals !== "" ? "&" : "?"
      }area_of_expertise=${area_of_expertise}`;
    }

    httpGetReq(surgeon_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setSurgeonList(response.data.data);
          setSearchToggle(!searchToggle);
        } else {
          setSurgeonList([]);
          setSearchToggle(!searchToggle);
        }
      }
    });
  }

  if (true) {
    return (
      <div className="main-data-container">
        <div>
          <AddSurgeon {...props} />
        </div>
        <div className="search-bar-container">
          <div className="search-bar-keep-right">
            <input
              className="search-bar-input-text"
              type="text"
              placeholder="Surgeon Name"
              size="small"
              value={surgeon_name}
              onChange={handleSurgeonNameChange}
            />
            <input
              className="search-bar-input-text"
              type="text"
              placeholder="Hospital Name"
              size="small"
              value={aligned_hospitals}
              onChange={handleHospitalNameChange}
            />
            <input
              className="search-bar-input-text"
              type="text"
              placeholder="Expertise"
              size="small"
              value={area_of_expertise}
              onChange={handleExpertiseNameChange}
            />
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                searchSurgeon(
                  surgeon_name,
                  aligned_hospitals,
                  area_of_expertise
                );
              }}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                getSurgeonList();
                setSurgeonName("");
                setHospitalName("");
                setExpertiseName("");
              }}
            >
              <RestartAltRoundedIcon />
            </button>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>
        <div className="property-list-container">
          <table className="property-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Hospital</th>
                <th>Area Of Expertise</th>
              </tr>
            </thead>
            <tbody className="property-table-body">
              {surgeonList.map((surgeon) => (
                <tr key={surgeon._id}>
                  <td className="r10start">{surgeon.surgeon_name}</td>
                  <td className="r20">{surgeon.aligned_hospitals}{ hospitalMap[surgeon.system_no] ? ' - ' + hospitalMap[surgeon.system_no].location : ''}</td>
                  <td className="r20end">
                    {surgeon.area_of_expertise
                      ? surgeon.area_of_expertise[0].toUpperCase() +
                        surgeon.area_of_expertise.slice(1)
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
