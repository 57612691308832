import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";

import AddUser from "../../components/addcomponents/AddUser";
import Pagination from "@mui/material/Pagination";

import UserContainerTable from "./UserContainerTable";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import "../../css/User.css";
import "../../css/CommonStyle.css";

import Lock from "../../components/Lock";

export const ClinicalSupportListing = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [userList, setUserList] = useState([]);
  const [clinicalSupporList, setClinicalSupporList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [show_edit_user, setShowEditUser] = useState(false);
  const [show_add_user, setShowAddUser] = useState(false);
  const [test_state, setTestState] = useState(false);
  const [test_state_2, setTestState2] = useState(false);
  const [test_state_3, setTestState3] = useState(false);

  const [searchToggle, setSearchToggle] = useState(false);
  const [username, setUsername] = useState("");
  const [usertype, setUserType] = useState("");
  const [emp_id, setEmpId] = useState("");
  const [emp_email, setEmpEmail] = useState("");
  const [user_id, setUserId] = useState("");
  const [closeEditForm, setCloseEditForm] = useState(false);

  const updateShowEditUser = (row) => {
    setShowEditUser(true);
    setTestState(!test_state);
    setTestState2(!test_state_2);
    setShowAddUser(false);
    setUsername(row.name);
    setUserType(row.user_type);
    setEmpId(row.emp_id);
    setEmpEmail(row.emp_email);
    setUserId(row._id);
  };

  const closeEditUser = () => {
    setShowEditUser(false);
    setTestState2(!test_state_2);
    setTestState3(!test_state_3);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUserList();
      // getUserList("clinical-support");
      // getUserList("service-engineer");
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, usertype]);

  const handleUserTypeChange = (event) => {
    const selectedUserType = event.target.value;
    setUserType(selectedUserType);
    if (selectedUserType !== "") {
      setCurrentPage(1);
    }
  };
  

  useEffect(() => {
    if (closeEditForm) {
      setShowEditUser(false);
      setCloseEditForm(false);
    }
  }, [closeEditForm]);

  const getUserList = () => {
    // let url = config.baseUrl + config.ver + config.userList; +"?user_type=" + userType;
    let url = `${config.baseUrl}${config.ver}${config.userList}?limit=${limitPerPage}&page=${currentPage}`;
    if (usertype !== "") {
      url += `&user_type=${usertype}`;
    }
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (response) {
        setUserList(response.data.data);
        setClinicalSupporList(response.data.data);
        // if (userType === "clinical-support") {
        //   console.log("----------------------------------");
        //   setClinicalSupporList(response.data.data);
        //   console.log(url);
        // } else if (userType === "service-engineer")
        //   setUserList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  if (props.UserInfo.user_type === "admin") {
    return (
      <div className="main-data-container">
        <div className="add-data-button-container">
          <AddUser
            closeEditUser_={closeEditUser}
            show_state={show_add_user}
            key={test_state}
          />
        </div>
        <div key="1" className="search-bar-container">
          <div className="search-bar-keep-right">
            <select
              className="search-bar-input-dropdown"
              labelId="area-zone-label"
              placeholder="User Type"
              value={usertype}
              onChange={handleUserTypeChange}
            >
              <option disabled value="">
                Select User Type
              </option>
              {ConVar.userTypes.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={getUserList}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setCurrentPage(1);
                setUserType("");
              }}
            >
              <RestartAltRoundedIcon />
            </button>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
                setCloseEditForm(true);
              }}
            />
          </div>
        </div>
        <div key="2">
          <UserContainerTable
            key={searchToggle}
            data={userList}
            rowsPerPage={15}
            updateEditUser={updateShowEditUser}
            username__={username}
            usertype__={usertype}
            emp_id__={emp_id}
            emp_email__={emp_email}
            setUsername_={setUsername}
            setUserType_={setUserType}
            setEmpId_={setEmpId}
            setEmpEmail_={setEmpEmail}
            user_id_={user_id}
            closeEditForm={closeEditForm}
          />
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
