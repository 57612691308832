import React, { useEffect, useState } from "react";
import { httpGetReq, httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import ReactSelect from "react-select";

import { Dialog } from "@mui/material";
import "../../css/CommonStyle.css";

const AddHospital = ({ open, onClose }) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [areaZone, setAreaZone] = useState("");
  const [installIndex, setInstallIndex] = useState(0);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [installDate, setInstallDate] = useState(null);
  const [systemNo, setSystemNo] = useState("");
  const [alignedClinicalTeam, setAlignedClinicalTeam] = useState([]);
  const [alignedFieldServiceTeam, setAlignedFieldServiceTeam] = useState([]);
  const [clinicalList, setClinicalList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    // getClinicalList();
    getUserList();
  }, []);

  // const getClinicalList = () => {
  //   const url = config.baseUrl + config.ver + config.userList + "/?user_type=clinical-support";
  //   httpGetReq(url, "application/json", (response) => {
  //     if (response.data && response.data.status === "success") {
  //       setClinicalList(response.data.data);
  //     }
  //   });
  // };

  const getUserList = () => {
    const url = config.baseUrl + config.ver + config.userList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setUserList(response.data.data);
      } else {
        console.error("Error fetching users list:", response.data.message);
      }
    });
  };

  const handleSubmit = async () => {
    const addHospitalUrl = config.baseUrl + config.ver + config.hostpitalNew;
    const currentTime = new Date().getTime();
    const formattedSystemNo = systemNo.startsWith("SYS_") ? systemNo : `SYS_${systemNo}`;
    const requestData = {
      area_zone: areaZone,
      install_index: parseInt(installIndex, 10), 
      name: name,
      system_no: systemNo,
      location: location,
      state: state,
      region: region,
      installed_date: new Date(installDate).getTime(),
      aligned_fse: alignedFieldServiceTeam.map((team) => team.value),
      aligned_clinical_team: alignedClinicalTeam.map((team) => team.value),
      last_updated: currentTime,
      mantra_system: {
        system_unique_no: formattedSystemNo,
        system_initial_install_date: new Date(installDate).getTime(),
        system_working_status: 0,
        last_routine_checked: 12345,
      },
    };
  
    httpPostReq(addHospitalUrl, requestData, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMessage("Hospital added successfully");
        setWarning("");
        setMessage("");
        setAreaZone("");
        setName("");
        setLocation("");
        setState("");
        setRegion("");
        setInstallIndex("");
        setInstallDate(null);
        setSystemNo("");
        setAlignedClinicalTeam([]);
        setAlignedFieldServiceTeam([]);
        setClinicalList([]);
        setUserList([]);
        onClose();
      } else if (response.data && response.data.status === "fail") {
        setWarning(response.data.message);
        setMessage("");
      }
    });
  };  

  return (
    <Dialog open={open} onClose={onClose} className="addproperty-dialog">
      {warning && <div className="error-message">{warning}</div>}
      {message && <div className="success-message">{message}</div>}
      <div className="addproperty-container">
        <div className="addproperty-form-title">Add Hospital</div>
        <div className="addproperty-form">
          <div className="addproperty-form-control">
            <label>Select Area Zone</label>
            <select
              label="Area Zone"
              value={areaZone}
              onChange={(e) => setAreaZone(e.target.value)}
              className="addproperty-select"
            >
              <option key={""} value={""}> Select Area Zone </option>
              {ConVar.hospitalZone.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="addproperty-form-control">
            <label>Hospital Name</label>
            <input
              type="text"
              placeholder="Hospital Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Installed Index</label>
            <input
            type="number"
              placeholder="Installed Index"
              value={installIndex}
              onChange={(e) => setInstallIndex(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Location</label>
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>State</label>
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>Region</label>
            <input
              type="text"
              placeholder="Region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>System Number</label>
            <input
              type="text"
              placeholder="SYS_000X"
              value={systemNo}
              onChange={(e) => setSystemNo(e.target.value.trim())}
              className="addproperty-text-field"
            />
          </div>
          <div className="addproperty-form-control">
            <label>System Installation Date</label>
            <input
              className="addproperty-date-picker"
              type="date"
              placeholder="System Installation Date"
              value={installDate}
              onChange={(e) => setInstallDate(e.target.value)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Assign Clinical Members</label>
            <ReactSelect
              className="addproperty-multiselect"
              value={alignedClinicalTeam}
              onChange={(selectedOptions) => setAlignedClinicalTeam(selectedOptions || [])}
              isMulti
              options={userList
                .filter(user => user.user_type === "clinical-support")
                .map((user) => ({
                  label: user.name,
                  value: user._id
                }))
              }
            />
          </div>
          <div className="addproperty-form-control">
            <label>Assign Field Service Members</label>
            <ReactSelect
              className="addproperty-multiselect"
              value={alignedFieldServiceTeam}
              onChange={(selectedOptions) => setAlignedFieldServiceTeam(selectedOptions || [])}
              isMulti
              options={userList
                .filter(user => user.user_type === "service-engineer")
                .map((user) => ({
                  label: user.name,
                  value: user._id
                }))
              }
            />
          </div>
        </div>
        <div className="addproperty-dialog-action">
          <button type="button" onClick={handleSubmit} className="addproperty-button" >
            Add Hospital
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddHospital;
