export const ServiceReportData = {
  customerInformation: {
    label: "Customer Information",
    type: "group",
    fields: [
      {
        key: "zone_id",
        label: "Zone Area",
        inputType: "select",
        required: true,
      },
      {
        key: "hospital_name",
        label: "Customer Name",
        inputType: "select",
        required: true,
      },
      {
        key: "address",
        label: "Address",
        inputType: "text",
        required: true,
      },
      {
        key: "state",
        label: "State",
        inputType: "text",
        required: true,
      },
      {
        key: "pin",
        label: "Pin",
        inputType: "number",
        required: true,
      },
      {
        key: "contactName",
        label: "Contact Name",
        inputType: "text",
        required: true,
      },
      {
        key: "mobile",
        label: "Mobile",
        inputType: "text",
        required: true,
      },
      {
        key: "email",
        label: "E-mail",
        inputType: "email",
        required: true,
      },
    ],
  },
  visitDetails: {
    label: "Visit Details",
    type: "group",
    fields: [
      {
        key: "visitType",
        label: "Visit Type",
        inputType: "radio",
        options: ["Installation", "Breakdown", "Preventive Maintenance"],
        required: true,
      },
    ],
  },
  equipmentStatus: {
    label: "Equipment Status",
    type: "group",
    fields: [
      {
        key: "equipmentStatus",
        label: "Equipment Status",
        inputType: "radio",
        options: ["Warranty", "New Sale", "Contract", "Chargeable"],
        required: true,
      },
    ],
  },
  callReport: {
    label: "Call Report",
    type: "group",
    fields: [
      {
        key: "issue_report",
        label: "Issue Report",
        inputType: "textarea",
        required: false,
      },
      {
        key: "callReportedBy",
        label: "Call Reported By",
        inputType: "text",
        required: true,
      },
      {
        key: "systemUsable",
        label: "System Usable",
        inputType: "radio",
        options: ["YES", "NO"],
        required: true,
      },
      {
        key: "nextSurgeryScheduleDate",
        label: "Next Surgery Schedule Date",
        inputType: "datetime-local",
        required: false,
      },
      {
        key: "locationOfSystemOTNumber",
        label: "Location of System (OT Number)",
        inputType: "text",
        required: false,
      },
    ],
  },
  description: {
    label: "Description",
    type: "group",
    fields: [
      {
        key: "description",
        label: "Description",
        inputType: "text",
        required: false,
      },
      {
        key: "physicalDamage",
        label: "Physical Damage",
        inputType: "radio",
        options: ["YES", "NO"],
        required: false,
      },
    ],
  },
  modelDetails: {
    label: "Model Details",
    type: "group",
    fields: [
      {
        key: "modelNo",
        label: "Model No",
        inputType: "text",
        required: false,
      },
      {
        key: "majorFinding",
        label: "Major Finding (Functional/Working)",
        inputType: "text",
        required: false,
      },
      {
        key: "srNo",
        label: "Serial No",
        inputType: "text",
        required: false,
      },
      {
        key: "modalremarks",
        label: "Remarks",
        inputType: "textarea",
        required: false,
      },
    ],
  },
  servicesRendered: {
    label: "Services Rendered",
    type: "group",
    fields: [
      {
        key: "servicesRendered",
        label: "Services Rendered",
        inputType: "textarea",
        required: false,
      },
    ],
  },
  engineerRemarks: {
    label: "Engineer Remarks",
    type: "group",
    fields: [
      {
        key: "engineerRemarks",
        label: "Engineer Remarks",
        inputType: "textarea",
        required: false,
      },
    ],
  },
  partsReplacement: {
    label: "Parts Replacement",
    type: "group",
    fields: [
      {
        key: "partsReplaced",
        label: "Parts Replaced",
        inputType: "radio",
        options: ["YES", "NO"],
        required: true,
      },
      {
        key: "partsReplacedDetails",
        label: "Parts Replaced Details",
        inputType: "textarea",
        required: false,
      },
      {
        key: "partsRequired",
        label: "Parts Required",
        inputType: "radio",
        options: ["YES", "NO"],
        required: true,
      },
      {
        key: "partsRequiredDetails",
        label: "Parts Required Details",
        inputType: "textarea",
        required: false,
      },
    ],
  },
  electricalSafetyChecksInspections: {
    label: "Electrical/Safety Checks and Other Inspections",
    type: "group",
    fields: [
      {
        label: "Site Voltage",
        type: "subgroup",
        fields: [
          {
            key: "ln",
            label: "L-N",
            inputType: "number",
            required: false,
          },
          {
            key: "ng",
            label: "N-G",
            inputType: "number",
            required: false,
          },
          {
            key: "lg",
            label: "L-G",
            inputType: "number",
            required: false,
          },
        ],
      },
      {
        label: "Electrical Safety Test (EST)",
        type: "subgroup",
        fields: [
          {
            key: "testResult",
            label: "Test Result",
            inputType: "radio",
            options: ["PASS", "FAIL", "NA"],
            required: true,
          },
        ],
      },
      {
        label: "Report Attached",
        type: "subgroup",
        fields: [
          {
            key: "reportAttached",
            label: "Report Attached",
            inputType: "radio",
            options: ["YES", "NO"],
            required: true,
          },
        ],
      },
      {
        label: "Power Supply Through",
        type: "subgroup",
        fields: [
          {
            key: "powerSupplyThrough",
            label: "Power Supply Through",
            inputType: "radio",
            options: ["UPS", "Raw PS", "Generator", "Iso-Transformer"],
            required: true,
          },
        ],
      },
      {
        label: "Other Findings",
        type: "subgroup",
        fields: [
          {
            key: "temperature",
            label: "Temperature (°C)",
            inputType: "number",
            required: false,
          },
          {
            key: "humidity",
            label: "Humidity",
            inputType: "number",
            required: false,
          },
        ],
      },
    ],
  },
  statusAfterServiceVisit: {
    label: "Status After Service Visit",
    type: "group",
    fields: [
      {
        key: "statusAfterServiceVisit",
        label: "Status After Service Visit",
        inputType: "radio",
        options: ["Completed", "Spare Required", "Under Observation"],
        required: true,
      },
    ],
  },
  customerFeedback: {
    label: "Customer Feedback",
    type: "group",
    fields: [
      {
        key: "customerfeedback",
        label: "Feedback",
        inputType: "radio",
        options: ["Unsatisfactory", "Needs Improvement", "Satisfactory", "Excellent"],
        required: true,
      },
      {
        key: "customerremarks",
        label: "Remarks",
        inputType: "textarea",
        required: false,
      },
    ],
  },
  engineerAndCustomerSign: {
    label: "Engineer's and Customer's Signature",
    type: "group",
    fields: [
      {
        key: "engineerSignature",
        label: "Engineer's Signature",
        inputType: "text",
        required: true,
      },
      {
        key: "customerSignature",
        label: "Customer's Signature",
        inputType: "text",
        required: true,
      },
    ],
  },
};
