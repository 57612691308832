import React from 'react';
import lockImage from '../images/lock.png';

function Lock() {
  return (
    <div className='lock-container'>
        <img className='lockImage' src={lockImage} alt="Lock.." />
      </div>
  );
}

export default Lock;