import defooooo from "../images/66.gif";
import monopolar_curved_scissors from "../images/mudra-new-icons/Y-MONOPOLAR-CURVED-SCISSORS.png";
import monopolar_cautery_hook from "../images/mudra-new-icons/Y-CAUTERY-HOOK.png";
import monopolar_cautery_spatula from "../images/mudra-new-icons/Y-CAUTERY-SPATULA.png";
import maryland_bipolar_forceps from "../images/mudra-new-icons/Y-MARYLAND-BIPOLAR-FORCEPS.png";
import fenestrated_bipolar_forceps from "../images/mudra-new-icons/Y-FENESTRATED-BIPOLAR-FORCEPS.png";
import small_titanium_clip_applier from "../images/mudra-new-icons/Y-SMALL-CLIP-APPLIER.png";
import medium_titanium_clip_applier from "../images/mudra-new-icons/Y-SMALL-CLIP-APPLIER.png";
import naslock_large_clip_applier from "../images/mudra-new-icons/Y-LARGE-CLIP-APPLIER.png";
import naslock_medium_clip_applier from "../images/mudra-new-icons/Y-MEDIUM-CLIP-APPLIER.png";
import large_needle_driver from "../images/mudra-new-icons/Y-LARGE-NEEDLE-DRIVER.png";
import super_needle_driver from "../images/mudra-new-icons/Y-LARGE-NEEDLE-DRIVER.png";
import sevana_cut_needle_driver from "../images/mudra-new-icons/Y-SEVANA.png";
import grasping_forceps from "../images/mudra-new-icons/Y-GRASPING-FORCEPS.png";
import tenaculum_forceps from "../images/mudra-new-icons/Y-TENACULUM-FORCEPS.png";
import long_tip_forceps from "../images/mudra-new-icons/Y-LONG-TIP-FORCEPS.png";
import grasping_retractor from "../images/mudra-new-icons/Y-GRASPING-RETRACTOR.png";
import fine_tip_scissors from "../images/mudra-new-icons/Y-POTTS-SCISSORS.png";
import debakey_forceps from "../images/mudra-new-icons/Y-DEBAKEY-FORCEPS.png";
import black_diamond_forceps from "../images/mudra-new-icons/Y-BLACK-DIAMOND.png";
import round_tip_scissors from "../images/mudra-new-icons/Y-ROUND-TIP-SCISSORS.png";
import nadi_anastomotic_connector from "../images/mudra-new-icons/Y-NADI.png";
import resano_forceps from "../images/mudra-new-icons/Y-RESANO-FORCEPS.png";
import cadiere_forceps from "../images/mudra-new-icons/Y-CADIERE-FORCEPS.png";
import valve_retractor_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import valve_retractor_long from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import small_bipolar_forceps from "../images/mudra-new-icons/Y-CARDIAC-BIPOLAR-FORCEPS.png";
import cardiac_stabilizer from "../images/mudra-new-icons/Y-CARDIAC-STABILIZER.png";
import selicut from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";

import cardiac_bipolar_forceps from "../images/mudra-new-icons/Y-CARDIAC-BIPOLAR-FORCEPS.png";
import micro_bipolar_forceps from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import paddle_blade from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import small_grasping_retractor from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import harmonic_scalpel from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import atrial_retractor_short_right from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import atrial_retractor_dual_blade from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import knife_30_deg from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import multifire_clip_applier from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import cobra_forceps from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import cardiac_probe_grasper from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import tip_up_fenestrated_retractor from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import curved_bipolar_dissector from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import long_bipolar_grasper from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import cautery_hook_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import cautery_spatula_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import fenestrated_bipolar_forceps_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import monopolar_curved_scissor_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import large_needle_driver_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import black_diamond_micro_forceps_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import atrial_retractor_short_right_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import cardiac_bipolar_forceps_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";
import grasping_forceps_short from "../images/mudra-new-icons/Y-ATRIAL-RETRACTOR.png";

const getImageUrl = (key) => {
  switch (key) {
    case "valve_retractor_short":
      return valve_retractor_short;
    case "valve_retractor_long":
      return valve_retractor_long;
    case "black_diamond_forceps":
      return black_diamond_forceps;
    case "cadiere_forceps":
      return cadiere_forceps;
    case "small_bipolar_forceps":
      return small_bipolar_forceps;
    case "cardiac_stabilizer":
      return cardiac_stabilizer;
    case "monopolar_cautery_hook":
      return monopolar_cautery_hook;
    case "monopolar_cautery_spatula":
      return monopolar_cautery_spatula;
    case "debakey_forceps":
      return debakey_forceps;
    case "fenestrated_bipolar_forceps":
      return fenestrated_bipolar_forceps;
    case "grasping_forceps":
      return grasping_forceps;
    case "grasping_retractor":
      return grasping_retractor;
    case "naslock_large_clip_applier":
      return naslock_large_clip_applier;
    case "naslock_medium_clip_applier":
      return naslock_medium_clip_applier;
    case "large_needle_driver":
      return large_needle_driver;
    case "super_needle_driver":
      return super_needle_driver;
    case "long_tip_forceps":
      return long_tip_forceps;
    case "maryland_bipolar_forceps":
      return maryland_bipolar_forceps;
    case "medium_titanium_clip_applier":
      return medium_titanium_clip_applier;
    case "monopolar_curved_scissors":
      return monopolar_curved_scissors;
    case "nadi_anastomotic_connector":
      return nadi_anastomotic_connector;
    case "fine_tip_scissors":
      return fine_tip_scissors;
    case "resano_forceps":
      return resano_forceps;
    case "round_tip_scissors":
      return round_tip_scissors;
    case "sevana_cut_needle_driver":
      return sevana_cut_needle_driver;
    case "small_titanium_clip_applier":
      return small_titanium_clip_applier;
    case "tenaculum_forceps":
      return tenaculum_forceps;
    default:
      return defooooo;
  }
};

export default getImageUrl;
