import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq } from "../../util/request";
import "../../css/MudraDashboard.css";
import Lock from "../../components/Lock";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";

export const MudraAssemblyHall = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalInstrumentManufactured, setTotalInstrumentManufactured] =
    useState();
  const [totalWareHouse, setTotalWareHouse] = useState();
  const [totalInHospital, setTotalInHospital] = useState();
  const [totalService, setTotalService] = useState();
  const [totalDiscard, setTotalDiscard] = useState();
  const [totalMinor, setTotalMinor] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // getTotalNubmerofInstrumentManufactured();
      // getTotalNubmerofInstrumentWarehouse();
      // getTotalNubmerofInstrumentInHospital();
      // // getTotalNubmerofInstrumentInMinor();
      // getTotalNubmerofInstrumentInService();
      // getTotalNubmerofInstrumentInScrap();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  // const getTotalNubmerofInstrumentManufactured = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalInstrument}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalInstrumentManufactured(response.data.total);
  //     }
  //   });
  // };

  // const getTotalNubmerofInstrumentWarehouse = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalWarehouse}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalWareHouse(response.data.total);
  //     }
  //   });
  // };

  // const getTotalNubmerofInstrumentInMinor = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalMinor}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalMinor(response.data.total);
  //     }
  //   });
  // };

  // const getTotalNubmerofInstrumentInService = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalInService}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalService(response.data.total);
  //     }
  //   });
  // };

  // const getTotalNubmerofInstrumentInHospital = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalInHospital}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalInHospital(response.data.total);
  //     }
  //   });
  // };

  // const getTotalNubmerofInstrumentInScrap = () => {
  //   let url = `${config.baseUrl}${config.ver}${config.totalInDiscard}`;
  //   setLoading(true);
  //   httpGetReq(url, "application/json", function (response) {
  //     setLoading(false);
  //     if (
  //       response.data &&
  //       response.data.status &&
  //       response.data.status === "success"
  //     ) {
  //       setTotalDiscard(response.data.total);
  //     }
  //   });
  // };

  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return function (...args) {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //     timeoutId = setTimeout(() => {
  //       func.apply(null, args);
  //     }, delay);
  //   };
  // };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
        <h2>Under Development</h2>
        <div className="mudra-dashbaord-container2">
          <h3>Roll Assembly</h3>
          <div className="dashboarddata2">
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
              <MenuItem component={Link} to="/mudra/r1">
                <h3>R1</h3>
              </MenuItem>{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>In Bucket:</label> {"XX"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Processing:</label> {"XX"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Completed:</label> {"XX"}{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>R2</h3>
            <div className="dashboarddata2-top"> {totalWareHouse} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>R3</h3>
            <div className="dashboarddata2-top"> {totalService} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
        </div>
        <div className="mudra-dashbaord-container2">
          <h3>IPQC </h3>
          <div className="dashboarddata2">
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
              <MenuItem component={Link} to="/mudra/ipqc1">
                <h3>IPQC 1</h3>
              </MenuItem>{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>In Bucket:</label> {"XX"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Processing:</label> {"XX"}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Completed:</label> {"XX"}{" "}
            </div>
          </div>
       
         
        </div>
        <div className="mudra-dashbaord-container2">
          <h3>Distal End</h3>
          <div className="dashboarddata2">
            <h3>D1</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>

            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>D2</h3>
            <div className="dashboarddata2-top"> {totalWareHouse} </div>

            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            {" "}
            <h3>D3</h3>
            <div className="dashboarddata2-top"> {totalService} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
        </div>
        <div className="mudra-dashbaord-container2">
          <h3>Stall </h3>
          <div className="dashboarddata2">
            <h3>Distal End</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>Proximal End</h3>
            <div className="dashboarddata2-top"> {totalWareHouse} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            {" "}
            <h3>For IPQC</h3>
            <div className="dashboarddata2-top"> {totalService} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
        </div>
        <div className="mudra-dashbaord-container2">
          <h3>IPQC Station</h3>
          <div className="dashboarddata2">
            <h3>S1</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>

            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>Pre Tension Station 1</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>

            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>

          <div className="dashboarddata2">
            <h3>Function Test Station 1</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>

            <div className="dashboarddata2-bottom">
              {" "}
              <label>Occupied By:</label> {"abc@test.com" + "John Doe"}{" "}
            </div>
          </div>
        </div>
        <div className="mudra-dashbaord-container2">
          <h3>Stall2 </h3>
          <div className="dashboarddata2">
            <h3>For Function Test</h3>
            <div className="dashboarddata2-top">
              {" "}
              {totalInstrumentManufactured}{" "}
            </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            <h3>For Pre Tension Test</h3>
            <div className="dashboarddata2-top"> {totalWareHouse} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            {" "}
            <h3>For Final QC</h3>
            <div className="dashboarddata2-top"> {totalService} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
          <div className="dashboarddata2">
            {" "}
            <h3>IPQC Done</h3>
            <div className="dashboarddata2-top"> {totalService} </div>
            <div className="dashboarddata2-bottom">
              {" "}
              <label>Count:</label> 123{" "}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
