import React from 'react';
import samplePdf from '../pdf/CP011_Cholecystectomy - SSi Procedure Card v3.pdf'; // Import the PDF file

const PdfViewer = () => {
  return (
    <div className="pdf-viewer">
      <embed src={samplePdf} type="application/pdf" width="100%" height="600px"/>
    </div>
  );
};

export default PdfViewer;

