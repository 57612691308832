import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import { convertTimestampToDate } from "../../util/util";
import AddInstrument from "../../components/mudracomponents/AddInstruments";
import SendHospital from "../../components/mudracomponents/SendHospital";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import "../../css/User.css";
import "../../css/CommonStyle.css";
import Lock from "../../components/Lock";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';

export const WarehouseListing = (props) => {
  const limitPerPage = 15;
  const [showAddUser, setShowAddUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [instrumentList, setInstrumentList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [selectedInstrumentId, setSelectedInstrumentId] = useState(null);
  const [isReset, setIsReset] = useState(null);
  const [instrumentCategory, setInstrumentCategory] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [productionYear, setProductionYear] = useState(null);
  const [productionMonth, setProductionMonth] = useState(null);
  const [verifiedBy, setVerifiedBy] = useState(null);
  const [instrumentId, setInstrumentId] = useState(null);
  const [mudraList, setMudraList] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMudraList();
      getWarehouseList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, isReset]);

  const toggleModal = (instrumentId) => {
    setSelectedInstrumentId(instrumentId);
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      const instrumentID = encodeURIComponent(instrumentId);
      navigate(`${location.pathname}?sendtohospital=${instrumentID}`);
    } else {
      navigate(location.pathname);
    }
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(location.pathname);
  };  

  const getMudraList = () => {
    const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = { ...prevExpandedRows };
      if (newExpandedRows[rowId]) {
        delete newExpandedRows[rowId];
        navigate(location.pathname);
      } else {
        newExpandedRows[rowId] = true;
        const instrumentID = encodeURIComponent(rowId);
        navigate(`${location.pathname}?detail=${instrumentID}`);
      }
      return newExpandedRows;
    });
  };

  const handleCategoryChange = (selectedCategory) => {
    const filteredNames = mudraList.filter((mudra) => mudra.category === selectedCategory).map((mudra) => mudra.label);
    setNameList(filteredNames);
  };

  const getWarehouseList = () => {
    let url = `${config.baseUrl}${config.ver}${config.warehouse}?limit=${limitPerPage}&page=${currentPage}`;
    if (instrumentCategory != null && instrumentCategory != "") {
      url += '&category=' + instrumentCategory;
    }
    if (selectedName != null && selectedName != "") {
      url += '&label=' + selectedName;
    }
    if (productionYear != null && productionYear != "") {
      url += '&production_year=' + productionYear;
    }
    if (productionMonth != null && productionMonth != "") {
      url += '&production_month=' + productionMonth;
    }
    if (verifiedBy != null && verifiedBy != "") {
      url += '&tested_by=' + verifiedBy;
    }
    if (instrumentId != null && instrumentId != "") {
      url += '&instrument_unique_no=' + instrumentId.trim();
    }
    console.log(url);
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setInstrumentList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  if (props.UserInfo.user_type === "mudra-admin" || props.UserInfo.user_type === "admin") {
    return (
      <div className="main-data-container">
        <h4>Page is under Development and Testing...</h4>
        <div>
          <AddInstrument show_state={showAddUser} />
        </div>
        <div className="search-bar-container">
          <div className="search-bar-keep-right">
            <select
              className="search-bar-input-dropdown" value={instrumentCategory}
              onChange={(e) => {
                setInstrumentCategory(e.target.value);
                handleCategoryChange(e.target.value);
                setSelectedName("");
              }}
            >
              <option key={""} value={""}>Select Instrument Category</option>
              {ConVar.instrumentCategory.map((inst) => (
                <option value={inst.value}>{inst.label}</option>
              ))}
            </select>
            <select
              style={{ marginRight: 10, width: 180 }}
              className="addproperty-select"
              value={selectedName}
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
            >
              <option key="" value="">Select Name</option>
              {nameList && nameList.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
            <select
              className="search-bar-input-dropdown" value={productionYear}
              onChange={(e) => { setProductionYear(e.target.value) }}
            >
              <option key={""} value={""}>Select Production Year</option>
              {ConVar.years.map((years) => (
                <option value={years.value}>{years.label}</option>
              ))}
            </select>
            <select
              className="search-bar-input-dropdown"
              value={productionMonth}
              onChange={(e) => { setProductionMonth(e.target.value) }}
            >
              <option key={""} value={""}>Select Month</option>
              {ConVar.monthsname.map((month) => (
                <option value={month.value}>{month.label}</option>
              ))}
            </select>
            <select
              className="search-bar-input-dropdown" value={verifiedBy}
              onChange={(e) => { setVerifiedBy(e.target.value) }}
            >
              <option key={""} value={""}>Select Verified By</option>
              <option key="cate-0" value="debasmita.parida@ssinnovations.org">Debasmita</option>
              <option key="cate-1" value="kunal.indra@ssinnovations.org">Kunal</option>
            </select>
            <input className="search-bar-input-text" placeholder="Type Instrument ID " value={instrumentId} onChange={(e) => { setInstrumentId(e.target.value) }} type="text" />
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                getWarehouseList();
              }}
              startIcon={<SearchIcon />}
            >
              <SearchIcon />
            </button>
            <button
              className="search-bar-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setInstrumentCategory("");
                setProductionYear("");
                setProductionMonth("");
                setVerifiedBy("");
                setInstrumentId("");
                setSelectedName("");
                setIsReset(!isReset);
              }}
            >
              <RestartAltRoundedIcon />
            </button>
          </div>
          <div className="pagination-setup">
            Page
            <Pagination
              className="pagination-setup-button"
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
            />
          </div>
        </div>
        <div className="property-list-container">
          <table className="property-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Production On</th>
                <th>Instrument Id</th>
                <th>Verified By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="property-table-body">
              {instrumentList.map((row, index) => (
                <React.Fragment key={row._id}>
                  <tr>
                    <td className="r20start">{row.label}</td>
                    <td className="r10">{convertTimestampToDate(row.production_date)}</td>
                    <td className="r10">{row.instrument_unique_no}</td>
                    <td className="r30">{row.tested_by}</td>
                    <td className="r20end">
                      <button onClick={() => toggleRow(row._id)}>
                        {expandedRows[row._id] ? (
                          <ExpandLessIcon className="chevron-right-icon" />
                        ) : (
                          <ExpandMore className="expand-more-icon" />
                        )}
                      </button>
                      <button className="red-button" title="Send To Hospital" onClick={() => toggleModal(row._id)}><LocalShippingRoundedIcon /></button>
                    </td>
                  </tr>
                  {expandedRows[row._id] && (
                    <tr>
                      <td colSpan="6" className="inner-property-expand">
                        <div className="inner-details-expand-styling">
                          <p><strong>Production On :</strong>{convertTimestampToDate(row.production_date)}</p>
                          <p><strong>Verified By :</strong>{row.tested_by}</p>
                          <p>Other Details...</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {isModalOpen && (
                    <SendHospital
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      longId={selectedInstrumentId}
                    />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <Lock />;
  }
};
