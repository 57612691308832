export const ConVar = {
  custname: "Sutra DB",
  custid: "SSI",

  defaultMsg: {
    tablemsg: "No data found",
  },

  hospitalZoneAssoArr: {
    "IN-01": "Northern Zone	",
    "IN-02": "Western Zone	",
    "IN-03": "Central Zone	",
    "IN-04": "Eastern Zone	",
    "IN-05": "Southern Zone	",
    "IN-06": "North-Eastern Zone",
  },

  surgeryYear: ["2022", "2023", "2024"],

  hospitalZone: [
    { label: "Northern Zone", value: "IN-01" },
    { label: "Western Zone", value: "IN-02" },
    { label: "Central Zone", value: "IN-03" },
    { label: "Eastern Zone", value: "IN-04" },
    { label: "Southern Zone", value: "IN-05" },
    { label: "North-Eastern Zone", value: "IN-06" },
  ],

  procedureCategory: [
    { label: "Urology", value: "urology" },
    { label: "General surgery", value: "general_surgery" },
    { label: "Gynecology", value: "gynecology" },
    { label: "Head and Neck", value: "head_and_neck" },
    { label: "Cardiac", value: "cardiac" },
    { label: "Gastrointestinal Surgery", value: "gastrointestinal_surgery" },
    { label: "Thoracic", value: "thoracic" },
    { label: "Other", value: "other" },
  ],
  deskType: [
    { label: "Testing", value: "testing" },
    { label: "Assembly", value: "assembly" },   
  ],
  deskTypeAssArr: {
    testing: "Testing",
    assembly: "Assembly",   
  },
  procedureCategoryAssArr: {
    urology: "Urology",
    general_surgery: "General surgery",
    gynecology: "Gynecology",
    head_and_neck: "Head and Neck",
    cardiac: "Cardiac",
    gastrointestinal_surgery: "Gastrointestinal Surgery",
    thoracic: "Thoracic",
    other: "Other",
  },

  userTypesAssoArr: {
    admin: "Administrator",
    "service-engineer": "Service Engineer",
    "mudra-admin": "Mudra Admin",
    "clinical-support": "Clinical Support",
  },

  userTypeVal: {
    admin: "Administrator",
    "mudra-admin": "Mudra Admin",
    // "service-engineer": "Service Engineer",
    //  "service-coordinator": "Service Coordinator",
    "clinical-support": "Clinical Support",
    surgeon: "Surgeon",
    other: "Other",
  },
  
  instrumentIssues: [
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
    { value: "4", label: "Four" },   
    { value: "5", label: "Other" },
  ],

  instrumentCategoryList: [
    {
      value: "monopolar_cautery_instruments",
      label: "MONOPOLAR CAUTERY INSTRUMENTS",
    },
    {
      value: "bipolar_cautery_instruments",
      label: "BIPOLAR CAUTERY INSTRUMENTS",
    },
    { value: "clip_appliers", label: "CLIP APPLIERS" },
    { value: "needle_drivers", label: "NEEDLE DRIVERS" },
    { value: "graspers", label: "GRASPERS" },
    { value: "speciality_instruments", label: "SPECIALITY INSTRUMENTS" },
    { value: "scissiors", label: "SCISSORS" },
  ],
  instrumentCategoryListAssoArr: {
     "monopolar_cautery_instruments": "MONOPOLAR CAUTERY INSTRUMENTS",    
     "bipolar_cautery_instruments": "BIPOLAR CAUTERY INSTRUMENTS",
     "clip_applie": "CLIP APPLIERS",
     "needle_drive": "NEEDLE DRIVERS",
     "graspe": "GRASPERS" ,
     "speciality_instrumen": "SPECIALITY INSTRUMENTS" ,
     "scissio": "SCISSORS" 
  },

  instrumentCategoryWise: {
    bipolar_cautery_instruments: [
      { value: "maryland_bipolar_forceps", label: "MARYLAND BIPOLAR FORCEPS" },
      { value: "fenestrated_bipolar_forceps",label: "FENESTRATED BIPOLAR FORCEPS"},
    ],
    monopolar_cautery_instruments: [
      { value: "monopolar_curved_scissors",label: "MONOPOLAR CURVED SCISSORS"},
      { value: "cautery_hook", label: "CAUTERY HOOK" },
      { value: "cautery_spatula", label: "CAUTERY SPATULA" },
    ],
    clip_appliers: [
      { value: "small_clip_applier", label: "SMALL CLIP APPLIER" },
      { value: "medium_hem-o-lokim_clip_applier", label: "MEDIUM HEM-O-LOKIM CLIP APPLIER" },
      { value: "large_hem-o-loktm_clip_applier",  label: "LARGE HEM-O-LOKTM CLIP APPLIER" },
    ],
    needle_drivers: [
      { value: "large_needle_driver", label: "LARGE NEEDLE DRIVER" },
      { value: "mega_needle_driver", label: "MEGA NEEDLE DRIVER" },
    ],
    graspers: [{ value: "demo", label: "Demo" }],
    scissiors: [{ value: "demo", label: "demo" }],
  },
  instrumentAssoArr: {
    "large_needle_driver": "LARGE NEEDLE DRIVER",
    "mega_needle_driver": "MEGA NEEDLE DRIVER",
    "small_clip_applier": "SMALL CLIP APPLIER",
    "medium_hem-o-lokim_clip_applier": "MEDIUM HEM-O-LOKIM CLIP APPLIER",
    "large_hem-o-loktm_clip_applier": "LARGE HEM-O-LOKTM CLIP APPLIER",
    "monopolar_curved_scissors": "MONOPOLAR CURVED SCISSORS",
    "cautery_hook": "CAUTERY HOOK",
    "cautery_spatula": "CAUTERY SPATULA",
    "maryland_bipolar_forceps": "MARYLAND BIPOLAR FORCEPS",
    "fenestrated_bipolar_forceps": "FENESTRATED BIPOLAR FORCEPS",
  },

  userTypes: [
    { value: "admin", label: "Administrator" },
    { value: "service-engineer", label: "Service Engineer" },
    { value: "mudra-admin", label: "Mudra Admin" },
    // { value: "service-coordinator", label: "Service Coordinator" },
    { value: "clinical-support", label: "Clinical Support" },
    // { value: "surgeon", label: "Surgeon" },
    { value: "other", label: "Other" },
  ],

  taskTypes: [
    { value: "maintenance-checkup", label: "Maintenance Checkup" },
    { value: "diagnos", label: "Diagnos" },
    { value: "software-update", label: "Software Update" },
    { value: "system-upgrade", label: "System Upgrade" },
    { value: "system-service", label: "System Service" },
    { value: "component-replacement", label: "Component Replacement" },
    { value: "other", label: "Other" },
  ],
  instrumentIssueList: 
  [
    { "value": "Checking Purpose", "label": "Checking Purpose" },
    { "value": "Cutting Issue", "label": "Cutting Issue" },
    { "value": "RFID Not Detecting", "label": "RFID Not Detecting" },
    { "value": "Jaw Open", "label": "Jaw Open" },
    { "value": "Jaw Not Opening", "label": "Jaw Not Opening" },
    { "value": "Current Leakage", "label": "Current Leakage" },
    { "value": "Excess Jaw Opening", "label": "Excess Jaw Opening" },
    { "value": "Jaw Pin Broken", "label": "Jaw Pin Broken" },
    { "value": "Gripping Issue", "label": "Gripping Issue" },
    { "value": "Long Tube Broken(From Insulation tube)", "label": "Long Tube Broken(From Insulation tube)" },
    { "value": "Jaws Not Closing", "label": "Jaws Not Closing" },
    { "value": "Huckey Puck Cable Came Out From Groove", "label": "Huckey Puck Cable Came Out From Groove" },
    { "value": "End Crimp Cable Came out", "label": "End Crimp Cable Came out" },
    { "value": "Center Crimp Cable Came Out from Groove", "label": "Center Crimp Cable Came Out from Groove" },
    { "value": "Jaw Misalligned", "label": "Jaw Misalligned" },
    { "value": "Cautery Cable Broken", "label": "Cautery Cable Broken" },
    { "value": "Pivot Pin Came out", "label": "Pivot Pin Came out" },
    { "value": "Cable Broken(Backend)", "label": "Cable Broken(Backend)" },
    { "value": "Disc Skitting", "label": "Disc Skitting" },
    { "value": "Jaw Broke", "label": "Jaw Broke" },
    { "value": "Long tube Broken(Distal End)", "label": "Long tube Broken(Distal End)" },
    { "value": "Pitch Broken", "label": "Pitch Broken" },
    { "value": "Tool Housing Broken", "label": "Tool Housing Broken" },
    { "value": "Cable fray(Distal End)", "label": "Cable fray(Distal End)" },
    { "value": "Cable Broken(Distal End)", "label": "Cable Broken(Distal End)" },
    { "value": "Old Version Jaw", "label": "Old Version Jaw" },
    { "value": "Old Version Pitch", "label": "Old Version Pitch" },
    { "value": "Old Version Clevis", "label": "Old Version Clevis" },
    { "value": "Old Version(Jaw,Pitch,Clevis)", "label": "Old Version(Jaw,Pitch,Clevis)" },
    { "value": "Tip Dusting Not Fine", "label": "Tip Dusting Not Fine" },
    { "value": "Disc Tight/Not Rotating", "label": "Disc Tight/Not Rotating" },
    { "value": "Tool Bracket Broken", "label": "Tool Bracket Broken" },
    { "value": "Old Instruments Parts(Backend)", "label": "Old Instruments Parts(Backend)" },
    { "value": "Ball Bearing Damaged", "label": "Ball Bearing Damaged" },
    { "value": "Insulation Tube Bent", "label": "Insulation Tube Bent" },
    { "value": "Honning line Damaged", "label": "Honning line Damaged" },
    { "value": "Overmold Shaft Damaged", "label": "Overmold Shaft Damaged" },
    { "value": "Cautery Malfuction", "label": "Cautery Malfuction" },
    { "value": "Insulation Tube Broken", "label": "Insulation Tube Broken" },
    { "value": "Cable Loose", "label": "Cable Loose" },
    { "value": "Tip Blunt", "label": "Tip Blunt" },
    { "value": "Pitch Not Mapping", "label": "Pitch Not Mapping" },
    { "value": "Cautery Cable Damaged", "label": "Cautery Cable Damaged" },
    { "value": "Jaw Bent", "label": "Jaw Bent" },
    { "value": "Pitch Damaged", "label": "Pitch Damaged" },
    { "value": "Jaw Tip Blunt", "label": "Jaw Tip Blunt" },
    { "value": "Cable Loose", "label": "Cable Loose" },
    { "value": "Roll Cable Loose", "label": "Roll Cable Loose" },
    { "value": "Roll Cable Buldged", "label": "Roll Cable Buldged" },
    { "value": "Bipolar Pin Bent", "label": "Bipolar Pin Bent" },
    { value: "other", label: "Other" },
  ],

  sendToScrap: [
    { value: 1, label: "Break" },
    { value: 2, label: "Exhausted" },
  ],

  subSystems: [
    { value: "robotic-arm ", label: "Robotic Arm" },
    { value: "scc", label: "SCC" },
    { value: "vision-cart", label: "Vision Cart" },
    { value: "robotic-arm-cart", label: "Robotic Arm Cart" },
    { value: "tool-interface", label: "Tool Interface" },
    { value: "instrument", label: "Instrument" },
    { value: "software", label: "Software" },
    { value: "actuator", label: "Actuator" },
    { value: "setup-joint", label: "Setup Joint" },
    { value: "system", label: "System" },
    { value: "shcd", label: "SHCD" },
  ],

  mainComponent: {
    main_component_list: [
      { value: "scc", label: "Surgeon Command Center" },
      { value: "vc", label: "Vision Cart" },
      { value: "psac", label: "Patient Side Arm Cart" },
      { value: "accessories", label: "Accessories" },
      { value: "instruments", label: "Instruments" },
    ],
    accessories: [
      { value: "drip", label: "Drip" },
      { value: "seal", label: "Seal" },
      { value: "canula", label: "Canula" },
    ],
    instruments: [
      { value: "lnd", label: "LND" },
      { value: "fpf", label: "FPF" },
      { value: "mbf", label: "MBF" },
    ],
    cart_list: [
      { value: "one", label: "One" },
      { value: "two", label: "Two" },
      { value: "camera", label: "Camera" },
      { value: "three", label: "Three" },
      { value: "four", label: "Four" },
    ],
    scc: [
      { value: "head_tracking_camera", label: "Head Tracking Camera" },
      { value: "3d_hd_4k_monitor", label: "3D HD 4K" },
      { value: "2d_pre_op_monitor", label: "2d Pre Operations Monitor" },
      { value: "foot_pedal_control", label: "Foot Pedal Control" },
      { value: "control_panels", label: "Control Panels" },
      { value: "shcd_left", label: "SHCD Left" },
      { value: "shcd_right", label: "SHCD Right" },
      { value: "software", label: "Software" },
    ],
    vc: [
      { value: "ups", label: "UPS" },
      { value: "control_system-box", label: "Control System Box" },
      { value: "endoscopic-unit", label: "Endoscopic Unit" },
      { value: "3d_omni", label: "3D Omni" },
    ],
    psac: [
      { value: "parking_lock", label: "Parking Lock" },
      { value: "boom", label: "Boom" },
      { value: "setup_joints", label: "Setup Joints" },
      { value: "active_joints", label: "Active Joints" },
      { value: "telescopic_column", label: "Telescopic Column" },
      { value: "tool_interface", label: "Tool Interface" },
      { value: "actuator", label: "Actuator" },
    ],
  },

  statusList: [
    { value: "error", label: "Error" },
    { value: "init", label: "Initalize" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
  ],

  DateTypeOption: [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ],

  ingestPartnerStatusOptions: [
    { value: "all", label: "All" },
    { value: "completed", label: "Completed" },
    { value: "error", label: "Error" },
    { value: "pending", label: "Pending" },
  ],

  // instrumentCategory: [
  //   { value: "cautery_instruments", label: "Cautery Instruments" },
  //   { value: "scissors_instruments", label: "Scissors Instruments" },
  //   { value: "needle_driver_instruments", label: "Needle Driver Instruments" },
  //   { value: "forceps_and_retractors_instruments", label: "Forceps and Retractors Instruments", },
  //   { value: "clip_applier_instruments", label: "Clip Applier Instruments" },
  //   { value: "speciality_instruments", label: "Speciality Instruments" },
  // ],

  instrumentCategory: [
    { value: "monopolar_cautery_instruments", label: "Monopolar Cautery Instruments" },
    { value: "clip_appliers", label: "Clip Appliers" },
    { value: "needle_drivers", label: "Needle Drivers" },
    { value: "forceps_and_retractors", label: "Forceps and Retractors Instruments", },
    { value: "cardiac_speciality_instruments", label: "Cardiac Speciality Instruments" },
    { value: "harmonic_instruments", label: "Harmonic Instruments" },
  ],

  months: {
    jan: "01",
    feb: "02",
    mar: "03",
    apr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    aug: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dec: "12",
  },

  // monthsname: {
  //   jan: "January",
  //   feb: "February",
  //   mar: "March",
  //   apr: "April",
  //   may: "May",
  //   jun: "June",
  //   jul: "July",
  //   aug: "August",
  //   sep: "September",
  //   oct: "October",
  //   nov: "November",
  //   dec: "December",
  // },

  years: [
    // { value: "2019", label: "2019" },
    // { value: "2020", label: "2020" },
    // { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ],

  typeOfClavien: [
    { value: "grade_1", label: "Grade I" },
    { value: "grade_2", label: "Grade II" },
    { value: "grade_3a", label: "Grade IIIa" },
    { value: "grade_3b", label: "Grade IIIb" },
    { value: "grade_4a", label: "Grade IVa" },
    { value: "grade_4b", label: "Grade IVb" },
    { value: "grade_5", label: "Grade V" },
  ],

  monthsname: [
    { value: "jan", label: "January" },
    { value: "feb", label: "February" },
    { value: "mar", label: "March" },
    { value: "apr", label: "April" },
    { value: "may", label: "May" },
    { value: "jun", label: "June" },
    { value: "jul", label: "July" },
    { value: "aug", label: "August" },
    { value: "sep", label: "September" },
    { value: "oct", label: "October" },
    { value: "nov", label: "November" },
    { value: "dec", label: "December" },
  ],

  taskTypesStatusTypes: [
    { value: "1", label: "Assigned" },
    { value: "2", label: "Started" },
    { value: "3", label: "Completed" },
    { value: "-1", label: "Cancelled" },
    { value: "-2", label: "Request Transfer" },
  ],
};
