import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchUsers, fetchHospitals, fetchUserDetails, authenticateUser, fetchProcedureDetails, fetchSurgeonDetails, } from "./store/actionCreator";
import { httpGetReq } from "./util/request";
import { BrowserRouter as Router, Route, Routes, Navigate, } from "react-router-dom";
import config from "./config/configUrl";
import { saveCookie, loadCookie, acitivityLog } from "./util/util";

import '../src/app.scss';

// import { useHistory } from "react-router-dom";
// const history = useHistory();
// import { useNavigate } from "react-router-dom";

import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";
import Logout from "./pages/Logout/Logout";
import Login from "./pages/Login/Login";

import { Dashboard } from "./container/DashboardContainer.js";
import { Profile } from "./container/ProfileContainer";
import { Hospitals } from "./container/clinical/Hospital";
import { New } from "./container/New";
import { MyTasks } from "./container/fieldservice/MyTaskContainer.js";
import TaskList from "./container/fieldservice/TaskList";
import SurgeryList from "./container/clinical/SurgeryList";
import SurgeryInstrumentList from "./container/clinical/SurgeryInstrumentList";
import PermissionsList from "./container/AppPermissionContainer";
import { ServiceEngineerListing } from "./container/fieldservice/ServiceEngineerContainer";
import { ClinicalSupportListing } from "./container/clinical/UserContainer";
import { SutraUserListing } from "./container/SutraUserContainer";
import { Mudras } from "./container/mudra/MudraContainer.js";
import { MantraUnits } from "./container/fieldservice/MantraUnitsContainer";
import { SurgeryProcedure } from "./container/clinical/SurgeryProcedureContainer";
import { CallsContainer } from "./container/ZZ_CallContainer.js";
import { SurgeonContainer } from "./container/clinical/SurgeonContainer";
import TimeTesting from "./container/clinical/TimeTesting";
import TimeTestingNew from "./container/clinical/TimeTestingNew";
import {ServiecReportContainer} from "./components/report/ServiceReportContainer";
import ScheduleCalendar from "./components/ScheduleCalendar";
import { MudraDashboard } from "./container/mudra/MudraDashboardContainer";
import { DeskR1 } from "./container/mudra/DeskR1.js";
import { Ipqc1 } from "./container/mudra/Ipqc1.js";
import {WarehouseListing} from "./container/mudra/WarehouseContainer";
import { ServiceListing } from "./container/mudra/ServiceContainer";
import { DiscardedListing } from "./container/mudra/DiscardListingContainer";
import { AllTaskContainer } from "./container/fieldservice/AllTaskContainer.js";
import { AllCallContainer } from "./container/fieldservice/AllCallContainer.js";
import { MudraAssemblyHall } from "./container/mudra/InstrumentAssemblyHall";


import "./css/Sidebar.css";
import "./css/Header.css";
import "./css/style.css";
import { MaintenanceContainer } from "./container/fieldservice/MaintenanceContainer.js";
import { InHospitalListing } from "./container/mudra/InHospitalsListingContainer";
import {InstrumentInHospital}  from "./container/mudra/InstrumentInHospital";

import ServiceReportList from "./components/report/ServiceReportList";
import { InstrumentUseContainer } from "./container/mudra/InstrumentUseContainer";
import { MudraServiceContainer } from "./container/mudra/MudraServiceContainer";
import { InstrumentDeskContainer } from "./container/mudra/InstrumentDesk.js";
import { ServiceRequestedListing } from "./container/mudra/ServiceRequested";


const App = (props) => {
  let logged = loadCookie(config.authCookieName) !== undefined ? true : false;
  let udetail = loadCookie(config.authCookieName);
  const [isLoged, setisLoged] = useState(logged);
  const [isLoading, setIsLoading] = useState(true);
  let logSend = false;

  const doAuthenticate = (email) => {
    logSend = true;
    let url = config.baseUrl + config.ver + config.auth + email;
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        props.fetchUserDetails(response.data.data);
        setIsLoading(false);
        let LogObj = {
          action_name: "Authentication",
          action_by: email,
          action_on: Date.now(),
          action_type: "Auth",
          action_Detail: 1,
        };
        acitivityLog(LogObj);
      } else {
        setisLoged(false);
      }
    });
  };

  const handleLogin = (user) => {
    saveCookie(config.authCookieName, JSON.stringify(user));
    setisLoged(true);
    props.fetchUserDetails(user);
    let LogObj = {
      action_name: "Login",
      action_by: user.emp_email,
      action_on: Date.now(),
      action_type: "Login",
      action_Detail: 1,
    };
    acitivityLog(LogObj);
    logSend = true;
    doAuthenticate(user.emp_email);
    window.location.replace("/dashboard");
  };
  
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (isLoged && !logSend) {
        doAuthenticate(udetail.emp_email);
      } else {
        setIsLoading(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (props.hospitals.length === 0) {
    props.fetchHospitals(function () {
      if (props.users.length === 0) {
        props.fetchUsers();
      }
    });
  }

  if (isLoading && isLoged) {
    return <div>Authenticating....</div>;
  }

  // KEPT HEADER INSDIDE ROUTER TO MAKE SURE THAT HEADER ALSO HAS FEW FUNCTIONALITY
  // PREVIOUS STATE ROUTER IS COMMENTED AND REVERT BACK IF IT AFFECT THE CURRENT FUNCTIONALITY

  return (
    <>
    <Router>
    {isLoged && <Header userInfo={udetail} />}
      {isLoged ? (
        <>
          <div className="App">
          {/* <Router> */}
              <Sidebar usertype={udetail.user_type} />
              <Routes>
                <Route
                  path="/"
                  element={
                    isLoged ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route exact path="/dashboard" element={<Dashboard UserInfo={udetail} {...props} />} />
                <Route exact path="/profile" element={<Profile UserInfo={udetail} {...props} />} />
                <Route exact path="/user/service-engineer" element={<ServiceEngineerListing UserInfo={udetail} />} />
                <Route exact path="/user/clinical-support" element={<ClinicalSupportListing UserInfo={udetail} />} />
                <Route exact path="/sutra-user" element={<SutraUserListing UserInfo={udetail} />} />
                {/* <Route exact path="/user"//element={ navigate(-1) }/> */}
                <Route exact path="/hospitals" element={<Hospitals UserInfo={udetail} {...props} />} />

                <Route exact path="/oldsurgerydata" element={<New UserInfo={udetail} {...props} />} />
                <Route exact path="/schedulecalendar" element={<ScheduleCalendar UserInfo={udetail} {...props} />} />

                <Route exact path="/mantra" element={<MantraUnits UserInfo={udetail} />} />
                <Route exact path="/procedures" element={<SurgeryProcedure UserInfo={udetail} />} />
                <Route exact path="/mytasks" element={<MyTasks UserInfo={udetail} />} />
                <Route exact path="/mudra" element={<Mudras UserInfo={udetail} />} />
                <Route exact path="/alltasksnew" element={<TaskList UserInfo={udetail} />} />
                <Route exact path="/surgeries" element={<SurgeryList UserInfo={udetail} {...props} />} />
                <Route exact path="/surgeries-instruments" element={<SurgeryInstrumentList UserInfo={udetail} {...props} />} />
                <Route exact path="/surgeon" element={<SurgeonContainer UserInfo={udetail} />} />
                

                {/* MUDRA NEW WORKING */}

                <Route exact path="/mudra/dashboard" element={<MudraDashboard UserInfo={udetail} />} /> 
                <Route exact path="/mudra/r1" element={<DeskR1 UserInfo={udetail} />} /> 
                <Route exact path="/mudra/ipqc1" element={<Ipqc1 UserInfo={udetail} />} />
                <Route exact path="/mudra/warehouse" element={<WarehouseListing UserInfo={udetail} />} />                 
                <Route exact path="/mudra/inhospitals" element={<InHospitalListing UserInfo={udetail} />} />
                {/* <Route exact path="/mudra/service" element={<ServiceListing UserInfo={udetail} />} />  */}
                <Route exact path="/mudra/service" element={<MudraServiceContainer UserInfo={udetail} />} />
                <Route exact path="/mudra/service/in-service" element={<ServiceListing UserInfo={udetail} />} />
                <Route exact path="/mudra/service/service-request" element={<ServiceRequestedListing UserInfo={udetail} />} /> 
                <Route exact path="/mudra/discarded" element={<DiscardedListing UserInfo={udetail} />} /> 
                <Route exact path="/mudra/instrument-in-hospitals" element={<InstrumentInHospital UserInfo={udetail} />} />
                <Route exact path="/mudra/do-surgery" element={<InstrumentUseContainer UserInfo={udetail} />} />
                <Route exact path="/muddra-assembly/dashboard" element={<MudraAssemblyHall UserInfo={udetail} />} />
                <Route exact path="/mudra-assembly/desks" element={<InstrumentDeskContainer UserInfo={udetail} />} />               

                <Route exact path="/callgenerate" element={<CallsContainer UserInfo={udetail} />} />
                <Route exact path="/callgenerate/alltasks" element={<AllTaskContainer UserInfo={udetail} />} />
                <Route exact path="/callgenerate/allcalls" element={<AllCallContainer UserInfo={udetail} />} />

                <Route exact path="/mantra-maintenance" element={<MaintenanceContainer UserInfo={udetail} />} />

                <Route exact path="/service-report" element={<ServiecReportContainer UserInfo={udetail} />} />
                <Route exact path="/service-report-list" element={<ServiceReportList UserInfo={udetail} {...props} />} />
                
                <Route exact path="/surgical-timesheet" element={<TimeTesting />} />
                <Route exact path="/surgical-timesheet-newtesting" element={<TimeTestingNew />} />
                <Route exact path="/permsission" element={<PermissionsList UserInfo={udetail} {...props} />} />
                <Route exact path="/logout" element={<Logout />} />
              </Routes>
              {/* </Router> */}
          </div>
        </>
      ) : (
        <>       
        {window.location.pathname == "/service-report" ? <> <ServiecReportContainer/></> : <> <div className="LoginBox">
          <Login onLogin={handleLogin} />
        </div></>}    
        </>       
      )}
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
    surgeons: state.surgeons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
    fetchHospitals: (callback) => fetchHospitals(dispatch, callback),
    fetchUserDetails: (udetail) => fetchUserDetails(udetail, dispatch),
    fetchProcedureDetails: (callback) => fetchProcedureDetails(dispatch, callback),
    fetchSurgeonDetails: (callback) => fetchSurgeonDetails(dispatch, callback),
    // authenticateUser: (email) => authenticateUser(dispatch, email),
  };
};

// export default App;
//Dummy Push

export default connect(mapStateToProps, mapDispatchToProps)(App);
