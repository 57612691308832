import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import config from "../config/configUrl";
import { ConVar } from "../config/configVar";
import Button from "@mui/material/Button";
import { httpGetReq, httpPostReq } from "../util/request";
import DialogActions from "@mui/material/DialogActions";
import { convertSecondsToHrMin, getTimeDifferenceInMs, convertMillisecondsToHrMin, calculateDayDifference, } from "../util/util";
import YesNoPrompt from "../components/YesNoPrompt";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { connect } from "react-redux";
import CustomizedSnackbars from "../components/Alert";
import { fetchProcedureDetails } from "../store/actionCreator";

import "../css/CommonStyle.css";
import "../css/dummy.css";

export const New = (props) => {
  if (props.procedures.length === 0) {
    props.fetchProcedureDetails();
  }

  const [yourCommentShow, setYourCommentShow] = useState(false);
  const [entryRemarks, setEntryRemarks] = useState(null);
  const [zone_name, setZoneName] = useState("all");
  const [fitleredHospitalList, setFilteredHospitalList] = useState([]);
  const [fitlererdProcedure, setFilteredProcedure] = useState([]);
  const [surgerySteps, setSurgerySteps] = useState([]);
  const [surgeonOption, setSurgeonOption] = useState([]);
  const [optionInstrumentList, setOptionInstrumentList] = useState([]);
  const [patientName, setPatientName] = useState(null);
  const [surgerySpecification, setSurgerySpecification] = useState();
  const [system_no, setSystem_no] = useState(null);
  const [numberOfArms, setNumberOfArms] = useState(null);
  const [selectedSurgeon, setSelectedSurgeon] = useState([]);
  const [selectedAssistantSurgeon, setSelectedAssistantSurgeon] = useState([]);
  const [diagnosis, setDiagnosis] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [caseNo, setCaseNo] = useState(null);
  const [patientID, setPatientId] = useState(null);
  const [gender, setGender] = useState(null);
  const [mortality, setMortality] = useState(null);
  const [caseConversion, setCaseConversion] = useState(null);

  const [comorbidities, setComorbidities] = useState();
  const [age, setAge] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [bmi, setBmi] = useState();

  const [DateofAdmission, setDateOfAdmission] = useState();
  const [DateofSurgery, setDateOfSurgery] = useState();

  const [PatientInTime, setPatientIntime] = useState();
  const [patientOutTime, setPatientOutTime] = useState();

  const [systemOnTime, setSystemOnTime] = useState();
  const [systemOfTime, setSystemOfTime] = useState();
  const [systemOperatingDuration, setSystemOperatingDuration] = useState();

  const [drappingStartTime, setDrappingStartTime] = useState();
  const [drappingEndTime, setDrappingEndTime] = useState();
  const [drapingDuration, setDrappingDuration] = useState();

  const [intubationStartTime, setIntubationStartTime] = useState();
  const [intubationEndTime, setIntubationEndTime] = useState();
  const [intubationDuration, setIntubationDuration] = useState();

  const [patientIncisionTime, setPatinetIncisionTime] = useState();
  const [patientSkinClosureTime, setPatientSkinClosureTime] = useState();
  const [surgeryDuration, setSurgeryDuration] = useState();

  const [portPlacementStartTime, setPorPlacementStartTime] = useState();
  const [portPlacementEndTime, setPorPlacementEndTime] = useState();
  const [portPlacmentDuration, setPortPlacementDuration] = useState();

  const [portPlacementStartTime2, setPorPlacementStartTime2] = useState();
  const [portPlacementEndTime2, setPorPlacementEndTime2] = useState();
  const [portPlacmentDuration2, setPortPlacementDuration2] = useState();

  const [dockingStarTime, setDockingStartTime] = useState();
  const [dockingEndTime, setDcokingEndTime] = useState();
  const [dockingDuration, setDockingDuration] = useState();

  const [dockingStarTime2, setDockingStartTime2] = useState();
  const [dockingEndTime2, setDcokingEndTime2] = useState();
  const [dockingDuration2, setDockingDuration2] = useState();

  const [consoleStartTime, setConsoleStartTime] = useState();
  const [consoleEndTime, setConsoleEndTime] = useState();
  const [consoleDuration, setConsoleDuration] = useState();
  const [consoleInterruptTime, setConsoleInterruption] = useState();
  const [consoleRemarks, setConsoleRemarks] = useState();

  const [unDockStartTime, setUndockStartTime] = useState();
  const [unDockeEndTime, setUndockEndTime] = useState();
  const [unDockDuration, setUnDockDuration] = useState();

  const [unDockStartTime2, setUndockStartTime2] = useState();
  const [unDockeEndTime2, setUndockEndTime2] = useState();
  const [unDockDuration2, setUnDockDuration2] = useState();

  const [procedureCategory, setProcedureCategory] = useState(null);
  const [surgeryStepTimings, setSurgeryStepTimings] = useState([]);

  const [selectedInstrumentList, setSelectedInstrumentList] = useState([]);
  const [instrumentIssue, setInstrumentIssue] = useState();

  const [subComponentIssue, setSubComponentIssue] = useState();
  const [deviceIssue, setDeviceIssue] = useState();
  const [devicePatientComplication, setDevicePatientComplications] = useState();
  const [systemIssueRemark, setSystemIssueRemark] = useState();
  const [intraOperatingComplications, setIntraOperatingComplications] = useState();

  const [dateOfDischarge, setDateOfDischarge] = useState();
  const [lengthofStay, setLengthOfStay] = useState();

  const [Readmission, setReAdmission] = useState(false);
  const [postDischargeComplication, setPostDiscahrgeComplication] = useState();
  const [bloodTransfusion, setBloodTransfusion] = useState();
  const [bloodLoss, setBloodLoss] = useState();
  const [clavien, setClavien] = useState();

  const [showPrompt, setShowPrompt] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popUpMessage, setShowPopupMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState( "Please check all values are correct." );

  const handleSurgeryStepTimingChange = (step, timing) => {
    setSurgeryStepTimings((prevState) => ({
      ...prevState,
      [step]: timing,
    }));
  };

  const handleReAdmissionChange = (event) => {
    setReAdmission(event.target.checked);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleCaseConversion = (event) => {
    setCaseConversion(event.target.value);
  };

  const handleMortality = (event) => {
    setMortality(event.target.value);
  };

  const calculateTimeDifferenceInSeconds = (startTimeStr, endTimeStr) => {
    const startTime = new Date(startTimeStr);
    const endTime = new Date(endTimeStr);
    const timeDifferenceInMs = endTime.getTime() - startTime.getTime();
    const timeDifferenceInSeconds = timeDifferenceInMs / 1000;
    return timeDifferenceInSeconds;
  };

  const onChangeHosptialZone = (zone) => {
    let hospital_search_url = `${config.baseUrl}${config.ver}${config.hospitalList}?`;
    if (zone !== "all") {
      hospital_search_url += `area_zone=${zone}`;
    }
    httpGetReq(hospital_search_url, "application/json", function (response) {
      if (response && response.data) {
        if (response.data.available > 0) {
          setFilteredHospitalList(response.data.data);
        } else {
          setFilteredHospitalList([]);
        }
      }
    });
  };

  const onChangeProcedureCategory = (category) => {
    let url = `${config.baseUrl}${config.ver}${config.procedureList}`;
    if (category != undefined && category != "") {
      url += "?procedure_category=" + category;
    }
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setFilteredProcedure(response.data.data);
      }
    });
  };

  const onChangeProcedure = (procedure_code) => {
    let url = `${config.baseUrl}${config.ver}${config.procedureList}`;
    if (procedure_code != undefined && procedure_code != "") {
      url += "?procedure_code=" + procedure_code;
    }
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        if (
          response.data.data &&
          response.data.data[0] &&
          response.data.data[0].surgery_steps
        )
          setSurgerySteps(response.data.data[0].surgery_steps);
      }
    });
  };

  const timeToMilliseconds = (time) => {
    let [hour, minute] = time.split(":").map(Number);
    let totalMilliseconds = (hour * 60 + minute) * 60 * 1000;
    return totalMilliseconds;
  };

  const onChangeHospital = (system_no) => {
    let url = `${config.baseUrl}${config.ver}${config.surgeonList}`;
    if (system_no != undefined && system_no != "") {
      url += "?system_no=" + system_no;
    }
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let sgopt = [];
        response.data.data.forEach((element) => {
          sgopt.push({ label: element.surgeon_name, value: element._id });
        });
        setSurgeonOption(sgopt);
      }
    });
  };

  const instrumentList = () => {
    let url = `${config.baseUrl}${config.ver}${config.mudraList}?limit=100}`;
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let sgopt = [];
        response.data.data.forEach((element) => {
          sgopt.push({ label: element.label, value: element.key });
        });
        setOptionInstrumentList(sgopt);
      }
    });
  };

  const submitSurgery = () => {
    const validations = [
      [ surgerySpecification == "" || surgerySpecification == undefined || surgerySpecification == null, "SurgerySpecification Not Selected!", ],
      [system_no == null, "Hospital Not Selected!"],
      [selectedSurgeon.length === 0, ", Surgeon Not Selected!"],
      [selectedInstrumentList.length === 0, ", Instrument Not Selected!"],
      [ selectedAssistantSurgeon.length === 0, ", Assistant Surgeon Not Selected!", ],
      [patientName == null, "Patient not filled!"],
      [diagnosis == null, "Diagnosis not filled!"],
      [serialNo == null, "SerialNo not filled!"],
      [caseNo == null, "CaseNo not filled!"],
      [patientID == null, "PatientID not filled!"],
      [gender == undefined, "Gender not selected!"],
      [age == undefined, "Age not selected!"],
      [height == undefined, "Height not selected!"],
      [weight == undefined, "Weight not selected!"],
      [DateofAdmission == undefined, "DateofAdmission not filled!"],
      [DateofSurgery == undefined, "DateofSurgery not filled!"],
      [PatientInTime == undefined, "PatientInTime not filled!"],
      [patientOutTime == undefined, "PatientOutTime not filled!"],
      [systemOnTime == undefined, "SystemOnTime not filled!"],
      [systemOfTime == undefined, "SystemOfTime not filled!"],
      [drappingStartTime == undefined, "DrappingStartTime not filled!"],
      [drappingEndTime == undefined, "DrappingEndTime not filled!"],
      [patientIncisionTime == undefined, "PatientIncisionTime not filled!"],
      [ patientSkinClosureTime == undefined, "PatientSkinClosureTime not filled!", ],
      [ portPlacementStartTime == undefined, "PortPlacementStartTime not filled!", ],
      [portPlacementEndTime == undefined, "PortPlacementEndTime not filled!"],
      [dockingStarTime == undefined, "DockingStartTime not filled!"],
      [dockingEndTime == undefined, "DockingEndTime not filled!"],
      [consoleStartTime == undefined, "ConsoleStartTime not filled!"],
      [consoleEndTime == undefined, "ConsoleEndTime not filled!"],
      [unDockStartTime == undefined, "UnDockStartTime not filled!"],
      [unDockeEndTime == undefined, "UnDockeEndTime not filled!"],
      [procedureCategory == null, "ProcedureCategory not selected!"],
      [surgeryStepTimings.length === 0, ", SurgeryStepTimings Not Selected!"],
      [ selectedInstrumentList.length === 0, ", SelectedInstrumentList Not Selected!", ],
    ];

    let error = validations.reduce((errorString, [condition, message]) => {
      if (condition) {
        errorString += message;
      }
      return errorString;
    }, "");
    setWarningMessage(error);
    setShowPrompt(true);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      instrumentList();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (zone_name) {
      onChangeHosptialZone(zone_name);
    }
  }, [zone_name]);

  useEffect(() => {
    if (systemOnTime && systemOfTime) {
      setSystemOperatingDuration(
        convertSecondsToHrMin(
          calculateTimeDifferenceInSeconds(systemOnTime, systemOfTime)
        )
      );
    }
  }, [systemOnTime, systemOfTime]);

  useEffect(() => {
    if (drappingStartTime && drappingEndTime) {
      setDrappingDuration(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(drappingStartTime, drappingEndTime)
        )
      );
    }
  }, [drappingStartTime, drappingEndTime]);

  useEffect(() => {
    if (intubationStartTime && intubationEndTime) {
      setIntubationDuration(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(intubationStartTime, intubationEndTime)
        )
      );
    }
  }, [intubationStartTime, intubationEndTime]);

  useEffect(() => {
    if (patientIncisionTime && patientSkinClosureTime) {
      setSurgeryDuration(
        convertSecondsToHrMin(
          calculateTimeDifferenceInSeconds(
            patientIncisionTime,
            patientSkinClosureTime
          )
        )
      );
    }
  }, [patientIncisionTime, patientSkinClosureTime]);

  useEffect(() => {
    if (portPlacementStartTime && portPlacementEndTime) {
      setPortPlacementDuration(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(portPlacementStartTime, portPlacementEndTime)
        )
      );
    }
  }, [portPlacementStartTime, portPlacementEndTime]);

  useEffect(() => {
    if (portPlacementStartTime2 && portPlacementEndTime2) {
      setPortPlacementDuration2(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(portPlacementStartTime2, portPlacementEndTime2)
        )
      );
    }
  }, [portPlacementStartTime2, portPlacementEndTime2]);

  useEffect(() => {
    if (dockingStarTime && dockingEndTime) {
      setDockingDuration(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(dockingStarTime, dockingEndTime)
        )
      );
    }
  }, [dockingStarTime, dockingEndTime]);

  useEffect(() => {
    if (dockingStarTime2 && dockingEndTime2) {
      setDockingDuration2(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(dockingStarTime2, dockingEndTime2)
        )
      );
    }
  }, [dockingStarTime2, dockingEndTime2]);

  useEffect(() => {
    if (consoleStartTime && consoleEndTime) {
      setConsoleDuration(
        convertSecondsToHrMin(
          calculateTimeDifferenceInSeconds(consoleStartTime, consoleEndTime)
        )
      );
    }
  }, [consoleStartTime, consoleEndTime]);

  useEffect(() => {
    if (unDockStartTime && unDockeEndTime) {
      setUnDockDuration(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(unDockStartTime, unDockeEndTime)
        )
      );
    }
  }, [unDockStartTime, unDockeEndTime]);

  useEffect(() => {
    if (unDockStartTime2 && unDockeEndTime2) {
      setUnDockDuration2(
        convertMillisecondsToHrMin(
          getTimeDifferenceInMs(unDockStartTime2, unDockeEndTime2)
        )
      );
    }
  }, [unDockStartTime2, unDockeEndTime2]);

  useEffect(() => {
    if (DateofAdmission && dateOfDischarge) {
      setLengthOfStay(calculateDayDifference(DateofAdmission, dateOfDischarge));
    }
  }, [DateofAdmission, dateOfDischarge]);

  useEffect(() => {
    if (height && weight) {
      let heightInMeter = height / 100;
      let heightInMeterSquare = heightInMeter * heightInMeter;
      let bmitemp = weight / heightInMeterSquare;
      setBmi(bmitemp.toFixed(2));
    }
  }, [height, weight]);

  const OnDenied = () => {
    setShowPrompt(false);
  };

  const OnOk = () => {
    setShowPrompt(false);
    setYourCommentShow(true);
  };

  const SendData = async () => {
    const addSurgeryParameterUrl = config.baseUrl + config.ver + config.surgeriesNew;
    let postBody = {};

    // Patient Information
    let patientInfo = {
      "patient_name": patientName ? patientName : "N/A",
      "serial_no": serialNo ? serialNo : "N/A",
      "case_no": caseNo ? caseNo : "N/A",
      "patient_id": patientID ? patientID : "N/A",
      "gender": gender ? gender : "N/A",
      "age": age ? age : 0,
      "weight": weight ? weight : 0,
      "height": height ? height : 0,
      "bmi": bmi ? bmi : 0,
      "diagnosis": diagnosis ? diagnosis : "N/A",
    };
    
    // Mantra System Operating Info
    let mantra_system_operating_info = {
      "on_time": systemOnTime ? new Date(systemOnTime).getTime() : 0,
      "off_time": systemOfTime ? new Date(systemOfTime).getTime() : 0,
      "duration": systemOnTime && systemOfTime ? new Date(systemOfTime).getTime() - new Date(systemOnTime).getTime() : 0
    };
    
    // Draping Information
    let drapping = {
      "start": drappingStartTime ? timeToMilliseconds(drappingStartTime) : 0,
      "end": drappingEndTime ? timeToMilliseconds(drappingEndTime) : 0,
      "duration": drappingStartTime && drappingEndTime ? timeToMilliseconds(drappingEndTime) - timeToMilliseconds(drappingStartTime) : 0
    };
    
    // Intubation Information
    let intubation = {
      "start": intubationStartTime ? timeToMilliseconds(intubationStartTime) : 0,
      "end": intubationEndTime ? timeToMilliseconds(intubationEndTime) : 0,
      "duration": intubationStartTime && intubationEndTime ? timeToMilliseconds(intubationEndTime) - timeToMilliseconds(intubationStartTime) : 0
    };
    
    // Surgery Information
    let surgery = {
      "start": patientIncisionTime ? new Date(patientIncisionTime).getTime() : 0,
      "end": patientSkinClosureTime ? new Date(patientSkinClosureTime).getTime() : 0,
      "duration": patientIncisionTime && patientSkinClosureTime ? new Date(patientSkinClosureTime).getTime() - new Date(patientIncisionTime).getTime() : 0
    };
    
    // Port Placement Information
    let port_placement = {
      "start": portPlacementStartTime ? timeToMilliseconds(portPlacementStartTime) : 0,
      "end": portPlacementEndTime ? timeToMilliseconds(portPlacementEndTime) : 0,
      "duration": portPlacementStartTime && portPlacementEndTime ? timeToMilliseconds(portPlacementEndTime) - timeToMilliseconds(portPlacementStartTime) : 0
    };
    
    // Second Port Placement Information
    let second_port_placement = {
      "start": portPlacementStartTime2 ? timeToMilliseconds(portPlacementStartTime2) : 0,
      "end": portPlacementEndTime2 ? timeToMilliseconds(portPlacementEndTime2) : 0,
      "duration": portPlacementStartTime2 && portPlacementEndTime2 ? timeToMilliseconds(portPlacementEndTime2) - timeToMilliseconds(portPlacementStartTime2) : 0
    };
    
    // Docking Information
    let dock = {
      "start": dockingStarTime ? timeToMilliseconds(dockingStarTime) : 0,
      "end": dockingEndTime ? timeToMilliseconds(dockingEndTime) : 0,
      "duration": dockingStarTime && dockingEndTime ? timeToMilliseconds(dockingEndTime) - timeToMilliseconds(dockingStarTime) : 0
    };
    
    // Second Docking Information
    let second_dock = {
      "start": dockingStarTime2 ? timeToMilliseconds(dockingStarTime2) : 0,
      "end": dockingEndTime2 ? timeToMilliseconds(dockingEndTime2) : 0,
      "duration": dockingStarTime2 && dockingEndTime2 ? timeToMilliseconds(dockingEndTime2) - timeToMilliseconds(dockingStarTime2) : 0
    };
    
    // Console Information
    let console_ = {
      "start": consoleStartTime ? new Date(consoleStartTime).getTime() : 0,
      "end": consoleEndTime ? new Date(consoleEndTime).getTime() : 0,
      "duration": consoleStartTime && consoleEndTime ? new Date(consoleEndTime).getTime() - new Date(consoleStartTime).getTime() : 0,
      "console_interrupt_remark": consoleRemarks,
      "interrupt": consoleInterruptTime ? consoleInterruptTime : 0
    };
    
    // Undocking Information
    let undock = {
      "start": unDockStartTime ? timeToMilliseconds(unDockStartTime) : 0,
      "end": unDockeEndTime ? timeToMilliseconds(unDockeEndTime) : 0,
      "duration": unDockStartTime && unDockeEndTime ? timeToMilliseconds(unDockeEndTime) - timeToMilliseconds(unDockStartTime) : 0
    };
    
    // Second Undocking Information
    let second_undock = {
      "start": unDockStartTime2 ? timeToMilliseconds(unDockStartTime2) : 0,
      "end": unDockeEndTime2 ? timeToMilliseconds(unDockeEndTime2) : 0,
      "duration": unDockStartTime2 && unDockeEndTime2 ? timeToMilliseconds(unDockeEndTime2) - timeToMilliseconds(unDockStartTime2) : 0
    };
    
    // Instruments Information
    let instruments = {
      "used": selectedInstrumentList ? selectedInstrumentList : [],
      "issues": instrumentIssue ? instrumentIssue : []
    };
    
    // System Issues Information
    let system_issues = {
      "sub_components": subComponentIssue ? subComponentIssue : "",
      "devices_issues": deviceIssue ? deviceIssue : "",
      "device_patient_complications": devicePatientComplication ? devicePatientComplication : ""
    };
    
    // Post Surgery Information
    let post_surgery_info = {
      "date_of_discharge": dateOfDischarge ? new Date(dateOfDischarge).getTime() : 3,
      "length_of_stay_days": lengthofStay ? lengthofStay : 0,
      "readmission": Readmission ? Readmission : false,
      "post_discharge_complications": postDischargeComplication ? postDischargeComplication : "",
      "clavien": clavien ? clavien : "",
    };
  
    // Surgery Steps
    let surgery_steps = surgeryStepTimings ? surgeryStepTimings : {};

    postBody["entry_remarks_info"] = entryRemarks;
    postBody["procedure_code"] = procedureCategory ? procedureCategory : "N/A";
    postBody["surgery_specification"] = surgerySpecification ? surgerySpecification : "N/A";
    postBody["patient_in_time"] = PatientInTime ? new Date(PatientInTime).getTime() : 3;
    postBody["patient_out_time"] = patientOutTime ? new Date(patientOutTime).getTime() : 3;
    postBody["date_of_surgery"] = DateofSurgery ? new Date(DateofSurgery).getTime() : 3;
    postBody["date_of_admission"] = DateofAdmission ? new Date(DateofAdmission).getTime() : 3;
    postBody["system_no"] = system_no ? system_no : "N/A";
    const date_temp = new Date(DateofSurgery);
    postBody["surgery_year"] = date_temp.getFullYear();
    postBody["surgery_month"] = date_temp.getMonth() + 1;
    postBody["patient_info"] = patientInfo;
    postBody["mantra_system_operating_info"] = mantra_system_operating_info;
    postBody["drapping"] = drapping;
    postBody["patient_intubation"] = intubation;
    postBody["surgery"] = surgery;
    postBody["port_placement"] = port_placement;
    postBody["second_port_placement"] = second_port_placement;
    postBody["dock"] = dock;
    postBody["second_dock"] = second_dock;
    postBody["console"] = console_;
    postBody["undock"] = undock;
    postBody["second_undock"] = second_undock;
    postBody["instruments"] = instruments;
    postBody["system_issues"] = system_issues;
    postBody["surgery_steps"] = surgery_steps;
    postBody["post_surgery_info"] = post_surgery_info;
    postBody["mortality"] = mortality ? mortality : "N/A";
    postBody["case_conversion"] = caseConversion ? caseConversion : "N/A";
    postBody["blood_transfusion"] = bloodTransfusion ? bloodTransfusion : 0;
    postBody["blood_loss"] = bloodLoss ? bloodLoss : 0;
    postBody["co_morbidities"] = comorbidities ? comorbidities : "N/A";
    postBody["surgeon_name"] = selectedSurgeon ? selectedSurgeon : [];
    postBody["assistant_surgeon"] = selectedAssistantSurgeon ? selectedAssistantSurgeon : [];
    postBody["no_of_arms"] = numberOfArms ? numberOfArms : "N/A";

    httpPostReq(
      addSurgeryParameterUrl,
      postBody,
      "application/json",
      (response) => {
        if (response.data && response.data.status && response.data.status === "success") {
          setShowPopupMessage("Success");
          setShowPopup(true);
        } else if (response.data && response.data.status && response.data.status === "fail") {
          setShowPopupMessage(response.data.message);
          setShowPopup(true);
        }
      },
      props.UserInfo.emp_email
    );
  };  

  return (
    <div className="dummy-container">
      <div className="dummy-list-container">
        <div>
          <YesNoPrompt
            heading={"Are you Sure?"}
            detail={warningMessage}
            open={showPrompt}
            Ok={OnOk}
            Cancel={OnDenied}
          />
        </div>

        <div>
          <Dialog open={yourCommentShow}>
            <DialogContent>
              <DialogContentText>
                Your comments about the whole Entry
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                value={entryRemarks}
                type="text"
                onChange={(e) => {
                  setEntryRemarks(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setYourCommentShow(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setYourCommentShow(false);
                  SendData();
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          {showPopup && (
            <CustomizedSnackbars
              message={popUpMessage}
              open={showPopup}
              close={() => {
                setShowPopup(false);
              }}
            />
          )}
        </div>

        <div className="form-container">
          <h4>Hospital Info</h4>
          <div className="input-group">
            <span>
              <label>Area Zone</label>
              <select
                className="custom-select"
                value={zone_name}
                onChange={(e) => {
                  setZoneName(e.target.value);
                  onChangeHosptialZone(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  Select Area Zone
                </option>
                {ConVar.hospitalZone.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>Hospital</label>
              <select
                className="custom-select"
                value={system_no}
                onChange={(e) => {
                  onChangeHospital(e.target.value);
                  setSystem_no(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  Select Hospital
                </option>
                {fitleredHospitalList.length ? (
                  fitleredHospitalList.map((option) => (
                    <option key={option._id} value={option.system_no}>
                      {option.name}-{option.location}
                    </option>
                  ))
                ) : (
                  <option key={"no_2353"} value={"No hospital"}>
                    {"Oops! No System Installed yet🫠"}
                  </option>
                )}
              </select>
            </span>
            <span>
              <label>Number of Arms</label>
              <select
                className="custom-select"
                value={numberOfArms}
                onChange={(e) => {
                  setNumberOfArms(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  {" "}
                  Select Number or Arm{" "}
                </option>
                <option key={"1c"} value={3}>
                  {" "}
                  Three{" "}
                </option>
                <option key={"2c"} value={4}>
                  {" "}
                  Four{" "}
                </option>
                <option key={"3c"} value={5}>
                  {" "}
                  Five{" "}
                </option>
              </select>
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Surgeon Info</h4>
          <div className="input-group">
            <span>
              <label>Surgeon</label>
              <div style={{ width: 400 }}>
                <MultiSelect
                  hasSelectAll={false}
                  options={surgeonOption}
                  value={selectedSurgeon}
                  onChange={setSelectedSurgeon}
                />
              </div>
            </span>
            <span>
              <label>Assistant Surgeon</label>
              <div style={{ width: 400 }}>
                <MultiSelect
                  hasSelectAll={false}
                  options={surgeonOption}
                  value={selectedAssistantSurgeon}
                  onChange={setSelectedAssistantSurgeon}
                />
              </div>
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Patient Info</h4>
          <div className="input-group">
            <span>
              <label>Patient Name</label>
              <input
                type="text"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
              />
            </span>
            <span>
              <label>Surgery Specification</label>
              <input
                type="text"
                value={surgerySpecification}
                onChange={(e) => setSurgerySpecification(e.target.value)}
              />
            </span>
            <span>
              <label>Diagnosis</label>
              <input
                type="text"
                value={diagnosis}
                onChange={(e) => setDiagnosis(e.target.value)}
              />
            </span>
            <span>
              <label>Serial No</label>
              <input
                type="text"
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
              />
            </span>
            <span>
              <label>Case No</label>
              <input
                type="text"
                value={caseNo}
                onChange={(e) => setCaseNo(e.target.value)}
              />
            </span>
            <span>
              <label>Patient Id</label>
              <input
                type="text"
                value={patientID}
                onChange={(e) => setPatientId(e.target.value)}
              />
            </span>
            <span>
              <label>Gender</label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={handleGenderChange}
                />
                Male
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={handleGenderChange}
                />
                Female
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="other"
                  onChange={handleGenderChange}
                />
                Other
              </label>
            </span>
            <span>
              <label>Case Conversion</label>
              <label>
                <input
                  type="radio"
                  name="caseConversion"
                  value="No Conversion"
                  onChange={handleCaseConversion}
                />
                No Conversion
              </label>
              <label>
                <input
                  type="radio"
                  name="caseConversion"
                  value="Laparoscopic"
                  onChange={handleCaseConversion}
                />
                Laparoscopic
              </label>
              <label>
                <input
                  type="radio"
                  name="caseConversion"
                  value="Open Surgery"
                  onChange={handleCaseConversion}
                />
                Open Surgery
              </label>
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Health Information</h4>
          <div className="input-group">
            <span>
              <label>Comorbidities</label>
              <input
                className="duration-number"
                type="text"
                value={comorbidities}
                onChange={(e) => {
                  setComorbidities(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Age</label>
              <input
                min={0}
                className="duration-number"
                type="number"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Weight (Kg)</label>
              <input
                min={0}
                className="duration-number"
                type="number"
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Height (cm)</label>
              <input
                min={0}
                className="duration-number"
                value={height}
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
                type="number"
              />
            </span>
            <span>
              <label>BMI</label>
              <input
                min={0}
                className="duration-number"
                type="text"
                value={bmi}
                disabled
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Admission & Surgery Information</h4>
          <div className="input-group">
            <span>
              <label>Date of Admission</label>
              <input
                value={DateofAdmission}
                type="date"
                onChange={(e) => {
                  setDateOfAdmission(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Date of Surgery</label>
              <input
                value={DateofSurgery}
                type="date"
                onChange={(e) => {
                  setDateOfSurgery(e.target.value);
                }}
              />
            </span>

            <span>
              <label>Patient In Time</label>
              <input
                type="datetime-local"
                value={PatientInTime}
                onChange={(e) => {
                  setPatientIntime(e.target.value);
                  if (
                    patientOutTime &&
                    new Date(e.target.value) > new Date(patientOutTime)
                  ) {
                    setShowPopupMessage( "Patient In Time cannot be later than Patient Out Time." );
                    setShowPopup(true);
                    //setPatientIntime("");
                  }
                }}
              />
            </span>
            <span>
              <label>Patient Out Time</label>
              <input
                type="datetime-local"
                value={patientOutTime}
                onChange={(e) => {
                  setPatientOutTime(e.target.value);
                  if (
                    PatientInTime &&
                    new Date(e.target.value) < new Date(PatientInTime)
                  ) {
                    setShowPopupMessage( "Patient Out Time cannot be earlier than Patient In Time." );
                    //setPatientOutTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Mantra System Operating Time</h4>
          <div className="input-group">
            <span>
              <label>System On Time</label>
              <input
                type="datetime-local"
                value={systemOnTime}
                onChange={(e) => {
                  setSystemOnTime(e.target.value);
                  if (
                    systemOfTime &&
                    new Date(e.target.value) > new Date(systemOfTime)
                  ) {
                    setShowPopupMessage( "System On Time cannot be later than System Off Time." );
                    //setSystemOnTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>System Off Time</label>
              <input
                type="datetime-local"
                value={systemOfTime}
                onChange={(e) => {
                  setSystemOfTime(e.target.value);
                  if (
                    systemOnTime &&
                    new Date(e.target.value) < new Date(systemOnTime)
                  ) {
                    setShowPopupMessage( "System Off Time cannot be earlier than System On Time." );
                    //setSystemOfTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={systemOperatingDuration}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Intubation</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={intubationStartTime}
                onChange={(e) => {
                  setIntubationStartTime(e.target.value);
                  if (intubationEndTime && e.target.value > intubationEndTime) {
                    setShowPopupMessage( "Intubation Start Time cannot be later than End Time." );
                    //setIntubationStartTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={intubationEndTime}
                onChange={(e) => {
                  setIntubationEndTime(e.target.value);
                  if (
                    intubationStartTime &&
                    e.target.value < intubationStartTime
                  ) {
                    setShowPopupMessage( "Intubation End Time cannot be earlier than Start Time." );
                    setShowPopup(true);
                    //setIntubationEndTime("");
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                value={intubationDuration}
                type="text"
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Draping</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={drappingStartTime}
                onChange={(e) => {
                  setDrappingStartTime(e.target.value);
                  if (drappingEndTime && e.target.value > drappingEndTime) {
                    setShowPopupMessage("Draping Start Time cannot be later than End Time.");
                    setDrappingStartTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={drappingEndTime}
                onChange={(e) => {
                  setDrappingEndTime(e.target.value);
                  if (drappingStartTime && e.target.value < drappingStartTime) {
                    setShowPopupMessage( "Draping End Time cannot be earlier than Start Time." );
                    setDrappingEndTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                value={drapingDuration}
                type="text"
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Surgery Time</h4>
          <div className="input-group">
            <span>
              <label>Patient Incision Time</label>
              <input
                type="datetime-local"
                value={patientIncisionTime}
                onChange={(e) => {
                  setPatinetIncisionTime(e.target.value);
                  if (
                    patientSkinClosureTime &&
                    new Date(e.target.value) > new Date(patientSkinClosureTime)
                  ) {
                    setShowPopupMessage( "Patient Incision Time cannot be later than Skin Closure Time." );
                    setPatinetIncisionTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Patient Skin Closure Time</label>
              <input
                type="datetime-local"
                value={patientSkinClosureTime}
                onChange={(e) => {
                  setPatientSkinClosureTime(e.target.value);
                  if (
                    patientIncisionTime &&
                    new Date(e.target.value) < new Date(patientIncisionTime)
                  ) {
                    setShowPopupMessage( "Patient Skin Closure Time cannot be earlier than Incision Time." );
                    setPatientSkinClosureTime("");
                   setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                value={surgeryDuration}
                type="text"
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Port Placement</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={portPlacementStartTime}
                onChange={(e) => {
                  setPorPlacementStartTime(e.target.value);
                  if (
                    portPlacementEndTime &&
                    e.target.value > portPlacementEndTime
                  ) {
                    setShowPopupMessage( "Port Placement Start Time cannot be later than End Time." );
                    setPorPlacementStartTime("");
                   setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={portPlacementEndTime}
                onChange={(e) => {
                  setPorPlacementEndTime(e.target.value);
                  if (
                    portPlacementStartTime &&
                    e.target.value < portPlacementStartTime
                  ) {
                    setShowPopupMessage( "Port Placement End Time cannot be earlier than Start Time." );
                    setPorPlacementEndTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={portPlacmentDuration}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Port Placement2</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={portPlacementStartTime2}
                onChange={(e) => {
                  setPorPlacementStartTime2(e.target.value);
                  if (
                    portPlacementEndTime2 &&
                    e.target.value > portPlacementEndTime2
                  ) {
                    setShowPopupMessage( "Port Placement2 Start Time cannot be later than End Time." );
                    setPorPlacementStartTime2("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={portPlacementEndTime2}
                onChange={(e) => {
                  setPorPlacementEndTime2(e.target.value);
                  if (
                    portPlacementStartTime2 &&
                    e.target.value < portPlacementStartTime2
                  ) {                   
                    setShowPopupMessage( "Port Placement2 End Time cannot be earlier than Start Time." );
                    setShowPopup(true);
                    setPorPlacementEndTime2("");
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={portPlacmentDuration2}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Docking</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={dockingStarTime}
                onChange={(e) => {
                  setDockingStartTime(e.target.value);
                  if (dockingEndTime && e.target.value > dockingEndTime) {
                    setShowPopupMessage("Docking Start Time cannot be later than End Time.");
                    setDockingStartTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={dockingEndTime}
                onChange={(e) => {
                  setDcokingEndTime(e.target.value);
                  if (dockingStarTime && e.target.value < dockingStarTime) {
                    setShowPopupMessage(
                      "Docking End Time cannot be earlier than Start Time."
                    );
                    setShowPopup(true);
                    setDcokingEndTime("");
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={dockingDuration}
              />
            </span>
          </div>
        </div>
        <div className="form-container">
          <h4>Docking2</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={dockingStarTime2}
                onChange={(e) => {
                  setDockingStartTime2(e.target.value);
                  if (dockingEndTime2 && e.target.value > dockingEndTime2) {
                    setShowPopupMessage( "Docking Start Time2 cannot be later than End Time2." );
                    setDockingStartTime2("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={dockingEndTime2}
                onChange={(e) => {
                  setDcokingEndTime2(e.target.value);
                  if (dockingStarTime2 && e.target.value < dockingStarTime2) {
                    setShowPopupMessage( "Docking End Time2 cannot be earlier than Start Time2." );
                    setDcokingEndTime2("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={dockingDuration2}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Console</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="datetime-local"
                value={consoleStartTime}
                onChange={(e) => {
                  setConsoleStartTime(e.target.value);
                  if (
                    consoleEndTime &&
                    new Date(e.target.value) > new Date(consoleEndTime)
                  ) {
                    setShowPopupMessage("Console Start Time cannot be later than End Time.");
                    setConsoleStartTime("");
                    setShowPopup(true);
                  } else if (consoleEndTime) {
                    setConsoleDuration( convertSecondsToHrMin( calculateTimeDifferenceInSeconds( e.target.value, consoleEndTime ) ) );
                  }
                }}
              />
            </span>

            <span>
              <label>End</label>
              <input
                type="datetime-local"
                value={consoleEndTime}
                onChange={(e) => {
                  setConsoleEndTime(e.target.value);
                  if (
                    consoleStartTime &&
                    new Date(e.target.value) < new Date(consoleStartTime)
                  ) {
                    setShowPopupMessage( "Console End Time cannot be earlier than Start Time." );
                    setConsoleEndTime("");
                    setShowPopup(true);
                  } else if (consoleStartTime) {
                    setConsoleDuration( convertSecondsToHrMin( calculateTimeDifferenceInSeconds( consoleStartTime, e.target.value ) ) );
                  }
                }}
              />
            </span>
            <span>
              <label>Interrupt (mins)</label>
              <input
                type="number"
                value={consoleInterruptTime}
                onChange={(e) => setConsoleInterruption(e.target.value)}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={consoleDuration}
              />
            </span>
            <span>
              <label>Remarks</label>
              <input
                type="text"
                value={consoleRemarks}
                onChange={(e) => setConsoleRemarks(e.target.value)}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Undock</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={unDockStartTime}
                onChange={(e) => {
                  setUndockStartTime(e.target.value);
                  if (unDockeEndTime && e.target.value > unDockeEndTime) {
                    setShowPopupMessage( "Undocking Start Time cannot be later than End Time." );
                   setUndockStartTime("");
                   setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={unDockeEndTime}
                onChange={(e) => {
                  setUndockEndTime(e.target.value);
                  if (unDockStartTime && e.target.value < unDockStartTime) {
                    setShowPopupMessage( "Undocking End Time cannot be earlier than Start Time." );
                    setUndockEndTime("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={unDockDuration}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Undock2</h4>
          <div className="input-group">
            <span>
              <label>Start</label>
              <input
                type="time"
                value={unDockStartTime2}
                onChange={(e) => {
                  setUndockStartTime2(e.target.value);
                  if (unDockeEndTime2 && e.target.value > unDockeEndTime2) {
                    setShowPopupMessage( "Undocking Start Time2 cannot be later than End Time2." );
                    setUndockStartTime2("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>End</label>
              <input
                type="time"
                value={unDockeEndTime2}
                onChange={(e) => {
                  setUndockEndTime2(e.target.value);
                  if (unDockStartTime2 && e.target.value < unDockStartTime2) {
                    setShowPopupMessage( "Undocking End Time2 cannot be earlier than Start Time2." );
                    setUndockEndTime2("");
                    setShowPopup(true);
                  }
                }}
              />
            </span>
            <span>
              <label>Duration</label>
              <input
                className="duration-number"
                disabled
                type="text"
                value={unDockDuration2}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Procedures</h4>
          <div className="input-group">
            <span>
              <label>Procedure Category</label>
              <select
                value={procedureCategory}
                onChange={(e) => {
                  onChangeProcedureCategory(e.target.value);
                  setSurgeryStepTimings([]);
                }}
              >
                <option key={""} value={""}>
                  Select Procedure Category
                </option>
                {ConVar.procedureCategory.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>Procedure Name</label>
              <select
                value={procedureCategory}
                onChange={(e) => {
                  setProcedureCategory(e.target.value);
                  onChangeProcedure(e.target.value);
                  setSurgeryStepTimings([]);
                }}
              >
                <option key={""} value={""}>
                  Select Procedure
                </option>
                {fitlererdProcedure.map((option, idx) => (
                  <option
                    key={option.label + idx}
                    value={option.procedure_code}
                  >
                    {option.procedure_name}({option.procedure_code})
                  </option>
                ))}
              </select>
            </span>
            <div className="input-group">
              <span>
                <label>Procedure Steps</label>
                <div className="custom-steps">
                  {surgerySteps.map((step, index) => (
                    <div key={index}>
                      <label>{step.label}</label>
                      <input
                        type="time"
                        value={surgeryStepTimings[step.key] || ""}
                        onChange={(e) =>
                          handleSurgeryStepTimingChange(
                            step.key,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="form-container">
          <h4>Instruments</h4>
          <div className="input-group">
            <div>
              <label>Instruments used</label>
              <textarea
                style={{ width: "350px" }}
                onChange={(e) => {
                  setTimeout(() => {
                    let val = e.target.value.trim();
                    const arr = val.split(",");
                    let finalArr = [];
                    arr.forEach((val) => {
                      let temp = {};
                      temp["instrument_used"] = val;
                      finalArr.push(temp);
                    });
                    setSelectedInstrumentList(finalArr);
                  }, 700);
                }}
              ></textarea>
              <span className="hint">
                Please fill like this (MCS72, FBF75, LND01, GPF62)
              </span>
            </div>
          </div>
          <div className="input-group">
            <span>
              <label>Issue</label>
              <textarea
                value={instrumentIssue}
                onChange={(e) => setInstrumentIssue(e.target.value)}
              ></textarea>
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>System Issues</h4>
          <div className="input-group">
            <span>
              <label>Sub Components</label>
              <input
                type="text"
                value={subComponentIssue}
                onChange={(e) => {
                  setSubComponentIssue(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Device Issue</label>
              <input
                type="text"
                value={deviceIssue}
                onChange={(e) => {
                  setDeviceIssue(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Device Patient Complications</label>
              <input
                type="text"
                value={devicePatientComplication}
                onChange={(e) => {
                  setDevicePatientComplications(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Remarks</label>
              <input
                type="text"
                value={systemIssueRemark}
                onChange={(e) => {
                  setSystemIssueRemark(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Intra-Operative Complications</label>
              <input
                type="text"
                value={intraOperatingComplications}
                onChange={(e) => {
                  setIntraOperatingComplications(e.target.value);
                }}
              />
            </span>
          </div>
        </div>

        <div className="form-container">
          <h4>Patient Post Surgery Information</h4>
          <div className="input-group">
            <span>
              <label>Date of Discharge</label>
              <input
                type="date"
                value={dateOfDischarge}
                onChange={(e) => {
                  setDateOfDischarge(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Length of Stay</label>
              <input
                className="duration-number"
                disabled
                type="number"
                value={lengthofStay}
              />
            </span>
            <span>
              <label>Readmission</label>
              <input
                type="checkbox"
                checked={Readmission}
                onChange={handleReAdmissionChange}
              />
            </span>
            <span>
              <label>Blood Transfusion (ml)</label>
              <input
                type="number"
                value={bloodTransfusion}
                onChange={(e) => {
                  setBloodTransfusion(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Post Discharge Complications</label>
              <input
                type="text"
                value={postDischargeComplication}
                onChange={(e) => {
                  setPostDiscahrgeComplication(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Clavien</label>
              <select
                value={clavien}
                onChange={(e) => {
                  setClavien(e.target.value);
                }}
              >
                <option value="">
                  Select Clavien Dindo Scale of Surgical Complications
                </option>
                {ConVar.typeOfClavien.map((complication) => (
                  <option key={complication.value} value={complication.value}>
                    {complication.label}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>Blood Loss (ml)</label>
              <input
                className="duration-number"
                type="number"
                value={bloodLoss}
                onChange={(e) => {
                  setBloodLoss(e.target.value);
                }}
              />
            </span>
            <span>
              <label>Mortality</label>
              <label>
                <input
                  type="radio"
                  name="mortality"
                  value="yes"
                  onChange={handleMortality}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="moratality"
                  value="no"
                  onChange={handleMortality}
                />
                No
              </label>
            </span>
          </div>
        </div>

        <div className="form-container">
          <button className="addproperty-button" onClick={submitSurgery}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcedureDetails: (callback) => fetchProcedureDetails(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
