export const PreOperationData = [
  {
    key: "patient_info",
    label: "Patient Information",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "serial_no",
        label: "Serial No",
        inputType: "text",
        noteTime: false,
        required: true,
      },
      {
        key: "case_no",
        label: "Case No",
        inputType: "text",
        noteTime: false,
        required: true,
      },
      {
        key: "patient_id",
        label: "Patient ID",
        inputType: "text",
        noteTime: false,
        required: true,
      },
      {
        key: "patient_name",
        label: "Patient Name",
        inputType: "text",
        noteTime: false,
        required: true,
      },
      {
        key: "gender",
        label: "Gender",
        inputType: "radio",
        options: ["Male", "Female", "Others"],
        noteTime: false,
        required: true,
      },
      {
        key: "age",
        label: "Age",
        inputType: "number",
        noteTime: false,
        required: true,
      },
      {
        key: "height",
        label: "Height (cm)",
        inputType: "number",
        noteTime: false,
        required: true,
      },
      {
        key: "weight",
        label: "Weight (Kg)",
        inputType: "number",
        noteTime: false,
        required: true,
      },
      {
        key: "bmi",
        label: "BMI",
        inputType: "text",
        noteTime: false,
        required: false,
      },
      {
        key: "diagnosis",
        label: "Diagnosis",
        inputType: "text",
        noteTime: false,
        required: true,
      },
    ],
  },
  {
    key: "present_clinical_people",
    label: "Present Clinical Service Members",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "present_service_people",
    label: "Present Field Service Members",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "co_morbidities",
    label: "Co-morbidities",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "date_of_admission",
    label: "Date of Admission",
    inputType: "date",
    noteTime: false,
    required: true,
  },
  {
    key: "date_of_surgery",
    label: "Date of Surgery",
    inputType: "date",
    noteTime: false,
    required: true,
  },
  {
    key: "zone_id",
    label: "Zone",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "hospital_name",
    label: "Hospital Name",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "system_no",
    label: "System No",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "surgeon_name",
    label: "Surgeon Name",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "assistant_surgeon",
    label: "Assistant Surgeon",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "procedure_category",
    label: "Procedure Category",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "procedure_code",
    label: "Procedure Code",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "surgery_specification",
    label: "Surgery Specification",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "patient_in_time",
    label: "Patient In Time",
    inputType: "datetime-local",
    noteTime: true,
    required: true,
  },
];

////////////////////////////////////////////////////////////////////////////////////////////////

export const MantraOperationData = [
  {
    key: "mantra_system_operating_info",
    label: "Mantra System Operating Info",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "on_time",
        label: "Mantra System Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
    ],
  },
  {
    key: "no_of_arms",
    label: "No. of ARM Setup",
    inputType: "radio",
    options: ["3 ARM", "4 ARM", "5 ARM"],
    noteTime: false,
    required: true,
  },
  {
    key: "patient_intubation",
    label: "Intubation",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "Intubation Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "Intubation End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "Intubation Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "drapping",
    label: "Draping",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "Draping Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "Draping End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "Draping Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "port_placement",
    label: "1st Port Placement",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "1st Port Placement Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "1st Port Placement End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "1st Port Placement Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "second_port_placement",
    label: "2nd Port Placement",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "2nd Port Placement Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "2nd Port Placement End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "2nd Port Placement Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "dock",
    label: "1st Dock",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "1st Dock Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "1st Dock End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "1st Dock Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "second_dock",
    label: "2nd Dock",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "2nd Dock Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "2nd Dock End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "2nd Dock Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "console",
    label: "Console",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "Console Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
    ],
  },
  {
    key: "surgery",
    label: "Surgery",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "Surgery Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
    ],
  },
  // {
  //   key: "surgery_steps",
  //   label: "Surgery Steps",
  //   inputType: "text",
  //   noteTime: true,
  //   required: true,
  // },
  {
    key: "instruments",
    label: "Instruments",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      // {
      //   key: "instrument_category",
      //   label: "Instrument Category",
      //   inputType: "text",
      //   noteTime: false,
      //   required: true,
      // },
      // {
      //   key: "instrument_used",
      //   label: "Instruments Used",
      //   inputType: "text",
      //   noteTime: false,
      //   required: true,
      // },
      // {
      //   key: "instrument_id",
      //   label: "Instruments ID",
      //   inputType: "text",
      //   noteTime: false,
      //   required: true,
      // },
      // {
      //   key: "instrument_issues",
      //   label: "Instruments Issues",
      //   inputType: "text",
      //   noteTime: false,
      //   required: true,
      // },
    ],
  },
  {
    key: "blood_transfusion",
    label: "Blood Transfusion (ml)",
    inputType: "number",
    noteTime: false,
    required: true,
  },
  {
    key: "mortality",
    label: "Mortality",
    inputType: "radio",
    options: ["Yes", "No"],
    noteTime: false,
    required: true,
  },
  {
    key: "surgery",
    label: "Surgery",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "end",
        label: "Surgery End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "Surgery Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "case_conversion",
    label: "Case Conversion",
    inputType: "radio",
    options: ["No Conversion", "Laparoscopic", "Open Surgery"],
    noteTime: false,
    required: true,
  },
  {
    key: "blood_loss",
    label: "Blood Loss (ml)",
    inputType: "number",
    noteTime: false,
    required: true,
  },
  {
    key: "intra_operative_complications",
    label: "Intra Operative Complications",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "console",
    label: "Console",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "end",
        label: "Console End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "Console Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
      {
        key: "console_interrupt",
        label: "Console Interrupt",
        inputType: "text",
        noteTime: false,
        required: false,
      },
      {
        key: "console_interrupt_remark",
        label: "Console Interrupt Remarks",
        inputType: "text",
        noteTime: false,
        required: false,
      },
    ],
  },
  {
    key: "undock",
    label: "1st Undock",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "1st Undock Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "1st Undock End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "1st Undock Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "second_undock",
    label: "2nd Undock",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "start",
        label: "2nd Undock Start",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "end",
        label: "2nd Undock End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "2nd Undock Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "mantra_system_operating_info",
    label: "Mantra System Operating Info",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "off_time",
        label: "Mantra System End",
        inputType: "datetime-local",
        noteTime: true,
        required: true,
      },
      {
        key: "duration",
        label: "Mantra System Duration",
        inputType: "text",
        noteTime: true,
        required: false,
      },
    ],
  },
  {
    key: "patient_out_time",
    label: "Patient Out Time",
    inputType: "datetime-local",
    noteTime: true,
    required: true,
  },
  {
    key: "system_issues",
    label: "System Issues",
    subType: "group",
    noteTime: false,
    required: false,
    subMenu: [
      {
        key: "sub_components",
        label: "Sub Components",
        inputType: "text",
        noteTime: false,
        required: false,
      },
      {
        key: "devices_issues",
        label: "Device Issues",
        inputType: "text",
        noteTime: false,
        required: false,
      },
      {
        key: "device_patient_complications",
        label: "Device Patient Complications",
        inputType: "text",
        noteTime: false,
        required: false,
      },
    ],
  },
];

////////////////////////////////////////////////////////////////////////////////////////////////

export const PostOperationData = [
  {
    key: "post_surgery_info",
    label: "Post-Surgery Information",
    subType: "group",
    noteTime: false,
    required: true,
    subMenu: [
      {
        key: "date_of_discharge",
        label: "Date of Discharge",
        inputType: "date",
        noteTime: false,
        required: true,
      },
      {
        key: "length_of_stay_days",
        label: "Length of Stay (Days)",
        inputType: "number",
        noteTime: false,
        required: false,
      },
      {
        key: "readmission",
        label: "Readmission",
        inputType: "radio",
        options: ["Yes", "No"],
        noteTime: false,
        required: true,
      },
    ],
  },
  {
    key: "clavien",
    label: "Clavien-Dindo Classification",
    inputType: "text",
    noteTime: false,
    required: true,
  },
  {
    key: "post_discharge_complications",
    label: "Post-Discharge Complications",
    inputType: "text",
    noteTime: false,
    required: true,
  },
];
