// frontend/src/components/AddUser.js
import React, { useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq } from "../../util/request";
import { Dialog, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import "../../css/AddUser.css";
import "../../css/CommonStyle.css";

const AddUser = (props) => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usertype, setUserType] = useState("");
  const [emp_id, setEmpId] = useState("");
  const [emp_email, setEmpEmail] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const handleSubmit = async () => {
    if (username == "") {
      setWarning("Username cannot be empty.");
      return null;
    }
    if (password == "") {
      setWarning("Password cannot be empty.");
      return null;
    }
    if (usertype == "") {
      setWarning("Usertype cannot be empty.");
      return null;
    }
    if (emp_id == "") {
      setWarning("Employee ID cannot be empty.");
      return null;
    }
    if (emp_email == "") {
      setWarning("Employee email cannot be empty.");
      return null;
    }

    let add_user_url = config.baseUrl + config.ver + config.userNew;
    // console.log(add_user_url);

    httpPostReq(
      add_user_url,
      {
        name: username,
        user_type: usertype,
        emp_id: emp_id,
        description: "",
        password: password,
        emp_email: emp_email,
      },
      "application/json",
      (response) => {
        console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setWarning("");
          setMessage("User Added successfully.");
          setOpen(false);
          window.location.reload();
          return null;
        } else if (
          response.data &&
          response.data.status &&
          response.data.status === "fail"
        ) {
          setMessage("");
          setWarning(response.data.message);
          return null;
        }
      }
    );
  };

  const SelectBox = ({ lable_, options, value_, onChange }) => {
    return (
      <select
        label={lable_}
        onChange={onChange}
        value={value_}
        className="addproperty-select"
      >
        <option key={""} value={""}> Select User Type </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  const UserTypeOptions = ConVar.userTypes.map((obj) => {
    return { value: obj.value, label: obj.label };
  });

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddUserModal(false);
    }
  };

  return (
    <>
      <button
        className="add-data-button"
        
        onClick={() => setOpenAddUserModal(true)}
      >
        <PersonAddAltRoundedIcon />
        <span style={{ marginLeft: "10px" }}>Add User</span>
      </button>
      <Dialog open={openAddUserModal} onClose={handleClose} className="addproperty-dialog" >
        {warning && <div className="error-message">{warning}</div>}
        {message && <div className="success-message">{message}</div>}
        <div className="addproperty-container">
          <div className="addproperty-form-title">Add User</div>
          <div className="addproperty-form">
            <div fullWidth className="addproperty-form-control">
              <label>Profile Name</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Full Profile Name"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div fullWidth className="addproperty-form-control">
              <label>Select User Type</label>
              <SelectBox
                placeholder="Select User Type"
                options={UserTypeOptions}
                value_={usertype}
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              />
            </div>
            <div fullWidth className="addproperty-form-control">
              <label>Employee ID</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="SSI-000"
                value={emp_id}
                onChange={(e) => setEmpId(e.target.value)}
              />
            </div>
            <div fullWidth className="addproperty-form-control">
              <label>Employee Email</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Employee Email"
                value={emp_email}
                onChange={(e) => setEmpEmail(e.target.value)}
              />
            </div>
            <div fullWidth className="addproperty-form-control">
              <label>Password</label>
              <input
                className="addproperty-text-field"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="addproperty-dialog-action">
            <button
              type="button"
              className="addproperty-button"
              onClick={handleSubmit}
            >
              Add
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddUser;
