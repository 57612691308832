import cookie from "react-cookies";
import CryptoJS from "crypto-js";
import { httpPostReqNew } from "../util/request";
import config from "../config/configUrl";
import "../css/util.css";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import CurtainsClosedRoundedIcon from "@mui/icons-material/CurtainsClosedRounded";
import CurtainsRoundedIcon from "@mui/icons-material/CurtainsRounded";

// Function to save cookie
export function saveCookie(cookieName, cookieValue) {
  cookie.save(cookieName, cookieValue);
}

// Function to remove cookie
export function ClearCookie(cookieName) {
  cookie.remove(cookieName);
}

// Function to remove cookie
export function convertSecondToHrMinute(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  return formattedTime;
}

// export function millisecondsToTime(milliseconds) {

//   var seconds = Math.floor(milliseconds / 1000);
//   var hours = Math.floor(seconds / 3600);
//   seconds %= 3600;
//   var minutes = Math.floor(seconds / 60);
//   var remainingSeconds = seconds % 60;

//   let time = hours + ":" + minutes + ":" + remainingSeconds;
//   return time;
// }

export function millisecondsToTime(milliseconds) {
  var seconds = Math.floor(milliseconds / 1000);
  var hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  var formattedHours = hours < 10 ? "0" + hours : hours;
  var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  var formattedSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  let time = formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  return time;
}

export function getOS() {
  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  if (navigator.appVersion.indexOf("Android") != -1) OSName = "Android";
  if (navigator.appVersion.indexOf("like Mac") != -1) OSName = "iOS";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "Mac/iOS";
  return OSName;
}

// Function to load cookie
export function loadCookie(cookieName) {
  return cookie.load(cookieName);
}
export function numberToMonthName(a) {
  let b = "";
  switch (a) {
    case 1:
      b = "Jan";
      break;
    case 2:
      b = "Feb";
      break;
    case 3:
      b = "Mar";
      break;
    case 4:
      b = "Apr";
      break;
    case 5:
      b = "May";
      break;
    case 6:
      b = "Jun";
      break;
    case 7:
      b = "Jul";
      break;
    case 8:
      b = "Aug";
      break;
    case 9:
      b = "Sep";
      break;
    case 10:
      b = "Oct";
      break;
    case 11:
      b = "Nov";
      break;
    case 12:
      b = "Dec";
      break;
    default:
      break;
  }
  return b;
}

// Function for data encryption
function encryptObject(obj, key) {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), key).toString();
  return ciphertext;
}
export const acitivityLog = async (obj) => {
  //console.log('log will');
  // let activityLogUrl = config.baseUrl + config.ver + config.activityLog;
  // const encryptedObject = encryptObject(obj, process.env.REACT_APP_SECRET_KEY);
  // httpPostReqNew(
  //     activityLogUrl,
  //     {data: encryptedObject},
  //     "application/json",
  //     (response) => {
  //        console.log('Log  ==>',response);
  //     }
  // );
};

// Function to format week name
export const weekName = (label) => {
  if (label.includes("-")) {
    const week = label.split("-")[1].replace("W", "Week ");
    const year = label.split("-")[0];
    label = `${week} - ${year}`;
  }
  return label;
};

// Function to get task status
export function getTaskStatus(status) {
  const statusMap = {
    1: ( <button className="status-badge Assigned" title="Assigned"> <AssignmentIndRoundedIcon /> </button> ),
    2: ( <button className="status-badge Open" title="Open"> <CurtainsRoundedIcon /> </button> ),
    3: ( <button className="status-badge Closed" title="Closed"> <CurtainsClosedRoundedIcon /> </button> ),
    default: ( <button className="status-badge Closed" title="Closed"> <CurtainsClosedRoundedIcon /> </button> ),
  };
  return statusMap[status] || statusMap.default;
}

// Function to get call status
export function getCallStatus(status) {
  const statusMap = {
    1: "Generated",
    2: "Open",
    3: "Closed",
    default: "Pending",
  };
  return statusMap[status] || statusMap.default;
}

// Function to get task status for engineer
export function getTaskStatusEngineer(status) {
  const statusMap = {
    1: "not-started",
    2: "started",
    3: "completed",
    default: "pending",
  };
  return statusMap[status] || statusMap.default;
}

export function convertSecondsToHrMin(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const result = `${hours} : ${minutes} `;

  return result;
}

export function calculateDayDifference(dateOfAdmission, dateOfDischarge) {
  const admissionDate = new Date(dateOfAdmission);
  const dischargeDate = new Date(dateOfDischarge);
  const differenceInMs = dischargeDate - admissionDate;
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return Math.floor(differenceInDays);
}

export function convertMillisecondsToHrMin(totalMilliseconds) {
  const totalSeconds = totalMilliseconds / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const result = `${hours} : ${minutes} `;

  return result;
}

export function millisecondsToTime2 (milliseconds) {
  let totalSeconds = milliseconds / 1000;
  let hour = Math.floor(totalSeconds / 3600);
  let minute = Math.floor((totalSeconds % 3600) / 60);
  hour = hour < 10 ? "0" + hour : hour;
  minute = minute < 10 ? "0" + minute : minute;
  return hour + ":" + minute;
};

export function getTimeDifferenceInMs(time1, time2) {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);
  const totalMilliseconds1 = hours1 * 3600000 + minutes1 * 60000;
  const totalMilliseconds2 = hours2 * 3600000 + minutes2 * 60000;
  const differenceInMs = Math.abs(totalMilliseconds2 - totalMilliseconds1);

  return differenceInMs;
}

// Function to convert timestamp to date time
export function convertTimestampToDateTime(timestamp) {
  const date = new Date(timestamp);
  const formattedDateTime = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  return formattedDateTime;
}

// Function to convert timestamp to date time
export function convertTimestampToDate(timestamp) {
  if (timestamp >= 1000000000000) {
    const date = new Date(timestamp);
    const formattedDateTime = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} `;
    return formattedDateTime;
  } else {
    const date = new Date(timestamp * 1000);
    const formattedDateTime = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} `;
    return formattedDateTime;
  }
}

// Function to find object by key value in array
export function findObjectByKeyValue(array, key, value) {
  return array.find((obj) => obj[key] === value);
}

// Function to check if value is undefined, null, or empty
export function isUBN(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    value === "undefined"
  );
}
