import React, { useEffect, useState } from "react";
import { convertTimestampToDate, getTaskStatus } from "../util/util";
import { httpGetReq } from "../util/request";
import config from "../config/configUrl";

const TaskDetail = (props) => {
  const TaskDetail = props.data;
  const [taskInfo, setTaskInfo] = useState({});

  useEffect(() => {
    const getTaskDetail = () => {
      let url = config.baseUrl + config.ver + config.taskDetail + TaskDetail._id;
      httpGetReq(url, "application/json", function (response) {
        if (response) {
          if (
            response.data &&
            response.data.status &&
            response.data.status === "success"
          ) {
            setTaskInfo(response.data.data);
          }
        }
      });
    };
    getTaskDetail();
    return () => {};
  }, [TaskDetail]);

  return (
    <div className="inner-details-expand-styling">
      {/* <label> <strong>Name : </strong> {taskInfo.task_name} </label> */}
      <label> <strong>Assigned Time : </strong> {convertTimestampToDate(taskInfo.assign_time)} </label>
      <label> <strong>Hospital : </strong> {taskInfo.hospital_name} </label>
      <label> <strong>Status:</strong> {getTaskStatus(taskInfo.status)} </label>
      <label> <strong>Sub System : </strong> {taskInfo.sub_system} </label>
      <label> <strong>Assigned By : </strong> {taskInfo.updatedBy} </label>
      {taskInfo.actions && (
        <div>
          {taskInfo.actions.map((actn) => (
            <>
              <pre>{JSON.stringify(actn)}</pre>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskDetail;
