import React, { useState, useEffect } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq, httpGetReq } from "../../util/request";
import { findObjectByKeyValue } from "../../util/util";

import "../../css/AddTask.css";
import "../../css/CallGenerate.css";
import "../../css/AddUser.css";
import "../../css/CommonStyle.css";
import ReactSelect from "react-select";
import { Dialog } from "@mui/material";

const AddTask = (props) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(
    props.isFromConvertCallToTask ? "Convert Call to Task" :
    props.isFromAssignMaintenance ? "Schedule Maintenance" :
    props.title
  );
  const [taskname, setTaskname] = useState(
    (props.isFromAssignMaintenance || props.isFromConvertCallToTask) ? props.data.call_name : null
  );  
  const [taskType, setTaskType] = useState( props.data && props.data.taskType ? props.data.taskType : null );;
  const [mainComponent, setMainComponent] = useState(null);
  const [hospital_name, setHospitalName] = useState( props.data && props.data.system_no ? props.data.system_no : "" );
  const [subComponentList, setSubComponentList] = useState([]);
  const [hospitalMap, setHospitalMap] = useState({});
  const [engineer_name, setEngineerName] = useState([]);
  const [hospital_list, setHospitalList] = useState([]);
  const [enginer_list, setEngineerList] = useState([]);
  const [subSystem, setSubSystem] = useState(null);
  const [systemNo, setSystemNo] = useState(null);
  const [extraDesc, setExtraDesc] = useState( props.data && props.data.extra_desc ? props.data.extra_desc : null );
  const [call_id_, setCall_id_] = useState( props.data && props.data.call_id ? props.data.call_id : null );

  const [error, setError] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [taskID, setTaskID] = useState("");

  let taskCreateUrl = config.baseUrl + config.ver + config.taskNew;
  let taskListUrl = config.baseUrl + config.ver + config.taskList;
  let taskAssignUrl = config.baseUrl + config.ver + config.taskAssign;

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddUserModal(false);
    }
  };

  const getHospitalList = () => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?limit=200";
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let map = {};
        response.data.data.forEach((hospital) => {
          map[hospital.system_no] = {
            name: hospital.name,
            area_zone: hospital.area_zone,
            system_no: hospital.system_no,
            location: hospital.location,
          };
        });
        setHospitalMap(map);
        setHospitalList(response.data.data);
      }
    });
  };

  const getServiceEngineerList = () => {
    let url = config.baseUrl + config.ver + config.userList + "?user_type=service-engineer&limit=200";
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setEngineerList(response.data.data);
      }
    });
  };

  const onChangeMainComponet = (arg) => {
    setSubComponentList(ConVar.mainComponent[arg]);
  };

  const handleSubmit = async () => {
    let hospitlal_ = hospitalMap[hospital_name];
    let postBody = {
      task_name: taskname,
      task_type: taskType,
      assigned: engineer_name,
      system_no: hospital_name,
      area_zone: hospitlal_.area_zone,
      hospital_name: hospitlal_.name,
      sub_system: subSystem,
      assign_time: Date.now(),
      last_updated: Date.now(),
      status: 1,
      call_id: call_id_,
    };

    httpPostReq(taskCreateUrl, postBody, "application/json", (response) => {
      if (response.data.status === "fail") {
        setError("Invalid taskname or password");
      } else if (response.data.status === "success") {
        httpGetReq(taskListUrl, "application/json", (response) => {
          if (
            response.data &&
            response.data.status &&
            response.data.status === "success"
          ) {
            const tasks = response.data.data;
            if (call_id_ !== undefined) {
              let urlconvert = config.baseUrl + config.ver + config.callToTask + call_id_;
              httpGetReq(urlconvert, "application/json", function (response) {
                if (
                  response.data &&
                  response.data.status &&
                  response.data.status === "success"
                ) {
                  window.location.reload(true);
                }
              });
            }
            const task = tasks.find((task) => task.task_name === postBody.task_name);
            if (task) {
              setTaskID(task._id);
            }
          }
        });
        props.close();
        
      }
    });
  };

  useEffect(() => {
    if (taskID && engineer_name.length > 0) {
      engineer_name.forEach((engineer) => {
        let postBody = {
          emp_email: engineer.value,
        };

        let assignUrl = `${taskAssignUrl}/${taskID}`;
        httpPostReq(assignUrl, postBody, "application/json", (response) => {
        });
      });
    }
  }, [taskID, engineer_name]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getHospitalList();
      getServiceEngineerList();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (props.isFromAssignMaintenance && hospital_name && hospitalMap[hospital_name]) {
      const hospital = hospitalMap[hospital_name];
      const taskName = `${hospital.name}_${mainComponent}_${hospital.system_no}`;
      setTaskname(taskName);
    }
  }, [props.isFromAssignMaintenance, hospital_name, mainComponent, hospitalMap]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        className="addproperty-dialog"
      >
        {warning && <div className="error-message">{warning}</div>}
        {message && <div className="success-message">{message}</div>}
        <div className="addproperty-container">
          <div className="addproperty-form-title">{title}</div>
          <div className="addproperty-form">
            <div className="addproperty-form-control">
              <label>Task Name</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Task Name"
                variant="outlined"
                value={taskname}
                disabled={props.isFromAssignMaintenance || props.isFromConvertCallToTask}
                onChange={(e) => setTaskname(e.target.value)}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Select Task Type</label>
              <select
                label="Select Task Type"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
                disabled={props.data && props.data.taskType ? true : false}
                className="addproperty-select"
              >
                <option key={""} value={""}>
                  Select Task Type
                </option>
                {ConVar.taskTypes.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Select Main Component</label>
              <select
                label="Select Main Component"
                value={mainComponent}
                onChange={(e) => {
                  setMainComponent(e.target.value);
                  onChangeMainComponet(e.target.value);
                }}
                className="addproperty-select"
              >
                <option key={""} value={""}>
                  Select Main Component
                </option>
                {ConVar.mainComponent.main_component_list.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              {mainComponent === "psac" && (
                <>
                  <label htmlFor="selectCart">Select Cart</label>
                  <select
                    label="Select Cart"
                    value={subSystem}
                    onChange={(e) => {
                      setSubSystem(e.target.value);
                    }}
                    className="addproperty-select"
                  >
                    <option key={""} value={""}>
                      Select Cart
                    </option>
                    {ConVar.mainComponent.cart_list.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
            <div className="addproperty-form-control">
              <label>Select Sub System</label>
              <select
                label="Select Sub System"
                value={subSystem}
                onChange={(e) => {
                  setSubSystem(e.target.value);
                }}
                className="addproperty-select"
              >
                <option key={""} value={""}>
                  Select Sub System
                </option>
                {ConVar.subSystems.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Select Hospital</label>
              <select
                label="Select Hospital"
                value={hospital_name}
                onChange={(e) => {
                  setHospitalName(e.target.value);
                }}
                disabled={props.data && props.data.system_no ? true : false}
                className="addproperty-select"
              >
                <option key={""} value={""}>
                  Select Hospital
                </option>
                {hospital_list.map((option) => (
                  <option key={option.system_no} value={option.system_no}>
                    {option.name}, {option.location}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Select Engineer</label>
              <ReactSelect
                className="addproperty-multiselect"
                onChange={(selectedOptions) => {
                  setEngineerName(selectedOptions);
                }}
                value={engineer_name}
                isMulti
                options={enginer_list.map((option) => ({
                  label: option.name,
                  value: option.emp_email,
                }))}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Extra Comment Desc.</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Extra Comment Desc."
                value={extraDesc}
                onChange={(e) => {
                  setExtraDesc(e.target.value);
                }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="addproperty-dialog-action">
            <button type="button" className="addhospital-button" onClick={handleSubmit} > Add Task </button>
            <button type="button" className="cancel-button" onClick={() => { props.close(); }} > Cancel </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddTask;
