import React, { useState } from "react";
import { connect } from "react-redux";
import { Collapse } from "@mui/material";
import "../../css/HospitalsContainer.css";
import { ConVar } from "../../config/configVar";
import HospitalDetail from "../../components/hospital/hospitalDetail";
import Loader from "../../components/Loader";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const HospitalTable = (props) => {
  const { data } = props;
  const [openDetail, setOpenDetail] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [expandedDetailRows, setExpandedDetailRows] = useState({});

  const showDetailIndex = (index) => {
    setOpenDetail((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const toggleRow = (rowId, type) => {
    if (type === "detail") {
      setExpandedDetailRows((prevExpandedDetailRows) => ({
        ...prevExpandedDetailRows,
        [rowId]: !prevExpandedDetailRows[rowId],
      }));
    }
  };

  return (
    <div className="property-list-container">
      <table className="property-table">
        <thead>
          <tr>
            <th>System Number</th>
            <th>Hospital name</th>
            <th>Area zone</th>
            <th>Location</th>
            {/* <th>Installed Date</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="property-table-body">
          {data.map((row, index) => (
            <React.Fragment key={row._id}>
              <tr onClick={() => showDetailIndex(index)}>
                <td className="r10start">{row.system_no}</td>
                <td className="r20">{row.name}</td>
                {/*({row.install_index ? row.install_index:''})*/}
                <td className="r20"> {row.area_zone ? ConVar.hospitalZoneAssoArr[row.area_zone] : ""} {" (" + row.area_zone + ") "} </td>
                <td className="r20"> {row.location ? row.location[0].toUpperCase() + row.location.slice(1) : ""} {" , "} {row.state ? row.state[0].toUpperCase() + row.state.slice(1) : ""} </td>
                {/* <td className="hr5">{row.mantra_system &&  row.mantra_system[0] && row.mantra_system[0].system_initial_install_date ?  new Date(row.mantra_system[0].system_initial_install_date).toLocaleDateString() : ''} {new Date(row.installed_date).toLocaleDateString()}  </td> */}
                {/* <td className="hr5">{ row.installed_date ? new Date(row.installed_date).toLocaleDateString() : ''}  </td> */}
                {/* <td className="hr6">
                  <button onClick={() => showDetailIndex(index)}>
                    {openDetail === index ? (
                      <ExpandLessRoundedIcon />
                    ) : (
                      <ExpandMoreRoundedIcon />
                    )}
                  </button>
                </td> */}
                <td className="r20end">
                  <button onClick={() => toggleRow(row._id, "detail")}>
                    {expandedDetailRows[row._id] ? (
                      <ExpandLessIcon className="chevron-right-icon" />
                    ) : (
                      <ExpandMore className="expand-more-icon" />
                    )}
                  </button>
                </td>
              </tr>
              {expandedDetailRows[row._id] && (
                <tr>
                  <td colspan="8" className="inner-property-expand">
                    <HospitalDetail hospitalInfo={row} />
                  </td>
                </tr>
              )}
              {/* {openDetail === index && (
                <tr className="inner-expanded-row">
                  <td colSpan="6" className="inner-property-expand">
                    <Collapse in={openDetail === index}> 
                      <HospitalDetail hospitalInfo={row} />
                    </Collapse>
                  </td>
                </tr>
              )} */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
  hospitals: state.hospitals,
});

export default connect(mapStateToProps)(HospitalTable);
