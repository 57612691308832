import React, { useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpPostReq } from "../../util/request";
import { Dialog } from "@mui/material";
import "../../css/AddInstrument.css";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";

const SendToScrap = (props) => {
  const [open, setOpen] = useState(props.show_state);
  const [error, setError] = useState("");
  const [reason, setReason] = useState();
  const [description, setDescription] = useState();
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    let sendToScrapUrl = config.baseUrl + config.ver + config.sendScrap + props.longId;
    let postobj = {
      reason: reason,
      description: description,
      last_updated: Date.now(),
    };
    httpPostReq(sendToScrapUrl, postobj, "application/json", (response) => {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setWarning("");
        setMessage(" Transferred to Scrap successfully.");
        setOpen(false);
        return null;
      } else if (
        response.data &&
        response.data.status &&
        response.data.status === "fail"
      ) {
        setMessage(response.data.message);
        setWarning("");
        return null;
      }
    });
  };

  const handleShowModal = () => {
    setOpen(true);
  };

  const handleShowHide = () => {
    setOpen(!open);
  };

  return (
    <>
      <button className="red-button" title="Send To Scrap" onClick={handleShowModal}>
        <RestoreFromTrashRoundedIcon />
      </button>
      <Dialog
        open={open}
        onClose={handleShowHide}
        className="addproperty-dialog"
      >
        <div className="addproperty-container">
          <div className="addproperty-form-title">Send for Scrap</div>
          <div className="addproperty-form">
            {warning && <div className="error-message">{warning}</div>}
            {message && <div className="success-message">{message}</div>}
            <div className="addproperty-form-control">
              <label>Issue</label>
              <select
                className="addproperty-select"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              >
                <option value={""}>Select Reason</option>
                {/* <option value={1}>{"Break"}</option>
                <option value={2}>{"Exhausted"}</option> */}
                {ConVar.sendToScrap.map((inst) => (
                  <option value={inst.value}>{inst.label}</option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Description</label>
              <input
                className="addproperty-form-textarea-normal"
                type="text"
                placeholder="Description"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="addproperty-dialog-action">
            <button className="addproperty-button" onClick={handleSubmit}>
              Send for Scrap
            </button>
            <button className="cancel-button" onClick={handleShowHide}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SendToScrap;
