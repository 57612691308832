import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { httpGetReq, httpPostReq } from "../../util/request";
import config from "../../config/configUrl";
import "../../css/AddSurgeon.css";
import "../../css/CommonStyle.css";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";

const AddSurgeon = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [surgeonName, setSurgeonName] = useState("");
  const [areaExpertise, setAreaExpertise] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalMap, setHospitalMap] = useState({});
  
  const surgeonAddUrl = config.baseUrl + config.ver + config.surgeonNew;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getHospitalList();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getHospitalList = () => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?limit=200";
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let map = {};
        response.data.data.forEach((hospital) => {
          map[hospital.system_no] = {
            name: hospital.name,
            area_zone: hospital.area_zone,
            system_no: hospital.system_no,
            location: hospital.location,
          };
        });
        setHospitalMap(map);
        setHospitalList(response.data.data);
        }
    });
  };


  const handleSubmit = async () => {
    console.log("Submitting...");
    if (surgeonName == null || surgeonName == undefined || surgeonName == "") {
      setError("Give Surgeon name :)");
      return false;
    } else if (hospitalName == null || hospitalName == '') {
      setError("Select hospital :)");
      return false;
    }else if(areaExpertise == null || areaExpertise == ''){
      setError("Fill expertise :)");
      return false;
    }
    let postBodyObj = {
      surgeon_name: surgeonName,
      location: hospitalMap[hospitalName] && hospitalMap[hospitalName].location ? hospitalMap[hospitalName].location : "",
      area_zone: hospitalMap[hospitalName] && hospitalMap[hospitalName].area_zone ? hospitalMap[hospitalName].area_zone : "",     
      system_no: hospitalName,
      aligned_hospitals: hospitalMap[hospitalName] && hospitalMap[hospitalName].name ? hospitalMap[hospitalName].name : "", 
      area_of_expertise: areaExpertise,
    };
    httpPostReq(surgeonAddUrl, postBodyObj, "application/json", (response) => {
      console.log("Response:", response);
      if (response.code === "ERR_BAD_REQUEST") {
        setError("Unable to add surgeon. Try again later :)");
      } else if (response && response.data && response.data.status === "fail") {
        setError("Unable to add surgeon. Try again later :)");
      } else if (response.data.status === "success") {
        setShowModal(false);
        setSurgeonName("");
        setAreaExpertise("");
        setHospitalName("");
      }
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = (event, reason) => {
    setError("");
    setSurgeonName("");
    setAreaExpertise("");
    setHospitalName("");
    if (reason !== "backdropClick") {
      setShowModal(false);
    }
  };

  return (
    <div className="add-data-button-container">
      <button className="add-data-button"  onClick={handleShowModal}>
        <GroupAddRoundedIcon />
        <span style={{ marginLeft: "10px" }}>Add Surgeon</span>
      </button>
      <Dialog open={showModal} onClose={handleCloseModal} className="addproperty-dialog" >
        <div className="addproperty-container">
          <div className="addproperty-form-title">Add Surgeon</div>
          <div className="addproperty-form">
            <div className="addproperty-form-control">
              <label>Surgeon Name</label>
              <input
                type="text"
                placeholder="Surgeon Name"
                value={surgeonName}
                onChange={(e) => setSurgeonName(e.target.value)}
                className="addproperty-text-field"
              />
            </div>
            <div fullWidth className="addproperty-form-control">
              <label>Select Hospital</label>
              <select
                className="addproperty-select"
                value={hospitalName}
                onChange={(e) => setHospitalName(e.target.value)}
              >
                <option key={""} value={""}> Select Hospital </option>
                {hospitalList.map((option) => (
                  <option key={option._id} value={option.system_no}>
                    {option.name}-{option.location ? option.location:''}
                  </option>
                ))}
              </select>
            </div>
            <div className="addproperty-form-control">
              <label>Area Of Expertise</label>
              <input
                type="text"
                placeholder="Area of Expertise"
                value={areaExpertise}
                onChange={(e) => setAreaExpertise(e.target.value)}
                className="addproperty-text-field"
              />
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
          <div className="addproperty-dialog-action">
            <button className="addproperty-button" onClick={handleSubmit}>
              Add Surgeon
            </button>
            <button className="cancel-button" onClick={handleCloseModal}>
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddSurgeon;
