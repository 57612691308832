import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import { Collapse } from "@mui/material";
import { convertTimestampToDate } from "../../util/util";
import Pagination from "@mui/material/Pagination";
import DetailInstrument from "../../components/mudracomponents/instrumentDetail";

import "../../css/User.css";
import Lock from "../../components/Lock";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";

export const DiscardedListing = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [surgeonList, setSurgeonList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstrumentId, setSelectedInstrumentId] = useState(null);
  const [openDetail, setOpenDetail] = useState(-1);
  const [nameList, setNameList] = useState([]);
  const [isReset, setIsReset] = useState(null);
  const [instrumentCategory, setInstrumentCategory] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [productionYear, setProductionYear] = useState(null);
  const [productionMonth, setProductionMonth] = useState(null);
  const [verifiedBy, setVerifiedBy] = useState(null);
  const [instrumentId, setInstrumentId] = useState(null);
  const [mudraList, setMudraList] = useState([]);
  const [issue, setIssue] = useState();

  const showDetailIndex = (index) => {
    setOpenDetail((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMudraList();
      instrumentInService();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage]);

  const getMudraList = () => {
    const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };

  const handleCategoryChange = (selectedCategory) => {
    const filteredNames = mudraList
      .filter((mudra) => mudra.category === selectedCategory)
      .map((mudra) => mudra.label);
    setNameList(filteredNames);
  };

  const instrumentInService = () => {
    let url = `${config.baseUrl}${config.ver}${config.discarded}?limit=${limitPerPage}&page=${currentPage}`;

    if (instrumentCategory != null && instrumentCategory != "") {
      url += "&category=" + instrumentCategory;
    }
    if (selectedName != null && selectedName != "") {
      url += "&label=" + selectedName;
    }
    if (productionYear != null && productionYear != "") {
      url += "&production_year=" + productionYear;
    }
    if (productionMonth != null && productionMonth != "") {
      url += "&production_month=" + productionMonth;
    }
    if (verifiedBy != null && verifiedBy != "") {
      url += "&tested_by=" + verifiedBy;
    }
    if (instrumentId != null && instrumentId != "") {
      url += "&instrument_unique_no=" + instrumentId.trim();
    }

    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setSurgeonList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);

        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  if (
    props.UserInfo.user_type === "mudra-admin" ||
    props.UserInfo.user_type === "admin"
  ) {
    return (
      <div className="main-data-container">
          <h4>Page is under Development and Testing...</h4>{" "}
          <div className="searchnoheader-bar-container">
          <div className="search-bar-keep-right"
            style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", }}
          >
              <select
                style={{ width: 210 }}
                className="search-bar-input-dropdown"
                value={instrumentCategory}
                onChange={(e) => {
                  setInstrumentCategory(e.target.value);
                  handleCategoryChange(e.target.value);
                  setSelectedName("");
                }}
              >
                <option key={""} value={""}> Select Instrument Category </option>
                {ConVar.instrumentCategory.map((inst) => (
                  <option value={inst.value}>{inst.label}</option>
                ))}
              </select>
              <select
                style={{ marginRight: 10, width: 180 }}
                className="search-bar-input-dropdown"
                value={selectedName}
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
              >
                <option key="" value=""> Select Name </option>
                {nameList &&
                  nameList.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
              </select>

              <select
                style={{ marginRight: 10, width: 180 }}
                className="search-bar-input-dropdown"
                value={issue}
                onChange={(e) => {
                  setIssue(e.target.value);
                }}
              >
                <option key={""} value={""}> Issue </option>
                {ConVar.instrumentIssues.map((inst) => (
                  <option value={inst.value}>{inst.label}</option>
                ))}
              </select>
              <select
                style={{ width: 70 }}
                className="search-bar-input-dropdown"
                value={productionYear}
                onChange={(e) => {
                  setProductionYear(e.target.value);
                }}
              >
                <option key={""} value={""}> Year </option>
                {ConVar.years.map((years) => (
                  <option value={years.value}>{years.label}</option>
                ))}
              </select>
              <select
                style={{ width: 100 }}
                className="search-bar-input-dropdown"
                value={productionMonth}
                onChange={(e) => {
                  setProductionMonth(e.target.value);
                }}
              >
                <option key={""} value={""}> Month </option>
                {ConVar.monthsname.map((month) => (
                  <option value={month.value}>{month.label}</option>
                ))}
              </select>
              <select
                style={{ width: 150 }}
                className="search-bar-input-dropdown"
                value={verifiedBy}
                onChange={(e) => {
                  setVerifiedBy(e.target.value);
                }}
              >
                <option key={""} value={""}> Verified By </option>
                <option key="cate-0" value="debasmita.parida@ssinnovations.org"> Debasmita </option>
                <option key="cate-1" value="kunal.indra@ssinnovations.org"> Kunal </option>
              </select>
              <input
                className="search-bar-input-text"
                placeholder="Type Instrument ID "
                value={instrumentId}
                onChange={(e) => {
                  setInstrumentId(e.target.value);
                }}
                type="text"
              />
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  instrumentInService();
                }}
                startIcon={<SearchIcon />}
              >
                <SearchIcon />
              </button>
              <button
                className="search-bar-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setIssue("");
                  setInstrumentCategory("");
                  setProductionYear("");
                  setProductionMonth("");
                  setVerifiedBy("");
                  setInstrumentId("");
                  setSelectedName("");
                  setIsReset(!isReset);
                  instrumentInService();
                }}
              >
                <RestartAltRoundedIcon />
              </button>
            </div>
            <div className="pagination-setup">
              Page
              <Pagination
                className="pagination-setup-button"
                count={totalPages}
                color="primary"
                page={currentPage}
                onChange={(event, value) => {
                  setCurrentPage(value);
                }}
              />
            </div>
          </div>
          <div className="property-list-container">
            <table className="property-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Hospital</th>
                  <th>UID</th>
                  <th>Production Date</th>
                  {/* <th>Issue</th> */}
                  <th>Verified By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="property-table-body">
                {surgeonList.map((row, index) => (
                  <React.Fragment key={row._id}>
                    <tr>
                      <td className="r20start">{row.label}</td>
                      <td className="r10">{row.present_system_no}</td>
                      <td className="r10">{row.instrument_unique_no}</td>
                      {/* <td className="hr1">
                        {" "}
                        { (row && row.issues) &&  <>
                          {row.issues && row.issues[row.issues.length - 1].issue} {" | "}{" "}
                        {row.issues[row.issues.length - 1].description}{" "}
                        </>}
                      </td> */}
                      <td className="r10"> {row.production_date ? convertTimestampToDate(row.production_date) : "N/A"} </td>
                      <td className="r10">{row.tested_by} </td>
                      <td className="r20end">
                        <button onClick={() => showDetailIndex(index)}>
                        {openDetail === index ? (
                          <ExpandLessIcon className="chevron-right-icon" />
                        ) : (
                          <ExpandMore className="expand-more-icon" />
                        )}
                      </button>
                      </td>
                    </tr>
                    {openDetail === index && (
                      <tr className="inner-property-expand">
                        <td colSpan="6" className="inner-details-expand-styling">
                          <Collapse in={openDetail === index}>
                            <DetailInstrument insrumentId={row._id} />
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    );
  } else {
    return <Lock />;
  }
};
