import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import { httpGetReq } from "../../util/request";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import Lock from "../../components/Lock";
import PdfViewer from "../../components/PdfViewer";

import "../../css/Procedure.css";
import { Button } from "@mui/material";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import AddDesks from "../../components/addcomponents/AddDesk";
import EditDesk from "../../components/editcomponents/EditDesk"
import Pagination from "@mui/material/Pagination";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export const InstrumentDeskContainer = (props) => {
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [procedureList, setProcedureList] = useState([]);
  const [procedureCategory, setProcedureCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [openAddProcedureModal, setOpenAddProcedureModal] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [innerList, setInnerList] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProcedureList();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, innerList]);

  const getProcedureList = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.deskList}?limit=${limitPerPage}&page=${currentPage}`;

    if (procedureCategory != undefined && procedureCategory != "") {
      url += "&desk_type=" + procedureCategory;
    }
    setLoading(true);
    httpGetReq(url, "application/json", function (response) {
      setLoading(false);
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setProcedureList(response.data.data);
        setTotalRecords(response.data.total);
        setDataInPage(response.data.data.length);
        setTotalPages(Math.ceil(response.data.total / limitPerPage));
      }
    });
  };

  const toggleExpand = (row) => {
    if (expandedRow === row._id) {
      setExpandedRow(null);
      setInnerList([]);
    } else {
      setExpandedRow(row._id);
      if (expandedRow !== null) {
        setInnerList([]);
      }
      getProcedureSteps(row);
    }
  };

  const getProcedureSteps = (arg) => {
    console.log(arg);
    if (arg && arg.surgery_steps) {
      setInnerList(arg.surgery_steps);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddProcedureModal(false);
    }
  };

  if (true) {
    return (
      <>
        <div className="procedure-container">
        <h2>Under Development</h2>
          <div key="0" className="add-procedure-main-container">
            <Button
              variant="contained"
              
              color="primary"
              onClick={() => setOpenAddProcedureModal(true)}
            >
              <AddHomeRoundedIcon />
              <span style={{ marginLeft: "10px" }}>Add Desk</span>
            </Button>
            <AddDesks open={openAddProcedureModal} onClose={handleClose} />
          </div>

        
          <div className="property-list-container">
            <table className="property-table">
              <thead>
                <tr>
                  <th>Desk Name</th>
                  <th>Desk Type</th>
                  <th>Desk Code</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="property-table-body">
                {procedureList.map((surgery) => (
                  <React.Fragment key={surgery._id}>
                    <tr>
                      <td className="r10start">{surgery.desk_name}</td>
                      <td className="r20"> {ConVar.deskTypeAssArr[ surgery.desk_type ] ? ConVar.deskTypeAssArr[ surgery.desk_type ] : ""} </td>
                      <td className="r30">{surgery.desk_code}</td>
                      <td className="r20end">
                        <button onClick={() => toggleExpand(surgery)}>
                          <InfoRoundedIcon />
                        </button>
                        <EditDesk procedureDetail={surgery} />
                      </td>
                    </tr>
                    {expandedRow === surgery._id && (
                      <tr>
                        <td colSpan="4" className="inner-property-expand">
                          <div className="left-steps">
                            <strong>Steps:</strong> <br />
                            <div>
                              {innerList.map((subUnit, index) => (
                                <>
                                  <span key={index} className="procedure-steps">
                                    {subUnit.label ? subUnit.label : subUnit}
                                  </span>
                                  <br />
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="right-steps-pdf">
                            <PdfViewer />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return <Lock />;
};

export default InstrumentDeskContainer;
