import React, { useState, useEffect } from "react";
import { httpGetReq } from "../../util/request";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../config/configUrl";
import { ConVar } from "../../config/configVar";
import Pagination from "@mui/material/Pagination";
import { convertTimestampToDate } from "../../util/util";
import { fetchUsers } from "../../store/actionCreator";
import ServiceReportPDF from "./ServiceReportPDF";

import "../../css/AllSurgeries.css";
import "../../css/CommonStyle.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import ServiceReportDetails from "./ServiceReportDetails";
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';

const ServiceReportList = (props) => {
  let hospitalName = {};
  const [serviceList, setServiceList] = useState([]);
  const [filterHospitalList, setFilterHospitalList] = useState([]);
  const limitPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataInPage, setDataInPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [zone_name, setZoneName] = useState("all");
  const [hospital, setHospital] = useState();
  const [surgeryYear, setSurgeryYear] = useState();
  const [searchDependency, setSerachDependency] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const [expandedDetailRows, setExpandedDetailRows] = useState({});

  const handleClick = () => {
    if (pathname !== "/service-report") {
      window.location.href = "/service-report";
    }
  };

  if (props.hospitals && props.hospitals.length) {
    props.hospitals.forEach((val) => {
      hospitalName[val.system_no] = val.name;
    });
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllServiceReports();
    }
    return () => {
      mounted = false;
    };
  }, [currentPage, searchDependency]);

  const getAllServiceReports = (arg) => {
    let url = `${config.baseUrl}${config.ver}${config.serviceList}?limit=${limitPerPage}&page=${currentPage}`;
    if (surgeryYear !== "all" && surgeryYear !== undefined) {
      if (url.includes("?")) {
        url += "&surgery_year=" + surgeryYear;
      } else {
        url += "?surgery_year=" + surgeryYear;
      }
    }

    if (hospital !== "all" && hospital !== undefined) {
      if (url.includes("?")) {
        url += "&system_no=" + hospital;
      } else {
        url += "?system_no=" + hospital;
      }
    }

    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setServiceList(response.data.data);
          setTotalRecords(response.data.total);
          setDataInPage(response.data.data.length);
          setTotalPages(Math.ceil(response.data.total / limitPerPage));
        }
      }
    });
  };

  const getFilteredHospitals = (arg) => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?area_zone=" + arg;
    httpGetReq(url, "application/json", function (response) {
      if (response) {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          setFilterHospitalList(response.data.data);
        }
      }
    });
  };

  const toggleRow = (rowId, type) => {
    if (type === "detail") {
      setExpandedDetailRows((prevExpandedDetailRows) => ({
        ...prevExpandedDetailRows,
        [rowId]: !prevExpandedDetailRows[rowId],
      }));
    }
  };

  const handleDownloadPDF = (rowData) => {
    ServiceReportPDF(rowData);
  };  

  return (
    <div className="main-data-container">
       <h4>Page is under Observation and Testing...</h4>
      <div key="0" className="add-data-button-container">
        <button className="add-data-button" onClick={handleClick}>
          <RunningWithErrorsRoundedIcon />
          <span style={{ marginLeft: "10px" }}> Generate Service Report </span>
        </button>
      </div>
      <div key="1" className="search-bar-container">
        <div className="search-bar-keep-right">
          <select           
            value={zone_name}
            onChange={(event) => {
              setZoneName(event.target.value);
              getFilteredHospitals(event.target.value);
            }}
            style={{width:150}}
            className="search-bar-input-dropdown"
          >
            <option key={""} value={"all"}> Zone </option>
            {ConVar.hospitalZone.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select           
            className="search-bar-input-dropdown"
            value={hospital}
            onChange={(e) => {
              setHospital(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Select Hospitals </option>
            {filterHospitalList.map((option) => (
              <option key={option.label} value={option.system_no}>
                {option.name}
              </option>
            ))}
          </select>
          <select           
            value={surgeryYear}
            className="search-bar-input-dropdown"
            style={{width:100}}
            onChange={(e) => {
              setSurgeryYear(e.target.value);
            }}
          >
            <option key={""} value={"all"}> Year </option>
            {ConVar.surgeryYear.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSerachDependency(searchDependency + 1);
              getAllServiceReports();
            }}
            startIcon={<SearchIcon />}
          >
            <SearchIcon />
          </button>
          <button
            className="search-bar-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSurgeryYear("all");
              setHospital("all");
              setSerachDependency(searchDependency + 1);
              getAllServiceReports();
            }}
          >
            <RestartAltRoundedIcon />
          </button>
        </div>
        <div className="pagination-setup">
          Page
          <Pagination
            className="pagination-setup-button"
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
          />
        </div>
      </div>

      <div className="property-list-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Hospital ID</th>
              <th>Report ID</th>
              <th>Date of Service Report</th>
              <th>Service Description</th>
              <th>Type of Visit</th>
              <th>System Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="property-table-body">
            {serviceList.length ? (
              <>
                {serviceList.map((row) => (
                  <>
                    <tr key={row._id}>
                      <td className="r10start" >{row.customer.customer}</td>
                      <td className="r10"> {row.report_no}</td>
                      <td className="r10"> {convertTimestampToDate(row.customer.date)} </td>
                      <td className="r30"> {row.equipment.description} </td>
                      <td className="r10"> {row.visit} </td>
                      <td className="r10"> {row.equipment_status} </td>
                      <td className="r20end">
                        <button onClick={() => toggleRow(row._id, "detail")}>
                          {expandedDetailRows[row._id] ? (
                            <ExpandLessIcon className="chevron-right-icon" />
                          ) : (
                            <ExpandMore className="expand-more-icon" />
                          )}
                        </button>
                        <button type="button" className="red-button" onClick={() => handleDownloadPDF(row)}>
                        <CloudDownloadRoundedIcon />
                        </button>
                      </td>
                    </tr>
                    {expandedDetailRows[row._id] && (
                      <tr>
                        <td colspan="8" className="inner-row-surgery-details-upper">
                          <ServiceReportDetails serviceId={row._id} />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div className="no-match-found">No Match Found 😁</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    hospitals: state.hospitals,
    userDetails: state.userDetails,
    procedures: state.procedures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (callback) => fetchUsers(dispatch, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceReportList);
