import React from "react";
import { Dialog, Checkbox, FormControlLabel } from "@mui/material";

const EndTaskModal = ({
  observation,
  observedBy,
  correctPlan,
  suggestedBy,
  changesDoneBy,
  verifiedBy,
  isSolved,
  handleInputChange,
  handleSubmit,
  handleClose,
}) => {

  return (
    <Dialog open={true} onClose={handleClose} className="addproperty-dialog">
      <div className="addproperty-container">
        <div className="addproperty-form-title">End Task</div>
        <div className="addproperty-form">
          <div className="addproperty-form-control">
            <label>Observation</label>
            <input
              type="text"
              name="observation"
              className="addproperty-text-field"
              placeholder="Observation"
              variant="outlined"
              value={observation}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Observed By</label>
            <input
              type="text"
              name="observedBy"
              className="addproperty-text-field"
              placeholder="Observed By"
              variant="outlined"
              value={observedBy}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Correct Plan</label>
            <input
              type="text"
              name="correctPlan"
              className="addproperty-text-field"
              placeholder="Correct Plan"
              variant="outlined"
              value={correctPlan}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Suggested By</label>
            <input
              type="text"
              name="suggestedBy"
              className="addproperty-text-field"
              placeholder="Suggested By"
              variant="outlined"
              value={suggestedBy}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Changes Done By</label>
            <input
              type="text"
              name="changesDoneBy"
              className="addproperty-text-field"
              placeholder="Changes Done By"
              variant="outlined"
              value={changesDoneBy}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="addproperty-form-control">
            <label>Verified By</label>
            <input
              type="text"
              name="verifiedBy"
              className="addproperty-text-field"
              placeholder="Verified By"
              variant="outlined"
              value={verifiedBy}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                id="isSolved"
                name="isSolved"
                checked={isSolved}
                onChange={() => handleInputChange({ target: { name: 'isSolved', value: !isSolved } })}
              />
            }
            label="Solved"
          />
        </div>
        <div className="addproperty-dialog-action">
          <button
            type="button"
            className="addhospital-button"
            onClick={handleSubmit}
          >
            Submit and End Task
          </button>
          <button type="button" className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EndTaskModal;
