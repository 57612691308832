import React, { useEffect, useState } from "react";
import { convertTimestampToDate, millisecondsToTime,millisecondsToTime2 ,convertTimestampToDateTime,convertMillisecondsToHrMin } from "../util/util";
import { httpGetReq } from "../util/request";
import config from "../config/configUrl";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

const SurgeryDetails = ({ surgeryId }) => {
  const [surgeryDetail, setSurgeryDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mudraList, setMudraList] = useState([]);

  // const instrumentsToString = (arr) => {
  //   let formattedInstruments = arr.map((item) => {
  //     if (item.instrument_used) {
  //       return getInstrumentLabel(item.instrument_used.trim());
  //     }
  //     return '';
  //   });
  //   return formattedInstruments.join(', ');
  // };

  // const getInstrumentLabel = (instrumentKey) => {
  //   const instrument = mudraList.find(item => item.key === instrumentKey);
  //   return instrument ? instrument.label : instrumentKey;
  // };


    const downloadcsv = (datj) => {
      function convertToCSV() {
        let csv = `Patient Serial No,Case No,Patient Id,Patient Name,Gender,Age,Weight,Height,BMI,Diagnosis,\n`; // Header row       
        csv += `${datj.patient_info.serial_no},${datj.patient_info.case_no},${datj.patient_info.patient_id},${datj.patient_info.patient_name},${datj.patient_info.gender},${datj.patient_info.age},${datj.patient_info.weight},${datj.patient_info.height},${datj.patient_info.bmi},${datj.patient_info.diagnosis}        \n`; // Adding each student's data       
        csv += `\n`; // Header row 
        csv += `Mantra System Operating\n`; // Header row 
        csv += `Start,End,Duration\n`; 
        csv += `${convertTimestampToDateTime(datj.mantra_system_operating_info.on_time)},${convertTimestampToDateTime(datj.mantra_system_operating_info.off_time)},${millisecondsToTime(datj.mantra_system_operating_info.duration)}\n`; // Adding each student's data                          
       
        // csv += `Mantra Intubation\n`; // Header row 
        // csv += `Start,End,Duration(H:M)\n`; 
        // csv += `${millisecondsToTime2(datj.patient_intubation.on_time)},${millisecondsToTime2(datj.patient_intubation.off_time)},${millisecondsToTime(datj.dock.duration)}\n`; // Adding each student's data                          
         return csv;
    }
    
    function downloadCSV(csvString, fileName) {
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    

    const csvString = convertToCSV();
    const fileName = "surgeryDetail.csv";
    downloadCSV(csvString, fileName);
    
  };
  


  const instrumentsToString = (arr) => {
    let formattedInstruments = arr.map((item) => {
      if (item.instrument_used) {
        const instrumentLabel = getInstrumentLabel(item.instrument_used.trim());
        const instrumentID =
          item.instrument_id !== undefined ? `-${item.instrument_id}` : "";
        return `${instrumentLabel}${instrumentID}`;
      }
      return "";
    });
    return formattedInstruments.join(", ");
  };

  const getInstrumentLabel = (instrumentKey) => {
    const instrument = mudraList.find((item) => item.key === instrumentKey);
    return instrument ? instrument.short : instrumentKey;
  };

  useEffect(() => {
    getMudraList();
  }, []);

  useEffect(() => {
    const getSurgeryDetail = () => {
      let url =
        config.baseUrl + config.ver + config.surgeriesDetail + surgeryId;
      httpGetReq(url, "application/json", function (response) {
        if (response) {
          if (
            response.data &&
            response.data.status &&
            response.data.status === "success"
          ) {
            setSurgeryDetail(response.data.data);
          } else {
            setError("Failed to fetch surgery details");
          }
          setLoading(false);
        } else {
          setError("Failed to fetch surgery details");
          setLoading(false);
        }
      });
    };
    getSurgeryDetail();
    return () => {};
  }, [surgeryId]);

  const getMudraList = () => {
    const url = config.baseUrl + config.ver + config.mudraList + "?limit=1000";
    httpGetReq(url, "application/json", (response) => {
      if (response.data && response.data.status === "success") {
        setMudraList(response.data.data);
      } else {
        console.error("Error fetching instrument list:", response.data.message);
      }
    });
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;



  return (
    <div className="inner-row-surgery-details">
      <button onClick={()=>{ downloadcsv(surgeryDetail); }} > <DownloadForOfflineRoundedIcon /></button>
      <div className="container-details-row">
        {surgeryDetail.patient_info &&
          surgeryDetail.patient_info.patient_id && (
            <span className="surgery-detail-span">
              <label>Patient Id:</label>
              <input
                type="text"
                value={surgeryDetail.patient_info.patient_id}
                disabled
              />
            </span>
          )}

        {surgeryDetail.patient_info && surgeryDetail.patient_info.case_no && (
          <span className="surgery-detail-span">
            <label>Case No :</label>
            <input
              type="text"
              value={surgeryDetail.patient_info.case_no}
              disabled
            />
          </span>
        )}

        {surgeryDetail.system_no && (
          <span className="surgery-detail-span">
            <label>Hospital :</label>
            <input type="text" value={surgeryDetail.system_no} disabled />
          </span>
        )}

        {surgeryDetail.surgeon_name && surgeryDetail.surgeon_name[0] && (
          <span className="surgery-detail-span">
            <label>Surgeon :</label>
            <input
              type="text"
              value={surgeryDetail.surgeon_name[0].label}
              disabled
            />
          </span>
        )}

        {surgeryDetail.procedure_code && (
          <span className="surgery-detail-span">
            <label>Procedure :</label>
            <input type="text" value={surgeryDetail.procedure_code} disabled />
          </span>
        )}

        {surgeryDetail.surgery_specification && (
          <span className="surgery-detail-span">
            <label>Surgery Specification :</label>
            <input type="text" value={surgeryDetail.surgery_specification} disabled />
          </span>
        )}

        {surgeryDetail.patient_info && surgeryDetail.patient_info.bmi && (
          <span className="surgery-detail-span">
            <label>BMI :</label>
            <input
              type="text"
              value={parseFloat(surgeryDetail.patient_info.bmi.toPrecision(4))}
              disabled
            />
          </span>
        )}

        {surgeryDetail.date_of_surgery && (
          <span className="surgery-detail-span">
            <label>Date of Surgery :</label>
            <input
              type="text"
              value={convertTimestampToDate(surgeryDetail.date_of_surgery)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.surgery && surgeryDetail.surgery.duration && (
          <span className="surgery-detail-span">
            <label>Surgery Duration :</label>
            <input
              type="text"
              value={millisecondsToTime(surgeryDetail.surgery.duration)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.port_placement &&
          surgeryDetail.port_placement.duration && (
            <span className="surgery-detail-span">
              <label>Port Placement Duration :</label>
              <input
                type="text"
                value={millisecondsToTime(
                  surgeryDetail.port_placement.duration
                )}
                disabled
              />
            </span>
          )}

        {surgeryDetail.second_port_placement &&
          surgeryDetail.second_port_placement.duration && (
            <span className="surgery-detail-span">
              <label>2nd Port Placement Duration :</label>
              <input
                type="text"
                value={millisecondsToTime(
                  surgeryDetail.second_port_placement.duration
                )}
                disabled
              />
            </span>
          )}

        {surgeryDetail.dock && surgeryDetail.dock.duration && (
          <span className="surgery-detail-span">
            <label>Docking Duration :</label>
            <input
              type="text"
              value={millisecondsToTime(surgeryDetail.dock.duration)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.second_dock && surgeryDetail.second_dock.duration && (
          <span className="surgery-detail-span">
            <label>2nd Docking Duration :</label>
            <input
              type="text"
              value={millisecondsToTime(surgeryDetail.second_dock.duration)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.console && surgeryDetail.console.duration && (
          <span className="surgery-detail-span">
            <label>Console Duration :</label>
            <input
              type="text"
              value={millisecondsToTime(surgeryDetail.console.duration)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.undock && surgeryDetail.undock.duration && (
          <span className="surgery-detail-span">
            <label>Undock Duration :</label>
            <input
              type="text"
              value={millisecondsToTime(surgeryDetail.undock.duration)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.second_undock &&
          surgeryDetail.second_undock.duration && (
            <span className="surgery-detail-span">
              <label>2nd Undock Duration :</label>
              <input
                type="text"
                value={millisecondsToTime(surgeryDetail.second_undock.duration)}
                disabled
              />
            </span>
          )}

        {surgeryDetail.post_surgery_info &&
          surgeryDetail.post_surgery_info.length_of_stay_days && (
            <span className="surgery-detail-span">
              <label>Length of stay :</label>
              <input
                type="text"
                value={surgeryDetail.post_surgery_info.length_of_stay_days}
                disabled
              />
            </span>
          )}

        {surgeryDetail.instruments && surgeryDetail.instruments.used && (
          <span className="surgery-detail-span">
            <label>Instrument Used :</label>
            <input
              type="text"
              value={instrumentsToString(surgeryDetail.instruments.used)}
              disabled
            />
          </span>
        )}

        {surgeryDetail.blood_loss && (
            <span className="surgery-detail-span">
              <label>Blood Loss (ml) :</label>
              <input
                type="text"
                value={surgeryDetail.blood_loss}
                disabled
              />
            </span>
          )}

        {surgeryDetail.blood_transfusion && (
          <span className="surgery-detail-span">
            <label>Blood Transfusion :</label>
            <input
              type="text"
              value={surgeryDetail.blood_transfusion}
              disabled
            />
          </span>
        )}

        {surgeryDetail.co_morbidities && (
          <span className="surgery-detail-span">
            <label>Co-morbidities :</label>
            <input type="text" value={surgeryDetail.co_morbidities} disabled />
          </span>
        )}

        {surgeryDetail.mortality && (
          <span className="surgery-detail-span">
            <label>Mortality :</label>
            <input type="text" value={surgeryDetail.mortality} disabled />
          </span>
        )}

        {surgeryDetail.case_conversion && (
          <span className="surgery-detail-span">
            <label>Case Conversion :</label>
            <input type="text" value={surgeryDetail.case_conversion} disabled />
          </span>
        )}

        {surgeryDetail.post_surgery_info &&
          surgeryDetail.post_surgery_info.post_discharge_complications && (
            <span className="surgery-detail-span">
              <label>Post Discharge Complication :</label>
              <input
                type="text"
                value={
                  surgeryDetail.post_surgery_info.post_discharge_complications
                }
                disabled
              />
            </span>
          )}

        {surgeryDetail.no_of_arms && (
          <span className="surgery-detail-span">
            <label>No. of ARMS Used :</label>
            <input type="text" value={surgeryDetail.no_of_arms} disabled />
          </span>
        )}

        {surgeryDetail.entry_remarks_info && (
          <span className="surgery-detail-span">
            <label>Clinical User Remarks :</label>
            <input
              type="text"
              value={surgeryDetail.entry_remarks_info}
              disabled
            />
          </span>
        )}

        {surgeryDetail.updatedBy && (
          <span className="surgery-detail-span">
            <label>Updated By :</label>
            <input type="text" value={surgeryDetail.updatedBy} disabled />
          </span>
        )}

      </div>
    </div>
  );
};

export default SurgeryDetails;
