import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
import { BarChart } from '@mui/x-charts';

export default function ChartsOverviewDemo(props) {

  if(props.data.length){
    let xDAta =[],yData=[];
    props.data.map(myFunction); 
    function myFunction(item) {
      xDAta.push(item._id);
      yData.push(item.count)
  }
    return (
      <BarChart
        series={[
          // { data: [35, 44, 24, 34] },
          // { data: [51, 6, 49, 30] },
          // { data: [15, 25, 30, 50] },
          { data: yData },
        ]}
        height={200}
        xAxis={[{ data: xDAta, scaleType: 'band' }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    );
  }else{
    return (<>Loading</>
     
      
    );
  }
  
  
}