import React, { useEffect, useState } from "react";
import config from "../../config/configUrl";
import { httpGetReq, httpPostReq } from "../../util/request";

import "../../css/CallGenerate.css";
import "../../css/AddUser.css";
import "../../css/CommonStyle.css";
import ReactSelect from "react-select";
import { Dialog } from "@mui/material";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";

const AddCall = (props) => {
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [systemNo, setSystemNo] = useState("");
  const [callName, setCallName] = useState("");
  const [extraDesc, setExtraDesc] = useState("");
  const [hospital_name, setHospitalName] = useState("");
  const [hospitalMap, setHospitalMap] = useState({});
  const [hospital_list, setHospitalList] = useState([]);
  const [enginer_list, setEngineerList] = useState([]);
  const [btn_lablel, setBtn_lable] = useState("Add Call");
  const [engineer_name, setEngineerName] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  let taskCreateUrl = config.baseUrl + config.ver + config.callNew;

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddUserModal(false);
    }
  };

  const SelectBox2 = ({ label_, options, value_, onChange }) => {
    return (
      <ReactSelect
        className="addproperty-multiselect"
        onChange={(selectedOption) => onChange(selectedOption)}
        value={value_}
        isMulti
        options={options.map((option) => ({
          label: option.name,
          value: option.emp_email,
        }))}
      />
    );
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getHospitalList();
      getServiceEngineerList();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (engineer_name.length) {
      setBtn_lable("Assign Diagnos Task");
    }
  }, [engineer_name]);

  const getHospitalList = () => {
    let url = config.baseUrl + config.ver + config.hospitalList + "?limit=200";

    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        let map = {};
        response.data.data.forEach((hospital) => {
          map[hospital.system_no] = {
            name: hospital.name,
            area_zone: hospital.area_zone,
            system_no: hospital.system_no,
            location: hospital.location,
          };
        });
        setHospitalMap(map);
        setHospitalList(response.data.data);
      }
    });
  };

  const getServiceEngineerList = () => {
    let url = config.baseUrl + config.ver + config.userList + "?user_type=service-engineer&limit=200";
    httpGetReq(url, "application/json", function (response) {
      if (
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        setEngineerList(response.data.data);
      }
    });
  };

  const handleSubmit = async () => {
    if (callName == null) {
      setError("Give purose of call name :)");
      return false;
    } else if (hospital_name == null) {
      setError("Select hostpital :)");
      return false;
    }
    let hospitlal_ = hospitalMap[hospital_name];
    let postBodyObj = {
      call_name: callName,
      call_created_by: props.UserInfo.emp_email,
      call_creation_date: Date.now(),
      system_no: hospital_name,
      call_status: 1,
      location: hospitlal_.location,
      area_zone: hospitlal_.area_zone,
      last_updated: Date.now(),
      extra_desc: extraDesc,
    };
    httpPostReq(taskCreateUrl, postBodyObj, "application/json", (response) => {
      if (response.code === "ERR_BAD_REQUEST") {
        setError("Unable to generate call ,Try after sometime :)");
      } else if (response && response.data && response.data.status === "fail") {
        setError("Unable to generate call ,Try after sometime :)");
      } else if (response.data.status === "success") {
        setCallName(null);
        setSystemNo(null);
        setExtraDesc(null);
        window.location.reload(true);
      }
    });
  };

  return (
    <>
      <button
        className="add-data-button"
        onClick={() => setOpenAddUserModal(true)}
      >
        <PersonAddAltRoundedIcon />
        <span style={{ marginLeft: "10px" }}>Generate Call</span>
      </button>
      <Dialog open={openAddUserModal} onClose={handleClose} className="addproperty-dialog" >
        {warning && <div className="error-message">{warning}</div>}
        {message && <div className="success-message">{message}</div>}
        <div className="addproperty-container">
          <div className="addproperty-form-title">Generate Call</div>
          <div className="addproperty-form">
            <div className="addproperty-form-control">
              <label>Call Name</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Call Name"
                variant="outlined"
                value={callName}
                onChange={(e) => setCallName(e.target.value)}
              />
            </div>
            <div className="addproperty-form-control">
              <label>Select Hospital</label>
              <select
                label="Select Hospital"
                value_={hospital_name}
                onChange={(e) => {
                  setHospitalName(e.target.value);
                }}
                disabled={props.data && props.data.system_no ? true : false}
                className="addproperty-select"
              >
                <option key={""} value={""}>
                  Select Hospital
                </option>
                {hospital_list.map((option) => (
                  <option key={option.system_no} value={option.system_no}>
                    {option.name}, {option.location}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="addproperty-form-control">
              <label>Select Engineer</label>
              <SelectBox2
                label_={"Select Engineer"}
                options={enginer_list}
                value_={engineer_name}
                onChange={(selectedOptions) => {
                  setEngineerName(selectedOptions);
                  if (selectedOptions.length > 0) {
                    setBtn_lable("Assign Diagnos Task");
                  } else {
                    setBtn_lable("Add Call");
                  }
                }}
              />
            </div> */}
            <div className="addproperty-form-control">
              <label>Extra Comment Desc.</label>
              <input
                type="text"
                className="addproperty-text-field"
                placeholder="Extra Comment Desc."
                value={extraDesc}
                onChange={(e) => {
                  setExtraDesc(e.target.value);
                }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="addproperty-dialog-action">
            <button type="button" className="addproperty-button" onClick={handleSubmit} > Add Call </button>
            <button type="button" className="cancel-button" onClick={() => { setCallName(null); setSystemNo(null); setExtraDesc(null); handleClose(); }} > Cancel </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddCall;
